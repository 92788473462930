import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingIndicator from "../../components/LoadingIndicator";
import webService from "../../services/web.service";
import { toast } from "react-hot-toast";
import SlideOver from "./SlideOver";
import AdvisorEdit from "./AdvisorEdit";

function Advisor({ language }) {
  const { token, id_config } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [advisors, setAdvisors] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [advisorEdit, setAdvisorEdit] = React.useState();

  React.useEffect(() => {
    if (id_config) fetchAdvisor();
  }, [language]);

  const fetchAdvisor = async () => {
    setLoading(true);
    webService.getAdvisors(token, id_config, language.value).then(
      (result) => {
        const status = result.status;
        if (status === 200) {
          setAdvisors(result.data);
        } else {
          toast.error("Si è verificato un errore");
        }
        setLoading(false);
      },
      (error) => {
        toast.error("Si è verificato un errore");
        setLoading(false);
      }
    );
  };
  const editAdvisor = async (advisor) => {
    setOpen(true);
    setAdvisorEdit(advisor);
  };
  const createAdvisor = async () => {
    setAdvisorEdit({
      id_advisor: null,
      id_config: id_config,
      action_url: "",
      translation_text_obj: {
        language: language.value,
        text: "",
      },
      action_text_obj: {
        language: language.value,
        text: "",
      },
      title_text_obj: {
        language: language.value,
        text: "",
      },
    });
    setOpen(true);
  };

  const SingleAdvisor = ({ advisor }) => (
    <div className="rounded-lg bg-white shadow p-5">
      <div className="flex flex-row items-center">
        <h2 className="font-semibold text-lg">
          Annuncio #{advisor.id_advisor}
        </h2>

        <span
          className="ml-auto cursor-pointer text-indigo-600 
                px-5 py-2 text-base font-medium 
                 hover:text-indigo-700"
          onClick={() => editAdvisor(advisor)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
            />
          </svg>
        </span>
      </div>
      <div className="mt-5">
        <div className="flex flex-row w-full">
          <div className="w-1/3 font-semibold text-gray-500">Titolo:</div>
          <div className="w-2/3 font-semibold">
            {advisor.title_text_obj.text}
          </div>
        </div>

        <div className="flex flex-row w-full mt-1">
          <div className="w-1/3 font-semibold text-gray-500">
            Corpo Annuncio:
          </div>
          <div className="w-2/3">{advisor.translation_text_obj.text}</div>
        </div>
        <div className="flex flex-row w-full mt-1">
          <div className="w-1/3 font-semibold text-gray-500">Azione:</div>
          <div className="w-2/3">{advisor.action_text_obj.text}</div>
        </div>
        <div className="flex flex-row w-full mt-1">
          <div className="w-1/3 font-semibold text-gray-500">Url Azione:</div>
          <div className="w-2/3">{advisor.action_url}</div>
        </div>
      </div>
    </div>
  );
  return (
    <>
      <SlideOver open={open} setOpen={setOpen}>
        <AdvisorEdit
          advisor={advisorEdit}
          advisors={advisors}
          setAdvisor={setAdvisorEdit}
          setAdvisors={setAdvisors}
          setOpen={setOpen}
        />
      </SlideOver>

      {loading ? (
        <div className="w-full h-full flex items-center justify-center">
          <LoadingIndicator />
        </div>
      ) : (
        <div className="bg-gray-100">
          <div className="pt-5 pr-5 w-full flex justify-end">
            <button
              className={`rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
              onClick={createAdvisor}
              type="button"
            >
              Nuovo Annuncio
            </button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 p-5">
            {advisors?.map((advisor, index) => (
              <SingleAdvisor advisor={advisor} key={"adv-" + index} />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default Advisor;
