import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import React from "react";
import { BootstrapButtonForward } from "./button";

export default function RoomsSelectButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (id_room) => {
    props.setRooms(id_room);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ padding: "10px", fontWeight: "600" }}>
          Seleziona le stanze
        </Typography>
        {
          <List
            sx={{
              width: "100%",
              maxWidth: 360,
              maxHeight: "300px",
              bgcolor: "background.paper",
            }}
          >
            {props.rooms && props.rooms.length > 0 && (
              <>
                {props.rooms.map((room) => {
                  const labelId = `checkbox-list-label-${room.id_room}`;

                  return (
                    <ListItem key={"room-" + room.id_room} disablePadding>
                      <ListItemButton
                        role={undefined}
                        onClick={() => handleToggle(room.id_room)}
                        dense
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={room.is_checked}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={room.name} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </>
            )}
          </List>
        }
      </Popover>

      <Typography
        className="custom-input-label"
        sx={{
          color: "#F1F5FF",
          display: { xs: "none", lg: "block" },
        }}
      >
        seleziona stanze
      </Typography>
      <BootstrapButtonForward
        onClick={handleClick}
        style={{ width: "100%", height: "51px" }}
      >
        Seleziona le stanze
      </BootstrapButtonForward>
    </>
  );
}
