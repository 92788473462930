import { Button } from "antd";
import React from "react";

function InformationFooter() {
  return (
    <div className="mt-auto flex flex-col">
      <div className="border-gray-200 border-b w-full" />
      <div className="flex flex-row items-center gap-0 py-1 overflow-auto">
        <Button type="link" className="!text-[11px] sm:!text-xs">
          Policy rimborso
        </Button>
        <Button type="link" className="!text-[11px] sm:!text-xs">
          Privacy policy
        </Button>
        <Button type="link" className="!text-[11px] sm:!text-xs">
          Termini di servizio
        </Button>
        <Button type="link" className="!text-[11px] sm:!text-xs">
          Termini di iscrizione
        </Button>
      </div>
    </div>
  );
}

export default InformationFooter;
