import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import preventiviService from "../../../services/preventivi.service";
import { Spin } from "antd";
import { Margined } from "../../prices/partials/styles";

export default function PreventivoSuccess() {
  const { uuid } = useParams();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (uuid) fetchConfirmMessage();
  }, []);

  const fetchConfirmMessage = async () => {
    setLoading(true);
    preventiviService.getConfirmPreventivo(uuid).then(
      (result) => {
        if (result.status === 200) {
          setMessage(result.data);
        }
        setLoading(false);
      },
      (error) => {
        navigate("/error", {
          replace: true,
          state: {
            error:
              "Si è verificato un errore inaspettato. Contattare la struttura",
          },
        });
      }
    );
  };
  return (
    <Spin spinning={loading}>
      <Margined>
        <div className="container-success">
          {!loading && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CheckCircleIcon sx={{ color: "#3BB90D", fontSize: "6rem" }} />
              </Grid>
              <Grid item xs={12}>
                <h3>Hai confermato il tuo preventivo!</h3>
                <div>
                  {message ? (
                    message
                  ) : (
                    <div>
                      Ti ringraziamo per aver confermato il preventivo. A breve
                      riceverai una mail con tutti i dettagli.
                      <br></br>
                      <br></br>
                      E' possibile chiudere questa finestra.
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          )}
        </div>
      </Margined>
    </Spin>
  );
}
