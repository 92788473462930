import * as React from "react";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import DynamicHeroIcon from "../../../components/heroicons/hicon";

export default function VariantButton(props) {
  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) props.onSelect(newAlignment);
  };

  return (
    <ToggleButtonGroup
      value={props.value}
      onChange={handleAlignment}
      color="primary"
      exclusive
    >
      <ToggleButton value="%">
        <span style={{ fontSize: "16px", fontWeight: 800 }}>&#37;</span>
      </ToggleButton>
      <ToggleButton value="€">
        <span style={{ fontSize: "16px", fontWeight: 800 }}>&euro;</span>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
