import axios from "axios";

//const API_URL = "http://localhost:3001";
const API_URL = "https://localhost:44392/api/pay/";

class PaymentService {
  getSelectCountries() {
    return axios.get(API_URL + "select/countries");
  }
  getCheckout(uuid) {
    return axios.get(API_URL + "checkout/" + uuid);
  }
  saveCheckout(uuid, checkout) {
    return axios.post(API_URL + "checkout/info/" + uuid, checkout);
  }
  getOnlinePayment(uuid) {
    return axios.get(API_URL + "payment/card/check/" + uuid);
  }
}
export default new PaymentService();
