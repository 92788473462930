import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import common_it from "./translations/it/common.json";
import common_en from "./translations/en/common.json";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "it",
  resources: {
    en: {
      common: common_en,
    },
    it: {
      common: common_it,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>
);
