import React from "react";
import webService from "../../services/web.service";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import LoadingIndicator from "../../components/LoadingIndicator";
import BannerAddImage from "./BannerImagesAdd";

function Banner({ language }) {
  const { token, id_config } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [banners, setBanners] = React.useState([]);

  React.useEffect(() => {
    if (id_config) fetchBanner();
  }, [language]);

  const fetchBanner = async () => {
    setLoading(true);
    webService.getBanners(token, id_config).then(
      (result) => {
        const status = result.status;
        if (status === 200) {
          setBanners(result.data);
        } else {
          toast.error("Si è verificato un errore");
        }
        setLoading(false);
      },
      (error) => {
        toast.error("Si è verificato un errore");
        setLoading(false);
      }
    );
  };
  const deleteBanner = (id_banner) => {
    webService.deleteBannerImage(token, id_banner).then(
      (result) => {
        const status = result.status;
        if (status === 200) {
          toast.success("La foto è stata eliminata.");
          setBanners(
            banners.filter((banner) => banner.id_banner !== id_banner)
          );
        } else {
          toast.error("Si è verificato un errore");
        }
      },
      (error) => {
        toast.error("Si è verificato un errore");
      }
    );
  };

  const SingleBanner = ({ banner }) => (
    <div className="rounded-lg bg-white shadow p-5">
      <div className="flex flex-row items-center">
        <h2 className="font-semibold text-lg">Banner #{banner.id_banner}</h2>

        <span
          className="ml-auto cursor-pointer text-indigo-600 
                px-5 py-2 text-base font-medium 
                 hover:text-indigo-700"
          onClick={() => {
            if (window.confirm("Eliminare la foto selezionata?")) {
              deleteBanner(banner.id_banner);
            }
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </span>
      </div>
      <div className="mt-5">
        <img
          src={banner.image_url}
          alt={banner.alt_image}
          className="object-cover w-92 h-72 rounded-lg mt-5"
          loading="lazy"
        />
      </div>
    </div>
  );
  return (
    <>
      {loading ? (
        <div className="w-full h-full flex items-center justify-center">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <BannerAddImage
            open={open}
            setOpen={setOpen}
            banners={banners}
            setBanners={setBanners}
          />
          <div className="bg-gray-100">
            <div className="pt-5 pr-5 w-full flex justify-end">
              <button
                className={`rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                onClick={() => setOpen(true)}
                type="button"
              >
                Aggiungi Immagini
              </button>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5 p-5">
              {banners?.map((banner, index) => (
                <SingleBanner banner={banner} key={"banner-" + index} />
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Banner;
