import React, { useEffect, useState } from "react";
import LoadingIndicator from "../../components/LoadingIndicator";
import { Badge, Button, Result, Tag } from "antd";
import { StyleProvider } from "@ant-design/cssinjs";
import Scanner from "./Scanner";
import ocrService from "../../services/ocr.service";
import { useParams } from "react-router-dom";

function Ocr() {
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [isScanning, setIsScanning] = useState(false);
  const [info, setInfo] = useState({});
  const [error, setError] = useState("");

  useEffect(() => {
    fetchOcr();
  }, []);

  const fetchOcr = () => {
    setError("");
    setLoading(true);
    ocrService.getOcr(token).then(
      (result) => {
        result = result.data;
        setInfo(result);
        setLoading(false);
      },
      (error) => {
        if (error.response.data.ExceptionMessage) {
          setError(error.response.data.ExceptionMessage);
        } else setError("Si è verificato un errore imprevisto.");

        setLoading(false);
      }
    );
  };

  return (
    <div className="w-screen h-screen flex items-center justify-center">
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          {error ? (
            <Result status="error" title="Errore" subTitle={error} />
          ) : (
            <StyleProvider hashPriority="high">
              <div className="w-[600px] h-auto relative">
                <img
                  src="/assets/images/ocr_logo.png"
                  className="mx-auto w-[200px]"
                />
                <Tag
                  className="!font-semibold absolute top-0 right-0 !rounded-full"
                  color="purple"
                >
                  {info.total_scans - info.used_scans}
                </Tag>
                <div
                  className=" rounded-lg items-center justify-center h-full
                flex flex-col"
                >
                  {!isScanning ? (
                    <Button type="primary" onClick={() => setIsScanning(true)}>
                      Inizia
                    </Button>
                  ) : (
                    <div className="flex flex-col items-center justify-center gap-3">
                      <Scanner info={info} setInfo={setInfo} />
                    </div>
                  )}
                </div>
              </div>
            </StyleProvider>
          )}
        </>
      )}
    </div>
  );
}

export default Ocr;
