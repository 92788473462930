import React, { useRef, useState } from "react";
import {
  Alert,
  Button,
  Divider,
  Form,
  Tooltip,
  Typography,
  Modal,
  Input,
  Spin,
  Popconfirm,
  Popover,
} from "antd";
import { Card } from "antd";
import dayjs from "dayjs";
import PreventivoCardRow from "./PreventivoCardRow";
import TempisticheHelper from "../Helpers/TempisticheHelper";
import PreventivoDueDate from "../PreventivoDueDate";

function PreventivoTempistiche({
  preventivo,
  UpdatePreventivo,
  setPreventivo,
}) {
  return (
    <Card
      title={
        <div className="flex flex-row items-center gap-3">
          Tempistiche <TempisticheHelper />
        </div>
      }
      bordered={false}
    >
      <Form
        labelCol={{
          span: 6,
        }}
        layout="horizontal"
      >
        <PreventivoCardRow
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 text-slate-600"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
              />
            </svg>
          }
          label={"Scadenza"}
          text={
            <PreventivoDueDate
              preventivo={preventivo}
              setPreventivo={setPreventivo}
              UpdatePreventivo={UpdatePreventivo}
            />
          }
          value={preventivo.due_date}
        />
        <PreventivoCardRow
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 text-slate-600"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
              />
            </svg>
          }
          label={"Creato il"}
          text={
            <span className="capitalize text-slate-500 ml-2">
              {dayjs(preventivo.creation_date).format("dddd, DD MMM YY, HH:mm")}
            </span>
          }
          value={preventivo.creation_date}
        />
        {preventivo.seen_date && (
          <PreventivoCardRow
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5 text-slate-600"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            }
            label={"Visualizzato il"}
            text={
              <span className="capitalize text-slate-500 ml-2">
                {dayjs(preventivo.seen_date).format("dddd, DD MMM YY, HH:mm")}
              </span>
            }
            value={preventivo.seen_date}
          />
        )}

        {preventivo.confirmed_date && preventivo.status === 2 && (
          <PreventivoCardRow
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                class="w-5 h-5 text-green-600"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
              </svg>
            }
            label={"Confermato il"}
            text={
              <span className="capitalize text-green-500 font-semibold ml-2">
                {dayjs(preventivo.confirmed_date).format(
                  "dddd, DD MMM YY, HH:mm"
                )}
              </span>
            }
            value={preventivo.confirmed_date}
          />
        )}

        {preventivo.confirmed_date && preventivo.status === 3 && (
          <PreventivoCardRow
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                class="w-5 h-5 text-red-600"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                />
              </svg>
            }
            label={"Rifiutato il"}
            text={
              <span className="capitalize text-red-500">
                {dayjs(preventivo.confirmed_date).format(
                  "dddd, DD MMM YY, HH:mm"
                )}
              </span>
            }
            value={preventivo.confirmed_date}
          />
        )}

        {preventivo.checkin_time &&
          preventivo.rows?.filter((el) => el.id_category).length >= 1 && (
            <PreventivoCardRow
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5 text-slate-600"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              }
              label={"Orario previsto del checkin"}
              text={
                <span className="capitalize text-slate-500 ml-2">
                  {preventivo.checkin_time}
                </span>
              }
              value={preventivo.checkin_time}
            />
          )}
      </Form>
    </Card>
  );
}

export default PreventivoTempistiche;
