import React from "react";
import { Link, useParams } from "react-router-dom";
import LoadingIndicator from "../../components/LoadingIndicator";
import webService from "../../services/web.service";
import { Toaster } from "react-hot-toast";

function WebsitePanel() {
  const { token } = useParams();
  const [isLoading, setIsLoading] = React.useState(true);
  const [configs, setConfigs] = React.useState([]);

  React.useEffect(() => {
    document.title = "";
    fetchConfiguration();
  }, []);

  const fetchConfiguration = async () => {
    setIsLoading(true);
    webService.getConfigs(token).then(
      (result) => {
        result = result.data;
        setConfigs(result);
        setIsLoading(false);
      },
      (error) => {}
    );
  };

  return (
    <div className="w-full h-screen flex flex-col md:flex-row items-center bg-white">
      <div className="w-full h-full">
        {isLoading ? (
          <div className="w-full h-full flex items-center justify-center">
            <LoadingIndicator />
          </div>
        ) : (
          <div className="flex flex-col">
            <div className="w-full p-2 flex justify-end">
              <Link
                to={"/website/config/edit/" + token}
                className="
                cursor-pointer
                justify-center rounded-md border 
                border-transparent bg-indigo-600 
                px-5 py-2 text-base font-medium 
                text-white shadow-sm hover:bg-indigo-700
            "
              >
                Nuova configurazione
              </Link>
            </div>
            <table className="table-fixed w-full configs-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Struttura</th>
                  <th>Nome Configurazione</th>
                  <th>Data di creazione</th>
                  <th>Stato Sito</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {configs?.map((conf, index) => (
                  <tr>
                    <td>{conf.id_config}</td>
                    <td>{conf.reg_business_name}</td>
                    <td>{conf.config_name}</td>
                    <td>{conf.creation_date}</td>
                    <td>
                      {conf.is_in_maintenance ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6 text-yellow-600"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M21.75 6.75a4.5 4.5 0 01-4.884 4.484c-1.076-.091-2.264.071-2.95.904l-7.152 8.684a2.548 2.548 0 11-3.586-3.586l8.684-7.152c.833-.686.995-1.874.904-2.95a4.5 4.5 0 016.336-4.486l-3.276 3.276a3.004 3.004 0 002.25 2.25l3.276-3.276c.256.565.398 1.192.398 1.852z"
                          />
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.867 19.125h.008v.008h-.008v-.008z"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6 text-green-600"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12.75l6 6 9-13.5"
                          />
                        </svg>
                      )}
                    </td>
                    <td>
                      <Link
                        to={
                          "/website/config/edit/" + token + "/" + conf.id_config
                        }
                        className="text-indigo-600 font-semibold"
                      >
                        Modifica
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <Toaster />
    </div>
  );
}

export default WebsitePanel;
