import axios from "axios";
import { BACKEND_URL } from "../config/backendConfig";

class PricesService {
  getConfig(auth) {
    return axios.post(BACKEND_URL + "prices/auth", auth);
  }
  getRooms(auth) {
    return axios.post(BACKEND_URL + "prices/rooms", auth);
  }
  savePrices(state) {
    return axios.post(BACKEND_URL + "prices/create", state);
  }
}
export default new PricesService();
