import axios from "axios";
import { BACKEND_URL } from "../config/backendConfig";

const token = "Bearer " + localStorage.getItem("token");
class adminService {
  jwtLogin(token) {
    return axios.get(BACKEND_URL + "admin/auth/token/" + token);
  }
  getLessBookingStructures(page, filter) {
    return axios.post(BACKEND_URL + "admin/data/widget/1/" + page, filter, {
      headers: { Authorization: token },
    });
  }
  getHasListinoStructure(filter) {
    return axios.post(BACKEND_URL + "admin/data/widget/2", filter, {
      headers: { Authorization: token },
    });
  }
}
export default new adminService();
