import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IconButton } from "@mui/material";
import DynamicHeroIcon from "../heroicons/hicon";
import Grid from "@mui/material/Grid";
import Flickity from "react-flickity-component";
import Room from "../room/room";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@mui/material/Tooltip";
import LoadingButton from "@mui/lab/LoadingButton";
import ReactTextCollapse from "react-text-collapse";
import structureService from "../../services/structure.service";
import { GetLabel } from "../../languages/i18n";
import cloneDeep from "lodash/cloneDeep";

const style = {
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  zIndex: 9999,
  borderRadius: "15px",
  marginBottom: "20px",
  display: "flex",
  flexDirection: "column",
};

export default function RoomDetails({
  nrooms,
  room,
  open,
  setOpen,
  uuid,
  addCart,
  tax,
  language,
}) {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isBooking, setIsBooking] = React.useState(false);
  const [cancellationRules, setCancellationRules] = React.useState("");

  const TEXT_COLLAPSE_OPTIONS = {
    collapse: false, // default state when component rendered
    collapseText: GetLabel(language, "LABEL_ROOM_DESCR_SHOW_MORE"), // text to show when collapsed
    expandText: GetLabel(language, "LABEL_ROOM_DESCR_SHOW_LESS"), // text to show when expanded
    minHeight: 150, // component height when closed
    maxHeight: 350, // expanded to
  };

  const marginLeftSm = "20px !important";

  React.useEffect(() => {
    fetchCancellactionRules();
  }, [room]);

  const fetchCancellactionRules = async () => {
    structureService.getCancellationRules(uuid).then(
      (result) => {
        result = result.data;
        setCancellationRules(result);
      },
      (error) => {}
    );
  };

  const SaveRoom = () => {
    setOpen(false);

    let r = cloneDeep(room);
    addCart({ ...r, isNotRefundable: false }, false);
  };
  const BookNow = () => {
    let r = cloneDeep(room);
    addCart({ ...r, isNotRefundable: false }, true);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
      >
        <Slide in={open}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              paddingBottom: "20px",
            }}
          >
            <Box sx={style} className="room-details">
              <div className="header">
                <h2 className="text-lg text-gray-800 truncate">
                  {room.roomName} ({room.tabName})
                </h2>

                <IconButton
                  sx={{ marginLeft: "auto" }}
                  onClick={() => setOpen(false)}
                >
                  <DynamicHeroIcon
                    icon={"XIcon"}
                    className="input-icon"
                  ></DynamicHeroIcon>
                </IconButton>
              </div>
              <div className="content">
                <Grid container spacing={2} sx={{ padding: "16px" }}>
                  <Grid item sm={5} xs={12}>
                    {room && room.images && (
                      <Flickity options={{ lazyLoad: true }}>
                        {room.images.map((image, i) => {
                          return (
                            <div
                              style={{ background: "url(" + image.image + ")" }}
                              className="carousel-img"
                            ></div>
                          );
                        })}
                      </Flickity>
                    )}
                    <Typography
                      variant="h6"
                      component="h2"
                      className="description"
                      style={{ marginTop: "20px" }}
                    >
                      {GetLabel(language, "LABEL_SERVICES")}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          marginLeft: "0",
                          whiteSpace: "pre",
                          overflowX: "auto",
                          marginRight: "5px",
                        }}
                        className="ios-scroll"
                      >
                        {room.services && room.services.length > 0 ? (
                          room.services.map((service, i) => {
                            return (
                              <p
                                style={{
                                  marginRight: "10px",
                                  marginTop: "3px",
                                  marginBottom: "3px",
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={service.icon}
                                  className="service-icon"
                                />
                                <span className="service-icon-text">
                                  {service.name}
                                </span>
                              </p>
                            );
                          })
                        ) : (
                          <p
                            style={{
                              marginRight: "10px",
                              marginTop: "3px",
                              marginBottom: "3px",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={"fa-times"}
                              className="service-icon"
                            />
                            <span className="service-icon-text">
                              {GetLabel(language, "LABEL_NO_SERVICE")}
                            </span>
                          </p>
                        )}
                      </Box>
                    </Box>

                    <Typography
                      variant="h6"
                      component="h2"
                      className="description"
                      style={{ marginTop: "20px" }}
                    >
                      {GetLabel(language, "LABEL_TAX_STAY")}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        marginTop: "10px",
                        flexDirection: "column",
                        maxHeight: "100px",
                        overflowY: "auto",
                      }}
                      className="tax-box"
                    >
                      {tax.taxes ? (
                        <>
                          {tax.taxes.map((item, index) => {
                            return (
                              <>
                                {tax.btax === true ? (
                                  <span
                                    className="service-icon-text"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <b>
                                      {item.price}{" "}
                                      {GetLabel(
                                        language,
                                        "LABEL_CURRENCY_LONG"
                                      )}{" "}
                                    </b>
                                    {item.age && `(${item.age} anni)`}{" "}
                                    {GetLabel(language, "LABEL_STAY_TAX_DESCR")}
                                  </span>
                                ) : (
                                  <span
                                    className="service-icon-text"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    {GetLabel(language, "LABEL_FROM_DAY_SHORT")}{" "}
                                    {item.date_from}{" "}
                                    {GetLabel(language, "LABEL_TO_DAY")}{" "}
                                    {item.date_to} <br></br>
                                    <b>
                                      {item.price}{" "}
                                      {GetLabel(
                                        language,
                                        "LABEL_CURRENCY_LONG"
                                      )}{" "}
                                    </b>
                                    {item.age && `(${item.age} anni)`}{" "}
                                    {GetLabel(language, "LABEL_STAY_TAX_DESCR")}
                                  </span>
                                )}
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <p
                          style={{
                            marginRight: "10px",
                            marginTop: "3px",
                            marginBottom: "3px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={"fa-times"}
                            className="service-icon"
                          />
                          <span className="service-icon-text">
                            {GetLabel(language, "LABEL_NO_TAX_TO_PAY")}
                          </span>
                        </p>
                      )}
                    </Box>
                  </Grid>
                  <Grid item sm={7} xs={12}>
                    <Typography
                      variant="h6"
                      component="h2"
                      className="description"
                    >
                      {GetLabel(language, "LABEL_DESCRIPTION")}
                    </Typography>
                    <Typography
                      id="transition-modal-description"
                      style={{ fontSize: "14px" }}
                    >
                      {room !== undefined && room.description !== undefined ? (
                        room !== null &&
                        room.description !== null &&
                        room.description.length > 300 ? (
                          <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: room.description,
                              }}
                            />
                          </ReactTextCollapse>
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: room.description,
                            }}
                          />
                        )
                      ) : (
                        <></>
                      )}
                    </Typography>

                    <Typography
                      variant="h6"
                      component="h2"
                      className="description"
                      style={{ marginTop: "30px" }}
                    >
                      {GetLabel(language, "LABEL_BOOKING_TERMS")}
                    </Typography>
                    <Typography
                      id="transition-modal-description"
                      style={{ fontSize: "14px" }}
                    >
                      {cancellationRules !== null &&
                      cancellationRules.length > 300 ? (
                        <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: cancellationRules,
                            }}
                          />
                        </ReactTextCollapse>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: cancellationRules,
                          }}
                        />
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <div className="footer">
                  <Grid container>
                    <Grid item sm={5} xs={12} className="container">
                      <Tooltip
                        title={GetLabel(language, "LABEL_SEARCH_ADULTI")}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: "20px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={"male"}
                            className="recap-icon"
                          />
                          <p className="adults">
                            x{" "}
                            {Array.isArray(room.childs)
                              ? room.guests - room.childs.length
                              : room.guests - room.childs}
                          </p>
                        </div>
                      </Tooltip>
                      {(Array.isArray(room.childs) && room.childs.length) ||
                        (room.childs > 0 && (
                          <Tooltip
                            title={GetLabel(language, "LABEL_SEARCH_BAMBINI")}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "20px",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={"child"}
                                className="recap-icon-small"
                              />
                              <p className="adults">x {room.childs}</p>
                            </div>
                          </Tooltip>
                        ))}

                      <div style={{ marginLeft: "auto" }}>
                        <Tooltip title={GetLabel(language, "LABEL_PRICE")}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              marginRight: "10px",
                              fontSize: "28px",
                              fontWeight: "500",
                              color: "green",
                              marginLeft: "auto",
                            }}
                          >
                            € {parseFloat(room.price).toFixed(2)}
                          </div>
                        </Tooltip>
                      </div>
                    </Grid>
                    <Grid item sm={7} xs={12} className="container">
                      {/* {nrooms > 1 && ( */}
                      <LoadingButton
                        fullWidth
                        variant="contained"
                        sx={{
                          width: "250px",
                          marginRight: "10px",
                          fontSize: "14px !important",
                          fontWeight: "500 !important",
                          marginLeft: "auto",
                        }}
                        className="btn-primary"
                        loading={isBooking}
                        onClick={() => SaveRoom()}
                        loadingPosition="start"
                        color="secondary"
                      >
                        {GetLabel(language, "LABEL_ADD_TO_CART")}
                        <DynamicHeroIcon
                          icon={"ShoppingCartIcon"}
                          className="input-icon"
                          style={{ color: "#FFF", marginLeft: "5px" }}
                        ></DynamicHeroIcon>
                      </LoadingButton>
                      {/* )} */}

                      <LoadingButton
                        fullWidth
                        variant="contained"
                        sx={{
                          width: "200px",
                          fontSize: "14px !important",
                          fontWeight: "500 !important",
                          marginLeft: nrooms > 1 ? "" : "auto",
                        }}
                        className="btn-primary"
                        loading={isBooking}
                        onClick={BookNow}
                        loadingPosition="start"
                      >
                        {GetLabel(language, "LABEL_BOOK_NOW")}
                        <DynamicHeroIcon
                          icon={"ArrowRightIcon"}
                          className="input-icon"
                          style={{ color: "#FFF", marginLeft: "5px" }}
                        ></DynamicHeroIcon>
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Box>
          </div>
        </Slide>
      </Modal>
    </div>
  );
}
