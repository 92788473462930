import React from "react";

function Row(props) {
  return (
    <div
      className={`flex flex-row items-center w-full ${
        props.gap && "gap-" + props.gap
      } ${props.className}`}
    >
      {props.children}
    </div>
  );
}

export default Row;
