import React, { useState } from "react";
import SimpleImageSlider from "react-simple-image-slider";
import Row from "./Row";
import { useTranslation } from "react-i18next";
import { Button, Tooltip } from "antd";
import PreventivoGuestGalleryRoom from "./PreventivoGuestGalleryRoom";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import useStateCallback from "../../../components/useStateCallback";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

function PreventivoGuestRow({ row }) {
  const { t } = useTranslation("common");
  const [galleryImages, setGalleryImages] = useStateCallback([]);
  const [open, setOpen] = React.useState(false);

  return (
    <div className="p-3 rounded-md w-full">
      {galleryImages?.length > 0 && (
        <Lightbox
          open={open}
          close={() => setOpen(false)}
          slides={galleryImages}
          plugins={[Thumbnails]}
        />
      )}

      <div className="grid grid-cols-12 gap-3">
        <div className="block rounded-md overflow-hidden h-[200px] relative col-span-12 xl:col-span-4">
          {row.category.imagesLightBox?.length > 0 && (
            <PreventivoGuestGalleryRoom
              images={row.category.imagesLightBox}
              setGalleryImages={setGalleryImages}
              setOpen={setOpen}
            />
          )}
          {row.category.images?.length > 0 ? (
            <SimpleImageSlider
              images={row.category.images}
              showBullets={true}
              showNavs={true}
              width={"100%"}
              height={"100%"}
            />
          ) : (
            <img
              src="https://www.idaweb.eu/Archive/Media/empty.png"
              alt="no-image-room"
              className="w-full h-full object-cover"
            />
          )}
        </div>
        <div className="flex flex-col gap-5 col-span-12 text-center xl:text-left xl:col-span-8 p-0 xl:px-5">
          <div className="grid grid-cols-12 items-center">
            <div className="flex flex-col truncate w-full items-center xl:items-baseline gap-2 col-span-12 xl:col-span-12">
              <h1 className="text-xl font-medium">
                {row.category.category_name}
              </h1>
              <span className="text-gray-400">
                {t("rooms.stayFor")}
                <strong>{row.category.nguests}</strong>&nbsp;{t("rooms.guests")}
              </span>
            </div>
          </div>
          <div className="text-gray-600">
            <div
              dangerouslySetInnerHTML={{ __html: row.category.description }}
            />
          </div>
          {row.category.taxes?.length > 0 && (
            <>
              <h3 className="text-xl">{t("rooms.stayTax")}</h3>
              <div className="flex flex-col gap-2 w-full">
                {row.category.taxes.map((tax, index) => (
                  <div className="grid grid-cols-12 w-full items-center">
                    <span className="font-semibold text-green-500 col-span-2 text-md">
                      {tax.price} &euro;
                    </span>
                    <span className="ml-1 col-span-10 text-xs">
                      {tax.description}
                    </span>
                  </div>
                ))}
              </div>
            </>
          )}

          <div className="w-full mt-4">
            <div className="flex flex-col text-center xl:text-right">
              <span className="text-gray-400">{t("rooms.stayPrice")}</span>
              {row.category.striked_price &&
              row.category.striked_price !== row.category.price ? (
                <div className="flex flex-col mt-2 xl:mt-0 xl:flex-row justify-center items-center xl:ml-auto xl:justify-end gap-3">
                  <span className="text-red-500 line-through text-md">
                    {parseFloat(row.category.striked_price).toFixed(2)} &euro;
                  </span>
                  <span className="text-xl text-green-500 font-semibold">
                    {parseFloat(row.category.price).toFixed(2)} &euro;
                  </span>
                </div>
              ) : (
                <span className="text-xl text-green-500 font-semibold">
                  {parseFloat(row.category.price).toFixed(2)} &euro;
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreventivoGuestRow;
