import { Input } from "antd";
import dayjs from "dayjs";
import React from "react";
import Cards from "react-credit-cards";
import InputMask from "react-input-mask";

export default class PaymentForm extends React.Component {
  state = {
    cvc: "",
    expiry: "",
    focus: "",
    name: "",
    number: "",
  };

  componentDidUpdate = (prevProps, prevState) => {
    var isDisabled = false;
    if (!this.state.cvc) isDisabled = true;
    if (!this.state.expiry) isDisabled = true;
    else {
      if (this.state.expiry.includes("_")) isDisabled = true;
      const dates = this.state.expiry.split("/");
      if (parseInt(dates[1]) < parseInt(dayjs().format("YY"))) {
        isDisabled = true;
      }
      if (parseInt(dates[0]) > 12) isDisabled = true;
    }
    if (!this.state.name) isDisabled = true;
    if (!this.state.number) isDisabled = true;
    this.props.setIsDisabledPayment(isDisabled);
  };

  handleInputFocus = (e) => {
    this.setState({ focus: e.target.name });
  };

  handleInputChange = (e) => {
    var { name, value } = e.target;
    if (name === "number" && value !== "") {
      let cardNumber = value;
      value = value.replace(/\D/g, "");
      if (/^3[47]\d{0,13}$/.test(value)) {
        cardNumber = value
          .replace(/(\d{4})/, "$1 ")
          .replace(/(\d{4}) (\d{6})/, "$1 $2 ");
      } else if (/^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(value)) {
        // diner's club, 14 digits
        cardNumber = value
          .replace(/(\d{4})/, "$1 ")
          .replace(/(\d{4}) (\d{6})/, "$1 $2 ");
      } else if (/^\d{0,16}$/.test(value)) {
        // regular cc number, 16 digits
        cardNumber = value
          .replace(/(\d{4})/, "$1 ")
          .replace(/(\d{4}) (\d{4})/, "$1 $2 ")
          .replace(/(\d{4}) (\d{4}) (\d{4})/, "$1 $2 $3 ");
      }
      this.setState({ number: cardNumber.trimRight() });
    } else if (name === "expiry") {
      this.setState({ expiry: value });
    } else this.setState({ [name]: value });

    this.props.setCard({ ...this.props.card, [name]: value });
  };

  render() {
    return (
      <div id="PaymentForm">
        <Cards
          cvc={this.state.cvc}
          expiry={this.state.expiry}
          focused={this.state.focus}
          name={this.state.name}
          number={this.state.number}
          locale={{ valid: "Scadenza" }}
          placeholders={{ name: "IL TUO NOME" }}
        />
        <form className="mt-5 flex flex-col gap-4">
          <div className="flex flex-col">
            <label className="text-white">
              {this.props.t("payment.paymentForm.cardNumber")}
            </label>
            <Input
              type="tel"
              name="number"
              placeholder="Numero Carta"
              onChange={this.handleInputChange}
              onFocus={this.handleInputFocus}
              value={this.state.number}
              maxLength={19}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-white">
              {this.props.t("payment.paymentForm.cardHolder")}
            </label>
            <Input
              type="text"
              name="name"
              placeholder="Mario Rossi"
              onChange={this.handleInputChange}
              onFocus={this.handleInputFocus}
              value={this.state.name}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-white">
              {this.props.t("payment.paymentForm.cardExpire")}
            </label>

            <InputMask
              mask="99/99"
              value={this.state.expiry}
              onChange={this.handleInputChange}
              onFocus={this.handleInputFocus}
              name="expiry"
            >
              {(inputProps) => (
                <Input type="text" placeholder="12/24" {...inputProps} />
              )}
            </InputMask>
          </div>
          <div className="flex flex-col">
            <label className="text-white">CVC</label>
            <Input
              type="text"
              name="cvc"
              placeholder="123"
              onChange={this.handleInputChange}
              onFocus={this.handleInputFocus}
              value={this.state.cvc}
              maxLength={3}
            />
          </div>
        </form>
      </div>
    );
  }
}
