import { Alert, Button } from "antd";
import React, { useState } from "react";
import PreventiviRequestPreview from "./PreventiviRequestPreview";

function PreventivoRequestDetails({ preventivo }) {
  const [focusedRequest, setRequest] = useState();

  if (preventivo.request && preventivo.request.id_request)
    return (
      <div className="pt-10 px-10">
        <PreventiviRequestPreview
          id_request={focusedRequest}
          hideFooter={true}
          setFocusedRequest={() => {
            setRequest();
          }}
        />
        <Alert
          message={
            <span>
              Stai rispondendo alla{" "}
              <b>Richiesta #{preventivo.request.id_request}</b> del cliente{" "}
              <b className="capitalize">
                {preventivo.request.surname + " " + preventivo.request.name}
              </b>
            </span>
          }
          type="info"
          showIcon
          action={
            <Button
              size="small"
              type="primary"
              onClick={() => setRequest(preventivo.request.id_request)}
            >
              Visualizza Richiesta
            </Button>
          }
        />
      </div>
    );
  else return <></>;
}

export default PreventivoRequestDetails;
