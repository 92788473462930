import React from "react";
import SecondStepRoom from "./SecondStepRoom";
import { Button } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

function SecondStep({ state, setState }) {
  const { t } = useTranslation("common");
  return (
    <div
      className="my-10 backdrop-blur-lg w-full h-full 
  rounded-lg py-10 border border-dashed border-gray-500
  px-5 md:px-20 gap-5 flex flex-col"
    >
      <div className="!py-2 w-full flex justify-end">
        <Button
          type="dashed"
          className="text-white"
          onClick={() =>
            setState({
              ...state,
              rooms: [
                ...state.rooms,
                {
                  id: state.rooms.length + 1,
                  dates: [dayjs(), dayjs().add(2, "day")],
                  treatment: "ALLIN",
                  adults: 1,
                  children: 0,
                },
              ],
            })
          }
        >
          {t("request.step2.addRoom")}
        </Button>
      </div>
      {state.rooms?.map((room, index) => (
        <SecondStepRoom
          room={room}
          state={state}
          setState={setState}
          key={"room-" + index}
          index={index}
        />
      ))}
    </div>
  );
}

export default SecondStep;
