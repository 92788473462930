import React from "react";
import { Tabs } from "antd";
import Advisor from "./Advisor";
import LanguageSelect from "./LanguageSelect";
import SlideOver from "./SlideOver";
import Banner from "./Banner";
import BestChoiceEdit from "./BestChoiceEdit";
import BookingEngineEdit from "./BookingEngineEdit";
import PagesEdit from "./PagesEdit";
import Rooms from "./Rooms";
import StructurePreviewEdit from "./StructurePreviewEdit";
import StructureServices from "./StructureServices";
import SurroundingsEdit from "./SurroundingsEdit";
import Gallery from "./Gallery";
import Restaurants from "./Restaurants";
import Events from "./Events";
import Services from "./Services";
import WhereAreWe from "./WhereAreWe";

function WebsiteConfig() {
  const [language, setLanguage] = React.useState({
    id: 1,
    name: "Italiano",
    value: "IT",
    avatar: "https://www.idaweb.eu/Images/Sys/flag/IT.png",
  });
  return (
    <>
      <div className="flex flex-col w-full">
        <div className="py-4 w-full md:w-48">
          <LanguageSelect selected={language} setSelected={setLanguage} />
        </div>
        <Tabs
          defaultActiveKey="1"
          style={{
            marginBottom: 32,
          }}
          items={[
            {
              label: "Annunci",
              key: "1",
              children: <Advisor language={language} />,
            },
            {
              label: "Banner",
              key: "2",
              children: <Banner language={language} />,
            },
            {
              label: "Prezzi",
              key: "3",
              children: <BestChoiceEdit language={language} />,
            },
            {
              label: "Booking Engine",
              key: "4",
              children: <BookingEngineEdit language={language} />,
            },
            {
              label: "Pagine Sito",
              key: "5",
              children: <PagesEdit language={language} />,
            },
            {
              label: "Stanze",
              key: "6",
              children: <Rooms language={language} />,
            },
            {
              label: "Anteprima Struttura",
              key: "7",
              children: <StructurePreviewEdit language={language} />,
            },
            {
              label: "Galleria",
              key: "8",
              children: <Gallery language={language} />,
            },
            {
              label: "I dintorni",
              key: "9",
              children: <SurroundingsEdit language={language} />,
            },
            {
              label: "Ristorante",
              key: "10",
              children: <Restaurants language={language} />,
            },
            {
              label: "Eventi",
              key: "11",
              children: <Events language={language} />,
            },
            {
              label: "Servizi",
              key: "12",
              children: <Services language={language} />,
            },
            {
              label: "Dove Siamo",
              key: "13",
              children: <WhereAreWe language={language} />,
            },
          ]}
        />
      </div>
    </>
  );
}

export default WebsiteConfig;
