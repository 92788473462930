import { Avatar, Divider, List, Skeleton } from "antd";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import adminService from "../../../../services/admin.service";
import Chip from "@mui/material/Chip";
import dayjs from "dayjs";
import useStateCallback from "../../../../components/useStateCallback";
const LessBookingStructuresWidget = ({
  filter,
  search,
  setSearch,
  removeFilter,
  setRemoveFilter,
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useStateCallback([]);
  const [page, setPage] = useStateCallback(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (search && filter) {
      loadFilterData();
    }
  }, [search]);

  useEffect(() => {
    loadMoreData();
  }, []);

  useEffect(() => {
    if (removeFilter) {
      setRemoveFilter(false);
      setPage(0, () => {
        loadMoreData();
      });
    }
  }, [removeFilter]);

  const loadFilterData = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    adminService
      .getLessBookingStructures(0, {
        search: filter,
      })
      .then(
        (result) => {
          result = result.data;
          setData(result.rows);
          setTotal(result.total);
          setLoading(false);
          setPage(1);
          setSearch(false);
        },
        (error) => {
          setLoading(false);
          setSearch(false);
        }
      );
  };
  const loadMoreData = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    adminService
      .getLessBookingStructures(page, {
        search: filter,
      })
      .then(
        (result) => {
          result = result.data;
          if (page === 0) setData(result.rows);
          else setData([...data, ...result.rows]);
          setTotal(result.total);
          setLoading(false);
          setPage(page + 1);
          setSearch(false);
        },
        (error) => {
          setLoading(false);
          setSearch(false);
        }
      );
  };

  return (
    <div className="flex flex-col gap-3">
      <div
        id="scrollableDiv"
        style={{
          height: 400,
          overflow: "auto",
        }}
      >
        <InfiniteScroll
          dataLength={data.length}
          next={loadMoreData}
          hasMore={total > data.length}
          loader={
            <Skeleton
              avatar
              paragraph={{
                rows: 1,
              }}
              active
            />
          }
          endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
          scrollableTarget="scrollableDiv"
        >
          <List
            dataSource={data}
            renderItem={(item) => (
              <List.Item key={item.id_structure}>
                <List.Item.Meta
                  // avatar={<Avatar src={item.picture.large} />}
                  title={<span>{item.name}</span>}
                  description={
                    "Ultima prenotazione ricevuta: " +
                    dayjs(item.last_booking_date).format("DD/MM/YYYY")
                  }
                />
                {dayjs().diff(dayjs(item.last_booking_date), "day") >= 20 ? (
                  <Chip label="Da verificare" color="error" />
                ) : dayjs().diff(dayjs(item.last_booking_date), "day") >= 15 &&
                  dayjs().diff(dayjs(item.last_booking_date), "day") <= 20 ? (
                  <Chip
                    label={<div className="capitalize">è passato un pò</div>}
                    color="warning"
                  />
                ) : dayjs().diff(dayjs(item.last_booking_date), "day") >= 10 &&
                  dayjs().diff(dayjs(item.last_booking_date), "day") <= 20 ? (
                  <Chip
                    label={<div className="capitalize">è passato un pò</div>}
                    color="warning"
                  />
                ) : (
                  <Chip
                    label={<div className="capitalize">nessun problema</div>}
                    color="success"
                  />
                )}
              </List.Item>
            )}
          />
        </InfiniteScroll>
      </div>
      <span className="text-gray-400 w-full text-center">
        Mostro {data.length} di {total}
      </span>
    </div>
  );
};
export default LessBookingStructuresWidget;
