import { Button, Modal, QRCode, Spin } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Descriptions } from "antd";
import { Typography } from "antd";
import { toast } from "react-hot-toast";
import preventiviService from "../../services/preventivi.service";

const { Paragraph } = Typography;

const PreventivoInfo = ({ open, setOpen, preventivo }) => {
  const { token } = useParams();
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState("");

  useEffect(() => {
    if (token && open) fetchPreventivoUrl();
  }, [open]);

  const handleCancel = () => {
    setOpen(false);
  };

  const fetchPreventivoUrl = async () => {
    setLoading(true);
    preventiviService
      .getPreventivoUrl(`${token}/${preventivo.id_preventivo}`)
      .then(
        (result) => {
          setLink(result.data);
          setLoading(false);
        },
        (error) => {
          toast.error(error.response.data.ExceptionMessage);
        }
      );
  };

  return (
    <>
      <Modal
        title="Informazioni Preventivo"
        open={open}
        onCancel={handleCancel}
        footer={[
          <Button
            key="ok"
            onClick={() => {
              const url =
                window.location.origin +
                "/" +
                window.open(link + "/true", "_blank", "noreferrer");
            }}
            type="primary"
          >
            Visualizza Anteprima
          </Button>,
          <Button key="back" onClick={handleCancel} disabled={loading}>
            Chiudi
          </Button>,
        ]}
      >
        <Spin spinning={loading}>
          <div className="flex flex-col md:flex-row items-center gap-3 mb-10">
            <div className="w-full md:w-1/3">
              <QRCode
                value={link}
                size={128}
                iconSize={24}
                className="mt-10"
                icon={"https://www.idaweb.eu/Images/Sys/favicon_ida.png"}
              />
            </div>
            <div className="w-full md:w-2/3">
              <label className="text-md">
                Il tuo preventivo è disponibile anche tramite <b>QR-Code</b>!
                Inquadralo con il tuo smartphone.
              </label>
            </div>
          </div>
          <span className="text-red-500">
            Attenzione: il seguente link deve essere inviato al cliente
            altrimenti se visualizzato aggiornerà il preventivo corrente come
            "visualizzato". Per vedere l'anteprima premere su "
            <b>Visualizza Anteprima</b>".
          </span>
          <Descriptions column={1} className="mt-10">
            <Descriptions.Item label="Link">
              <Paragraph
                copyable={{
                  text: link,
                  tooltips: "Copia il link",
                }}
                className="!m-0"
              >
                <span>{link}</span>
              </Paragraph>
            </Descriptions.Item>
          </Descriptions>
        </Spin>
      </Modal>
    </>
  );
};
export default PreventivoInfo;
