import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

export const BootstrapButtonForward = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "10px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#2b5cdf",
  borderColor: "#2b5cdf",
  color: "#FFF",
  width: "100px",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    backgroundColor: "#2b54c1",
    borderColor: "#2b54c1",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#6363c7",
    borderColor: "#6363c7",
  },
  "&.Mui-disabled": {
    color: "#c1c1c1",
    backgroundColor: "#FFF",
    border: "1px dashed #c1c1c1",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});
