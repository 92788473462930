import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export default function Language(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const setLanguage = (lang) => {
    props.setLanguage(lang);
    handleClose();
  };

  React.useEffect(() => {
    console.log(props.language);
  }, [props.language]);

  return (
    <div className={props.className}>
      <Button
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <img
          src={`https://www.idaweb.eu/Images/Sys/flag/${props.language}.png`}
          className="flag-selected"
        ></img>
        {props.language}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={() => setLanguage("IT")}>
          <img
            src="https://www.idaweb.eu/Images/Sys/flag/IT.png"
            className="flag-select"
          ></img>
          Italiano
        </MenuItem>
        <MenuItem onClick={() => setLanguage("EN")}>
          <img
            src="https://www.idaweb.eu/Images/Sys/flag/EN.png"
            className="flag-select"
          ></img>
          Inglese
        </MenuItem>
        {/* <MenuItem onClick={() => setLanguage("DE")} disabled>
          <img
            src="https://www.idaweb.eu/Images/Sys/flag/DE.png"
            className="flag-select"
          ></img>
          Tedesco
        </MenuItem> */}
      </Menu>
    </div>
  );
}
