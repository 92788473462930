import React from "react";
import { Collapse, theme } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

function PreventivoCheckoutOta() {
  const { token } = theme.useToken();

  const panelStyle = {
    borderRadius: token.borderRadiusLG,
    border: "none",
  };

  const CheckoutRow = ({ label, value, perc }) => {
    return (
      <div className="flex flex-col lg:flex-row items-center py-3 text-lg">
        <span className="text-red-900">{label}</span>
        <div className="lg:ml-auto text-red-700 flex flex-row items-center">
          {value} &euro; <span className="text-sm ml-2">(+{perc}%)</span>
        </div>
      </div>
    );
  };
  const HeaderOta = () => (
    <div className="text-lg text-red-700 font-semibold uppercase flex flex-row items-center gap-1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6 text-red-500 hidden lg:visible"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
        />
      </svg>
      Quanto avresti pagato sugli altri canali?
    </div>
  );
  return (
    <Collapse
      bordered={false}
      defaultActiveKey={["1"]}
      className="!bg-red-50"
      expandIcon={({ isActive }) => (
        <CaretRightOutlined
          rotate={isActive ? 90 : 0}
          className="!text-red-500"
        />
      )}
    >
      <Panel header={<HeaderOta />} key="1" style={panelStyle}>
        <div className="!bg-red-50 p-7 pt-0 flex-col gap-3 rounded-sm">
          <ul role="list" className="-my-6 divide-y divide-gray-200 mt-6">
            <li key={"4"} className="py-1">
              <CheckoutRow label={"Booking.com"} value={"400.00"} perc={"18"} />
            </li>
            <li key={"6"} className="py-1">
              <CheckoutRow label={"AirBnB"} value={"390.00"} perc={"11"} />
            </li>
          </ul>
        </div>
      </Panel>
    </Collapse>
  );
}

export default PreventivoCheckoutOta;
