import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import adminService from "../../../../services/admin.service";

function AuthAdmin(props) {
  const { token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (token) jwtLogin();
    else navigate("/404");
  }, []);

  const jwtLogin = async () => {
    setLoading(true);
    adminService.jwtLogin(token).then(
      (result) => {
        if (result.status === 200) {
          localStorage.setItem("token", token);
          setLoading(false);
        } else navigate("/unauthorized");
      },
      (error) => {
        navigate("/unauthorized");
      }
    );
  };

  return (
    <Spin spinning={loading}>
      {loading ? (
        <div className="h-screen w-screen">&nbsp;</div>
      ) : (
        <>{props.children}</>
      )}
    </Spin>
  );
}

export default AuthAdmin;
