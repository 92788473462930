import React, { useState } from "react";
import PreventivoForm from "./PreventivoForm";
import { useNavigate, useParams } from "react-router-dom";
import preventiviService from "../../services/preventivi.service";
import { Button, QRCode, Spin, Tooltip } from "antd";
import { Layout, Space } from "antd";
import { Typography, Modal } from "antd";
import { Toaster, toast } from "react-hot-toast";
import useStateCallback from "../../components/useStateCallback";
import PreventivoDeleteConfirm from "./PreventivoDeleteConfirm";
import PreventivoFromTemplate from "./Templates/ModalTemplateSelection";
import PreventivoDuplicateConfirm from "./PreventivoDuplicateConfirm";
import {
  InfoCircleFilled,
  InfoCircleOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import PreventivoInfo from "./PreventivoInfo";
import SaveAsTemplatePreventivo from "./Templates/ModalSaveAsTemplate";
import PreventiviRequestPreview from "./PreventiviRequestPreview";
import PreventivoRequestDetails from "./PreventivoRequestDetails";
import PreventivoAlerts from "./PreventivoAlerts";

const { Title } = Typography;
const { Header, Footer, Content } = Layout;

const headerStyle = {
  color: "#fff",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "1rem",
  height: "auto",
};

function PreventivoEdit() {
  const { token, id } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [preventivo, setPreventivo] = useStateCallback({});
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openInfo, setOpenInfo] = React.useState(false);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (token && id) fetchPreventivo();
  }, []);

  React.useEffect(() => {}, [preventivo]);

  const fetchPreventivo = async () => {
    setLoading(true);
    preventiviService.getPreventivo(`${token}/${id}`).then(
      (result) => {
        setPreventivo(result.data);
        setLoading(false);
      },
      (error) => {
        navigate("/error", {
          replace: true,
          state: {
            error: "Il preventivo in questione non è stato trovato",
          },
        });
      }
    );
  };

  const HeaderPreventivo = () => (
    <div className="flex flex-col xl:flex-row items-center gap-5 w-full">
      <PreventivoInfo
        open={openInfo}
        setOpen={setOpenInfo}
        preventivo={preventivo}
      />
      <Tooltip title="Ottieni informazioni preventivo">
        <InfoCircleFilled
          className="text-2xl !cursor-pointer hover:text-yellow-300 transition"
          onClick={() => setOpenInfo(true)}
        />
      </Tooltip>

      <Title
        editable={{
          text: preventivo.name,
          onChange: (new_name) => {
            setPreventivo({ ...preventivo, name: new_name }, () =>
              UpdatePreventivo("9", new_name)
            );
            toast.success("Il preventivo è stato salvato");
          },
          tooltip: "Modifica il nome preventivo",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-8 h-8 !ml-2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
              />
            </svg>
          ),
        }}
        className="!m-0 !text-white flex items-center"
      >
        {preventivo.name}
      </Title>

      <div className="xl:ml-auto flex flex-col md:flex-row items-center gap-3">
        <Button
          className="!flex !flex-row !items-center !ml-auto !bg-[#1677ff] hover:!bg-[#4096ff] w-full"
          type="primary"
          onClick={() => {
            setLoading(true);
            window.location.replace("/preventivi/list/" + token);
          }}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 text-white mr-2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
              />
            </svg>
          }
        >
          Indietro
        </Button>
        {preventivo.id_preventivo && (
          <>
            {preventivo.status !== 2 &&
              preventivo.status !== 3 &&
              preventivo.status !== 4 && (
                <PreventivoFromTemplate
                  preventivo={preventivo}
                  setPreventivo={setPreventivo}
                />
              )}
            <SaveAsTemplatePreventivo preventivo={preventivo} />
            <PreventivoDuplicateConfirm
              preventivo={preventivo}
              setLoadingPage={setLoading}
            />

            <Button
              className="!flex !flex-row !items-center w-full"
              type="primary"
              onClick={() => setOpenDelete(true)}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-white mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
              }
              danger
            >
              Elimina Preventivo
            </Button>
          </>
        )}
      </div>
    </div>
  );

  const fillCustomerData = async (newPreventivo, is_customer, value) => {
    preventiviService
      .getCustomerData(
        `${token}/${is_customer}/${value}/${preventivo.id_preventivo}`
      )
      .then(
        (result) => {
          result = result.data;
          if (result) {
            setPreventivo({
              ...newPreventivo,
              email_free: !preventivo.email_free
                ? result.email
                : preventivo.email_free,
              phone_free: !preventivo.phone_free
                ? result.phone
                : preventivo.phone_free,
              vat_number_free: !preventivo.vat_number_free
                ? result.vat_number
                : preventivo.vat_number_free,
            });
          }
        },
        (error) => {}
      );
  };

  const UpdatePreventivo = async (action, data) => {
    preventiviService
      .patchPreventivo(`${token}/${action}/${id}`, { value: data })
      .then(
        (result) => {
          result = result.data;
          if (result.success) {
            toast.success("Il preventivo è stato salvato.");

            setPreventivo(
              {
                ...preventivo,
                id_firm: result.model.id_firm,
                id_customer: result.model.id_customer,
                name_free: result.model.name_free,
                surname_free: result.model.surname_free,
                name: result.model.name,
                email_free: result.model.email_free,
                phone_free: result.model.phone_free,
                vat_number_free: result.model.vat_number_free,
                due_date: result.model.due_date,
                cancellation_rules: result.model.cancellation_rules,
                notes: result.model.notes,
                warranity_method: result.model.warranity_method,
                confirm_message: result.model.confirm_message,
                confirm_message_email: result.model.confirm_message_email,
                language: result.model.language,
                subject: result.model.subject,
                body: result.model.body,
                message: result.model.message,
                bonifico_text: result.model.bonifico_text,
              },
              (newPreventivo) => {
                if (action === "0" || action === "1") {
                  fillCustomerData(
                    newPreventivo,
                    action === "0" ? true : false,
                    data
                  );
                }
              }
            );
          } else toast.error("Errore!");
        },
        (error) => {
          toast.error("Errore!");
        }
      );
  };

  return (
    <>
      <PreventivoDeleteConfirm
        open={openDelete}
        setOpen={setOpenDelete}
        preventivo={preventivo}
        setLoading={setLoading}
      />
      <Spin spinning={loading}>
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
          size={[0, 48]}
        >
          <Layout>
            <Header style={headerStyle}>
              <HeaderPreventivo />
            </Header>

            <Content>
              <PreventivoRequestDetails preventivo={preventivo} />
              <PreventivoAlerts preventivo={preventivo} />
              <PreventivoForm
                preventivo={preventivo}
                setPreventivo={setPreventivo}
                UpdatePreventivo={UpdatePreventivo}
              />
            </Content>
            {/* <Footer>Footer</Footer> */}
          </Layout>
        </Space>
      </Spin>
      <Toaster />
    </>
  );
}

export default PreventivoEdit;
