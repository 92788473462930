import styled from "@emotion/styled";
import React from "react";
import WizardStatusStep from "./wizardStatusStep";

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export default function WizardStepContainer(props) {
  return (
    <Flex>
      <WizardStatusStep {...props} />
      {React.cloneElement(props.children, { ...props })}
    </Flex>
  );
}
