export const navigation = [
  { name: "Dashboard", href: "/admin", current: true },
  { name: "Siti Web", href: "/admin/websites", current: false },
  //   { name: "Projects", href: "#", current: false },
  //   { name: "Calendar", href: "#", current: false },
  //   { name: "Reports", href: "#", current: false },
];
export const userNavigation = [
  { name: "Torna su Ida", href: "https://www.idaweb.eu/dashboard" },
  { name: "Sign out", href: "#" },
];
