import React, { useEffect, useState } from "react";
import { Button, Descriptions, Modal, Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import preventiviService from "../../services/preventivi.service";
import dayjs from "dayjs";
import { toast } from "react-hot-toast";

const RoomRow = ({ room, index }) => {
  return (
    <Descriptions title={"Stanza #" + index + 1} layout="horizontal">
      <Descriptions.Item label="Categoria">
        <h3 className="font-semibold">{room.category.category_name}</h3>
      </Descriptions.Item>
      <Descriptions.Item label="Trattamento">
        {room.category.treatment_text}
      </Descriptions.Item>
      <Descriptions.Item label="Periodo">
        <div className="flex flex-row items-center gap-2">
          <span>{dayjs(room.date_from).format("DD/MM/YYYY")}</span>
          <span>-</span>
          <span>{dayjs(room.date_to).format("DD/MM/YYYY")}</span>
        </div>
      </Descriptions.Item>
    </Descriptions>
  );
};

const ServiceRow = ({ service, index }) => {
  return (
    <Descriptions title={"Servizio Aggiuntivo"} layout="horizontal">
      <Descriptions.Item label="Servizio">
        <h3 className="font-semibold">{service.title}</h3>
      </Descriptions.Item>
      <Descriptions.Item label="Cadenza Ammontare?" span="2">
        {service.is_day
          ? "Prezzo da aggiungere ogni giorno alla camera"
          : "Prezzo da aggiungere al totale del preventivo"}
      </Descriptions.Item>
      <Descriptions.Item label="Prezzo">
        {parseFloat(service.price).toFixed(2)} &euro;
      </Descriptions.Item>
      <Descriptions.Item label="Prodotto Collegato">
        {service.product_name}
      </Descriptions.Item>
    </Descriptions>
  );
};

function PreventiviRequestPreview({
  id_request,
  setFocusedRequest,
  hideFooter,
}) {
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [request, setRequest] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (id_request) {
      setIsModalOpen(true);
      fetchRequest();
    }
  }, [id_request]);

  const fetchRequest = async () => {
    setLoading(true);
    preventiviService.getRequest(token, id_request).then(
      (result) => {
        result = result.data;
        setRequest(result);
        setLoading(false);
      },
      (error) => {}
    );
  };

  const handleCancel = () => {
    setFocusedRequest();
    setIsModalOpen(false);
  };
  const answer = () => {
    setLoading(true);
    preventiviService.answerRequest(token, id_request).then(
      (result) => {
        if (result.status === 200) {
          navigate(
            "/preventivo/edit/" + token + "/" + result.data.id_preventivo
          );
        }
        setLoading(false);
      },
      (error) => {
        toast.error("Errore: qualcosa non ha funzionato");
      }
    );
  };

  return (
    <Modal
      open={isModalOpen}
      onCancel={handleCancel}
      footer={
        hideFooter
          ? [
              <Button key="back" onClick={handleCancel} disabled={loading}>
                Chiudi
              </Button>,
            ]
          : [
              <Button key="back" onClick={handleCancel} disabled={loading}>
                Chiudi
              </Button>,
              <Button type="primary" onClick={answer} disabled={loading}>
                Rispondi
              </Button>,
            ]
      }
      className="!w-full lg:!w-[1000px]"
    >
      <Spin spinning={loading}>
        <div className="flex flex-col gap-6">
          <Descriptions title="Dettagli della richiesta" layout="horizontal">
            <Descriptions.Item label="Nome">
              {request.name + " " + request.surname}
            </Descriptions.Item>
            <Descriptions.Item label="Email">{request.email}</Descriptions.Item>
            <Descriptions.Item label="Numero di telefono">
              {request.phone}
            </Descriptions.Item>
            <Descriptions.Item label="Stato di provenienza">
              {request.country}
            </Descriptions.Item>
            <Descriptions.Item label="Provincia di provenienza">
              {request.province}
            </Descriptions.Item>
            <Descriptions.Item label="Metodo di contatto">
              {request.contact_type === 1 ? "Email" : "Email & Whatsapp"}
            </Descriptions.Item>
            <Descriptions.Item label="Ha accettato la privacy?">
              {request.privacy ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-green-600"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-red-600"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </Descriptions.Item>
            <Descriptions.Item
              label="Ha accettato l'utilizzo della mail per motivi commerciali?"
              span={2}
            >
              {request.newsletter ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-green-600"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-red-600"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Richieste specifiche del cliente">
              {request.notes}
            </Descriptions.Item>
          </Descriptions>

          {request.rooms?.map((room, index) => (
            <RoomRow room={room} key={"room-" + index} index={index} />
          ))}

          {request.services?.map((service, index) => (
            <ServiceRow
              service={service}
              key={"service-" + index}
              index={index}
            />
          ))}
        </div>
      </Spin>
    </Modal>
  );
}

export default PreventiviRequestPreview;
