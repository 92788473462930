import { Select, Space } from "antd";
import { placeholders } from "./MessagePlaceholders";
const { Option } = Select;

const NewMessagePlaceholder = ({ message, setMessage }) => {
  const handleChange = (value) => {
    setMessage({ ...message, body: message.body + ` %${value}%` });
  };
  return (
    <div className="flex flex-col md:flex-row items-center">
      <label className="w-full">Inserisci campo dinamico: </label>
      <Select
        className="w-full"
        defaultValue="cliente.nome"
        onChange={handleChange}
        options={placeholders}
      />
    </div>
  );
};
export default NewMessagePlaceholder;
