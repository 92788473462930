import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export default function MultipleImageUploadComponent(props) {
  const validateSelectedFile = (file) => {
    const MIN_FILE_SIZE = 1024; // 1MB
    const MAX_FILE_SIZE = 1024; // 1MB

    const fileSizeKiloBytes = file.size / 1024;

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      MySwal.fire({
        title: "Oops.. qualcosa non va",
        html: (
          <span>
            Il file '{file.name}' che si sta cercando di caricare è troppo
            grande!
          </span>
        ),
        icon: "error",
      });
      return false;
    }

    return true;
  };

  const handleFileChange = (e) => {
    var is_valid = true;

    if (e.target.files.length > 3) {
      MySwal.fire({
        title: "Oops.. qualcosa non va",
        html: <span>E' consentito caricare massimo 3 immagini</span>,
        icon: "error",
      });
      is_valid = false;
    }

    if (is_valid) {
      for (let i = 0; i < e.target.files.length; i++) {
        const cur_file = e.target.files[i];
        if (!validateSelectedFile(cur_file)) {
          is_valid = false;
          break;
        }
      }
    }
    if (is_valid) props.setImages(e.target.files);
    else props.setImages();
  };

  //   const handleUploadClick = () => {
  //     if (!fileList) {
  //       return;
  //     }

  //     // 👇 Create new FormData object and append files
  //     const data = new FormData();
  //     files.forEach((file, i) => {
  //       data.append(`file-${i}`, file, file.name);
  //     });

  //     // 👇 Uploading the files using the fetch API to the server
  //     fetch("https://httpbin.org/post", {
  //       method: "POST",
  //       body: data,
  //     })
  //       .then((res) => res.json())
  //       .then((data) => console.log(data))
  //       .catch((err) => console.error(err));
  //   };

  // 👇 files is not an array, but it's iterable, spread to get an array of files
  const files = props.images ? [...props.images] : [];

  return (
    <div>
      <input
        type="file"
        onChange={handleFileChange}
        multiple
        className="input-file"
      />

      {files.map((file, i) => (
        <img
          src={URL.createObjectURL(file)}
          style={{
            width: "150px",
            height: "150px",
            objectFit: "cover",
            marginRight: "10px",
            borderRadius: "5px",
          }}
          key={"img-" + i}
        />
      ))}
    </div>
  );
}
