import {
  Alert,
  Button,
  Checkbox,
  ConfigProvider,
  Drawer,
  Form,
  Input,
  Select,
  Spin,
} from "antd";
import React, { useState } from "react";
import "dayjs/locale/it";
import locale from "antd/locale/it_IT";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import { useParams } from "react-router-dom";
import preventiviService from "../../../services/preventivi.service";
import { StyleProvider } from "@ant-design/cssinjs";
import { toast } from "react-hot-toast";

const { RangePicker } = DatePicker;

const NewRowRoom = ({ open, setOpen, preventivo, setPreventivo }) => {
  const { token, id } = useParams();
  const [newRoom, setNewRoom] = React.useState({
    date_from: dayjs(),
    date_to: dayjs().add(2, "day"),
    id_category: null,
    price: 0.0,
    striked_price: 0.0,
    name: "",
    base_price: 0.0,
    base_striked_price: 0.0,
  });
  const [categories, setCategories] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [isValidPeriod, setIsValidPeriod] = React.useState();

  React.useEffect(() => {
    if (token && open) fetchCategories();
    if (!open) {
      setNewRoom({
        id_preventivo: id,
        date_from: dayjs(),
        date_to: dayjs().add(2, "day"),
        id_category: null,
        price: 0.0,
        striked_price: 0.0,
        name: "",
        pin: false,
        booking_name: preventivo.booking_name,
      });
      setIsValidPeriod();
    }
  }, [open]);

  React.useEffect(() => {
    if (token && open) {
      fetchPriceCategory();
      fetchCategoryDisponibility();
    }
  }, [newRoom.id_category]);

  React.useEffect(() => {
    if (token && open && newRoom.id_category) {
      fetchCategoryDisponibility();
    }
  }, [newRoom.date_to]);

  React.useEffect(() => {
    var disabled = false;
    if (!newRoom.date_from) disabled = true;
    if (!newRoom.date_to) disabled = true;
    if (!newRoom.id_category) disabled = true;
    if (!newRoom.price) disabled = true;

    if (!isValidPeriod) disabled = true;

    if (newRoom.pin && !newRoom.booking_name) disabled = true;
    if (newRoom.pin && !preventivo.id_customer && !newRoom.customer_name)
      disabled = true;

    if (dayjs(newRoom.date_to).diff(dayjs(newRoom.date_from), "day") === 0)
      disabled = true;

    setIsDisabled(disabled);
  }, [newRoom, isValidPeriod]);

  const fetchCategoryDisponibility = async () => {
    if (newRoom.id_category) {
      setLoading(true);
      preventiviService
        .getDisponibilityCategory(token, newRoom.id_category, {
          date_from: dayjs(newRoom.date_from).format("DD/MM/YYYY"),
          date_to: dayjs(newRoom.date_to).format("DD/MM/YYYY"),
        })
        .then(
          (result) => {
            result = result.data;
            setIsValidPeriod(result);
            setLoading(false);
          },
          (error) => {}
        );
    }
  };
  const fetchCategories = async () => {
    setLoading(true);
    preventiviService.getCategories(token).then(
      (result) => {
        result = result.data;
        var categories_av = [];
        result.map((cat) => {
          if (cat.name) {
            categories_av.push(cat);
          }
        });
        setCategories(categories_av);
        setLoading(false);
      },
      (error) => {}
    );
  };
  const fetchPriceCategory = async () => {
    preventiviService.getPriceCategory(token, newRoom.id_category).then(
      (result) => {
        result = result.data[0];
        const days = dayjs(newRoom.date_to).diff(newRoom.date_from, "day");
        setNewRoom({
          ...newRoom,
          base_price: result.price,
          base_striked_price: result.striked_price,
          price: parseFloat(parseFloat(result.price).toFixed(2) * days).toFixed(
            2
          ),
          striked_price: parseFloat(
            parseFloat(result.striked_price).toFixed(2) * days
          ).toFixed(2),
        });
      },
      (error) => {}
    );
  };
  const onClose = () => {
    setOpen(false);
  };
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };
  const saveNewRoomRow = () => {
    setLoading(true);
    const newRoomObj = {
      ...newRoom,
      date_from_patch: dayjs(newRoom.date_from).toISOString(),
      date_to_patch: dayjs(newRoom.date_to).toISOString(),
    };
    preventiviService.saveNewRoomRow(token, newRoomObj).then(
      (result) => {
        result = result.data;

        setPreventivo({
          ...preventivo,
          booking_name: newRoom.booking_name,
          rows: [...preventivo.rows, result],
        });

        toast.success("La stanza è stata aggiunta al preventivo.");
        onClose();
        setLoading(false);
      },
      (error) => {
        toast.error(error.response.data.ExceptionMessage);
        setLoading(false);
      }
    );
  };
  function isNumber(str) {
    if (typeof str != "string") return false; // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  }
  return (
    <Drawer
      title={"Aggiungi una camera"}
      placement={"right"}
      width={500}
      onClose={onClose}
      open={open}
      footer={
        <div className="flex flex-row items-center w-full">
          <Button onClick={onClose}>Annulla</Button>
          <Button
            type="primary"
            onClick={saveNewRoomRow}
            className="ml-auto"
            disabled={isDisabled}
          >
            Salva
          </Button>
        </div>
      }
    >
      <Spin spinning={loading}>
        <Form layout={"vertical"}>
          <Form.Item label="Data di checkin / checkout">
            <ConfigProvider locale={locale}>
              <RangePicker
                disabledDate={disabledDate}
                onChange={(dates) => {
                  var price = 0.0;
                  var striked_price = 0.0;

                  if (newRoom.base_price) {
                    const days = dayjs(dates[1].toISOString()).diff(
                      dates[0].toISOString(),
                      "day"
                    );
                    price = parseFloat(
                      parseFloat(newRoom.base_price).toFixed(2) * days
                    ).toFixed(2);
                    striked_price = newRoom.base_striked_price
                      ? parseFloat(
                          parseFloat(newRoom.base_striked_price).toFixed(2) *
                            days
                        ).toFixed(2)
                      : newRoom.striked_price;
                  }

                  setNewRoom({
                    ...newRoom,
                    date_from: dates[0].toISOString(),
                    date_to: dates[1].toISOString(),
                    price: price,
                    striked_price: striked_price,
                  });
                }}
                value={[dayjs(newRoom.date_from), dayjs(newRoom.date_to)]}
                format={"DD/MM/YYYY"}
                className="w-full"
                allowClear={false}
              />
            </ConfigProvider>
          </Form.Item>
          <Form.Item label="Categoria">
            <Select
              value={newRoom.id_category}
              onChange={(e, opt) => {
                setNewRoom({ ...newRoom, id_category: e, name: opt.label });
              }}
              options={categories?.map((cat) => {
                return { value: cat.id_category, label: cat.name };
              })}
            />
          </Form.Item>
          <Form.Item label="Prezzo">
            <StyleProvider hashPriority="high">
              <Input
                placeholder={"0.00"}
                value={newRoom.price}
                onChange={(e) => {
                  if (e.target.value) {
                    if (isNumber(e.target.value) === false) {
                      toast.error("Sono accettati solo numeri.");
                      return;
                    }
                  }
                  setNewRoom({ ...newRoom, price: e.target.value });
                }}
              />
            </StyleProvider>
          </Form.Item>
          <Form.Item label="Prezzo Barrato">
            <StyleProvider hashPriority="high">
              <Input
                placeholder={"0.00"}
                className="line-through !text-red-600"
                value={newRoom.striked_price}
                onChange={(e) => {
                  if (e.target.value) {
                    if (isNumber(e.target.value) === false) {
                      toast.error("Sono accettati solo numeri.");
                      return;
                    }
                  }
                  setNewRoom({ ...newRoom, striked_price: e.target.value });
                }}
              />
            </StyleProvider>
          </Form.Item>
          <Form.Item label="Fissa sul planner">
            <Checkbox
              onChange={(e) => {
                setNewRoom({
                  ...newRoom,
                  pin: e.target.checked,
                  booking_name: preventivo.booking_name,
                });
              }}
              checked={newRoom.pin}
              value={newRoom.pin}
            >
              Crea una prenotazione per riservare la stanza all'ospite fino a
              Scadenza del preventivo o all'accettazione.
            </Checkbox>
          </Form.Item>
          {newRoom.pin && (
            <Form.Item label="Nome della prenotazione">
              <StyleProvider hashPriority="high">
                {preventivo.booking_name && preventivo.booking_name !== "" ? (
                  <div className="flex flex-col">
                    <span className="text-blue-500 font-semibold">
                      {preventivo.booking_name}
                    </span>
                    <span className="text-blue-300">
                      La nuova stanza viene collegata alla prenotazione già
                      esistente
                    </span>
                  </div>
                ) : (
                  <Input
                    placeholder={"Inserisci un nome"}
                    value={newRoom.booking_name}
                    onChange={(e) => {
                      setNewRoom({ ...newRoom, booking_name: e.target.value });
                    }}
                    defaultValue={"PR." + preventivo.name}
                  />
                )}
              </StyleProvider>
            </Form.Item>
          )}
          {newRoom.pin && !preventivo.id_customer && (
            <Form.Item label="Nome del cliente della prenotazione">
              <StyleProvider hashPriority="high">
                <Input
                  placeholder={
                    "Inserisci il nome del responsabile (Es. Mario Rossi)"
                  }
                  value={newRoom.customer_name}
                  onChange={(e) => {
                    setNewRoom({ ...newRoom, customer_name: e.target.value });
                  }}
                />
              </StyleProvider>
            </Form.Item>
          )}
        </Form>
        {isValidPeriod === false ? (
          <Alert
            message={"Nessuna disponibilità"}
            description={
              "Sembra che il periodo selezionato per questa categoria sia tutto pieno. Assicurati della tua disponibilità sul tuo Planner."
            }
            type="error"
            showIcon
          />
        ) : (
          isValidPeriod && (
            <Alert
              message={"Il periodo selezionato è valido"}
              description={
                "La categoria selezionata per il periodo inserito ha disponibilità."
              }
              type="success"
              showIcon
            />
          )
        )}
      </Spin>
    </Drawer>
  );
};
export default NewRowRoom;
