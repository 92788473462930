import React from "react";
import PreventivoHelper from "../PreventivoHelper";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { placeholders } from "../Communication/MessagePlaceholders";

const content = (
  <div className="max-w-md max-h-52 overflow-y-auto">
    Quando il cliente aprirà il preventivo, questo testo sarà la prima cosa che
    leggerà, assicurati di scrivere un messaggio che esprima empatia, sicurezza
    e professionalità.
    <br></br>
    Ti ricordiamo che in questo campo è possibile inserire dei campi "
    <b>placeholder</b>", ovvero delle etichette che, quando saranno visualizzate
    dal cliente, saranno sostituite con il con un valore preciso.
    <br></br>
    <br></br>
    Esempio:{" "}
    <span className="text-blue-500">
      <i>%cliente.nome%</i>
    </span>{" "}
    sarà sostiuito con il nome del cliente di questo preventivo es.{" "}
    <i>Mario Rossi</i>
    <br></br>
    <br></br>
    Di seguito ti riportiamo la lista dei placeholder disponibili:
    <br></br>
    <ul>
      {placeholders?.map((item, index) => (
        <li key={"placeholder-ex-" + index}>
          <span className="text-blue-500">%{item.value}%</span>
          <span className="ml-2">{item.label}</span>
        </li>
      ))}
    </ul>
  </div>
);

function MessageHelper() {
  return (
    <PreventivoHelper
      title={<h3 className="text-lg">Il tuo messaggio al cliente</h3>}
      content={content}
    >
      <QuestionCircleOutlined
        className="text-blue-500"
        style={{ fontSize: "18px" }}
      />
    </PreventivoHelper>
  );
}

export default MessageHelper;
