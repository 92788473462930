import React from "react";
import MultipleImageUploadComponent from "../imageUpload";
import {
  Bolder,
  Container,
  Helper,
  SecondaryText,
  Text,
  Title,
} from "../styles";
import TextInput from "../textInput";
import WizardButtons from "../wizardButtons";

export default function Step7(props) {
  const [cannotProceed, setCannotProceed] = React.useState(false);

  return (
    <>
      <Container>
        <Helper>
          <Title>
            🖼️Carica delle belle <font color="#2b5cdf">foto</font> delle tue
            stanze
          </Title>
          <Text>
            La maggior parte dei clienti sceglieranno una stanza per la sua
            immagine, scegli delle foto <b>accattivanti</b>.
            <br />
            <br />
            In questa procedura è consentito caricare massimo <b>3 foto</b> se
            si desidera caricarne altre sarà necessario farlo dal proprio
            account di <b>IdaWeb</b>.
            <br />
            <br />
            E' necessario caricare foto con una risoluzione di almeno{" "}
            <b>1920</b> x <b>1080</b>.
            <br />
            <br />
          </Text>
          <SecondaryText>
            Questo step è <b>opzionale</b>.
          </SecondaryText>
        </Helper>

        <MultipleImageUploadComponent
          images={props.images}
          setImages={props.setImages}
        />
      </Container>

      <WizardButtons
        cannotProceed={cannotProceed}
        currentStep={props.currentStep}
        previousStep={props.previousStep}
        totalSteps={props.totalSteps}
        nextStep={props.nextStep}
      />
    </>
  );
}
