import React from "react";

function Widget(props) {
  return (
    <div
      className="w-full bg-white rounded-md border-0 flex flex-col
     text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
     placeholder:text-gray-400 focus:ring-2 focus:ring-inset
     focus:ring-indigo-600 sm:text-sm sm:leading-6 p-5"
    >
      {props.title && (
        <h2 className="text-center text-xl font-bold leading-9 tracking-tight text-gray-900 mb-3">
          {props.title}
        </h2>
      )}

      {props.children}
    </div>
  );
}

export default Widget;
