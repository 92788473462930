import React from "react";
import PreventivoHelper from "../PreventivoHelper";
import { QuestionCircleOutlined } from "@ant-design/icons";

const content = (
  <div className="max-w-md max-h-52 overflow-y-auto">
    Specifica di seguito in maniera esauriente tutte le condizioni per
    l'accettazione del preventivo, eventuali eccezioni o termini.
    <br></br>
    <br></br>
    Si possono specificare orari, metodi di checkin e qualsiasi cosa possa
    aiutare la struttura ad assicurarsi un'ottima accomodation per l'ospite
    rispettando le necessità dell'albergatore.
  </div>
);

function CondizioniHelper() {
  return (
    <PreventivoHelper
      title={<h3 className="text-lg">Le condizioni del preventivo</h3>}
      content={content}
    >
      <QuestionCircleOutlined
        style={{ fontSize: "18px" }}
        className="text-blue-500"
      />
    </PreventivoHelper>
  );
}

export default CondizioniHelper;
