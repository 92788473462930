import { Grid } from "@mui/material";
import React from "react";
import MultipleImageUploadComponent from "../imageUpload";
import {
  Bolder,
  Container,
  Flex,
  Helper,
  SecondaryText,
  Text,
  Title,
} from "../styles";
import TextInput from "../textInput";
import VariantButton from "../variationButton";
import WizardButtons from "../wizardButtons";

export default function Step8(props) {
  const [cannotProceed, setCannotProceed] = React.useState(false);

  React.useEffect(() => {
    var cannotProceedVar = false;
    props.state.channels.map((channel) => {
      if (!channel.value || parseInt(channel.value) === 0) {
        cannotProceedVar = true;
        return;
      }
    });
    setCannotProceed(cannotProceedVar);
  }, [props.state.channels]);

  return (
    <>
      <Container>
        <Helper>
          <Title>
            🔗Inserisci le maggiorazioni dei tuoi{" "}
            <font color="#2b5cdf">canali OTA</font>
          </Title>
          <Text>
            Ogni canale OTA (Booking, AirBnB,...) richiede una <b>franchigia</b>{" "}
            per ogni prenotazione confermata (<b>18%, 10%,</b>...) che varia da
            struttura a struttura.
            <br></br>
            <br></br>
            Inserisci di seguito le tue maggiorazioni.
          </Text>
        </Helper>
        <Bolder>Risposta</Bolder>

        {props.state.channels.map((channel, i) => {
          return (
            <Grid
              container
              spacing={2}
              key={channel.id}
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Grid item lg={1} xs={1}>
                <img
                  src={channel.image}
                  style={{
                    width: "32px",
                    borderRadius: "999px",
                    objectFit: "cover",
                  }}
                  alt="logo-ota"
                />
              </Grid>
              <Grid item lg={3} xs={11}>
                <span
                  style={{
                    color: "var(--text-primary)",
                    fontSize: "20px",
                    fontWeight: 600,
                  }}
                >
                  {channel.name}
                </span>
              </Grid>
              <Grid item lg={2} xs={3}>
                <VariantButton
                  value={props.state.channels[i].type}
                  onSelect={(newValue) => {
                    props.setState({
                      ...props.state,
                      channels: props.state.channels.map((ch) => {
                        if (ch.id === channel.id) ch.type = newValue;
                        return ch;
                      }),
                    });
                  }}
                />
              </Grid>
              <Grid item lg={6} xs={9}>
                <TextInput
                  placeholder={"18"}
                  value={props.state.channels[i].value}
                  type="number"
                  onChange={(e) =>
                    props.setState({
                      ...props.state,
                      channels: props.state.channels.map((ch) => {
                        if (ch.id === channel.id) ch.value = e.target.value;
                        return ch;
                      }),
                    })
                  }
                />
              </Grid>
            </Grid>
          );
        })}
      </Container>

      <WizardButtons
        cannotProceed={cannotProceed}
        currentStep={props.currentStep}
        previousStep={props.previousStep}
        totalSteps={props.totalSteps}
        nextStep={props.nextStep}
      />
    </>
  );
}
