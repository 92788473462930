import React from "react";
import cloneDeep from "lodash/cloneDeep";

function PaymentProducts({
  products,
  focused_products,
  setFocusedProducts,
  setProducts,
}) {
  return (
    <div className="w-full h-full flex flex-col gap-5 p-5 overflow-y-auto">
      {products?.map((product, index) => (
        <div className="flex flex-row items-center p-3 bg-white rounded-lg shadow-lg">
          <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
            <img
              src={"https://www.idaweb.eu/" + product.imageSrc}
              alt={product.imageAlt}
              className="h-full w-full object-cover object-center"
              loading="lazy"
            />
          </div>
          <div className="ml-4 flex flex-1 flex-col">
            <div>
              <div className="flex justify-between text-base font-medium text-gray-900">
                <h3>
                  <a href={product.href}>{product.name}</a>
                </h3>
                <p className="ml-4">€ {product.price}</p>
              </div>
              <p className="mt-1 text-sm text-gray-500">{product.imageAlt}</p>
            </div>
            <div className="flex flex-1 items-end justify-between text-sm">
              <div className="flex flex-row items-center gap-5 mt-4">
                <label
                  htmlFor="price"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Quantità
                </label>
                <div className="relative rounded-md shadow-sm">
                  <select
                    name="price"
                    id="price"
                    className="block w-full rounded-md border-0 py-1.5  text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={product.quantity}
                    onChange={(e) => {
                      setProducts(
                        products.map((prod) => {
                          if (prod.id === product.id)
                            prod.quantity = e.target.value;
                          return prod;
                        })
                      );
                    }}
                  >
                    {Array.from(Array(100), (e, i) => {
                      return <>{i > 0 && <option value={i}>{i}</option>}</>;
                    })}
                    <option value="all">Altro</option>
                  </select>
                </div>
              </div>

              <div className="flex">
                <button
                  type="button"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                  onClick={() => {
                    const new_product = cloneDeep(
                      products.filter((el) => el.id === product.id)[0]
                    );

                    const existing_product = focused_products.filter(
                      (el) => el.id === product.id
                    )[0];

                    if (existing_product) {
                      var existing_product_new = cloneDeep(existing_product);
                      setFocusedProducts(
                        focused_products.map((el) => {
                          if (el.id === existing_product_new.id) {
                            existing_product_new.quantity =
                              parseInt(existing_product_new.quantity) + 1;
                            return existing_product_new;
                          }
                          return el;
                        })
                      );
                    } else
                      setFocusedProducts([...focused_products, new_product]);
                  }}
                >
                  Aggiungi
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default PaymentProducts;
