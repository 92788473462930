import React from "react";
import { PhotographIcon, UserCircleIcon } from "@heroicons/react/outline";
import { useNavigate, useParams } from "react-router-dom";
import StructureSelect from "./StructureSelect";
import WebsiteConfig from "./WebsiteConfig";
import { Toaster, toast } from "react-hot-toast";
import webService from "../../services/web.service";
import WebsiteStatusSelect from "./WebsiteStatusSelect";
import ColorsSelect from "./ColorSelect";
import { colors } from "./Colors";

function EditConfig() {
  const { token, id_config } = useParams();
  const [config, setConfig] = React.useState({
    id_config: null,
    config_name: "",
    url_video: "",
    color: "",
    white_logo: "",
    structure: {
      id_structure: null,
      reg_business_name: "",
    },
  });
  const [canSave, setCanSave] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    var can_save = true;
    if (!config.config_name) can_save = false;
    setCanSave(can_save);
  }, [config]);

  React.useEffect(() => {
    if (id_config) fetchConfig();
  }, []);

  const saveConfig = async () => {
    setCanSave(false);

    const save_config = {
      ...config,
      id_structure: config.structure.id_structure,
    };

    if (id_config) {
      webService.patchConfig(token, save_config).then(
        (result) => {
          const status = result.status;
          if (status === 200) {
            toast.success("La configurazione è stata salvata.");
            setCanSave(true);
          } else {
            toast.error("Si è verificato un errore");
            setCanSave(true);
          }
        },
        (error) => {
          toast.error("Si è verificato un errore");
          setCanSave(true);
        }
      );
    } else {
      webService.saveConfig(token, save_config).then(
        (result) => {
          const status = result.status;
          result = result.data;
          if (status === 201) {
            toast.success("La configurazione è stata salvata.");
            navigate("/website/config/edit/" + token + "/" + result.id_config);
          } else {
            toast.error("Si è verificato un errore");
            setCanSave(true);
          }
        },
        (error) => {
          toast.error("Si è verificato un errore");
          setCanSave(true);
        }
      );
    }
  };
  const deleteConfig = async () => {
    webService.deleteConfig(token, id_config).then(
      (result) => {
        const status = result.status;
        if (status === 200) {
          toast.success("La configurazione è stata eliminata.");
          navigate("/website/config/" + token);
        } else {
          toast.error("Si è verificato un errore");
          setCanSave(true);
        }
      },
      (error) => {
        toast.error("Si è verificato un errore");
        setCanSave(true);
      }
    );
  };
  const fetchConfig = async () => {
    webService.getConfig(token, id_config).then(
      (result) => {
        const status = result.status;
        if (status === 200) {
          setConfig(result.data);
        } else {
          toast.error("Si è verificato un errore");
          setCanSave(true);
        }
      },
      (error) => {
        toast.error("Si è verificato un errore");
        setCanSave(true);
      }
    );
  };

  return (
    <form className="bg-white p-10">
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-xl font-semibold leading-7 text-gray-900">
            {id_config ? "Modifica " : "Nuova "} configurazione
          </h2>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="">
              <StructureSelect
                value={config.structure}
                onChange={(e) => {
                  setConfig({ ...config, structure: e });
                }}
              />
            </div>
            <div className="">
              <label
                htmlFor="username"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Nome configurazione
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="text"
                    className="ml-2 block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="Nome configurazione"
                    onChange={(e) =>
                      setConfig({ ...config, config_name: e.target.value })
                    }
                    value={config.config_name}
                  />
                </div>
              </div>
            </div>
            <div className="">
              <label
                htmlFor="username"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                URL video
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="text"
                    className="ml-2 block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="https://youtube.com/..."
                    onChange={(e) =>
                      setConfig({ ...config, url_video: e.target.value })
                    }
                    value={config.url_video}
                  />
                </div>
              </div>
            </div>
            <div className="">
              <WebsiteStatusSelect
                selected={{ id: 0, value: config.is_in_maintenance }}
                setSelected={(e) => {
                  setConfig({ ...config, is_in_maintenance: e.value });
                }}
              />
            </div>
            <div className="">
              <ColorsSelect
                selected={
                  colors.filter((el) => el.value === config.color).length === 1
                    ? colors.filter((el) => el.value === config.color)[0]
                    : colors[0]
                }
                setSelected={(e) => setConfig({ ...config, color: e.value })}
              />
            </div>
            <div className="">
              <label
                htmlFor="username"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                URL Logo Bianco
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="text"
                    className="ml-2 block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="https://..."
                    onChange={(e) =>
                      setConfig({ ...config, white_logo: e.target.value })
                    }
                    value={config.white_logo}
                  />
                </div>
              </div>
            </div>

            <div className="col-span-full">
              {!id_config ? (
                <div className="flex flex-row items-center text-red-500 gap-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                    />
                  </svg>
                  <h2>
                    Bisogna prima salvare la nuova configurazione prima di
                    modificare questi parametri
                  </h2>
                </div>
              ) : (
                <>
                  <h2 className="text-xl font-semibold leading-7 text-gray-900">
                    Dettagli sito
                  </h2>

                  <WebsiteConfig />
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        {id_config && (
          <button
            type="button"
            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 mr-auto"
            onClick={() => {
              if (window.confirm("Eliminare la configurazione corrente?")) {
                deleteConfig();
              }
            }}
          >
            Elimina
          </button>
        )}

        <button
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
          onClick={() => navigate("/website/config/" + token)}
        >
          Annulla
        </button>
        <button
          className={`rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
            !canSave ? "!pointer-events-none !bg-gray-100 !text-gray-400" : ""
          }`}
          disabled={!canSave}
          onClick={saveConfig}
        >
          Salva
        </button>
      </div>
      <Toaster />
    </form>
  );
}

export default EditConfig;
