import React, { useEffect, useState } from "react";
import preventiviService from "../../services/preventivi.service";
import { useParams } from "react-router-dom";
import { Alert } from "antd";

function PreventivoAlerts({ preventivo }) {
  const { token } = useParams();
  const [alerts, setAlerts] = useState([]);

  const fetchData = () => {
    preventiviService
      .getPreventivoAlerts(token + "/" + preventivo.id_preventivo)
      .then(
        (result) => {
          result = result.data;
          setAlerts(result);
        },
        (error) => {}
      );
  };

  const deleteAlert = async (id_preventivo_alert) => {
    preventiviService.deletePreventivoAlert(token, id_preventivo_alert).then(
      (result) => {
        if (result.status === 200)
          setAlerts(
            alerts.filter(
              (el) => el.id_preventivo_alert !== id_preventivo_alert
            )
          );
      },
      (error) => {}
    );
  };

  useEffect(() => {
    if (preventivo.id_preventivo) fetchData();
  }, [preventivo]);

  return (
    <div className="flex flex-col gap-2 pt-5 px-10">
      {alerts?.map((alert, index) => (
        <Alert
          message={alert.title}
          description={alert.text}
          type={
            alert.alert_type === 0
              ? "success"
              : alert.alert_type === 1
              ? "info"
              : alert.alert_type === 2
              ? "error"
              : "warning"
          }
          showIcon
          key={"alert-" + index}
          closable
          onClose={() => deleteAlert(alert.id_preventivo_alert)}
        />
      ))}
    </div>
  );
}

export default PreventivoAlerts;
