import React from "react";
import DynamicHeroIcon from "../../../../components/heroicons/hicon";
import Collapse from "../collapse";
import {
  Bolder,
  Container,
  Flex,
  Helper,
  HelperText,
  Text,
  Title,
} from "../styles";
import TextInput from "../textInput";
import WizardButtons from "../wizardButtons";
import cloneDeep from "lodash/cloneDeep";
import pricesService from "../../../../services/prices.service";

export default function Step1(props) {
  const [cannotProceed, setCannotProceed] = React.useState(false);

  React.useEffect(() => {
    if (!props.state.n_categories || parseInt(props.state.n_categories) === 0)
      setCannotProceed(true);
    else setCannotProceed(false);
  }, [props.state.n_categories]);

  return (
    <>
      <Container>
        <Helper>
          <Title>
            🏷️ Quante <font color="#2b5cdf">categorie</font> vendi?
          </Title>

          <Text>
            Inserisci il numero di categorie che vendi attualmente sul tuo{" "}
            <b>Booking.com</b>.<br></br>Ti basterà accedere al tuo account
            Booking.com, navigare sul calendario e contare quante categorie
            vengono mostrate.
            <br></br>
            <br></br>
            Se non hai Booking.com esegui lo stesso procedimento sul tuo
            portale.
          </Text>

          <Collapse
            title={
              <Flex>
                <DynamicHeroIcon
                  icon="QuestionMarkCircleIcon"
                  style={{
                    width: "24px",
                    color: "var(--primary)",
                    marginRight: "10px",
                  }}
                />{" "}
                Non sai come controllare quali categorie hai?
              </Flex>
            }
            style={{ marginTop: "30px" }}
          >
            <HelperText>
              * con <b>categorie</b> intendiamo un gruppo di camere vendute allo
              stesso modo e allo stesso prezzo, Es: Camere Singole, Camere
              Doppie. La categoria può essere composta da una o più stanze.
            </HelperText>

            <img
              src={process.env.PUBLIC_URL + "/assets/prices/img1.png"}
              className="img1"
              style={{ width: "100%", marginTop: "20px" }}
            />
          </Collapse>
        </Helper>

        <Bolder>Risposta</Bolder>
        <select
          value={props.state.n_categories}
          className="custom-input"
          onChange={(e) => {
            var new_categories = [];
            var n_categories = parseInt(e.target.value);

            for (let i = 0; i < n_categories; i++) {
              new_categories.push({
                name: "",
                description_it: "",
                description_en: "",
                nguests: 1,
                rooms: cloneDeep(props.rooms),
                base_price: 0,
              });
            }
            props.setState({
              ...props.state,
              n_categories: e.target.value,
              categories: new_categories,
            });
          }}
        >
          {[...Array(parseInt(20))].map((e, i) => (
            <option value={i + 1} key={"categories-step1-" + i + 1}>
              {i + 1}
            </option>
          ))}
        </select>
      </Container>
      <WizardButtons
        cannotProceed={cannotProceed}
        currentStep={props.currentStep}
        previousStep={props.previousStep}
        totalSteps={props.totalSteps}
        nextStep={props.nextStep}
      />
    </>
  );
}
