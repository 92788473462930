import { Button, Empty, Spin, Tooltip } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useRef } from "react";
import { SendOutlined } from "@ant-design/icons";
function ChatBox({ messages, setOpen, isPublic }) {
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // useEffect(() => {
  //   if (messages.length > 0) scrollToBottom();
  // }, [messages]);

  const GuestMessage = ({ message }) => {
    return (
      <div class="col-start-1 col-end-12 md:col-end-8 p-3 rounded-lg">
        <div class="flex flex-row items-start">
          <div class="flex items-center justify-center h-10 w-10 rounded-full bg-slate-400 flex-shrink-0 text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-6 h-6"
            >
              <path
                fill-rule="evenodd"
                d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div className="flex flex-col ml-3">
            <div class="relative text-sm bg-white py-2 px-4 shadow rounded-xl gap-2 flex flex-col">
              {message.subject && (
                <div className="font-semibold text-gray-800">
                  {message.subject}
                </div>
              )}
              <div dangerouslySetInnerHTML={{ __html: message.body }}></div>
            </div>
            <span className=" text-gray-400 text-sm mt-1 ml-3">
              {dayjs(message.creation_date).format("DD/MM/YY, HH:mm:ss")}
            </span>
          </div>
        </div>
      </div>
    );
  };
  const StructureMessage = ({ message }) => {
    return (
      <div class="col-start-1 md:col-start-6 col-end-13 p-3 rounded-lg">
        <div class="flex items-start justify-start flex-row-reverse">
          <div class="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 text-white flex-shrink-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-6 h-6"
            >
              <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
              <path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
            </svg>
          </div>
          <div className="flex flex-col mr-3 w-full">
            <div class="relative text-sm bg-indigo-100 py-2 px-4 shadow rounded-xl gap-2 flex flex-col">
              <div className="flex flex-row w-full">
                <div className="font-semibold text-gray-800">
                  {message.subject}
                </div>
                {message.sent_date && !isPublic && (
                  <Tooltip
                    title={
                      "Il messaggio è stato inoltrato al cliente alle " +
                      dayjs(message.sent_date).format("DD/MM/YYYY HH:mm:ss")
                    }
                  >
                    <SendOutlined
                      className="ml-auto text-green-700"
                      rotate="-120"
                    />
                  </Tooltip>
                )}
              </div>
              <div dangerouslySetInnerHTML={{ __html: message.body }}></div>
              <div className="text-indigo-400">@{message.email_address}</div>
            </div>
            <span className=" text-gray-400 text-sm mt-1 ml-auto mr-1">
              {dayjs(message.creation_date).format("DD/MM/YY, HH:mm:ss")}
            </span>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="grid grid-cols-12 gap-y-2">
      {messages?.map((message, index) => (
        <>
          {message.sender === 0 ? (
            <GuestMessage message={message} key={"guest-message-" + index} />
          ) : (
            <StructureMessage
              message={message}
              key={"structure-message-" + index}
            />
          )}
        </>
      ))}
      {messages?.length === 0 && (
        <div className="w-full flex items-center justify-center col-span-12">
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <span className="text-gray-400">
                E' un pò troppo silenzioso qui...
              </span>
            }
          />
        </div>
      )}
      <div ref={messagesEndRef} />
    </div>
  );
}

export default ChatBox;
