import * as React from "react";
import Grid from "@mui/material/Grid";
import Language from "../language/language";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DynamicHeroIcon from "../heroicons/hicon";
import CircularProgress from "@mui/material/CircularProgress";
import { GetLabel } from "../../languages/i18n";
import { useNavigate } from "react-router-dom";

export default function HorizontalBar(props) {
  return (
    <div key={props.uuid}>
      <Grid
        container
        className="horizontal-bar"
        sx={{ paddingLeft: { sm: "10px", xs: "10px" } }}
      >
        <Grid
          item
          sm={6}
          xs={12}
          sx={{
            mt: { xs: 1, sm: 0 },
            justifyContent: { xs: "center", sm: "left" },
          }}
          className="structure-details"
        >
          <Grid container>
            <Grid
              item
              sm={2}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: { xs: "10px", sm: 0 },
              }}
            >
              <img
                src={"https://www.idaweb.eu" + props.structure_logo}
                style={{
                  width: "128px",
                  maxHeight: "64px",
                  objectFit: "cover",
                }}
              ></img>
              {/* <img src="https://bookinghotel.qcterme.com/Repository/2489//Loghi/BormioBV1.png" style={{width:'128px'}}></img> */}
            </Grid>
            <Grid
              item
              sm={10}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", sm: "left" },
                alignItems: "center",
              }}
            >
              {props.isStructureLoading ? (
                <CircularProgress className="loading-indicator" />
              ) : (
                props.structure_name
              )}
              <Box
                component="span"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                <span
                  style={{
                    marginLeft: "5px",
                    marginRight: "5px",
                    color: "#adadad",
                  }}
                >
                  •
                </span>
                {props.isStructureLoading ? (
                  <CircularProgress className="loading-indicator" />
                ) : (
                  props.structure_address
                )}
                <span
                  style={{
                    marginLeft: "5px",
                    marginRight: "5px",
                    color: "#adadad",
                  }}
                >
                  •
                </span>
                {props.isStructureLoading ? (
                  <CircularProgress className="loading-indicator" />
                ) : (
                  props.structure_location
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sm={6}
          xs={12}
          className="buttons"
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "right" },
            alignItems: "center",
            marginTop: { xs: "10px", sm: "0" },
          }}
        >
          <Grid container spacing={0} sx={{ justifyContent: "end" }}>
            <Grid
              item
              sm={5}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", sm: "end" },
              }}
            >
              <Button
                variant="text"
                className="action-button"
                onClick={() => {
                  window.location.href = "mailto:" + props.structure_email;
                }}
              >
                <DynamicHeroIcon
                  icon={"MailIcon"}
                  className="button-icon"
                ></DynamicHeroIcon>
                {props.isStructureLoading ? (
                  <CircularProgress className="loading-indicator" />
                ) : (
                  props.structure_email
                )}
              </Button>
            </Grid>
            <Grid
              item
              sm={3}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", sm: "center" },
              }}
            >
              <Button variant="text" className="action-button">
                <DynamicHeroIcon
                  icon={"PhoneIcon"}
                  className="button-icon"
                ></DynamicHeroIcon>
                {props.isStructureLoading ? (
                  <CircularProgress className="loading-indicator" />
                ) : (
                  props.structure_phone
                )}
              </Button>
            </Grid>
            <Grid
              item
              sm={3}
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="text"
                className="action-button"
                onClick={() =>
                  window.location.replace(
                    " https://www.idaweb.eu/be/be_reservation/LanguageBE/IT/" +
                      props.uuid
                  )
                }
              >
                <DynamicHeroIcon
                  icon={"BookmarkIcon"}
                  className="button-icon"
                ></DynamicHeroIcon>
                {GetLabel(props.language, "LABEL_GESTISCI_PRENOTAZIONE")}
              </Button>
            </Grid>
            <Grid
              item
              sm={1}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", sm: "right" },
              }}
            >
              <Language
                className="language-box"
                setLanguage={props.setLanguage}
                language={props.language}
              ></Language>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
