import React from "react";
import {
  Alert,
  Button,
  Divider,
  Form,
  Tooltip,
  Typography,
  Modal,
  Input,
  Spin,
  Popconfirm,
  Popover,
} from "antd";
import { Card } from "antd";
import { toast } from "react-hot-toast";
import PreventivoCardRow from "./PreventivoCardRow";
import PreventivoStatus from "./PreventivoStatus";
import PreventivoCustomer from "./PreventivoCustomer";
import { useParams } from "react-router-dom";
import PreventivoLingua from "./PreventivoLingua";
const { Paragraph } = Typography;

function PreventivoDettagli({ preventivo, UpdatePreventivo }) {
  const { token } = useParams();

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  return (
    <Card title="Dettagli del preventivo" bordered={false}>
      <Form
        labelCol={{
          span: 4,
        }}
        layout="horizontal"
      >
        <PreventivoCardRow
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 text-slate-600"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8.25 9.75h4.875a2.625 2.625 0 010 5.25H12M8.25 9.75L10.5 7.5M8.25 9.75L10.5 12m9-7.243V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185z"
              />
            </svg>
          }
          label={"Stato"}
          text={<PreventivoStatus status={preventivo.status} />}
          value={preventivo.status}
        />
        <PreventivoCardRow
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 text-slate-600"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          }
          label={"Cliente"}
          text={
            <>
              {preventivo && preventivo.id_preventivo && (
                <div className="w-full md:w-[600px]">
                  <PreventivoCustomer
                    id_customer={preventivo.id_customer}
                    id_firm={preventivo.id_firm}
                    name_free={preventivo.name_free}
                    surname_free={preventivo.surname_free}
                    preventivo={preventivo}
                    UpdatePreventivo={UpdatePreventivo}
                    token={token}
                    key="customer-select"
                  />
                </div>
              )}
            </>
          }
          value={preventivo.id_customer || preventivo.id_firm}
        />
        <PreventivoCardRow
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 text-slate-600"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3 3v1.5M3 21v-6m0 0l2.77-.693a9 9 0 016.208.682l.108.054a9 9 0 006.086.71l3.114-.732a48.524 48.524 0 01-.005-10.499l-3.11.732a9 9 0 01-6.085-.711l-.108-.054a9 9 0 00-6.208-.682L3 4.5M3 15V4.5"
              />
            </svg>
          }
          label={"Lingua"}
          text={
            <PreventivoLingua
              language={preventivo.language}
              UpdatePreventivo={UpdatePreventivo}
            />
          }
          value={preventivo.language}
        />

        {preventivo.feedback && (
          <PreventivoCardRow
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5 text-slate-600"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                />
              </svg>
            }
            label={"Feedback"}
            text={
              <span className="text-red-600 !text-sm">
                {preventivo.feedback}
              </span>
            }
            value={preventivo.feedback}
          />
        )}

        <PreventivoCardRow
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 text-slate-600"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
              />
            </svg>
          }
          label={"E-mail"}
          text={
            <Paragraph
              editable={{
                text: preventivo.email_free,
                onChange: (e) => {
                  if (!isValidEmail(e))
                    toast.error("Inserire una mail valida.");
                  else UpdatePreventivo("2", e);
                },
                tooltip: "Modifica la mail",
              }}
              className="!m-0 flex flex-row items-center"
            >
              {preventivo.email_free ? (
                <>{preventivo.email_free}</>
              ) : (
                <div className="flex flex-row items-center gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 text-slate-400"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                    />
                  </svg>
                  <span className="text-slate-400">vuoto</span>
                </div>
              )}
            </Paragraph>
          }
          value={preventivo.email_free}
          editable={false}
        />
        <PreventivoCardRow
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 text-slate-600"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
              />
            </svg>
          }
          label={"Telefono"}
          text={
            <Paragraph
              editable={{
                text: preventivo.phone_free,
                onChange: (e) => {
                  UpdatePreventivo("3", e);
                },
                tooltip: "Modifica il telefono",
              }}
              className="!m-0 flex flex-row items-center"
            >
              {preventivo.phone_free ? (
                <>{preventivo.phone_free}</>
              ) : (
                <div className="flex flex-row items-center gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 text-slate-400"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                    />
                  </svg>
                  <span className="text-slate-400">vuoto</span>
                </div>
              )}
            </Paragraph>
          }
          value={preventivo.phone_free}
          editable={false}
        />
        <PreventivoCardRow
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 text-slate-600"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
              />
            </svg>
          }
          label={"Partita IVA"}
          text={
            <Paragraph
              editable={{
                text: preventivo.vat_number_free,
                onChange: (e) => {
                  UpdatePreventivo("4", e);
                },
                tooltip: "Modifica la partita IVA",
              }}
              className="!m-0 flex flex-row items-center"
            >
              {preventivo.vat_number_free ? (
                <>{preventivo.vat_number_free}</>
              ) : (
                <div className="flex flex-row items-center gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 text-slate-400"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                    />
                  </svg>
                  <span className="text-slate-400">vuoto</span>
                </div>
              )}
            </Paragraph>
          }
          value={preventivo.vat_number_free}
        />
        <PreventivoCardRow
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 text-slate-600"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
              />
            </svg>
          }
          label={"Note"}
          text={
            <Paragraph
              editable={{
                text: preventivo.notes,
                onChange: (e) => {
                  UpdatePreventivo("8", e);
                },
                tooltip:
                  "Modifica le note del preventivo (non saranno visibili al cliente)",
              }}
              className="!m-0 flex flex-row items-center"
            >
              {preventivo.notes ? (
                <>{preventivo.notes}</>
              ) : (
                <div className="flex flex-row items-center gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 text-slate-400"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                    />
                  </svg>
                  <span className="text-slate-400">vuoto</span>
                </div>
              )}
            </Paragraph>
          }
          value={preventivo.notes}
        />
      </Form>
    </Card>
  );
}

export default PreventivoDettagli;
