import { Button, Checkbox, Form, Input, Modal, Select } from "antd";
import React, { useState } from "react";
import { StyleProvider } from "@ant-design/cssinjs";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import { toast } from "react-hot-toast";
import preventiviService from "../../../services/preventivi.service";
import { useParams } from "react-router-dom";
import QuillEditor from "../../../components/quill/QuillEditor";
import NewMessagePlaceholder from "./NewMessagePlaceholder";
import PreviewMessage from "./PreviewMessage";

const NewMessage = ({
  preventivo,
  setPreventivo,
  open,
  setOpen,
  messages,
  setMessages,
}) => {
  const { token } = useParams();
  const [loading, setLoading] = useState(false);
  const [isDifferentEmail, setIsDifferentEmail] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [html, setHtml] = useState("");
  const [message, setMessage] = useState({
    body: preventivo.message,
    email_address: "",
    subject: preventivo.subject,
    forward_email: true,
    send_method: "email",
  });

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  React.useEffect(() => {
    if (open) {
      setMessage({
        body: preventivo.body,
        email_address: preventivo.email_free,
        subject: preventivo.subject,
        forward_email: true,
        send_method: "email",
        include_link: true,
      });
    }
  }, [open]);

  React.useEffect(() => {
    if (message.email_address !== preventivo.email_free)
      setIsDifferentEmail(true);
    else setIsDifferentEmail(false);

    var is_disabled = false;
    if (!message.email_address || !isValidEmail(message.email_address))
      is_disabled = true;
    if (!message.body) is_disabled = true;
    if (!message.subject) is_disabled = true;
    setIsDisabled(is_disabled);
  }, [message]);

  const sendEmail = () => {
    setLoading(true);
    const messageObj = {
      ...message,
      id_preventivo: preventivo.id_preventivo,
    };
    preventiviService.sendMessage(`${token}`, messageObj).then(
      (result) => {
        result = result.data;
        if (result.success) {
          toast.success("Il messaggio è stato inviato.");
          if (preventivo.status === 0 || preventivo.status === 5)
            setPreventivo({ ...preventivo, status: 1 });
          setMessages([...messages, result.model]);
        } else {
          toast.error(result.model);
          setMessages([
            ...messages,
            { ...message, creation_date: dayjs().toISOString() },
          ]);
        }
        setOpen(false);
        setLoading(false);
      },
      (error) => {
        toast.error(error.response.data.ExceptionMessage);
        setLoading(false);
      }
    );
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const getPreview = async () => {
    setLoading(true);
    const messageObj = {
      ...message,
      id_preventivo: preventivo.id_preventivo,
    };
    preventiviService.getMessagePreview(token, messageObj).then(
      (result) => {
        result = result.data;
        setHtml(result);
        setPreviewOpen(true);
        setLoading(false);
      },
      (error) => {
        toast.error(error.response.data.ExceptionMessage);
        setLoading(false);
      }
    );
  };
  return (
    <>
      <PreviewMessage
        open={previewOpen}
        setOpen={setPreviewOpen}
        html={html}
        setHtml={setHtml}
      />
      <Modal
        open={open}
        title="Invia un nuovo messaggio al cliente"
        onOk={sendEmail}
        onCancel={handleCancel}
        footer={[
          <Button
            type="dashed"
            loading={loading}
            onClick={getPreview}
            disabled={isDisabled}
            className="float-left"
          >
            Anteprima
          </Button>,
          <Button key="back" onClick={handleCancel}>
            Annulla
          </Button>,
          <Button
            type="primary"
            loading={loading}
            onClick={sendEmail}
            disabled={isDisabled}
          >
            Invia Messaggio
          </Button>,
        ]}
      >
        <Form layout="vertical" className="mt-5 w-full">
          <StyleProvider hashPriority="high">
            <Form.Item
              label={"Email Destinatario"}
              labelAlign="left"
              className="!m-0 !mt-4"
            >
              <Input
                placeholder={"Inserisci la mail del cliente"}
                value={message.email_address}
                onChange={(e) => {
                  setMessage({ ...message, email_address: e.target.value });
                }}
                status={isDifferentEmail ? "warning" : ""}
              />
              {isDifferentEmail && (
                <div className="text-orange-400 flex flex-row items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                    />
                  </svg>

                  <span>
                    L'indirizzo email inserito non corrisponde a quello del
                    preventivo
                  </span>
                </div>
              )}
            </Form.Item>

            <Form.Item
              label={"Oggetto del messaggio"}
              labelAlign="left"
              className="!m-0 !mt-4"
            >
              <Input
                placeholder={"Inserisci l'oggetto"}
                value={message.subject}
                onChange={(e) => {
                  setMessage({ ...message, subject: e.target.value });
                }}
              />
            </Form.Item>

            <Form.Item
              label={"Corpo del messaggio"}
              labelAlign="left"
              className="!m-0 !mt-4 !mb-0 relative"
            >
              <div className="flex flex-col gap-2">
                <NewMessagePlaceholder
                  message={message}
                  setMessage={setMessage}
                />
                <QuillEditor
                  value={message.body}
                  setValue={(e) => setMessage({ ...message, body: e })}
                  fullWidth={true}
                />
              </div>
            </Form.Item>

            <Form.Item
              label={"Metodo di invio"}
              labelAlign="left"
              className="!m-0 !mt-4"
            >
              <Select
                defaultValue="email"
                onChange={(e) =>
                  setMessage({ ...message, send_method: e.value })
                }
                options={[
                  {
                    value: "email",
                    label: "E-mail",
                  },
                  {
                    value: "whatsapp",
                    label: "Whatsapp (in arrivo)",
                    disabled: true,
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              label={"Invia copia sulla mail del cliente"}
              labelAlign="left"
              className="!m-0 !mt-4"
            >
              <Checkbox
                value={message.forward_email}
                onChange={(e) =>
                  setMessage({ ...message, forward_email: e.target.checked })
                }
                checked={message.forward_email}
              >
                <span className="text-slate-500">
                  Spuntando questa casella il messaggio sarà inviato oltre che
                  sulla pagina del preventivo anche sulla mail del cliente.
                </span>
              </Checkbox>
            </Form.Item>

            <Form.Item
              label={"Includi link preventivo"}
              labelAlign="left"
              className="!m-0 !mt-4"
            >
              <Checkbox
                value={message.include_link}
                onChange={(e) =>
                  setMessage({ ...message, include_link: e.target.checked })
                }
                checked={message.include_link}
              >
                <span className="text-slate-500">
                  Spuntando questa casella se vuoi mostrare nella mail il
                  pulsante "Visualizza Preventivo"
                </span>
              </Checkbox>
            </Form.Item>
          </StyleProvider>
        </Form>
      </Modal>
    </>
  );
};
export default NewMessage;
