import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoadingIndicator from "../../components/LoadingIndicator";
import webService from "../../services/web.service";
import { toast } from "react-hot-toast";
import SlideOver from "./SlideOver";
import BestChoiceEditForm from "./BestChoiceEditForm";

function BestChoiceEdit({ language }) {
  const { token, id_config } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [bestChoiceEdit, setBestChoiceEdit] = React.useState(null);
  const [bestChoices, setBestChoices] = React.useState([]);

  React.useEffect(() => {
    if (id_config) fetchBestChoices();
  }, [language]);

  const fetchBestChoices = async () => {
    setLoading(true);
    webService.getBestChoices(token, id_config, language.value).then(
      (result) => {
        const status = result.status;
        if (status === 200) {
          setBestChoices(result.data);
        } else {
          toast.error("Si è verificato un errore");
        }
        setLoading(false);
      },
      (error) => {
        toast.error("Si è verificato un errore");
        setLoading(false);
      }
    );
  };

  const editBestChoice = (bestChoice) => {
    setBestChoiceEdit(bestChoice);
    setOpen(true);
  };

  const addNewBestChoice = () => {
    setBestChoiceEdit({
      id_best_choice: null,
      id_config: id_config,
      id_list_ota: null,
      is_official: false,
      price: 0.0,
      striked_price: 0.0,
      free_giveaway: "",
      source: "",
      url: "",
      image: "",
      free_giveaway_obj: {
        id_translation: null,
        group_uid: "",
        language: language.value,
        text: "",
      },
      pros: [
        {
          id_best_choice_pros: null,
          id_best_choice: null,
          choice_text: "",
          choice_text_obj: {
            id_translation: null,
            group_uid: "",
            language: language.value,
            text: "",
          },
        },
      ],
      source: "",
      source_obj: {
        id_translation: null,
        group_uid: "",
        language: language.value,
        text: "",
      },
      ota: {
        buyer_n: "",
        buyer: "",
      },
    });
    setOpen(true);
  };

  const BestChoice = ({ bestChoice }) => (
    <div className="rounded-lg bg-white shadow p-5">
      <div className="flex flex-row items-start">
        <div className="flex flex-col justify-start gap-2">
          <div className="flex flex-row items-center gap-2">
            {bestChoice.image && (
              <img
                src={bestChoice.image}
                alt={"bestChoice-" + bestChoice.id_best_choice}
                className="object-cover rounded-full w-6"
              />
            )}
            <h2 className="font-semibold text-lg">
              Categoria Prezzo #{bestChoice.id_best_choice}
            </h2>
          </div>
          {bestChoice.free_giveaway_obj.text && (
            <div className="text-sm px-2 rounded-full bg-indigo-50 text-indigo-600">
              {bestChoice.free_giveaway_obj.text}
            </div>
          )}
          <div className="w-full flex">
            {bestChoice.is_official && (
              <div className="text-sm px-2 rounded-full bg-green-50 text-green-600 w-auto">
                Prezzo Ufficiale
              </div>
            )}
            {bestChoice.source && (
              <div className="text-sm px-2 rounded-full bg-cyan-50 text-cyan-600 w-auto">
                {bestChoice.source_obj.text}
              </div>
            )}
          </div>
        </div>

        <span
          className="ml-auto cursor-pointer text-indigo-600 
                px-5 py-2 text-base font-medium 
                 hover:text-indigo-700"
          onClick={() => editBestChoice(bestChoice)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
            />
          </svg>
        </span>
      </div>
      <div className="mt-5 px-5 flex flex-row items-end">
        {/* <div className="flex flex-row w-full">
          <div className="w-1/3 font-semibold text-gray-500">Titolo:</div>
          <div className="w-2/3 font-semibold"></div>
        </div> */}
        <div className="w-full md:w-1/2">
          {bestChoice.pros.length > 0 && (
            <ul>
              {bestChoice.pros?.map((pro, index) => (
                <li className="list-disc">{pro.choice_text_obj.text}</li>
              ))}
            </ul>
          )}
        </div>
        <div className="w-full md:w-1/2 flex justify-end">
          <div className="flex flex-col">
            {bestChoice.striked_price &&
            parseInt(bestChoice.striked_price) > 0 ? (
              <div className="text-md line-through">
                {parseFloat(bestChoice.striked_price).toFixed(2)} &euro;
              </div>
            ) : (
              <></>
            )}
            <div className="text-2xl font-semibold text-green-700">
              {parseFloat(bestChoice.price).toFixed(2)} &euro;
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 px-5 truncate text-blue-500">
        <a href={bestChoice.url} target="_blank">
          {bestChoice.url}
        </a>
      </div>
    </div>
  );

  return (
    <>
      {loading ? (
        <div className="w-full h-full flex items-center justify-center">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <SlideOver open={open} setOpen={setOpen}>
            <BestChoiceEditForm
              bestChoice={bestChoiceEdit}
              setBestChoice={setBestChoiceEdit}
              bestChoices={bestChoices}
              setBestChoices={setBestChoices}
              language={language}
              setOpen={setOpen}
            />
          </SlideOver>

          <div className="bg-gray-100">
            <div className="pt-5 pr-5 w-full flex justify-end">
              <button
                className={`rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                onClick={addNewBestChoice}
                type="button"
              >
                Aggiungi Categoria
              </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5 p-5">
              {bestChoices?.map((bestChoice, index) => (
                <BestChoice
                  bestChoice={bestChoice}
                  key={"best-choice-" + index}
                />
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default BestChoiceEdit;
