import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoadingIndicator from "../../components/LoadingIndicator";
import webService from "../../services/web.service";
import { toast } from "react-hot-toast";

function BookingEngineEdit({ language }) {
  const { token, id_config } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [bookingEngine, setBookingEngine] = React.useState({
    translation: "",
    translation_obj: {
      id_translation: null,
      language: language.value,
      text: "",
    },
  });

  React.useEffect(() => {
    if (id_config) fetchBookingEngine();
  }, [language]);

  const fetchBookingEngine = async () => {
    setIsDisabled(true);
    webService.getBookingEngine(token, id_config, language.value).then(
      (result) => {
        setBookingEngine(result.data);
        setIsDisabled(false);
      },
      (error) => {
        toast.error("Si è verificato un errore");
        setIsDisabled(false);
      }
    );
  };

  const saveBookingEngine = async () => {
    setIsDisabled(true);
    const newBookingEngine = {
      ...bookingEngine,
      translation_obj: {
        ...bookingEngine.translation_obj,
        language: language.value,
      },
    };
    webService.saveBookingEngine(token, id_config, newBookingEngine).then(
      (result) => {
        if (result.status === 200)
          toast.success("Il Booking engine è stato salvato");
        else toast.error("Si è verificato un errore");
        setIsDisabled(false);
      },
      (error) => {
        toast.error("Si è verificato un errore");
        setIsDisabled(false);
      }
    );
  };

  return (
    <>
      {loading ? (
        <div className="w-full h-full flex items-center justify-center">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <div className="bg-gray-100">
            <div className="pt-5 pr-5 w-full flex justify-end">
              <button
                className={`rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                onClick={saveBookingEngine}
                type="button"
              >
                Salva Impostazioni Booking Engine
              </button>
            </div>
            <div className="flex flex-row">
              <div className="w-full p-5">
                <label
                  htmlFor="title-advisor"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Avviso sul Booking Engine
                </label>
                <div className="mt-2">
                  <div
                    className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 
                focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md bg-white"
                  >
                    <input
                      type="text"
                      className=" ml-2 block flex-1 border-0 bg-transparent py-1.5 pl-1  placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      value={bookingEngine.translation_obj.text}
                      onChange={(e) =>
                        setBookingEngine({
                          ...bookingEngine,
                          translation_obj: {
                            ...bookingEngine.translation_obj,
                            text: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default BookingEngineEdit;
