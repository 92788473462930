import React from "react";
import { Badge, Button, Table } from "antd";
import { useEffect, useState } from "react";
import preventiviService from "../../services/preventivi.service";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import PreventiviActions from "./PreventiviActions";
import { StyleProvider } from "@ant-design/cssinjs";
import { Input, Space } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import useStateCallback from "../../components/useStateCallback";
import PreventiviRequests from "./PreventiviRequests";

const { Search } = Input;

function Preventivi() {
  const { token } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [openDuplicate, setOpenDuplicate] = useStateCallback(false);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentId, setCurrentId] = useState();
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [nrequests, setNRequests] = useState(0);

  const columns = [
    {
      title: "Id",
      dataIndex: "id_preventivo",
    },
    {
      title: "Cliente",
      dataIndex: "surname_free",
      render: (row, record) => `${record.surname_free} ${record.name_free}`,
    },
    {
      title: "Nome Preventivo",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email_free",
    },
    {
      title: "Data Creazione",
      dataIndex: "creation_date",
    },
    {
      title: "Scadenza",
      dataIndex: "due_date",
    },
    {
      title: "Stato",
      dataIndex: "status",
      render: (row, record) => (
        <>
          {record.status === 0 ? (
            <div className="flex flex-row items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5 text-blue-400"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>

              <span className="capitalize">inviato</span>
            </div>
          ) : record.status === 1 ? (
            <div className="flex flex-row items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5 text-lime-600"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>

              <span className="capitalize">visualizzato</span>
            </div>
          ) : record.status === 2 ? (
            <div className="flex flex-row items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                class="w-5 h-5 text-green-600"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <span className="capitalize font-semibold text-green-600">
                confermato
              </span>
            </div>
          ) : record.status === 3 ? (
            <div className="flex flex-col">
              <div className="flex flex-row items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5 text-red-600"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>

                <span className="capitalize font-semibold text-red-600">
                  rifiutato
                </span>
              </div>
            </div>
          ) : record.status === 4 ? (
            <div className="flex flex-row items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5 text-red-500"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
                />
              </svg>

              <span className="capitalize text-red-500">scaduto</span>
            </div>
          ) : record.status === 5 ? (
            <div className="flex flex-row items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5 text-blue-500"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9.75 3.104v5.714a2.25 2.25 0 01-.659 1.591L5 14.5M9.75 3.104c-.251.023-.501.05-.75.082m.75-.082a24.301 24.301 0 014.5 0m0 0v5.714c0 .597.237 1.17.659 1.591L19.8 15.3M14.25 3.104c.251.023.501.05.75.082M19.8 15.3l-1.57.393A9.065 9.065 0 0112 15a9.065 9.065 0 00-6.23-.693L5 14.5m14.8.8l1.402 1.402c1.232 1.232.65 3.318-1.067 3.611A48.309 48.309 0 0112 21c-2.773 0-5.491-.235-8.135-.687-1.718-.293-2.3-2.379-1.067-3.61L5 14.5"
                />
              </svg>

              <span className="capitalize text-blue-500">creato</span>
            </div>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      title: "",
      render: (row, record) => (
        <PreventiviActions
          id_preventivo={row.id_preventivo}
          setCurrentId={setCurrentId}
        />
      ),
    },
  ];

  const fetchData = (pagination) => {
    setLoading(true);
    if (pagination != null) {
      preventiviService
        .getPreventivi(
          token +
            "/" +
            pagination.current +
            "/" +
            pagination.pageSize +
            "/" +
            base64_encode(filter)
        )
        .then(
          (result) => {
            result = result.data;
            setData(result.data);
            setNRequests(result.requests);
            setLoading(false);
            setTableParams({
              ...tableParams,
              pagination: {
                current: result.page,
                pageSize: result.per_page,
                total: result.total,
              },
            });
          },
          (error) => {}
        );
    } else {
      preventiviService
        .getPreventivi(
          token +
            "/" +
            tableParams.pagination.current +
            "/" +
            tableParams.pagination.pageSize +
            "/" +
            base64_encode(filter)
        )
        .then(
          (result) => {
            result = result.data;
            setData(result.data);
            setNRequests(result.requests);
            setLoading(false);
            setTableParams({
              ...tableParams,
              pagination: {
                ...tableParams.pagination,
                total: result.total,
              },
            });
          },
          (error) => {}
        );
    }
  };

  useEffect(() => {
    fetchData();
  }, [filter]);

  const handleTableChange = (pagination, filters, sorter) => {
    console.log(pagination);
    setTableParams({
      ...tableParams,
      pagination: {
        ...pagination,
      },
    });
    // setTableParams({
    //   pagination,
    //   filters,
    //   ...sorter,
    // });
    console.log(tableParams);
    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    } else {
      fetchData(pagination);
    }
  };

  const onSearch = (value) => {
    setFilter(value);
  };
  const createNewPreventivo = () => {
    setLoading(true);
    preventiviService.saveEmptyPreventivo(token).then(
      (result) => {
        result = result.data;
        navigate(`/preventivo/edit/${token}/${result.id_preventivo}`);
      },
      (error) => {}
    );
  };

  return (
    <StyleProvider hashPriority="high">
      <div className="flex flex-col">
        <div className="flex justify-end w-full items-center gap-4">
          <PreventiviRequests
            nrequests={nrequests}
            setNRequests={setNRequests}
          />
          <Button
            type="primary"
            className="!flex !flex-row !items-center w-auto"
            onClick={createNewPreventivo}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5 text-white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
            }
          >
            Nuovo Preventivo
          </Button>
          <div className="w-96 p-2">
            <Search
              placeholder="Digita un nome o email"
              allowClear
              enterButton="Cerca"
              size="large"
              onSearch={onSearch}
            />
          </div>
        </div>
        <Table
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={data}
          pagination={tableParams.pagination}
          loading={loading}
          onChange={handleTableChange}
        />
      </div>
    </StyleProvider>
  );
}
export default Preventivi;
