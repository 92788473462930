export const EN_labels = [
  {
    label: "LABEL_GESTISCI_PRENOTAZIONE",
    text: "Manage Booking",
  },
  {
    label: "LABEL_SEARCH_CAMERE",
    text: "Rooms",
  },
  {
    label: "LABEL_SEARCH_ADULTI",
    text: "Adults",
  },
  {
    label: "LABEL_SEARCH_BAMBINI",
    text: "Children",
  },
  {
    label: "LABEL_SEARCH_ROOM_PLACEHOLDER",
    text: "Room",
  },
  {
    label: "LABEL_SEARCH_BUTTON",
    text: "Check Availability",
  },
  {
    label: "LABEL_SEARCH_BUTTON_SEARCHING",
    text: "Searching ...",
  },
  {
    label: "LABEL_SEARCH_COUPON_CODE",
    text: "I have a discount code",
  },
  {
    label: "LABEL_SEARCH_PLACEHOLDER_COUPON",
    text: "Discount code",
  },
  {
    label: "LABEL_CALENDAR_ARRIVO",
    text: "CHECKIN",
  },
  {
    label: "LABEL_CALENDAR_PARTENZA",
    text: "CHECKOUT",
  },
  {
    label: "LABEL_CALENDAR_NOTTI",
    text: "NIGHTS",
  },
  {
    label: "LABEL_ROOM_DESCR_SHOW_MORE",
    text: "... Read more",
  },
  {
    label: "LABEL_ROOM_DESCR_SHOW_LESS",
    text: "... Show less",
  },
  {
    label: "LABEL_ROOM_GUESTS",
    text: "Guests",
  },
  {
    label: "LABEL_ROOM_ADDED_CART",
    text: "Your room has been added to your cart",
  },
  {
    label: "LABEL_FROM_DAY",
    text: "from the day",
  },
  {
    label: "LABEL_FROM_DAY_SHORT",
    text: "From",
  },
  {
    label: "LABEL_TO_DAY",
    text: "To",
  },
  {
    label: "LABEL_ROOM_REMOVED",
    text: "it is no longer available and has been removed ",
  },
  {
    label: "LABEL_REMOVE_FROM_CART",
    text: "Remove from your cart",
  },
  {
    label: "LABEL_OPS_EMPTY",
    text: "Oops.. it's empty here",
  },
  {
    label: "LABEL_ADD_ROOM_PLACEHOLDER",
    text: "Add a room, looking for the right one for you...",
  },
  {
    label: "LABEL_TOTAL",
    text: "Total:",
  },
  {
    label: "LABEL_GOTO_PAYMENT",
    text: "Go to the payment",
  },
  {
    label: "LABEL_NO_ROOMS_FOUND",
    text:
      "We are sorry, but the system is unable to respond to your request. " +
      "To check if we have other solutions we kindly ask you to contact us at following contact details:",
  },
  {
    label: "LABEL_SERVICES",
    text: "Services Available",
  },
  {
    label: "LABEL_NO_SERVICE",
    text: "No Services Available",
  },
  {
    label: "LABEL_TAX_STAY",
    text: "City tax",
  },
  {
    label: "LABEL_CURRENCY_LONG",
    text: "EUR",
  },
  {
    label: "LABEL_STAY_TAX_DESCR",
    text: "per night per guest to be paid on site.",
  },
  {
    label: "LABEL_NO_TAX_TO_PAY",
    text: "No tourist tax to pay.",
  },
  {
    label: "LABEL_DESCRIPTION",
    text: "Description",
  },
  {
    label: "LABEL_BOOKING_TERMS",
    text: "Booking conditions and cancellation policies",
  },
  {
    label: "LABEL_PRICE",
    text: "Price",
  },
  {
    label: "LABEL_ADD_TO_CART",
    text: "Add to cart",
  },
  {
    label: "LABEL_BOOK_NOW",
    text: "Book Now!",
  },
  {
    label: "ROOM_SERVICES",
    text: "Services available:",
  },
  {
    label: "NO_SERVICES",
    text: "No services available",
  },
  ,
  {
    label: "PRICE_FROM",
    text: "Starting from",
  },
  {
    label: "BUTTON_ROOM_INFO_BOOK",
    text: "Info and book",
  },
  {
    label: "LABEL_ROOM_REMOVED_APARTMENT",
    text: "The room / apartment ",
  },
  {
    label: "LABEL_RETURN_TO_SEARCH",
    text: "Back to search",
  },
  ,
  {
    label: "LABEL_YEARS",
    text: "years",
  },
  {
    label: "LABEL_UNAVAILABLE",
    text: "No longer available",
  },
  {
    label: "LABEL_ROOM_SEARCHED_FOR",
    text: "The room searched on from",
  },
  ,
  {
    label: "LABEL_NO_MORE_AVAILABLE",
    text: "is no longer available, please carry out a new search.",
  },
  {
    label: "LABEL_PRICE_BOOKING",
    text: "Booking price",
  },
  {
    label: "LABEL_DISCOUNT_CODE",
    text: "Discount code",
  },
  {
    label: "LABEL_PRICE_BOOKING",
    text: "Price of the stay:",
  },

  {
    label: "LABEL_YOUR_CART",
    text: "Your cart",
  },
  {
    label: "LABEL_TAX_PAY_EXCLUDED",
    text: "The city tax is not included in the price",
  },
  {
    label: "SUBTITLE_EXTRAS",
    text: "Make your stay unforgettable by selecting your favorite experiences",
  },
  {
    label: "LABEL_EXTRA_TOTAL",
    text: "Total including additional services",
  },
  {
    label: "LABEL_NO_ROOM_PAY",
    text: "Impossible to proceed with the purchase as there are no rooms or housing units available for booking.",
  },
  {
    label: "LABEL_NO_SEARCH_COMBINATIONS",
    text: "No combinations were found for the search\
    carried out, please enter in the box on the left\
    the dates of the stay, the number of people and the\
    required rooms.",
  },
  ,
  {
    label: "LABEL_PAYMENT_INSERT_YOUR_DATA",
    text: "Insert your data",
  },
  {
    label: "LABEL_PAYMENT_NO_MANDATORY",
    text: "The fields marked with an asterisk are mandatory",
  },
  {
    label: "LABEL_PAYMENT_FORM_NAME",
    text: "Name *",
  },
  {
    label: "LABEL_PAYMENT_FORM_ERROR_NAME",
    text: "Insert a valid name",
  },
  {
    label: "LABEL_PAYMENT_FORM_ERROR_SURNAME",
    text: "Insert a valid surname",
  },
  {
    label: "LABEL_PAYMENT_FORM_ERROR_EMAIL",
    text: "Insert a valid email",
  },
  {
    label: "LABEL_PAYMENT_FORM_ERROR_PHONE",
    text: "Insert a valid mobile number",
  },
  {
    label: "LABEL_PAYMENT_FORM_SURNAME",
    text: "Surname *",
  },
  {
    label: "LABEL_PAYMENT_FORM_EMAIL",
    text: "E-mail *",
  },
  {
    label: "LABEL_PAYMENT_FORM_CONFIRM_EMAIL",
    text: "Confirm E-mail *",
  },
  {
    label: "LABEL_PAYMENT_FORM_PHONE",
    text: "Mobile Number *",
  },
  {
    label: "LABEL_PAYMENT_CONFIRM_FORM_PHONE",
    text: "Conferm Mobile Number *",
  },
  {
    label: "LABEL_PAYMENT_FORM_COUNTRY",
    text: "Country",
  },
  {
    label: "LABEL_PAYMENT_FORM_CITY",
    text: "City",
  },
  {
    label: "LABEL_PAYMENT_FORM_ZIP_CODE",
    text: "Zip Code",
  },
  {
    label: "LABEL_PAYMENT_FORM_ADDRESS",
    text: "Address",
  },
  {
    label: "LABEL_PAYMENT_FORM_NOTES",
    text: "Notes / Additional information (optional)",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_METHODS",
    text: "Payment Methods",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_METHODS_1",
    text: "Select a payment method for the reservation",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_METHODS_2",
    text: "Type of payment",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_CARD",
    text: "Credit Card",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_TRANSFER",
    text: "Bank transfer",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_CARD_SUBTITLE_1",
    text: "Guaranteed Credit Card - secure site",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_CARD_SUBTITLE_2",
    text: `The details of
    your credit card are requested to guarantee the reservation
    and any extras.`,
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_CARD_SUBTITLE_3",
    text: ` reserves the
    right to temporarily withhold an amount equal to the first
    night before your arrival in the form of a pre-authorisation of the
    credit card provided. We recommend using a credit card
    credit instead of a debit or prepaid card to save time
    of bank management of the issue of any pre-authorisation.`,
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_1",
    text: `I have read and accept the data protection conditions personal data reported `,
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_2",
    text: `in the information`,
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_3",
    text: `By confirming this booking I also accept the `,
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_4",
    text: `general terms`,
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_5",
    text: ` and the booking conditions listed above for each unit housing.`,
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_6",
    text: `I would like to receive information about special offers and news.`,
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_7",
    text: `I have read and approve the personal data protection conditions reported `,
  },
  {
    label: "LABEL_PAYMENT_FORM_BUTTON_1",
    text: `Confirmation of reservation`,
  },
  {
    label: "LABEL_PAYMENT_FORM_BUTTON_2",
    text: `Pay Online`,
  },
  {
    label: "LABEL_PAYMENT_FORM_BUTTON_SUBTITLE_1",
    text: `There is still one step before the end of the procedure`,
  },
  {
    label: "LABEL_PAYMENT_FORM_ONE_MOMENT",
    text: "It will only take a moment",
  },
  {
    label: "BT_TITLE",
    text: "Payment method: Bank Transfer",
  },
  {
    label: "BT_SUBTITLE",
    text: "Instructions:",
  },
  {
    label: "BT_TEXT1",
    text: `Provision to make the payment within the next `,
  },
  {
    label: "BT_TEXT1_5",
    text: ` days.
    As soon as we receive the bank transfer, we will send you a confirmation email with the details of your purchase.`,
  },
  {
    label: "BT_TEXT2",
    text: `Once the transfer has been made, remember to save a copy of the receipt and upload it
    below.`,
  },
  {
    label: "BT_TEXT3",
    text: "Important",
  },
  {
    label: "BT_TEXT4",
    text: `We do not check the credits daily, so don't forget to upload the transfer receipt below,
    otherwise the reservation will not be valid.`,
  },
  {
    label: "BT_TEXT5",
    text: `* Please enter the details correctly, especially the reason to facilitate the fulfillment of your order`,
  },
  {
    label: "BT_FORM_1",
    text: "IBAN:",
  },
  {
    label: "BT_FORM_2",
    text: "CAUSAL:",
  },
  {
    label: "BT_FORM_3",
    text: "BENEFICIARY:",
  },
  {
    label: "BT_FORM_4",
    text: "AMOUNT:",
  },
  {
    label: "BT_FORM_5",
    text: "Upload and send transfer",
  },
  {
    label: "BT_PAY_1",
    text: "Drop files here!",
  },
  {
    label: "BT_PAY_2",
    text: "Drag and drop your receipt here!",
  },
  {
    label: "BT_PAY_3",
    text: "Or click here to select files",
  },
  {
    label: "BT_PAY_4",
    text: "Your file is ready to upload.",
  },
  {
    label: "BT_PAY_5",
    text: `Press "Upload and send transfer" to confirm the reservation.`,
  },
  {
    label: "CARD_FORM_1",
    text: "Card Number",
  },
  {
    label: "CARD_FORM_2",
    text: "Card holder name",
  },
  {
    label: "CARD_FORM_3",
    text: "Expire date",
  },
  {
    label: "CARD_FORM_4",
    text: "Month",
  },
  {
    label: "CARD_FORM_5",
    text: "Year",
  },
  {
    label: "CARD_FORM_6",
    text: "Confirm Booking",
  },
  {
    label: "CARD_FORM_7",
    text: "COMPLETE NAME",
  },
  {
    label: "CARD_FORM_8",
    text: "Expire",
  },
  {
    label: "SUCCESS_THANK_YOU",
    text: "Thank You",
  },
  {
    label: "SUCCESS_THANK_YOU_1",
    text: "your reservation is confirmed",
  },
  {
    label: "SUCCESS_THANK_YOU_2",
    text: "An email has been sent to you at",
  },
  {
    label: "SUCCESS_THANK_YOU_3",
    text: "with all the details.",
  },
  {
    label: "SUCCESS_THANK_YOU_4",
    text: "awaits you on the",
  },
  {
    label: "SUCCESS_THANK_YOU_5",
    text: "The payment",
  },
  {
    label: "SUCCESS_THANK_YOU_6",
    text: "will be managed by",
  },
  {
    label: "SUCCESS_THANK_YOU_7",
    text: "Modify your reservation or ask a question to the establishment in just a few clicks.",
  },
  {
    label: "SUCCESS_THANK_YOU_8",
    text: "Booking details",
  },
  {
    label: "SUCCESS_THANK_YOU_9",
    text: "Your reservation",
  },
  {
    label: "SUCCESS_THANK_YOU_10",
    text: "Reservation code",
  },
  {
    label: "SUCCESS_THANK_YOU_11",
    text: "Pin for editing",
  },
  {
    label: "SUCCESS_THANK_YOU_12",
    text: "Booked with name",
  },
  {
    label: "SUCCESS_THANK_YOU_13",
    text: "The extras of your stay",
  },
  {
    label: "SUCCESS_THANK_YOU_14",
    text: "Quantity:",
  },
  {
    label: "SUCCESS_THANK_YOU_15",
    text: "Taxes",
  },
  {
    label: "SUCCESS_THANK_YOU_16",
    text: "To be paid at the property",
  },
  {
    label: "SUCCESS_THANK_YOU_17",
    text: "The packet you've choosen",
  },
  {
    label: "SUCCESS_THANK_YOU_18",
    text: "The promo code you inserted",
  },
  {
    label: "PAYMENT_LOADING_TEXT_1",
    text: "We are contacting the structure ...",
  },
  {
    label: "PAYMENT_LOADING_TEXT_2",
    text: "We save your reservation ...",
  },
  {
    label: "PAYMENT_LOADING_TEXT_3",
    text: "We confirm the status ...",
  },
  {
    label: "ERROR_TITLE",
    text: "Oops.. something is wrong",
  },
  {
    label: "ERROR_CODE_5_TEXT",
    text: "Unfortunately one or more rooms in your solution have already been booked. You will be directed to a new search.",
  },
  {
    label: "ERROR_CODE_GENERIC_TEXT",
    text: "The reservation could not be created. We advise you to contact the establishment. ",
  },
  {
    label: "LABEL_ERROR_ALERT_1",
    text: "Oops.. The room is already occupied",
  },
  {
    label: "LABEL_BODY_ERROR_ALERT_1",
    text: "It seems that you are already reserving the same room but the structure has no more availability for this type, try performing a new search.",
  },
  {
    label: "LABEL_TITLE_ROOM_ADDED_CART",
    text: "The room is saved!",
  },
  {
    label: "LABEL_PRIVACY_POLICY",
    text: "Terms and privacy conditions ( PRIVACY POLICY )",
  },
  {
    label: "LABEL_BOOKING_TERMS",
    text: "Terms and booking conditions ( BOOKING TERMS )",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART1",
    text: "Pursuant to article 13 of Regulation (EU) 2016/679 on the protection of personal data, i.e. Regulation, we inform you that your personal data, freely provided by you, will be processed by F451 S.R.L, hereinafter Data Controller, mainly by means in order to follow up on your request to subscribe to our automated newsletter which will allow you to be updated on the services we offer and our promotional activities, as well as allow us to know your degree of satisfaction with regard to the services you have enjoyed , satisfy market surveys and send you advertising material.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART2",
    text: "We remind you that you will always have the possibility to revoke your consent to this registration.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART3",
    text: "The provision of your personal data is free, however failure to provide them will not allow us to fulfill the request and, consequently, to subscribe you to our newsletter.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART4",
    text: "If you decide to provide us with your data, we inform you that these will be processed exclusively by personnel authorized by the owner and will be kept only for the time necessary to pursue the aforementioned purpose.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART5",
    text: "In no case will your data be disclosed but may be communicated to third parties to whom the owner relies solely and exclusively to guarantee the correct pursuit of the aforementioned purposes.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART6",
    text: "We remind you that you are guaranteed: right of access, right of rectification, right of cancellation, right of limitation, right of portability, right of withdrawal of consent, right of complaint, right of opposition, right not to be subjected to a decision based solely on automated processing, including profiling with the exception of the cases provided for by the Regulation. To exercise these rights, you can contact the Data Controller by writing to F451 S.R.L. - Via Giuseppe Parini 13 or by sending an e-mail message to Sviluppo@idaitalia.com. Without prejudice to any other administrative or judicial appeal, you have the right to lodge a complaint with a Supervisory Authority, if you believe that the processing that concerns you violates the Regulation.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_TITLE",
    text: "Newsletters and Marketing",
  },
  {
    label: "BUTTON_NOREFUNDABLE",
    text: "Not refundable",
  },
  {
    label: "PACKET_STARTING",
    text: "Starting from",
  },
  {
    label: "PACKET_FOR",
    text: "for",
  },
  {
    label: "PACKET_NIGHTS",
    text: "nights.",
  },
  {
    label: "PACKET_SHOW",
    text: "See all packages",
  },
  {
    label: "PACKET_CHOOSE",
    text: "Choose package",
  },
  {
    label: "PACKET_BACK",
    text: "Come back",
  },
  {
    label: "PACKET_CHOOSE_DATE",
    text: "Choose a date:",
  },
  {
    label: "PACKET_VALID_PERIOD",
    text: "You have selected a valid period",
  },
  {
    label: "PACKET_WRONG_PERIOD",
    text: "You have selected an invalid period",
  },
  {
    label: "PACKET_SELECT_ROOM",
    text: "Select a room",
  },
];
