import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingIndicator from "../../components/LoadingIndicator";
import paymentsService from "../../services/payments.service";
import PaymentCart from "./PaymentCart";
import PaymentProducts from "./PaymentProducts";

function PaymentCreate() {
  const { token } = useParams();

  const [products, setProducts] = React.useState([
    // {
    //   id: 1,
    //   name: "Throwback Hip Bag",
    //   href: "#",
    //   color: "Salmon",
    //   price: "90.00",
    //   quantity: 1,
    //   imageSrc:
    //     "https://tailwindui.com/img/ecommerce-images/shopping-cart-page-04-product-01.jpg",
    //   imageAlt:
    //     "Salmon orange fabric pouch with match zipper, gray zipper pull, and adjustable hip belt.",
    // },
    // {
    //   id: 2,
    //   name: "Medium Stuff Satchel",
    //   href: "#",
    //   color: "Blue",
    //   price: "32.00",
    //   quantity: 1,
    //   imageSrc:
    //     "https://tailwindui.com/img/ecommerce-images/shopping-cart-page-04-product-02.jpg",
    //   imageAlt:
    //     "Front of satchel with blue canvas body, black straps and handle, drawstring top, and front zipper pouch.",
    // },
    // {
    //   id: 3,
    //   name: "Medium Stuff Satchel",
    //   href: "#",
    //   color: "Blue",
    //   price: "32.00",
    //   quantity: 1,
    //   imageSrc:
    //     "https://tailwindui.com/img/ecommerce-images/shopping-cart-page-04-product-02.jpg",
    //   imageAlt:
    //     "Front of satchel with blue canvas body, black straps and handle, drawstring top, and front zipper pouch.",
    // },
    // More products...
  ]);
  const [focused_products, setFocusedProducts] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    document.title = "";
    fetchProducts();
  }, []);
  const fetchProducts = async () => {
    setIsLoading(true);
    paymentsService.getProducts(token).then(
      (result) => {
        result = result.data;
        setProducts(result);
        setIsLoading(false);
      },
      (error) => {}
    );
  };

  return (
    <div className="w-full h-screen flex flex-col md:flex-row items-center">
      <div className="w-full md:w-2/3 h-full">
        {isLoading ? (
          <div className="w-full h-full flex items-center justify-center">
            <LoadingIndicator />
          </div>
        ) : (
          <PaymentProducts
            products={products}
            focused_products={focused_products}
            setFocusedProducts={setFocusedProducts}
            setProducts={setProducts}
          />
        )}
      </div>
      <div className="w-full md:w-1/3 h-full">
        <PaymentCart
          products={focused_products}
          focused_products={focused_products}
          setFocusedProducts={setFocusedProducts}
        />
      </div>
    </div>
  );
}

export default PaymentCreate;
