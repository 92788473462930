import { Button, Modal, Input, Spin } from "antd";
import { useEffect, useState } from "react";
import { StyleProvider } from "@ant-design/cssinjs";
import preventiviService from "../../services/preventivi.service";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";

const PreventivoDuplicateConfirm = ({ preventivo, setLoadingPage }) => {
  const { token } = useParams();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!name) setDisabled(true);
    else setDisabled(false);
  }, [name, open]);

  useEffect(() => {
    setName("");
  }, [open]);

  const showModal = () => {
    setOpen(true);
  };
  const handleOk = async (e) => {
    setLoadingPage(true);
    setLoading(true);
    preventiviService
      .duplicatePreventivo(token + "/" + preventivo.id_preventivo, {
        name: name,
      })
      .then(
        (result) => {
          if (result.status === 201) {
            window.location.replace(
              "/preventivo/edit/" + token + "/" + result.data.id_preventivo
            );
            toast.success("Il preventivo è stato duplicato correttamente");
            setLoading(false);
            setLoadingPage(false);
          }
        },
        (error) => {
          setLoading(false);
          setLoadingPage(false);
          toast.error(error.response.data.ExceptionMessage);
        }
      );
  };
  const handleCancel = (e) => {
    setOpen(false);
  };
  return (
    <StyleProvider hashPriority="high">
      <Button
        className="!flex !flex-row !items-center w-full xl:!ml-auto !bg-[#1677ff] hover:!bg-[#4096ff]"
        type="primary"
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 text-white mr-2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
            />
          </svg>
        }
        onClick={showModal}
      >
        Duplica Preventivo
      </Button>
      <Modal
        title="Duplica il preventivo corrente"
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          disabled: disabled,
        }}
      >
        <Spin spinning={loading}>
          <div>
            Il preventivo verrà ora duplicato, saranno replicati:
            <br></br>
            <br></br>
            <ul className="!list-disc ml-3">
              <li>Dettagli del preventivo</li>
              <li>Tempistiche</li>
              <li>Garanzia e condizioni</li>
              <li>Note</li>
              <li>Messaggi di conferma</li>
            </ul>
            <br></br>
            Inserire un nome per il nuovo preventivo:
            <Input
              placeholder="Preventivo #1"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="!mt-2"
            />
          </div>
        </Spin>
      </Modal>
    </StyleProvider>
  );
};
export default PreventivoDuplicateConfirm;
