import React, { useEffect, useState } from "react";
import { StyleProvider } from "@ant-design/cssinjs";
import * as itLocale from "antd/locale/it_IT";
import * as enLocale from "antd/locale/en_GB";
import RowForm from "./RowForm";
import {
  DatePicker,
  Select,
  ConfigProvider,
  Button,
  Segmented,
  Spin,
} from "antd";
import dayjs from "dayjs";
import { XIcon } from "@heroicons/react/outline";
import { useParams } from "react-router-dom";
import preventiviService from "../../../../services/preventivi.service";
import { deepClone } from "@mui/x-data-grid/utils/utils";
import { useTranslation } from "react-i18next";
import { antdLocales } from "./AntdLocale";

const { RangePicker } = DatePicker;

const CategoryList = ({
  row,
  categories,
  setCategories,
  state,
  setState,
  room,
  t,
  i18n,
}) => {
  useEffect(() => {
    if (!room.id_category && categories.length > 0) {
      setState({
        ...state,
        rooms: state.rooms.map((el) => {
          if (room.id === el.id) el.id_category = categories[0].id;
          return el;
        }),
      });
    }
  }, [room, categories]);
  return (
    <div className="grid grid-cols-12 gap-5">
      <div className="col-span-12 flex flex-row gap-4 w-full overflow-x-auto p-2 ios-scroll">
        {categories?.map((category, index) => (
          <div
            className={`rounded-md min-w-[200px] w-[200px] overflow-hidden
                transition ease-out ring-1 ring-gray-400
                cursor-pointer bg-cover bg-center bg-no-repeat
                relative ${category.checked && "!ring-4 !ring-blue-500"}`}
            key={"category-" + row + "-" + index}
            style={{
              backgroundImage: 'url("' + category.image + '")',
              height: "200px",
            }}
          >
            <div className="backdrop-blur-md text-white p-2 truncate w-full">
              {category.name}
            </div>

            {!category.checked && (
              <div className="absolute bottom-0 w-full p-2 flex flex-row items-center gap-2">
                <Button
                  type="primary"
                  onClick={() =>
                    setCategories(
                      categories.map((cat) => {
                        if (cat.id === category.id) {
                          if (cat.checked) cat.checked = false;
                          else cat.checked = true;
                        } else cat.checked = false;

                        if (cat.checked) {
                          setState({
                            ...state,
                            rooms: state.rooms.map((el) => {
                              if (el.id === room.id)
                                el.id_category = category.id;
                              return el;
                            }),
                          });
                        }

                        return cat;
                      })
                    )
                  }
                >
                  {t("request.step2.choose")}
                </Button>
              </div>
            )}
          </div>
        ))}
      </div>

      {categories?.filter((el) => el.checked).length === 1 && (
        <>
          <RowForm
            label={t("request.step2.adults")}
            input={
              <Segmented
                options={[1, 2, 3, 4, 5]}
                size="large"
                value={room.adults}
                onChange={(e) => {
                  setState({
                    ...state,
                    rooms: state.rooms.map((el) => {
                      if (el.id === room.id) el.adults = e;
                      return el;
                    }),
                  });
                }}
              />
            }
          />
          <RowForm
            label={<span>{t("request.step2.children")}</span>}
            input={
              <Segmented
                options={[0, 1, 2, 3, 4, 5]}
                size="large"
                value={room.children}
                onChange={(e) => {
                  setState({
                    ...state,
                    rooms: state.rooms.map((el) => {
                      if (el.id === room.id) el.children = e;
                      return el;
                    }),
                  });
                }}
              />
            }
          />
        </>
      )}
    </div>
  );
};

function SecondStepRoom({ state, setState, room, index }) {
  const { uuid } = useParams();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation("common");

  useEffect(() => {
    fetchCategories();
  }, [i18n.language]);

  const fetchCategories = async () => {
    setLoading(true);
    preventiviService.getPreventivoRequestCategories(uuid, i18n.language).then(
      (result) => {
        if (result.status === 200) {
          let fetched = result.data;
          fetched[0].checked = true;
          setCategories(fetched);
        }
        setLoading(false);
      },
      (error) => {}
    );
  };

  // eslint-disable-next-line arrow-body-style
  const disabledDate = (current) => {
    return current && current < dayjs().add(-1, "day").endOf("day");
  };
  return (
    <div
      className="border border-gray-200 border-dashed 
    p-4 rounded-md hover:bg-[#76767666] transition min-h-[660px]"
    >
      <Spin spinning={loading}>
        <StyleProvider hashPriority="high">
          <div className="grid grid-cols-12 gap-5">
            <div className="col-span-12 flex flex-row items-center">
              <h4 className="text-white font-semibold text-lg">
                {t("request.step2.room")} #{index + 1}
              </h4>
              {state.rooms?.length > 1 && index > 0 && (
                <Button
                  type="text"
                  onClick={() =>
                    setState({
                      ...state,
                      rooms: state.rooms.filter((el) => el.id !== room.id),
                    })
                  }
                  className="ml-auto !text-white !font-semibold !flex !flex-row !items-center !gap-2"
                >
                  <XIcon className="w-6 !m-0" />
                  <span>{t("request.step2.deleteRoom")}</span>
                </Button>
              )}
            </div>

            <RowForm
              label={t("request.step2.checkinCheckout")}
              input={
                <ConfigProvider locale={antdLocales[i18n.language]}>
                  <RangePicker
                    disabledDate={disabledDate}
                    className="w-full"
                    placeholder={["Checkin", "Checkout"]}
                    format={"ddd DD MMM YYYY"}
                    value={room.dates}
                    onChange={(newDates) =>
                      setState({
                        ...state,
                        rooms: state.rooms.map((rm) => {
                          if (rm.id === room.id)
                            room.dates = [newDates[0], newDates[1]];
                          return rm;
                        }),
                      })
                    }
                  />
                </ConfigProvider>
              }
            />

            <RowForm
              label={t("request.step2.treatment")}
              input={
                <Select
                  style={{
                    width: "100%",
                  }}
                  value={room.treatment}
                  onChange={(e) =>
                    setState({
                      ...state,
                      rooms: state.rooms.map((rm) => {
                        if (rm.id === room.id) room.treatment = e;
                        return rm;
                      }),
                    })
                  }
                  options={[
                    {
                      value: "ALLIN",
                      label: "All Inclusive",
                    },
                    {
                      value: "B&B",
                      label: "B&B",
                    },
                    {
                      value: "HALF",
                      label: t("request.step2.treatmentHalf"),
                    },
                    {
                      value: "FULL",
                      label: t("request.step2.treatmentFull"),
                    },
                    {
                      value: "BED",
                      label: t("request.step2.treatmentBooking"),
                    },
                  ]}
                />
              }
            />

            <div className="col-span-12 flex flex-col items-start gap-2">
              <span className="text-white w-full">
                {t("request.step2.chooseRoom")}
              </span>
              <CategoryList
                row={index}
                categories={categories}
                setCategories={setCategories}
                room={room}
                setState={setState}
                state={state}
                t={t}
                i18n={i18n}
              />
            </div>
          </div>
        </StyleProvider>
      </Spin>
    </div>
  );
}

export default SecondStepRoom;
