import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Skeleton,
  Tooltip,
} from "@mui/material";
import { useParams } from "react-router-dom";
import * as React from "react";
import structureService from "../../services/structure.service";
import HorizontalBar from "../layout/horizontalbar";
import Summary from "./summary";
import Flickity from "react-flickity-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTextCollapse from "react-text-collapse";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import it from "date-fns/locale/it"; // the locale you want
import moment from "moment";
import "moment/locale/it";
import PaymentRowDays from "./days";
import ExtraPayment from "./extra_payment";
import PaymentCards from "./payment_card";
import Bonifico from "./bonifico";
import { GetLabel } from "../../languages/i18n";
import ErrorBox from "./error_box";
import PaymentPacker from "../packets/PaymentPacker";

export default function Payment() {
  const { uuid, link } = useParams();
  const [isStructureLoading, setStructureLoading] = React.useState(false);
  const [structureInfo, setStructureInfo] = React.useState({
    structure_name: "",
    structure_address: "",
    structure_location: "",
    structure_logo: "",
    structure_phone: "",
    structure_email: "",
    uuid: null,
  });
  const [booking, setBooking] = React.useState({ rows: [] });
  const [room, setRoom] = React.useState({});
  const [tax, setTax] = React.useState({});
  const [cancellationRules, setCancellationRules] = React.useState("");
  const [extras, setExtras] = React.useState([]);
  const [total, setTotal] = React.useState(0.0);
  const [isSearching, setIsSearching] = React.useState(false);
  const [language, setLanguage] = React.useState("IT");
  const [error, setError] = React.useState("");
  const [packet, setPacket] = React.useState({});
  const [temp_packet, setTempPacket] = React.useState({});

  const marginLeftSm = "20px !important";
  const TEXT_COLLAPSE_OPTIONS = {
    collapse: false, // default state when component rendered
    collapseText: GetLabel(language, "LABEL_ROOM_DESCR_SHOW_MORE"), // text to show when collapsed
    expandText: GetLabel(language, "LABEL_ROOM_DESCR_SHOW_LESS"), // text to show when expanded
    minHeight: 150, // component height when closed
    maxHeight: 350, // expanded to
  };

  React.useEffect(() => {
    getStructureInfo(uuid);
    getBookingInfo();
  }, []);

  const getBookingInfo = async () => {
    setIsSearching(true);
    structureService.getBookingInfo(link).then(
      (result) => {
        result = result.data;
        if (result.success) {
          setBooking(result.model);
          setLanguage(result.model.booking.language);

          var new_total = 0;
          for (let j = 0; j < result.model.rows.length; j++) {
            for (let i = 0; i < result.model.rows[j].days.length; i++) {
              new_total += result.model.rows[j].days[i].price;
            }
          }

          setTotal(new_total);
          getExtras();
        } else {
          setIsSearching(false);
          setError(result.model.message);
        }
      },
      (error) => {}
    );
  };

  const getExtras = async () => {
    structureService.getStructureExtras(uuid).then(
      (result) => {
        result = result.data;
        setExtras(result);
        setIsSearching(false);
      },
      (error) => {}
    );
  };

  const getStructureInfo = async (uuid_pass) => {
    setStructureLoading(true);
    structureService.getStructureInfo(`${uuid_pass}`).then(
      (result) => {
        result = result.data;
        setStructureInfo(result);
        setStructureLoading(false);
      },
      (error) => {}
    );
  };

  function getPrice(days) {
    let price_booking = 0.0;
    for (let i = 0; i < days.length; i++) price_booking += days[i].price;
    return parseFloat(price_booking).toFixed(2);
  }

  return (
    <>
      <div
        className="bg-body"
        style={{
          background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('${structureInfo.structure_background}')`,
          minHeight: "100vh",
        }}
      >
        <HorizontalBar
          structure_name={structureInfo.structure_name}
          structure_address={structureInfo.structure_address}
          structure_location={structureInfo.structure_location}
          structure_logo={structureInfo.structure_logo}
          structure_phone={structureInfo.structure_phone}
          structure_email={structureInfo.structure_email}
          isStructureLoading={isStructureLoading}
          key={structureInfo.uuid}
          language={language}
          setLanguage={setLanguage}
        ></HorizontalBar>
        <Grid container spacing={2} sx={{ padding: "10px" }}>
          {isSearching ? (
            <>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "20px",
                }}
              >
                <CircularProgress sx={{ color: "#FFF" }} />
              </Grid>
              <Grid item sm={3} xs={12}>
                <Card sx={{ height: "200px", padding: "0px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    <Skeleton
                      width={"50%"}
                      height={"100px"}
                      sx={{ margin: "0" }}
                    ></Skeleton>
                    <Skeleton
                      width={"50%"}
                      height={"100px"}
                      sx={{ margin: "0" }}
                    ></Skeleton>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    <Skeleton
                      width={"33%"}
                      height={"100px"}
                      sx={{ margin: "0" }}
                    ></Skeleton>
                    <Skeleton
                      width={"33%"}
                      height={"100px"}
                      sx={{ margin: "0" }}
                    ></Skeleton>
                    <Skeleton
                      width={"33%"}
                      height={"100px"}
                      sx={{ margin: "0" }}
                    ></Skeleton>
                  </div>
                </Card>
              </Grid>
              <Grid item sm={7} xs={12}>
                <Card sx={{ height: "500px", padding: "20px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={4} sx={{ height: "300px" }}>
                      <Skeleton width={"100%"} height={"100%"}></Skeleton>
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      sx={{
                        height: "300px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Skeleton width={"100%"} height={"10%"}></Skeleton>
                      <Skeleton width={"100%"} height={"10%"}></Skeleton>
                      <Skeleton width={"100%"} height={"10%"}></Skeleton>
                      <Skeleton width={"100%"} height={"10%"}></Skeleton>
                      <Skeleton width={"100%"} height={"10%"}></Skeleton>
                    </Grid>
                    <Grid item xs={4} sx={{ height: "300px" }}>
                      <Skeleton width={"100%"} height={"10%"}></Skeleton>
                      <Skeleton width={"100%"} height={"10%"}></Skeleton>
                      <Skeleton width={"100%"} height={"10%"}></Skeleton>
                      <Skeleton width={"100%"} height={"10%"}></Skeleton>
                      <Skeleton width={"100%"} height={"10%"}></Skeleton>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </>
          ) : (
            <>
              {error ? (
                <div
                  style={{
                    width: "100%",
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ErrorBox
                    title={"Errore"}
                    text={error}
                    url=""
                    url_text="Torna indietro ed effettua una nuova ricerca"
                  />
                </div>
              ) : (
                <>
                  {booking.rows && booking.rows.length > 0 ? (
                    <>
                      {booking.rows.map((row, index) => {
                        return (
                          <>
                            {row.rooms.length > 0 && (
                              <>
                                <Grid item sm={3} xs={12}>
                                  <Summary
                                    summary={row.rooms[0].summary}
                                    language={language}
                                  ></Summary>
                                </Grid>
                                <Grid item sm={7} xs={12}>
                                  <PaymentPacker
                                    language={language}
                                    packet={packet}
                                    setTempPacket={setTempPacket}
                                    temp_packet={temp_packet}
                                    setPacket={setPacket}
                                  />

                                  <Card
                                    sx={{
                                      padding: "20px",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    <div className="content">
                                      <Grid container spacing={2}>
                                        <Grid item sm={5} xs={12}>
                                          {row.rooms && row.rooms[0].images && (
                                            <Flickity
                                              options={{ lazyLoad: true }}
                                            >
                                              {row.rooms[0].images.map(
                                                (image, i) => {
                                                  return (
                                                    <div
                                                      style={{
                                                        background:
                                                          "url(" +
                                                          image.image +
                                                          ")",
                                                        filter: row.is_available
                                                          ? ""
                                                          : "grayscale(100%)",
                                                      }}
                                                      className="carousel-img"
                                                    ></div>
                                                  );
                                                }
                                              )}
                                            </Flickity>
                                          )}

                                          {row.is_available && (
                                            <>
                                              <Typography
                                                variant="h6"
                                                component="h2"
                                                className="description"
                                                style={{ marginTop: "20px" }}
                                              >
                                                {GetLabel(
                                                  language,
                                                  "LABEL_SERVICES"
                                                )}
                                              </Typography>
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                }}
                                              >
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    marginLeft: "0",
                                                    whiteSpace: "pre",
                                                    overflowX: "auto",
                                                    marginRight: "5px",
                                                  }}
                                                  className="ios-scroll"
                                                >
                                                  {row.rooms[0].services &&
                                                  row.rooms[0].services.length >
                                                    0 ? (
                                                    row.rooms[0].services.map(
                                                      (service, i) => {
                                                        return (
                                                          <p
                                                            style={{
                                                              marginRight:
                                                                "10px",
                                                              marginTop: "3px",
                                                              marginBottom:
                                                                "3px",
                                                            }}
                                                          >
                                                            <FontAwesomeIcon
                                                              icon={
                                                                service.icon
                                                              }
                                                              className="service-icon"
                                                            />
                                                            <span className="service-icon-text">
                                                              {service.name}
                                                            </span>
                                                          </p>
                                                        );
                                                      }
                                                    )
                                                  ) : (
                                                    <p
                                                      style={{
                                                        marginRight: "10px",
                                                        marginTop: "3px",
                                                        marginBottom: "3px",
                                                      }}
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={"fa-times"}
                                                        className="service-icon"
                                                      />
                                                      <span className="service-icon-text">
                                                        {GetLabel(
                                                          language,
                                                          "LABEL_NO_SERVICE"
                                                        )}
                                                      </span>
                                                    </p>
                                                  )}
                                                </Box>
                                              </Box>

                                              <Typography
                                                variant="h6"
                                                component="h2"
                                                className="description"
                                                style={{ marginTop: "20px" }}
                                              >
                                                {GetLabel(
                                                  language,
                                                  "LABEL_TAX_STAY"
                                                )}
                                              </Typography>
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  marginTop: "10px",
                                                  flexDirection: "column",
                                                  maxHeight: "100px",
                                                  overflowY: "auto",
                                                }}
                                                className="tax-box"
                                              >
                                                {row.rooms[0].taxes ? (
                                                  <>
                                                    {row.rooms[0].taxes.taxes.map(
                                                      (item, index) => {
                                                        return (
                                                          <>
                                                            {row.rooms[0].taxes
                                                              .btax === true ? (
                                                              <span
                                                                className="service-icon-text"
                                                                style={{
                                                                  marginBottom:
                                                                    "10px",
                                                                }}
                                                              >
                                                                <b>
                                                                  {item.price}{" "}
                                                                  EUR{" "}
                                                                </b>
                                                                {item.age &&
                                                                  `(${
                                                                    item.age
                                                                  } ${GetLabel(
                                                                    language,
                                                                    "LABEL_YEARS"
                                                                  )})`}{" "}
                                                                {GetLabel(
                                                                  language,
                                                                  "LABEL_STAY_TAX_DESCR"
                                                                )}
                                                              </span>
                                                            ) : (
                                                              <span
                                                                className="service-icon-text"
                                                                style={{
                                                                  marginBottom:
                                                                    "10px",
                                                                }}
                                                              >
                                                                {GetLabel(
                                                                  language,
                                                                  "LABEL_FROM_DAY_SHORT"
                                                                )}{" "}
                                                                {item.date_from}{" "}
                                                                {GetLabel(
                                                                  language,
                                                                  "LABEL_TO_DAY"
                                                                )}{" "}
                                                                {item.date_to}{" "}
                                                                <br></br>
                                                                <b>
                                                                  {item.price}{" "}
                                                                  EUR{" "}
                                                                </b>
                                                                {item.age &&
                                                                  `(${item.age} anni)`}{" "}
                                                                {GetLabel(
                                                                  language,
                                                                  "LABEL_STAY_TAX_DESCR"
                                                                )}
                                                              </span>
                                                            )}
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                  </>
                                                ) : (
                                                  <p
                                                    style={{
                                                      marginRight: "10px",
                                                      marginTop: "3px",
                                                      marginBottom: "3px",
                                                    }}
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={"fa-times"}
                                                      className="service-icon"
                                                    />
                                                    <span className="service-icon-text">
                                                      {GetLabel(
                                                        language,
                                                        "LABEL_NO_TAX_TO_PAY"
                                                      )}
                                                    </span>
                                                  </p>
                                                )}
                                              </Box>
                                            </>
                                          )}
                                        </Grid>
                                        <Grid item sm={7} xs={12}>
                                          <h2
                                            style={{ marginTop: "0" }}
                                            className="text-3xl font-bold"
                                          >
                                            {row.rooms[0].roomName}
                                          </h2>
                                          {row.isNotRefundable && (
                                            <div className="w-fit text-xs py-1 px-3 mt-2 mb-4 rounded-full bg-purple-500 text-white font-semibold">
                                              Non rimborsabile
                                            </div>
                                          )}
                                          {row.is_available ? (
                                            <>
                                              <Typography
                                                variant="h6"
                                                component="h2"
                                                className="description"
                                                style={{
                                                  fontWeight: "700",
                                                  color: "#4a4a4a",
                                                  marginTop: "10px",
                                                }}
                                              >
                                                {GetLabel(
                                                  language,
                                                  "LABEL_DESCRIPTION"
                                                )}
                                              </Typography>
                                              <Typography
                                                id="transition-modal-description"
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.rooms[0] !== undefined &&
                                                row.rooms[0].description !==
                                                  undefined ? (
                                                  row.rooms[0] !== null &&
                                                  row.rooms[0].description !==
                                                    null &&
                                                  row.rooms[0].description
                                                    .length > 300 ? (
                                                    <ReactTextCollapse
                                                      options={
                                                        TEXT_COLLAPSE_OPTIONS
                                                      }
                                                    >
                                                      <div
                                                        dangerouslySetInnerHTML={{
                                                          __html:
                                                            row.rooms[0]
                                                              .description,
                                                        }}
                                                      />
                                                    </ReactTextCollapse>
                                                  ) : (
                                                    <div
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          row.rooms[0]
                                                            .description,
                                                      }}
                                                    />
                                                  )
                                                ) : (
                                                  <></>
                                                )}
                                              </Typography>

                                              <Typography
                                                variant="h6"
                                                component="h2"
                                                className="description"
                                                style={{
                                                  marginTop: "30px",
                                                  fontWeight: "700",
                                                  color: "#4a4a4a",
                                                }}
                                              >
                                                {GetLabel(
                                                  language,
                                                  "LABEL_BOOKING_TERMS"
                                                )}
                                              </Typography>
                                              <Typography
                                                id="transition-modal-description"
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.rooms[0].policy !== null &&
                                                row.rooms[0].policy.length >
                                                  300 ? (
                                                  <ReactTextCollapse
                                                    options={
                                                      TEXT_COLLAPSE_OPTIONS
                                                    }
                                                  >
                                                    <div
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          row.rooms[0].policy,
                                                      }}
                                                    />
                                                  </ReactTextCollapse>
                                                ) : (
                                                  <div
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        row.rooms[0].policy,
                                                    }}
                                                  />
                                                )}
                                              </Typography>
                                            </>
                                          ) : (
                                            <>
                                              <div
                                                style={{
                                                  borderRadius: 999,
                                                  backgroundColor: "#c90000",
                                                  color: "#FFF",
                                                  fontWeight: 600,
                                                  padding: 5,
                                                  width: "max-content",
                                                  marginBottom: 16,
                                                  fontSize: 14,
                                                }}
                                              >
                                                {GetLabel(
                                                  language,
                                                  "LABEL_UNAVAILABLE"
                                                )}
                                              </div>

                                              <span
                                                style={{
                                                  color: "#c90000",
                                                  fontSize: 14,
                                                }}
                                              >
                                                {GetLabel(
                                                  language,
                                                  "LABEL_ROOM_SEARCHED_FOR"
                                                )}
                                                <b>
                                                  {moment(row.days[0].date_ref)
                                                    .utc()
                                                    .format(" DD/MM/YYYY ")}
                                                </b>
                                                {GetLabel(
                                                  language,
                                                  "LABEL_TO_DAY"
                                                )}
                                                <b>
                                                  {moment(
                                                    row.days[
                                                      row.days.length - 1
                                                    ].date_ref
                                                  )
                                                    .utc()
                                                    .format(" DD/MM/YYYY ")}
                                                </b>
                                                {GetLabel(
                                                  language,
                                                  "LABEL_NO_MORE_AVAILABLE"
                                                )}
                                              </span>
                                            </>
                                          )}
                                        </Grid>
                                      </Grid>
                                    </div>
                                    {row.is_available &&
                                      row.rooms[0].discount_code && (
                                        <div className="flex flex-col text-red-600 mt-2">
                                          <div className="flex flex-row items-center text-red-600 gap-2">
                                            {GetLabel(
                                              language,
                                              "LABEL_DISCOUNT_AMOUNT"
                                            )}
                                            <span className="text-xl font-semibold">
                                              {row.rooms[0].discount_perc} %
                                            </span>
                                          </div>
                                          <div className="flex flex-row items-center text-red-500 text-sm">
                                            {GetLabel(
                                              language,
                                              "LABEL_DISCOUNT_CODE"
                                            )}
                                            &nbsp;
                                            {row.rooms[0].discount_code}
                                          </div>
                                        </div>
                                      )}
                                    {row.is_available && (
                                      <>
                                        <Typography
                                          variant="h6"
                                          component="h2"
                                          className="description"
                                          style={{
                                            marginTop: "20px",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                          }}
                                        >
                                          {GetLabel(
                                            language,
                                            "LABEL_PRICE_BOOKING"
                                          )}
                                          <Tooltip
                                            title={GetLabel(
                                              language,
                                              "LABEL_PRICE"
                                            )}
                                          >
                                            <div
                                              style={{
                                                fontSize: "24px",
                                                fontWeight: "500",
                                                color: "green",
                                                marginLeft: "20px",
                                              }}
                                            >
                                              €{" "}
                                              {packet && packet.id_promo
                                                ? parseFloat(
                                                    temp_packet.final_price
                                                  ).toFixed(2)
                                                : getPrice(row.days)}
                                            </div>
                                          </Tooltip>
                                        </Typography>
                                        <p
                                          style={{
                                            margin: "0",
                                            fontSize: "13px",
                                            color: "#838383",
                                          }}
                                        >
                                          {GetLabel(
                                            language,
                                            "LABEL_TAX_PAY_EXCLUDED"
                                          )}
                                        </p>
                                        <PaymentRowDays
                                          days={row.days}
                                          temp_packet={temp_packet}
                                          packet={packet}
                                        ></PaymentRowDays>
                                      </>
                                    )}
                                  </Card>
                                </Grid>
                              </>
                            )}
                          </>
                        );
                      })}
                      <Grid item sm={3} xs={12}></Grid>
                      <Grid item sm={7} xs={12}>
                        {extras && extras.length > 0 && (
                          <ExtraPayment
                            extras={extras}
                            setExtras={setExtras}
                            init_total={total}
                            total={total}
                            setTotal={setTotal}
                            language={language}
                            temp_packet={temp_packet}
                          ></ExtraPayment>
                        )}
                      </Grid>

                      <Grid item sm={3} xs={12}></Grid>
                      <Grid item sm={7} xs={12}>
                        {booking.rows &&
                        booking.rows.filter((item) => item.is_available)
                          .length > 0 ? (
                          <PaymentCards
                            structureInfo={structureInfo}
                            rows={booking.rows}
                            token={link}
                            extras={extras}
                            uuid={uuid}
                            total={total}
                            language={language}
                          />
                        ) : (
                          <Card
                            sx={{
                              padding: "20px",
                              textAlign: "center",
                              color: "#c90000",
                              borderRadius: "12px",
                            }}
                          >
                            <h2>{GetLabel(language, "LABEL_NO_ROOM_PAY")}</h2>
                          </Card>
                        )}
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "20px",
                        }}
                      >
                        <Card>
                          <CardContent>
                            <Grid container>
                              <Grid
                                item
                                xs={2}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <img src="/assets/images/no-calendar.png" />
                              </Grid>
                              <Grid
                                item
                                xs={10}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {GetLabel(
                                  language,
                                  "LABEL_NO_SEARCH_COMBINATIONS"
                                )}
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </div>
                    </Grid>
                  )}
                </>
              )}
            </>
          )}
        </Grid>
      </div>
    </>
  );
}
