import React, { useState } from "react";
import { HiOutlineHandThumbUp } from "react-icons/hi2";
import Flickity from "react-flickity-component";
import { FaMale } from "react-icons/fa";
import "../../flickity.css";

function PacketRoom({ room, rooms, setRooms }) {
  return (
    <div
      className={`w-full grid grid-cols-12 bg-white rounded-md shadow-md p-6 gap-3 hover:scale-105 
    cursor-pointer transition-all ease-out
    ${
      room.selected &&
      "border-2 ring-2 ring-blue-200 border-blue-400 !bg-blue-50"
    }`}
      onClick={() => {
        setRooms(
          rooms.map((el) => {
            if (el.id_promo_cat === room.id_promo_cat)
              el.selected = !el.selected;
            else el.selected = false;
            return el;
          })
        );
      }}
    >
      <div
        className="col-span-12 md:col-span-4"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {room.images.length === 0 ? (
          <div
            className="bg-gray-200 text-gray-500 
          uppercase w-full h-full rounded-lg flex items-center justify-center"
          >
            nessuna foto
          </div>
        ) : (
          <Flickity options={{ lazyLoad: true }}>
            {room.images.map((image, i) => {
              return (
                <div
                  style={{
                    background: "url(" + image + ")",
                  }}
                  className="carousel-img"
                ></div>
              );
            })}
          </Flickity>
        )}
      </div>
      <div className="col-span-12 md:col-span-8 flex flex-col gap-3">
        <h2
          className={`text-2xl font-bold ${
            room.selected ? "text-blue-500" : "text-gray-700"
          } truncate`}
        >
          {room.name}
        </h2>
        <div
          className="text-sm line-clamp-3"
          dangerouslySetInnerHTML={{ __html: room.description }}
        />

        <div className="flex flex-row items-center my-3 text-gray-500">
          <FaMale
            className={`${room.selected ? "text-blue-500" : "text-gray-700"}`}
          />
          Massimo Ospiti:{" "}
          <span
            className={`text-xl font-bold ${
              room.selected ? "text-blue-500" : "text-gray-700"
            } ml-1`}
          >
            {room.maxGuests}
          </span>
        </div>

        {room.selected && (
          <div className="text-blue-500 font-semibold flex flex-row items-center mt-auto gap-2">
            <HiOutlineHandThumbUp className="w-5 h-5 animate__animated animate__jackInTheBox" />
            <span>Ottima scelta!</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default PacketRoom;
