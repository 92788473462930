import { Badge, Button, Modal, Spin } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import preventiviService from "../../services/preventivi.service";
import { List } from "antd";
import useStateCallback from "../../components/useStateCallback";
import { Toaster, toast } from "react-hot-toast";
import dayjs from "dayjs";
import PreventiviRequestPreview from "./PreventiviRequestPreview";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const PreventiviRequests = ({ nrequests, setNRequests }) => {
  const { token } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [requests, setRequests] = useStateCallback([]);
  const [loading, setLoading] = useState(true);
  const [focusedRequest, setFocusedRequest] = useState();
  const [modal, contextHolder] = Modal.useModal();
  const navigate = useNavigate();

  useEffect(() => {
    if (token && isModalOpen) fetchRequests();
  }, [isModalOpen]);

  const fetchRequests = async () => {
    setLoading(true);
    setRequests([], () => {
      preventiviService.getRequests(token).then(
        (result) => {
          result = result.data;
          setRequests(result);
          setLoading(false);
        },
        (error) => {}
      );
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const deleteRequest = async (id_request) => {
    setLoading(true);
    preventiviService.deleteRequest(token, id_request).then(
      (result) => {
        if (result.status === 200) {
          setRequests(requests.filter((el) => el.id_request !== id_request));
          toast.success("La richiesta è stata eliminata.");
          setNRequests(nrequests - 1);
        }
        setLoading(false);
      },
      (error) => {}
    );
  };

  const confirm = (id_request) => {
    modal.confirm({
      title: "Conferma eliminazione",
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          Si sta eliminando la richiesta <b># {id_request}</b>, l'operazione è
          irreversibile. Proseguire?{" "}
        </>
      ),
      okText: "Sì, Elimina",
      cancelText: "Annulla",
      onOk: () => deleteRequest(id_request),
    });
  };

  const answer = (id_request) => {
    setLoading(true);
    preventiviService.answerRequest(token, id_request).then(
      (result) => {
        if (result.status === 200) {
          navigate("/preventivo/edit/" + token + "/" + result.data);
        }
        setLoading(false);
      },
      (error) => {
        toast.error("Errore: qualcosa non ha funzionato");
      }
    );
  };

  return (
    <>
      <PreventiviRequestPreview
        id_request={focusedRequest}
        setFocusedRequest={setFocusedRequest}
      />
      <Badge count={nrequests}>
        <Button
          type="dashed"
          className="!flex !flex-row !items-center w-auto"
          onClick={() => setIsModalOpen(true)}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 text-black"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z"
              />
            </svg>
          }
        >
          Le tue richieste
        </Button>
      </Badge>
      <Modal
        title={"Le tue richieste di preventivo"}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel} disabled={loading}>
            Annulla
          </Button>,
        ]}
      >
        <Spin spinning={loading}>
          <List
            itemLayout="horizontal"
            dataSource={requests}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      class="w-6 h-6 text-purple-500"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                      />
                    </svg>
                  }
                  title={
                    <div className="flex flex-row items-center w-full">
                      <div className="flex flex-row items-center gap-3">
                        <span>{item.name + " " + item.surname}</span>
                      </div>
                      <img
                        src={
                          "https://www.idaweb.eu/Images/Sys/flag/" +
                          item.language +
                          ".png"
                        }
                        alt="model-language"
                        className="ml-auto w-[20px]"
                      />
                    </div>
                  }
                  description={
                    <div className="flex flex-col gap-1">
                      <span className="text-gray-600 text-xs">
                        Ricevuta il{" "}
                        {dayjs(item.creation_date).format("DD/MM/YYYY HH:mm")}
                      </span>
                      <span>
                        {item.email} {item.phone && <>&#x2022; {item.phone}</>}
                      </span>

                      <div className="flex flex-row items-center gap-3 w-full mt-2">
                        <Button
                          size="small"
                          onClick={() => confirm(item.id_request)}
                          danger
                        >
                          Elimina
                        </Button>

                        <div className="ml-auto flex flex-row gap-3">
                          <Button
                            type="dashed"
                            size="small"
                            onClick={() => setFocusedRequest(item.id_request)}
                          >
                            Dettagli
                          </Button>
                          <Button
                            type="primary"
                            size="small"
                            onClick={() => answer(item.id_request)}
                          >
                            Rispondi
                          </Button>
                        </div>
                      </div>
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        </Spin>
      </Modal>
      {contextHolder}
      <Toaster />
    </>
  );
};
export default PreventiviRequests;
