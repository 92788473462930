import React from "react";
import dayjs from "dayjs";
import { Button, ConfigProvider, DatePicker } from "antd";

import "dayjs/locale/it";
import locale from "antd/locale/it_IT";
dayjs.locale("it");

const { RangePicker } = DatePicker;

function PreventivoDueDate({
  preventivo,
  setPreventivo,
  UpdatePreventivo,
  className,
}) {
  const [open, setOpen] = React.useState(false);
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  // eslint-disable-next-line arrow-body-style
  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().add(-1, "day");
  };

  const ExpireDate = () => {
    const new_date = preventivo.due_date && dayjs(preventivo.due_date);
    return (
      <div
        onClick={() => setOpen(true)}
        className={`hover:bg-slate-200 transition rounded-lg cursor-pointer px-2 ${className}`}
      >
        <>
          {!new_date ? (
            <div className="text-gray-400 flex flex-row items-center">
              Nessuna data di scadenza
            </div>
          ) : (
            <>
              {today.getFullYear() === new_date.toDate().getFullYear() &&
              today.getMonth() === new_date.toDate().getMonth() &&
              today.getDate() === new_date.toDate().getDate() ? (
                <>
                  {new_date.isBefore(dayjs()) ? (
                    <div className="text-red-500 flex flex-row items-center">
                      Scaduto
                      <div className="ml-3">
                        ({new_date.format("DD MMM YY, HH:mm")})
                      </div>
                    </div>
                  ) : (
                    <div className="text-orange-500 flex flex-row items-center">
                      Oggi
                      <div className="ml-3">
                        ({new_date.format("DD MMM YY, HH:mm")})
                      </div>
                    </div>
                  )}
                </>
              ) : tomorrow.toDateString() ===
                new_date.toDate().toDateString() ? (
                <div className="text-yellow-600 flex flex-row items-center">
                  Domani
                  <div className="ml-3">
                    ({new_date.format("DD MMM YY, HH:mm")})
                  </div>
                </div>
              ) : new_date.isBefore(dayjs()) ? (
                <div className="text-red-500 flex flex-row items-center">
                  Scaduto
                  <div className="ml-3">
                    ({new_date.format("DD MMM YY, HH:mm")})
                  </div>
                </div>
              ) : (
                <div className="text-slate-500 flex flex-row items-center">
                  {new_date.format("dddd, DD MMM YY, HH:mm")}
                </div>
              )}
            </>
          )}
        </>
      </div>
    );
  };

  return (
    <div className="flex flex-row items-center capitalize">
      <ExpireDate />

      <ConfigProvider locale={locale}>
        <DatePicker
          allowClear={true}
          disabledDate={disabledDate}
          showNow={false}
          open={open}
          onOpenChange={setOpen}
          value={preventivo.due_date && dayjs(preventivo.due_date)}
          onChange={(date, dateString) => {
            setPreventivo({ ...preventivo, due_date: date.toISOString() }, () =>
              UpdatePreventivo("5", date.format("DD/MM/YYYY HH:mm:ss"))
            );
          }}
          className="hide-dp"
          showTime={{
            defaultValue: dayjs("00:00:00", "HH:mm:ss"),
          }}
          renderExtraFooter={() => (
            <div className="p-1">
              <Button
                type="link"
                onClick={() => {
                  setPreventivo({ ...preventivo, due_date: null }, () =>
                    UpdatePreventivo("5", null)
                  );
                }}
              >
                Rimuovi data di scadenza
              </Button>
            </div>
          )}
        />
      </ConfigProvider>
    </div>
  );
}

export default PreventivoDueDate;
