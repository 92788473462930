import React from "react";
import webService from "../../services/web.service";
import LoadingIndicator from "../../components/LoadingIndicator";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import dayjs from "dayjs";
import { Button, DatePicker, Divider, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import { StyleProvider } from "@ant-design/cssinjs";

function WhereAreWe({ language }) {
  const { token, id_config } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [wherearewe, setWhereAreWe] = React.useState({});

  React.useEffect(() => {
    if (id_config) fetchWhereAreWe();
  }, [language]);

  const fetchWhereAreWe = async () => {
    webService.getWhereAreWe(token, id_config, language.value).then(
      (result) => {
        setWhereAreWe(result.data);
        setLoading(false);
      },
      (error) => {
        toast.error("Si è verificato un errore");
        setIsDisabled(false);
      }
    );
  };

  const saveWhereAreWe = () => {
    webService
      .saveWhereAreWe(token, id_config, language.value, wherearewe)
      .then(
        (result) => {
          if (result.status === 200)
            toast.success("Il 'Dove Siamo' è stato salvato");
          setLoading(false);
        },
        (error) => {
          toast.error("Si è verificato un errore");
          setIsDisabled(false);
        }
      );
  };

  return (
    <>
      <StyleProvider hashPriority="high">
        {loading ? (
          <div className="w-full h-full flex items-center justify-center">
            <LoadingIndicator />
          </div>
        ) : (
          <>
            <div className="bg-gray-100">
              <div className="pt-5 pr-5 w-full flex justify-end gap-4">
                <button
                  className={`rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                  onClick={saveWhereAreWe}
                  type="button"
                >
                  Salva Dove Siamo
                </button>
              </div>
              <div className="flex flex-col p-5">
                <label>Titolo:</label>
                <Input
                  value={wherearewe.title_obj.text}
                  onChange={(e) =>
                    setWhereAreWe({
                      ...wherearewe,
                      title_obj: {
                        ...wherearewe.title_obj,
                        text: e.target.value,
                      },
                    })
                  }
                />

                <label className="mt-3">Descrizione:</label>
                <TextArea
                  value={wherearewe.description_obj.text}
                  onChange={(e) =>
                    setWhereAreWe({
                      ...wherearewe,
                      description_obj: {
                        ...wherearewe.description_obj,
                        text: e.target.value,
                      },
                    })
                  }
                  rows={10}
                />

                <label className="mt-3">URL Mappa:</label>
                <Input
                  value={wherearewe.map_url}
                  onChange={(e) =>
                    setWhereAreWe({
                      ...wherearewe,
                      map_url: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </>
        )}
      </StyleProvider>
    </>
  );
}

export default WhereAreWe;
