import { Grid } from "@mui/material";
import React from "react";
import {
  Bolder,
  Container,
  Flex,
  Helper,
  SimpleSeparator,
  Text,
  Title,
} from "../styles";
import TextInput from "../textInput";
import WizardButtons from "../wizardButtons";
import YesNoSwitch from "../yesno";

export default function Step6(props) {
  const [cannotProceed, setCannotProceed] = React.useState(false);

  React.useEffect(() => {
    var cannotProceedVar = false;

    if (props.state.categories) {
      if (props.state.categories.length === 0) {
        cannotProceedVar = true;
      } else {
        props.state.categories.map((category) => {
          if (!category.description_it || !category.description_en) {
            cannotProceedVar = true;
            return;
          }
        });
      }
    }
    setCannotProceed(cannotProceedVar);
  }, [props.state.categories]);

  return (
    <>
      <Container>
        <Helper>
          <Title>📝Inserisci delle descrizioni per le categorie</Title>
          <Text>
            Ti consigliamo di descrivere brevemente ogni categoria che offri in
            modo da invogliare i tuoi clienti a prenotarle.
            <br></br>
            Dovrai inserire almeno una descrizione in <b>Italiano</b> e{" "}
            <b>Inglese</b>, per tradurre da Italiano a Inglese{" "}
            <a
              href="https://translate.google.it/?hl=it&sl=it&tl=en&op=translate"
              target="_blank"
            >
              clicca qui
            </a>
            <br></br>
            <br></br>
            <b>Esempio italiano</b>: Bellissima stanza con letto matrimoniale
            con sauna dotata di un balcone vista lago.
            <br></br>
            <b>Esempio inglese</b>: Beautiful room with double bed with sauna
            equipped with a balcony overlooking the lake.
          </Text>
        </Helper>
        <Bolder>Risposta</Bolder>

        {props.state.n_categories && (
          <>
            {[...Array(parseInt(props.state.n_categories))].map((e, i) => (
              <Grid container spacing={2} key={"categories-step6-" + i}>
                <Grid item xs={12}>
                  <TextInput
                    label={
                      <Flex>
                        <img
                          src="https://www.idaweb.eu/Images/Sys/flag/IT.png"
                          alt="logo ita"
                          style={{ width: "16px", marginRight: "10px" }}
                        />
                        Descrizione italiano &nbsp;{" "}
                        <b>
                          categoria {i + 1} ({props.state.categories[i].name})
                        </b>
                      </Flex>
                    }
                    value={props.state.categories[i].description_it}
                    placeholder={"categoria " + (i + 1)}
                    onChange={(e) =>
                      props.setState({
                        ...props.state,
                        categories: props.state.categories.map(
                          (item, index) => {
                            if (index === i)
                              item.description_it = e.target.value;
                            return item;
                          }
                        ),
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label={
                      <Flex>
                        <img
                          src="https://www.idaweb.eu/Images/Sys/flag/UK.png"
                          alt="logo eng"
                          style={{ width: "16px", marginRight: "10px" }}
                        />
                        Descrizione inglese &nbsp;{" "}
                        <b>
                          categoria {i + 1} ({props.state.categories[i].name})
                        </b>
                      </Flex>
                    }
                    value={props.state.categories[i].description_en}
                    placeholder={"categoria " + (i + 1)}
                    onChange={(e) =>
                      props.setState({
                        ...props.state,
                        categories: props.state.categories.map(
                          (item, index) => {
                            if (index === i)
                              item.description_en = e.target.value;
                            return item;
                          }
                        ),
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <SimpleSeparator />
                </Grid>
              </Grid>
            ))}
          </>
        )}
      </Container>
      <WizardButtons
        cannotProceed={cannotProceed}
        currentStep={props.currentStep}
        previousStep={props.previousStep}
        totalSteps={props.totalSteps}
        nextStep={props.nextStep}
      />
    </>
  );
}
