import React from "react";
import { Bolder, Container, Helper, Text, Title } from "../styles";
import TextInput from "../textInput";
import WizardButtons from "../wizardButtons";

export default function Step3(props) {
  const [cannotProceed, setCannotProceed] = React.useState(false);

  React.useEffect(() => {
    var cannotProceedVar = false;

    if (props.state.categories) {
      props.state.categories.map((category) => {
        if (!category.base_price || category.base_price === 0) {
          cannotProceedVar = true;
        }
      });
    }
    setCannotProceed(cannotProceedVar);
  }, [props.state.categories]);

  return (
    <>
      <Container>
        <Helper>
          <Title>💲Qual'è il prezzo di bassa stagione?</Title>
          <Text>
            Inserisci di seguito il <b>prezzo base</b> che offri per{" "}
            <b>una notte</b>
          </Text>
        </Helper>
        <Bolder>Risposta</Bolder>
        {props.state.categories && props.state.categories.length > 0 && (
          <>
            {props.state.categories.map((category, i) => {
              return (
                <TextInput
                  placeholder={"100,00"}
                  value={props.state.categories[i].base_price}
                  type="number"
                  label={
                    <>
                      Inserisci il prezzo base per la categoria:{" "}
                      <b>{props.state.categories[i].name}</b>
                    </>
                  }
                  onChange={(e) =>
                    props.setState({
                      ...props.state,
                      categories: props.state.categories.map((cat, index) => {
                        if (index === i) cat.base_price = e.target.value;
                        return cat;
                      }),
                    })
                  }
                  key={"category-price-" + i}
                  style={{ marginBottom: "20px" }}
                />
              );
            })}
          </>
        )}
      </Container>
      <WizardButtons
        cannotProceed={cannotProceed}
        currentStep={props.currentStep}
        previousStep={props.previousStep}
        totalSteps={props.totalSteps}
        nextStep={props.nextStep}
      />
    </>
  );
}
