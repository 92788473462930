export const colors = [
  {
    id: 1,
    value: "slate",
    color: "#475569",
  },
  {
    id: 2,
    value: "gray",
    color: "#4b5563",
  },
  {
    id: 3,
    value: "zinc",
    color: "#52525b",
  },
  {
    id: 4,
    value: "Neutral",
    color: "#475569",
  },
  {
    id: 5,
    value: "stone",
    color: "#57534e",
  },
  {
    id: 6,
    value: "red",
    color: "#dc2626",
  },
  {
    id: 7,
    value: "orange",
    color: "#ea580c",
  },
  {
    id: 8,
    value: "yellow",
    color: "#ca8a04",
  },
  {
    id: 9,
    value: "lime",
    color: "#65a30d",
  },
  {
    id: 10,
    value: "green",
    color: "#16a34a",
  },
  {
    id: 11,
    value: "emerald",
    color: "#059669",
  },
  {
    id: 12,
    value: "teal",
    color: "#0d9488",
  },
  {
    id: 13,
    value: "cyan",
    color: "#0891b2",
  },
  {
    id: 14,
    value: "sky",
    color: "#0284c7",
  },
  {
    id: 15,
    value: "blue",
    color: "#2563eb",
  },
  {
    id: 16,
    value: "indigo",
    color: "#4f46e5",
  },
  {
    id: 17,
    value: "violet",
    color: "#7c3aed",
  },
  {
    id: 18,
    value: "purple",
    color: "#9333ea",
  },
  {
    id: 19,
    value: "fuchsia",
    color: "#c026d3",
  },
  {
    id: 20,
    value: "pink",
    color: "#db2777",
  },
  {
    id: 21,
    value: "rose",
    color: "#e11d48",
  },
];
