import React, { useState } from "react";
import { Button, Checkbox, Form, Input, Select } from "antd";
import { StyleProvider } from "@ant-design/cssinjs";
import CountrySelect from "../../../../components/countrySelect";
import ProvinceSelect from "../../../../components/provinceSelect";
import PhoneInput from "react-phone-input-2";
import RowForm from "./RowForm";
import { useTranslation } from "react-i18next";

function FirstStep({ state, setState }) {
  const { t } = useTranslation("common");
  return (
    <div
      className="my-10 backdrop-blur-lg w-full h-full 
    rounded-lg py-10 border border-dashed border-gray-500
    px-5 md:px-20 min-h-[660px]"
    >
      <StyleProvider hashPriority="high">
        <div className="grid grid-cols-12 gap-5">
          <RowForm
            label={t("request.step1.name")}
            input={
              <Input
                placeholder={t("request.step1.placeholderName")}
                value={state.name}
                onChange={(e) => setState({ ...state, name: e.target.value })}
              />
            }
          />
          <RowForm
            label={t("request.step1.surname")}
            input={
              <Input
                placeholder={t("request.step1.placeholderSurname")}
                value={state.surname}
                onChange={(e) =>
                  setState({ ...state, surname: e.target.value })
                }
              />
            }
          />
          <RowForm
            label={t("request.step1.email")}
            input={
              <Input
                placeholder="Email"
                value={state.email}
                onChange={(e) => setState({ ...state, email: e.target.value })}
              />
            }
          />
          <RowForm
            label={
              <div className="flex flex-row w-full items-center">
                <span>{t("request.step1.from")}</span>
                <span
                  className="text-blue-400 !ml-auto hover:underline cursor-pointer text-sm"
                  onClick={() =>
                    setState({ ...state, is_italian: !state.is_italian })
                  }
                >
                  {state.is_italian
                    ? t("request.step1.changeNation")
                    : t("request.step1.changeProvince")}
                </span>
              </div>
            }
            input={
              <>
                {!state.is_italian ? (
                  <CountrySelect
                    value={state.country}
                    onChange={(newValue) =>
                      setState({ ...state, country: newValue })
                    }
                    style={{ width: "100%" }}
                    size="normal"
                  />
                ) : (
                  <ProvinceSelect
                    value={state.province}
                    onChange={(newValue) =>
                      setState({ ...state, province: newValue })
                    }
                    style={{ width: "100%" }}
                    size="normal"
                  />
                )}
              </>
            }
          />
          <RowForm
            label={t("request.step1.phone")}
            input={
              <PhoneInput
                className="!text-black !w-full phone-input"
                country={"it"}
                value={state.phone}
                onChange={(phone) => setState({ ...state, phone: phone })}
              />
            }
          />
          <RowForm
            label={t("request.step1.contact")}
            input={
              <Select
                className="!w-full"
                size="normal"
                value={state.contact_type}
                onChange={(e) => setState({ ...state, contact_type: e })}
                options={[
                  {
                    value: 1,
                    label: "Email",
                  },
                  {
                    value: 2,
                    label: "Email & Whatsapp",
                  },
                ]}
              />
            }
          />

          <div className="col-span-12 mt-10 flex flex-row items-start">
            <Checkbox
              onChange={(e) =>
                setState({ ...state, privacy: e.target.checked })
              }
              value={state.privacy}
              checked={state.privacy}
              className="w-full"
            >
              <div className="flex flex-col w-full text-white gap-2">
                <h4 className="text-xl">{t("request.step1.privacy")}</h4>
                <p className="text-md text-gray-300">
                  {t("request.step1.privacyBody")}
                </p>
              </div>
            </Checkbox>
          </div>

          <div className="col-span-12 mt-2 flex flex-row items-start">
            <Checkbox
              onChange={(e) =>
                setState({ ...state, newsletter: e.target.checked })
              }
              value={state.newsletter}
              checked={state.newsletter}
              className="w-full"
            >
              <div className="flex flex-col w-full text-white gap-2">
                <h4 className="text-xl">{t("request.step1.newsletter")}</h4>
                <p className="text-md text-gray-300">
                  {t("request.step1.newsletterBody")}
                </p>
              </div>
            </Checkbox>
          </div>
        </div>
      </StyleProvider>
    </div>
  );
}

export default FirstStep;
