import React from "react";
import {
  CalendarOutlined,
  CaretRightOutlined,
  PushpinOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  Button,
  Collapse,
  ConfigProvider,
  Divider,
  Form,
  Spin,
  Tooltip,
  theme,
} from "antd";
import dayjs from "dayjs";
import { Typography } from "antd";
import { DatePicker, Popconfirm, message } from "antd";
import { EditOutlined } from "@ant-design/icons";
import "dayjs/locale/it";
import locale from "antd/locale/it_IT";
import { toast } from "react-hot-toast";
import PrezzoHelper from "./Helpers/PrezzoHelper";
import preventiviService from "../../services/preventivi.service";
import PreventivoHelper from "./PreventivoHelper";
import LockedAction from "./Helpers/LockedAction";

const { RangePicker } = DatePicker;
const { Title, Paragraph, Text } = Typography;
const { Panel } = Collapse;

const contentRoom = (
  <div>
    La categoria corrente non ha disponibilità per le date immesse.
    <br></br>
    Controllare il planner per eventuali riassegnazioni.
  </div>
);

function PreventivoRoomRow({
  row,
  preventivo,
  setPreventivo,
  index,
  token_str,
}) {
  const { token } = theme.useToken();

  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };

  const [loading, setLoading] = React.useState(false);
  const [oldDates, setOldDates] = React.useState([row.date_from, row.date_to]);
  const [invalidDates, setInvalidDates] = React.useState(false);

  React.useEffect(() => {
    if (token_str && row.date_from && row.date_to && !row.id_booking_room) {
      fetchCategoryDisponibility();
    }
  }, [row.date_from, row.date_to]);

  const patchRoom = async () => {
    preventiviService
      .patchRowDates(token_str, {
        date_from_patch: dayjs(row.date_from).toISOString(),
        date_to_patch: dayjs(row.date_to).toISOString(),
        id_booking_room: row.id_booking_room,
        id_preventivo_row: row.id_preventivo_row,
        id_preventivo: row.id_preventivo,
        striked_price: row.striked_price,
        price: row.price,
      })
      .then(
        (result) => {
          result = result.data;
          if (!result) {
            setPreventivo({
              ...preventivo,
              rows: preventivo.rows.map((el, _index) => {
                if (_index === index) {
                  el.date_from = dayjs(oldDates[0]).toISOString();
                  el.date_to = dayjs(oldDates[1]).toISOString();
                }
                return el;
              }),
            });

            setInvalidDates(true);
          } else {
            setOldDates([row.date_from, row.date_to]);
            setInvalidDates(false);
          }
        },
        (error) => {
          setPreventivo({
            ...preventivo,
            rows: preventivo.rows.map((el, _index) => {
              if (_index === index) {
                el.date_from = dayjs(oldDates[0]).toISOString();
                el.date_to = dayjs(oldDates[1]).toISOString();
              }
              return el;
            }),
          });

          setInvalidDates(true);
          toast.error(error.response.data.ExceptionMessage);
        }
      );
  };
  const fetchCategoryDisponibility = async (patchRoom) => {
    setInvalidDates(false);

    setLoading(true);
    preventiviService
      .getDisponibilityCategory(token_str, row.id_category, {
        date_from: dayjs(row.date_from).format("DD/MM/YYYY"),
        date_to: dayjs(row.date_to).format("DD/MM/YYYY"),
        id_booking_room: row.id_booking_room,
      })
      .then(
        (result) => {
          result = result.data;
          if (!result) {
            setPreventivo({
              ...preventivo,
              rows: preventivo.rows.map((el, _index) => {
                if (_index === index) {
                  el.date_from = dayjs(oldDates[0]).toISOString();
                  el.date_to = dayjs(oldDates[1]).toISOString();
                }
                return el;
              }),
            });

            setInvalidDates(true);
          } else {
            setOldDates([row.date_from, row.date_to]);
            if (patchRoom) patchRoom();
          }
          setLoading(false);
        },
        (error) => {}
      );
  };
  const PreventivoCardRow = ({ icon, label, text, value, editable }) => (
    <Form.Item
      label={
        <div className="flex flex-row items-center gap-2">
          {icon}
          <span className="font-semibold text-slate-600">{label}</span>
        </div>
      }
      labelAlign="left"
      className="!m-0 "
    >
      {value !== "" || !editable ? (
        <span className="ml-3 flex items-start">{text}</span>
      ) : (
        <div className="flex flex-row items-center gap-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5 text-slate-400"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
            />
          </svg>
          <span className="text-slate-400">vuoto</span>
        </div>
      )}
    </Form.Item>
  );
  const ContainerHeader = () => (
    <div className="grid grid-cols-12 items-center">
      <div className="col-span-12 2xl:col-span-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          class="w-6 h-6 text-slate-600"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819"
          />
        </svg>
      </div>

      <div className="col-span-12 2xl:col-span-3">
        <Paragraph
          className="!m-0 font-medium text-lg text-slate-600"
          ellipsis={true}
        >
          {row.name}
        </Paragraph>
      </div>

      <div className="col-span-12 2xl:col-span-5">
        <div className="font-medium text-lg md:!ml-auto text-slate-500 flex flex-row items-center gap-3">
          {(row.id_booking_room || row.pin) && (
            <Tooltip
              title={
                <div>
                  <PushpinOutlined className="text-white mr-2 text-lg" />
                  <span>
                    Hai fissato questa camera sul tuo planner con il nome '
                    {row.booking_name}'"
                  </span>
                </div>
              }
            >
              <PushpinOutlined
                className="text-indigo-600"
                style={{ fontSize: "20px" }}
              />
            </Tooltip>
          )}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
            />
          </svg>

          <span>{dayjs(row.date_from).format("DD/MM/YYYY")}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
            />
          </svg>

          <span>{dayjs(row.date_to).format("DD/MM/YYYY")}</span>
        </div>
      </div>

      <div className="col-span-12 2xl:col-span-3 flex flex-row w-full">
        <Title level={4} className="2xl:!ml-auto !m-0 !text-slate-600">
          {parseFloat(row.price).toFixed(2)} &euro;
        </Title>
      </div>
    </div>
  );
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };
  function isNumber(str) {
    if (typeof str != "string") return false; // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  }
  const deleteRowRoom = () => {
    setLoading(true);
    preventiviService
      .deleteRowRoom(token_str + "/" + row.id_preventivo_row)
      .then(
        (result) => {
          result = result.data;
          if (result) {
            setPreventivo({
              ...preventivo,
              rows: preventivo.rows.filter((el, i) => i !== index),
            });
            message.success("La categoria è stata rimossa dal preventivo.");
          }

          setLoading(false);
        },
        (error) => {}
      );
  };

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={["1"]}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      className="bg-slate-50"
    >
      <Panel header={<ContainerHeader />} key="1" style={panelStyle}>
        <Spin spinning={loading}>
          <div className="mt-3">
            <Form
              labelCol={{
                span: 6,
              }}
              layout="horizontal"
            >
              <PreventivoCardRow
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 text-slate-600"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
                    />
                  </svg>
                }
                label={"Checkin / Checkout"}
                text={
                  <ConfigProvider locale={locale}>
                    <RangePicker
                      disabled={
                        preventivo.status === 2 ||
                        preventivo.status === 3 ||
                        preventivo.status === 4
                      }
                      disabledDate={disabledDate}
                      onChange={(dates) => {
                        setPreventivo(
                          {
                            ...preventivo,
                            rows: preventivo.rows.map((el, _index) => {
                              if (_index === index) {
                                el.date_from = dates[0].toISOString();
                                el.date_to = dates[1].toISOString();
                              }
                              return el;
                            }),
                          },
                          (s) => {
                            fetchCategoryDisponibility(true);
                          }
                        );
                      }}
                      value={[dayjs(row.date_from), dayjs(row.date_to)]}
                      format={"DD/MM/YYYY"}
                      className="w-auto"
                      allowClear={false}
                      status={invalidDates ? "error" : ""}
                    />
                    {invalidDates && (
                      <div className="ml-3">
                        <PreventivoHelper
                          title="La struttura risulta piena per le date scelte"
                          content={contentRoom}
                        >
                          <Text type="danger" style={{ marginTop: "5px" }}>
                            <CalendarOutlined /> Mancanza di disponibilità
                          </Text>
                        </PreventivoHelper>
                      </div>
                    )}
                  </ConfigProvider>
                }
                value={row.date_from}
              />

              <Divider plain>
                <div className="text-slate-400 flex flex-row items-center gap-3">
                  Prezzo
                  <PrezzoHelper />
                </div>
              </Divider>

              <PreventivoCardRow
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 text-slate-600"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M14.25 7.756a4.5 4.5 0 100 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                }
                label={"Prezzo barrato"}
                text={
                  <>
                    {preventivo.status === 2 ||
                    preventivo.status === 3 ||
                    preventivo.status === 4 ? (
                      <span className="text-red-500 line-through ">
                        {parseFloat(row.striked_price).toFixed(2)} &euro;
                      </span>
                    ) : (
                      <Paragraph
                        editable={{
                          tooltip: "Modifica il prezzo barrato",
                          text: row.striked_price,
                          onChange: (new_value) => {
                            if (isNumber(new_value) === false) {
                              toast.error("Sono accettati solo numeri.");
                              return;
                            }
                            setPreventivo(
                              {
                                ...preventivo,
                                rows: preventivo.rows.map((el, _index) => {
                                  if (_index === index) {
                                    el.striked_price =
                                      parseFloat(new_value).toFixed(2);
                                  }
                                  return el;
                                }),
                              },
                              patchRoom
                            );
                            toast.success("Il prezzo barrato è stato salvato");
                          },
                        }}
                        className="text-red-500 line-through !m-0 flex flex-row items-center"
                      >
                        {row.striked_price ? (
                          <>{parseFloat(row.striked_price).toFixed(2)} &euro;</>
                        ) : (
                          <div className="flex flex-row items-center gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-5 h-5 text-slate-400"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                              />
                            </svg>
                            <span className="text-slate-400">vuoto</span>
                          </div>
                        )}
                      </Paragraph>
                    )}
                  </>
                }
                value={row.striked_price}
              />

              <PreventivoCardRow
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 text-slate-600"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M14.25 7.756a4.5 4.5 0 100 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                }
                label={"Prezzo"}
                text={
                  <>
                    {preventivo.status === 2 ||
                    preventivo.status === 3 ||
                    preventivo.status === 4 ? (
                      <>{parseFloat(row.price).toFixed(2)} &euro;</>
                    ) : (
                      <Paragraph
                        editable={{
                          tooltip: "Modifica il prezzo",
                          text: row.price,
                          onChange: (new_value) => {
                            if (isNumber(new_value) === false) {
                              toast.error("Sono accettati solo numeri.");
                              return;
                            }

                            setPreventivo(
                              {
                                ...preventivo,
                                rows: preventivo.rows.map((el, _index) => {
                                  if (_index === index) {
                                    el.price = parseFloat(new_value).toFixed(2);
                                  }
                                  return el;
                                }),
                              },
                              patchRoom
                            );
                            toast.success("Il prezzo è stato salvato");
                          },
                        }}
                        className="!m-0 flex flex-row items-center"
                      >
                        {row.price ? (
                          <>{parseFloat(row.price).toFixed(2)} &euro;</>
                        ) : (
                          <div className="flex flex-row items-center gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-5 h-5 text-slate-400"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                              />
                            </svg>
                            <span className="text-slate-400">vuoto</span>
                          </div>
                        )}
                      </Paragraph>
                    )}
                  </>
                }
                value={row.price}
              />
            </Form>

            {preventivo.status === 2 ||
            preventivo.status === 3 ||
            preventivo.status === 4 ? (
              <div className="mt-6">
                <LockedAction />
              </div>
            ) : (
              <div className="w-full flex flex-row items-center mt-5">
                <Popconfirm
                  title="Eliminare la riga corrente?"
                  description={
                    <>
                      Vuoi eliminare la riga corrente dalla proposta?<br></br>
                      {(row.id_booking_room || row.pin) && (
                        <b>
                          La prenotazione '
                          <font className="text-red-600">
                            {row.booking_name}
                          </font>
                          ' associata sarà eliminata.
                          <br></br>
                        </b>
                      )}{" "}
                      L'operazione è irreversibile
                    </>
                  }
                  onConfirm={deleteRowRoom}
                  okText="Sì"
                  cancelText="Annulla"
                  className="ant-pop-confirm"
                >
                  <Button
                    className="!flex !flex-row !items-center"
                    type="dashed"
                    icon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6 text-red-500 mr-2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    }
                    danger
                  >
                    Elimina Riga
                  </Button>
                </Popconfirm>

                {(row.id_booking_room || row.pin) && (
                  <Tooltip
                    title={
                      <div>
                        <PushpinOutlined className="text-white mr-2 text-lg" />
                        <span>
                          Hai fissato questa camera sul tuo planner con il nome
                          '{row.booking_name}'
                        </span>
                      </div>
                    }
                  >
                    <div
                      className="text-indigo-600 bg-indigo-100 border ring-indigo-200 ring-1 rounded-lg 
                      p-2 flex flex-row items-center ml-auto gap-3"
                    >
                      <PushpinOutlined style={{ fontSize: "18px" }} />
                      <span>Camera fissata sul planner</span>
                    </div>
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        </Spin>
      </Panel>
    </Collapse>
  );
}

export default PreventivoRoomRow;
