import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import structureService from "../../services/structure.service";
import dayjs from "dayjs";

function PaymentPacker({
  language,
  packet,
  setPacket,
  temp_packet,
  setTempPacket,
}) {
  const { uuid, link } = useParams();

  useEffect(() => {
    structureService
      .getPaymentPacket(language + "/" + uuid + "/" + link)
      .then((result) => {
        if (result.status === 200) {
          setPacket(result.data.packet.packet);
          setTempPacket(result.data.temp_packet);
          console.log(result.data);
        }
      })
      .catch((error) => {});
  }, []);
  if (packet.id_promo)
    return (
      <div
        className="rounded-xl mb-5 p-5 text-white border border-white flex flex-col gap-3"
        style={{
          backgroundImage:
            'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://www.idaweb.eu' +
            packet.filepath +
            '")',
        }}
      >
        <span>Il pacchetto che hai scelto</span>
        <h1 className="text-3xl font-semibold">{packet.title}</h1>
        <span className="text-sm">
          {dayjs(temp_packet.date_from).format("DD/MM/YYYY")} -{" "}
          {dayjs(temp_packet.date_to).format("DD/MM/YYYY")}
        </span>
      </div>
    );
  else return null;
}

export default PaymentPacker;
