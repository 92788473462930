import { useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import PreventivoRequestSteps from "./PreventivoRequestSteps";
import { PhoneIcon } from "@heroicons/react/solid";
import { useNavigate, useParams } from "react-router-dom";
import preventiviService from "../../../services/preventivi.service";
import { Toaster, toast } from "react-hot-toast";
import { Spin } from "antd";
import RequestLanguage from "./RequestLanguage";
import { useTranslation } from "react-i18next";
import webService from "../../../services/web.service";

export default function PreventivoRequest() {
  const { uuid } = useParams();
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState({});
  const [structureInfo, setStructureInfo] = useState({
    structure_phone: "",
    structure_image: "",
  });
  const navigate = useNavigate();

  const { t, i18n } = useTranslation("common");

  useEffect(() => {
    document.title = "Richiedi preventivo";
    if (uuid) fetchPreventivo();
  }, []);

  const fetchPreventivo = async () => {
    setLoading(true);
    preventiviService.checkPreventivoModule(uuid).then(
      (result) => {
        fetchStructureInfo(uuid);
      },
      (error) => {
        navigate("/404");
      }
    );
  };
  const fetchStructureInfo = async () => {
    preventiviService.getStructurePublicInfo(uuid).then(
      (result) => {
        if (result.status === 200) setStructureInfo(result.data);
        setLoading(false);
      },
      (error) => {
        navigate("/404");
      }
    );
  };
  const confirmRequest = async (data) => {
    setLoading(true);
    preventiviService.confirmPreventivoRequest(uuid, data).then(
      (result) => {
        if (result.status === 200)
          navigate(`/public/preventivo/request/success/` + i18n.language);
        else
          toast.error(
            "Si è verificato un errore durante l'invio della richiesta, se il problema persiste contattare la struttura."
          );
        setLoading(false);
      },
      (error) => {
        toast.error(
          "Si è verificato un errore durante l'invio della richiesta, se il problema persiste contattare la struttura."
        );
        setLoading(false);
      }
    );
  };
  return (
    <Spin spinning={loading}>
      <div className="h-full w-full">
        <div
          className="h-full w-full p-10 bg-no-repeat bg-cover"
          style={{
            backgroundImage:
              'linear-gradient(rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)), url("https://www.idaweb.eu/Archive/Media/100/VKGPX2HM43.jpg")',
          }}
        >
          <div className="mx-auto max-w-7xl w-full flex flex-col gap-10">
            <header className="hidden lg:flex flex-row items-center gap-10">
              <img src="/assets/images/favicon_ida.png" className="w-10" />
              <div className="flex flex-col">
                <h1 className="text-2xl font-bold tracking-tight text-white sm:text-4xl">
                  {t("request.title")}
                </h1>
                <p className="text-lg leading-8 text-gray-400">
                  {t("request.subtitle")}
                </p>
              </div>
              <div className="ml-auto flex flex-col justify-center items-center text-center gap-2">
                <img
                  src={"https://www.idaweb.eu/" + structureInfo.structure_logo}
                  alt="structure"
                  className="w-24 rounded-full"
                />
                <div className="flex flex-row items-center gap-3">
                  <PhoneIcon className="w-5 text-white" />
                  <a
                    href={"tel:" + structureInfo.structure_phone}
                    className="text-white tracking-wider text-xs font-semibold"
                  >
                    {structureInfo.structure_phone}
                  </a>
                </div>
                <RequestLanguage />
              </div>
            </header>

            {/* Mobile */}
            <header className="flex lg:hidden flex-col items-center gap-10">
              <div className="grid grid-cols-12 w-full">
                <div className="col-span-6">
                  <img src="/assets/images/favicon_ida.png" className="w-14" />
                </div>
                <div className="col-span-6 flex justify-end">
                  <img
                    src="https://www.idaweb.eu/Archive/Media/150/G0R6PH5O2Y.png"
                    alt="structure"
                    className="w-24"
                  />
                </div>
              </div>
              <div className="flex flex-col text-center">
                <h1 className="text-2xl font-bold tracking-tight text-gray-300 sm:text-4xl">
                  {t("request.title")}
                </h1>
                <p className="text-lg leading-8 text-gray-400">
                  {t("request.subtitle")}
                </p>
              </div>
            </header>
            <PreventivoRequestSteps onConfirm={confirmRequest} />
          </div>
        </div>
        <Toaster />
      </div>
    </Spin>
  );
}
