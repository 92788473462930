import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GetLabel } from "../../languages/i18n";

function PacketItem({ item, index, language, hideViewAll, uuid }) {
  const navigate = useNavigate();

  return (
    <div className="w-full h-full bg-white rounded-md flex flex-col p-[1px] overflow-hidden">
      <div
        className="w-full h-full flex flex-col p-6 bg-no-repeat gap-2 border rounded-t-md"
        style={{
          backgroundImage:
            'linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5) ), url("https://www.idaweb.eu' +
            item.filepath +
            '")',
          backgroundSize: "cover",
        }}
      >
        <h2 className="font-bold text-2xl text-white mb-2">{item.title}</h2>
        <hr />
        <div
          className="!text-white text-sm mt-2 white-children line-clamp-3"
          dangerouslySetInnerHTML={{ __html: item.description }}
        />

        {/* <div className="text-white flex flex-row items-center gap-2 my-2">
                  <BsCalendar4Week className="w-6 h-6" /> Prossima data
                  disponibile:{" "}
                  <span className="font-semibold text-lg">
                    {dayjs(item.start_date).format("DD/MM/YYYY")}
                  </span>
                </div> */}
      </div>
      <div className="flex flex-col items-center md:flex-row mt-auto w-full p-2 gap-2">
        <div className="text-gray-500 text-md ml-2">
          {GetLabel(language, "PACKET_STARTING")}{" "}
          <span className="font-bold !text-xl text-blue-600">
            {item.price_night * item.min_nights} &euro;
          </span>{" "}
          {GetLabel(language, "PACKET_FOR")}{" "}
          <span className="font-bold !text-lg text-black">
            {item.min_nights}
          </span>{" "}
          {GetLabel(language, "PACKET_NIGHTS")}
        </div>

        <div className="md:ml-auto flex flex-row items-center gap-2">
          {!hideViewAll && (
            <div
              onClick={() => navigate("/packets-list/" + language + "/" + uuid)}
              className="p-2 px-3 text-blue-500
               hover:text-blue-600 hover:bg-blue-100 rounded-md cursor-pointer"
            >
              {GetLabel(language, "PACKET_SHOW")}
            </div>
          )}

          <div
            className="p-2 px-4 bg-blue-500 hover:bg-blue-600 cursor-pointer
                text-white rounded-md flex flex-row items-center gap-1"
            onClick={() =>
              navigate("/packet/" + language + "/" + uuid + "/" + item.uuid)
            }
          >
            {GetLabel(language, "PACKET_CHOOSE")}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              class="w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PacketItem;
