import React, { useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoadingIndicator from "../../components/LoadingIndicator";
import webService from "../../services/web.service";
import { toast } from "react-hot-toast";

function SurroundingsEdit({ language }) {
  const { token, id_config } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [surroundings, setSurroundings] = React.useState({});

  const inputFileRef1 = useRef();
  const inputFileRef2 = useRef();
  const inputFileRef3 = useRef();
  function handleImageChange1(e) {
    setSurroundings({
      ...surroundings,
      image1: URL.createObjectURL(e.target.files[0]),
      image1_obj: e.target.files[0],
    });
  }
  function handleImageChange2(e) {
    setSurroundings({
      ...surroundings,
      image2: URL.createObjectURL(e.target.files[0]),
      image2_obj: e.target.files[0],
    });
  }
  function handleImageChange3(e) {
    setSurroundings({
      ...surroundings,
      image3: URL.createObjectURL(e.target.files[0]),
      image3_obj: e.target.files[0],
    });
  }

  React.useEffect(() => {
    if (id_config) fetchSurroundings();
  }, [language]);

  const fetchSurroundings = async () => {
    webService.getSurroundings(token, id_config, language.value).then(
      (result) => {
        setSurroundings(result.data);
        setLoading(false);
      },
      (error) => {
        toast.error("Si è verificato un errore");
        setIsDisabled(false);
      }
    );
  };

  const saveSurroundings = async () => {
    setIsDisabled(true);

    const formData = new FormData();
    formData.append("image1", surroundings.image1_obj);
    formData.append("image2", surroundings.image2_obj);
    formData.append("image3", surroundings.image3_obj);
    formData.append(
      "surroundings",
      JSON.stringify({
        ...surroundings,
        image1_obj: null,
        image2_obj: null,
        image3_obj: null,
      })
    );

    webService
      .saveSurroundings(token, id_config, language.value, formData)
      .then(
        (result) => {
          if (result.status === 200) {
            setSurroundings(result.data);
            toast.success("I dintorni della struttura sono stati salvati");
          } else toast.error("Si è verificato un errore");
          setIsDisabled(false);
        },
        (error) => {
          toast.error("Si è verificato un errore");
          setIsDisabled(false);
        }
      );
  };

  const onBtnClick1 = () => {
    inputFileRef1.current.click();
  };
  const onBtnClick2 = () => {
    inputFileRef2.current.click();
  };
  const onBtnClick3 = () => {
    inputFileRef3.current.click();
  };
  const StructurImage1 = ({ url }) => (
    <div className="rounded-lg bg-white shadow p-5">
      <div className="flex flex-row items-center">
        <div className="flex flex-col">
          <h2 className="font-semibold text-lg">Foto #1</h2>
          <p>
            Consigliamo di scegliere una foto di dimensione <b>1080x1920</b>
          </p>
        </div>
        <input
          type="file"
          onChange={handleImageChange1}
          ref={inputFileRef1}
          className="hidden"
        />
        <span
          className="ml-auto cursor-pointer text-indigo-600 
                py-2 text-base font-medium 
                 hover:text-indigo-700"
          onClick={onBtnClick1}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
            />
          </svg>
        </span>
        {url && (
          <span
            className="cursor-pointer text-indigo-600 
                px-5 py-2 text-base font-medium 
                 hover:text-indigo-700"
            onClick={() => {
              if (window.confirm("Eliminare la foto selezionata?")) {
                setSurroundings({ ...surroundings, image1: null });
              }
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
        )}
      </div>
      <div className="mt-5 h-full">
        {url ? (
          <img
            src={url}
            alt={"surroundings-preview-1"}
            className="object-cover w-92 h-72 rounded-lg mt-5 mx-auto"
            loading="lazy"
          />
        ) : (
          <div className="flex flex-col items-center h-full w-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-12 h-12 text-gray-300"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H6.911a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661z"
              />
            </svg>
            <p className="text-gray-300">Nessuna foto.</p>
          </div>
        )}
      </div>
    </div>
  );
  const StructurImage2 = ({ url }) => (
    <div className="rounded-lg bg-white shadow p-5">
      <div className="flex flex-row items-center">
        <div className="flex flex-col">
          <h2 className="font-semibold text-lg">Foto #2</h2>
          <p>
            Consigliamo di scegliere una foto di dimensione <b>640x420</b>
          </p>
        </div>
        <input
          type="file"
          onChange={handleImageChange2}
          ref={inputFileRef2}
          className="hidden"
        />
        <span
          className="ml-auto cursor-pointer text-indigo-600 
                py-2 text-base font-medium 
                 hover:text-indigo-700"
          onClick={onBtnClick2}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
            />
          </svg>
        </span>
        {url && (
          <span
            className="cursor-pointer text-indigo-600 
                px-5 py-2 text-base font-medium 
                 hover:text-indigo-700"
            onClick={() => {
              if (window.confirm("Eliminare la foto selezionata?")) {
                setSurroundings({ ...surroundings, image2: null });
              }
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
        )}
      </div>
      <div className="mt-5 h-full">
        {url ? (
          <img
            src={url}
            alt={"surroundings-preview-2"}
            className="object-cover w-92 h-72 rounded-lg mt-5"
            loading="lazy"
          />
        ) : (
          <div className="flex flex-col items-center h-full w-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-12 h-12 text-gray-300"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H6.911a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661z"
              />
            </svg>
            <p className="text-gray-300">Nessuna foto.</p>
          </div>
        )}
      </div>
    </div>
  );
  const StructurImage3 = ({ url }) => (
    <div className="rounded-lg bg-white shadow p-5">
      <div className="flex flex-row items-center">
        <div className="flex flex-col">
          <h2 className="font-semibold text-lg">Foto #3</h2>
          <p>
            Consigliamo di scegliere una foto di dimensione <b>640x420</b>
          </p>
        </div>
        <input
          type="file"
          onChange={handleImageChange3}
          ref={inputFileRef3}
          className="hidden"
        />
        <span
          className="ml-auto cursor-pointer text-indigo-600 
                py-2 text-base font-medium 
                 hover:text-indigo-700"
          onClick={onBtnClick3}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
            />
          </svg>
        </span>
        {url && (
          <span
            className="cursor-pointer text-indigo-600 
                px-5 py-2 text-base font-medium 
                 hover:text-indigo-700"
            onClick={() => {
              if (window.confirm("Eliminare la foto selezionata?")) {
                setSurroundings({ ...surroundings, image3: null });
              }
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
        )}
      </div>
      <div className="mt-5 h-full">
        {url ? (
          <img
            src={url}
            alt={"surroundings-preview-3"}
            className="object-cover w-92 h-72 rounded-lg mt-5"
            loading="lazy"
          />
        ) : (
          <div className="flex flex-col items-center h-full w-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-12 h-12 text-gray-300"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H6.911a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661z"
              />
            </svg>
            <p className="text-gray-300">Nessuna foto.</p>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <>
      {loading ? (
        <div className="w-full h-full flex items-center justify-center">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <div className="bg-gray-100">
            <div className="pt-5 pr-5 w-full flex justify-end">
              <button
                className={`rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                onClick={saveSurroundings}
                type="button"
              >
                Salva i dintorni
              </button>
            </div>
            <div className="flex flex-col p-5">
              <div className="w-full">
                <label
                  htmlFor="body-advisor"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Descrizione #1 dei dintorni
                </label>
                <div className="mt-2">
                  <textarea
                    id="descr-surroundings-1"
                    rows={3}
                    className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
                    defaultValue={""}
                    placeholder="Es. I nostri boschetti nei dintorni offrono molte varietà di animali..."
                    value={surroundings.descr1_obj.text}
                    onChange={(e) =>
                      setSurroundings({
                        ...surroundings,
                        descr1_obj: {
                          ...surroundings.descr1_obj,
                          text: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className="w-full my-6">
                <label
                  htmlFor="body-advisor"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Descrizione #2 dei dintorni
                </label>
                <div className="mt-2">
                  <textarea
                    id="descr-surroundings-2"
                    rows={3}
                    className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
                    defaultValue={""}
                    placeholder="Es. I nostri boschetti nei dintorni offrono molte varietà di animali..."
                    value={surroundings.descr2_obj.text}
                    onChange={(e) =>
                      setSurroundings({
                        ...surroundings,
                        descr2_obj: {
                          ...surroundings.descr2_obj,
                          text: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 p-5 pl-0">
                <StructurImage1 url={surroundings.image1} />
                <StructurImage2 url={surroundings.image2} />
                <StructurImage3 url={surroundings.image3} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default SurroundingsEdit;
