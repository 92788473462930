import React from "react";
import { Bolder, Container, Helper, Text, Title } from "../styles";
import TextInput from "../textInput";
import WizardButtons from "../wizardButtons";
import YesNoSwitch from "../yesno";

export default function Step4(props) {
  const [cannotProceed, setCannotProceed] = React.useState(false);
  React.useEffect(() => {
    if (!props.state.last_minute) {
      if (!props.state.cutoff || parseInt(props.state.cutoff) === 0) {
        setCannotProceed(true);
      } else setCannotProceed(false);
    } else setCannotProceed(false);
  }, [props.state.last_minute, props.state.cutoff]);

  return (
    <>
      <Container>
        <Helper>
          <Title>🚀Accetti prenotazioni Last Minute?</Title>
          <Text>
            Una prenotazione <b>last minute</b> significa che l'ospite può
            arrivare prenotare oggi per oggi.
          </Text>
        </Helper>
        <Bolder>Risposta</Bolder>

        <YesNoSwitch
          checked={props.state.last_minute}
          onChange={(checked) =>
            props.setState({ ...props.state, last_minute: checked })
          }
        />

        {!props.state.last_minute && (
          <>
            <Text style={{ marginTop: "50px" }}>
              Indica di seguito quanti giorni prima del checkin consenti di
              prenotare
            </Text>
            <TextInput
              placeholder={"7"}
              value={props.state.cutoff}
              type="number"
              onChange={(e) =>
                props.setState({ ...props.state, cutoff: e.target.value })
              }
            />
          </>
        )}
      </Container>
      <WizardButtons
        cannotProceed={cannotProceed}
        currentStep={props.currentStep}
        previousStep={props.previousStep}
        totalSteps={props.totalSteps}
        nextStep={props.nextStep}
      />
    </>
  );
}
