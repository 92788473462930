import { Input, Checkbox, Select, Button } from "antd";
import React from "react";
import { LeftOutlined } from "@ant-design/icons";
import { StyleProvider } from "@ant-design/cssinjs";
import paymentService from "../services/payment.service";
import CountrySelect from "../components/countrySelect";
import { useParams } from "react-router-dom";
import { Skeleton } from "antd";
import ProvinceSelect from "../components/provinceSelect";

function InformationForm({ isLoading, setIsLoading, checkout, setCheckout }) {
  const { uuid } = useParams();

  const [state, setState] = React.useState({
    has_shipping: false,
    email: "",
    azienda: "",
    bill_as_firm: false,
    shipping: {
      subscribe_newsletter: false,
      country: "Italia",
      first_name: "",
      surname: "",
      address: "",
      zip_code: "",
      city: "",
      province: 0,
      same_info: false,
    },
    billing: {
      subscribe_newsletter: false,
      country: "Italia",
      first_name: "",
      surname: "",
      address: "",
      zip_code: "",
      city: "",
      province: 0,
      save_info: false,
    },
  });
  const [status, setStatus] = React.useState("error");
  const [isPaying, setIsPaying] = React.useState(false);

  React.useEffect(() => {
    var isInvalid = false;
    if (checkout) {
      if (
        checkout.invoice_info.email === "" ||
        checkout.invoice_info.email === null
      )
        isInvalid = true;
      if (
        checkout.invoice_info.phone === "" ||
        checkout.invoice_info.phone === null
      )
        isInvalid = true;
      if (
        checkout.invoice_info.country === "" ||
        checkout.invoice_info.country === null
      )
        isInvalid = true;
      if (
        checkout.invoice_info.name === "" ||
        checkout.invoice_info.name === null
      )
        isInvalid = true;
      if (
        checkout.invoice_info.surname === "" ||
        checkout.invoice_info.surname === null
      )
        isInvalid = true;
      if (
        checkout.invoice_info.address === "" ||
        checkout.invoice_info.address === null
      )
        isInvalid = true;
      if (
        checkout.invoice_info.zip_code === "" ||
        checkout.invoice_info.zip_code === null
      )
        isInvalid = true;
      if (
        checkout.invoice_info.municipality === "" ||
        checkout.invoice_info.municipality === null
      )
        isInvalid = true;
      if (
        checkout.invoice_info.province === "" ||
        checkout.invoice_info.province === 0 ||
        checkout.invoice_info.province === null
      )
        isInvalid = true;

      if (checkout.has_shipping) {
        if (
          checkout.shipping_info.country === "" ||
          checkout.shipping_info.country === null
        )
          isInvalid = true;
        if (
          checkout.shipping_info.name === "" ||
          checkout.shipping_info.name === null
        )
          isInvalid = true;
        if (
          checkout.shipping_info.surname === "" ||
          checkout.shipping_info.surname === null
        )
          isInvalid = true;
        if (
          checkout.shipping_info.address === "" ||
          checkout.shipping_info.address === null
        )
          isInvalid = true;
        if (
          checkout.shipping_info.zip_code === "" ||
          checkout.shipping_info.zip_code === null
        )
          isInvalid = true;
        if (
          checkout.shipping_info.municipality === "" ||
          checkout.shipping_info.municipality === null
        )
          isInvalid = true;
        if (
          checkout.shipping_info.province === "" ||
          checkout.shipping_info.province === 0 ||
          checkout.shipping_info.province === null
        )
          isInvalid = true;
      }
    } else isInvalid = true;
    if (isInvalid) setStatus("error");
    else setStatus("");
  }, [checkout]);

  const gotoPayment = () => {
    paymentService
      .saveCheckout(uuid, {
        shipping_info: checkout.shipping_info,
        invoice_info: checkout.invoice_info,
        subscribe_newsletter: checkout.invoice_info.subscribe_newsletter,
        discount_code: checkout.discount_code,
      })
      .then(
        (result) => {
          result = result.data;
          if (result.success) {
            setIsPaying(true);

            const script = document.createElement("script");
            script.src = `https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkout.hobex_id}`;
            script.async = true;
            document.body.appendChild(script);
          } else {
            // TODO: Gestire l'errore
          }
        },
        (error) => {}
      );
  };

  return (
    <>
      <StyleProvider hashPriority="high">
        {isPaying ? (
          <div>
            <form
              className="paymentWidgets"
              action={"http://localhost:3000/pay/result/success"}
              method="POST"
              data-brands="PAYPAL MASTER VISA"
            ></form>
          </div>
        ) : (
          <div className="flex flex-col mt-8">
            <h3 className="text-xl mb-2 font-normal">
              Informazioni di contatto
            </h3>

            {isLoading ? (
              <Skeleton.Input
                size="large"
                className="!w-full mb-1"
                active={true}
              />
            ) : (
              <div className="flex flex-col gap-2">
                {checkout.bill_as_firm && (
                  <Input
                    size="large"
                    value={checkout.azienda}
                    onChange={(e) =>
                      setCheckout({
                        ...checkout,
                        azienda: e.target.value,
                      })
                    }
                    placeholder="Nome Azienda"
                    className="mb-2 w-full"
                  />
                )}
                <div className="flex flex-col md:flex-row items-center gap-2">
                  <Input
                    size="large"
                    value={checkout.invoice_info.email}
                    onChange={(e) =>
                      setCheckout({
                        ...checkout,
                        invoice_info: {
                          ...checkout.invoice_info,
                          email: e.target.value,
                        },
                      })
                    }
                    placeholder="Email"
                    className="mb-2 w-full md:w-1/2"
                  />
                  <Input
                    size="large"
                    value={checkout.invoice_info.phone}
                    onChange={(e) =>
                      setCheckout({
                        ...checkout,
                        invoice_info: {
                          ...checkout.invoice_info,
                          phone: e.target.value,
                        },
                      })
                    }
                    placeholder="Telefono"
                    className="mb-2 w-full md:w-1/2"
                  />
                </div>
              </div>
            )}

            {!isLoading && (
              <div className="flex flex-col items-start justify-center mt-2">
                <Checkbox
                  onChange={(e) =>
                    setCheckout({
                      ...checkout,
                      invoice_info: {
                        ...checkout.invoice_info,
                        subscribe_newsletter: e.target.checked,
                      },
                    })
                  }
                  value={checkout.invoice_info.subscribe_newsletter}
                >
                  Mandami news e offerte sulla mail
                </Checkbox>
                <Checkbox
                  onChange={(e) =>
                    setCheckout({
                      ...checkout,
                      bill_as_firm: e.target.checked,
                    })
                  }
                  value={checkout.bill_as_firm}
                  className="!m-0"
                >
                  Fattura come azienda
                </Checkbox>
              </div>
            )}

            <h3 className="text-xl mb-2 mt-8 font-normal">
              Informazioni di fatturazione
            </h3>

            <div className="flex flex-col gap-2">
              {isLoading ? (
                <Skeleton.Input
                  size="large"
                  className="!w-full"
                  active={true}
                />
              ) : (
                <CountrySelect
                  value={checkout.invoice_info.country}
                  onChange={(e) => {
                    setCheckout({
                      ...checkout,
                      invoice_info: {
                        ...checkout.invoice_info,
                        country: e,
                      },
                    });
                  }}
                />
              )}

              <div className="flex flex-col md:flex-row gap-2">
                {isLoading ? (
                  <Skeleton.Input
                    size="large"
                    className="!w-full"
                    active={true}
                  />
                ) : (
                  <Input
                    size="large"
                    value={checkout.invoice_info.name}
                    onChange={(e) =>
                      setCheckout({
                        ...checkout,
                        invoice_info: {
                          ...checkout.invoice_info,
                          name: e.target.value,
                        },
                      })
                    }
                    placeholder="Nome"
                    className="w-full md:w-1/2"
                  />
                )}

                {isLoading ? (
                  <Skeleton.Input
                    size="large"
                    className="!w-full"
                    active={true}
                  />
                ) : (
                  <Input
                    size="large"
                    value={checkout.invoice_info.surname}
                    onChange={(e) =>
                      setCheckout({
                        ...checkout,
                        invoice_info: {
                          ...checkout.invoice_info,
                          surname: e.target.value,
                        },
                      })
                    }
                    placeholder="Cognome"
                    className="w-full md:w-1/2"
                  />
                )}
              </div>

              {isLoading ? (
                <Skeleton.Input
                  size="large"
                  className="!w-full"
                  active={true}
                />
              ) : (
                <Input
                  size="large"
                  value={checkout.invoice_info.address}
                  onChange={(e) =>
                    setCheckout({
                      ...checkout,
                      invoice_info: {
                        ...checkout.invoice_info,
                        address: e.target.value,
                      },
                    })
                  }
                  placeholder="Indirizzo"
                  className="w-full"
                />
              )}
              <div className="flex flex-col md:flex-row gap-2">
                {isLoading ? (
                  <>
                    <Skeleton.Input
                      size="large"
                      className="!w-full"
                      active={true}
                    />
                    <Skeleton.Input
                      size="large"
                      className="!w-full"
                      active={true}
                    />
                    <Skeleton.Input
                      size="large"
                      className="!w-full"
                      active={true}
                    />
                  </>
                ) : (
                  <>
                    <Input
                      size="large"
                      value={checkout.invoice_info.zip_code}
                      onChange={(e) =>
                        setCheckout({
                          ...checkout,
                          invoice_info: {
                            ...checkout.invoice_info,
                            zip_code: e.target.value,
                          },
                        })
                      }
                      placeholder="Codice Postale"
                      className="w-full md:w-1/3"
                    />
                    <Input
                      size="large"
                      value={checkout.invoice_info.municipality}
                      onChange={(e) =>
                        setCheckout({
                          ...checkout,
                          invoice_info: {
                            ...checkout.invoice_info,
                            municipality: e.target.value,
                          },
                        })
                      }
                      placeholder="Città"
                      className="w-full md:w-1/3"
                    />
                    <ProvinceSelect
                      value={checkout.invoice_info.province}
                      onChange={(e) => {
                        setCheckout({
                          ...checkout,
                          invoice_info: {
                            ...checkout.invoice_info,
                            province: e,
                          },
                        });
                      }}
                      className="w-full"
                    />
                  </>
                )}
              </div>

              {!isLoading && (
                <Checkbox
                  onChange={(e) =>
                    setCheckout({
                      ...checkout,
                      invoice_info: {
                        ...checkout.invoice_info,
                        same_info: e.target.value,
                      },
                    })
                  }
                  value={checkout.invoice_info.same_info}
                >
                  Salva le informazioni per la prossima volta
                </Checkbox>
              )}
            </div>

            {checkout && checkout.has_shipping && (
              <>
                <h3 className="text-xl mb-2 mt-8 font-normal">
                  Informazioni di spedizione
                </h3>

                <div className="flex flex-col gap-2">
                  <CountrySelect
                    value={checkout.shipping_info.country}
                    onChange={(e) => {
                      setCheckout({
                        ...checkout,
                        shipping_info: {
                          ...checkout.shipping_info,
                          country: e,
                        },
                      });
                    }}
                  />

                  <div className="flex flex-col md:flex-row gap-2">
                    <Input
                      size="large"
                      value={checkout.shipping_info.name}
                      onChange={(e) =>
                        setCheckout({
                          ...checkout,
                          shipping_info: {
                            ...checkout.shipping_info,
                            name: e.target.value,
                          },
                        })
                      }
                      placeholder="Nome"
                      className="w-full md:w-1/2"
                    />
                    <Input
                      size="large"
                      value={checkout.shipping_info.surname}
                      onChange={(e) =>
                        setCheckout({
                          ...checkout,
                          shipping_info: {
                            ...checkout.shipping_info,
                            surname: e.target.value,
                          },
                        })
                      }
                      placeholder="Cognome"
                      className="w-full md:w-1/2"
                    />
                  </div>

                  <Input
                    size="large"
                    value={checkout.shipping_info.address}
                    onChange={(e) =>
                      setCheckout({
                        ...checkout,
                        shipping_info: {
                          ...checkout.shipping_info,
                          address: e.target.value,
                        },
                      })
                    }
                    placeholder="Indirizzo"
                    className="w-full"
                  />

                  <div className="flex flex-col md:flex-row gap-2">
                    <Input
                      size="large"
                      value={checkout.shipping_info.zip_code}
                      onChange={(e) =>
                        setCheckout({
                          ...checkout,
                          shipping_info: {
                            ...checkout.shipping_info,
                            zip_code: e.target.value,
                          },
                        })
                      }
                      placeholder="Codice Postale"
                      className="w-full md:w-1/3"
                    />
                    <Input
                      size="large"
                      value={checkout.shipping_info.municipality}
                      onChange={(e) =>
                        setCheckout({
                          ...checkout,
                          shipping_info: {
                            ...checkout.shipping_info,
                            municipality: e.target.value,
                          },
                        })
                      }
                      placeholder="Città"
                      className="w-full md:w-1/3"
                    />
                    <ProvinceSelect
                      value={checkout.shipping_info.province}
                      onChange={(e) => {
                        setCheckout({
                          ...checkout,
                          shipping_info: {
                            ...checkout.shipping_info,
                            province: e,
                          },
                        });
                      }}
                      className="w-full"
                    />
                  </div>
                </div>
              </>
            )}

            <div className="flex flex-col md:flex-row mt-6 w-full items-center gap-2">
              <Button
                className="!flex !flex-row !items-center w-full justify-center text-center md:w-auto"
                type="link"
                icon={<LeftOutlined />}
              >
                Indietro
              </Button>

              <Button
                className="!flex !flex-row !items-center justify-center ml-auto w-full md:w-auto !p-5"
                type="primary"
                disabled={status === "error" ? true : false}
                onClick={gotoPayment}
              >
                Continua al pagamento
              </Button>
            </div>
          </div>
        )}
      </StyleProvider>
    </>
  );
}

export default InformationForm;
