import React from "react";
import PreventivoHelper from "../PreventivoHelper";
import { QuestionCircleOutlined } from "@ant-design/icons";

const content = (
  <div className="max-w-md max-h-52 overflow-y-auto">
    In questa sezione è possibile modificare due messaggi di conferma diversi,
    il primo in ordine di visualizzazione è il{" "}
    <b>messaggio di conferma online</b>, ovvero il testo che i tuoi clienti
    leggeranno quando avranno confermato il preventivo. Consigliamo di scrivere
    istruzioni, metodi di contatto, ... Buona prassi è, in ogni caso,
    ringraziare il cliente per l'accettazione.
    <br></br>
    <br></br>
    Il secondo messaggio ovvero il <b>messaggio di conferma email</b> è un testo
    che i clienti riceveranno assieme al recap del preventivo sulla loro email.
    I concetti di questo messaggio si applicano agli stessi del primo suddetto.
    <br></br>
    <br></br>
    L'<b>oggetto standard</b> indica il testo che il sistema pre-compilerà in
    automatico quando si sta inviando un nuovo messaggio al cliente.
    <br></br>
    <br></br>
    <i>
      Questo campo è utile soprattutto quando bisogna salvare il preventivo come
      modello.
    </i>
    <br></br>
    <br></br>
    Il <b>corpo standard</b> indica il contenuto della mail che il sistema
    pre-compilerà in automatico quando si sta inviando un nuovo messaggio al
    cliente.
    <br></br>
    <br></br>
    <i>
      Questo campo è utile soprattutto quando bisogna salvare il preventivo come
      modello
    </i>
    .
  </div>
);

function ConfirmHelper() {
  return (
    <PreventivoHelper
      title={<h3 className="text-lg">I messaggi di conferma</h3>}
      content={content}
    >
      <QuestionCircleOutlined
        className="text-blue-500"
        style={{ fontSize: "18px" }}
      />
    </PreventivoHelper>
  );
}

export default ConfirmHelper;
