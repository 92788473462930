import React, { useState } from "react";
import {
  ConfigProvider,
  DatePicker,
  Button,
  Popover,
  InputNumber,
  Divider,
  Tooltip,
  Input,
} from "antd";
import dayjs from "dayjs";
import "dayjs/locale/it";
import itIT from "antd/locale/it_IT";
import { CloseOutlined } from "@ant-design/icons";
import { StyleProvider } from "@ant-design/cssinjs";

const dateFormat = "DD MMMM YYYY";
const { RangePicker } = DatePicker;

function EmbedBookingEngine(props) {
  const [rooms, setRooms] = React.useState([
    {
      id: 1,
      adults: 2,
      children: 0,
    },
  ]);
  const [promoCode, setPromoCode] = React.useState("");

  const PopoverContent = ({ rooms, setRooms }) => (
    <div className="flex flex-col items-start w-full">
      {rooms?.map((room, index) => (
        <div key={"room-" + index} className="w-full">
          <Divider orientation="left" plain orientationMargin="0">
            <div className="flex flex-row items-center">
              {index > 0 && (
                <Tooltip title="Rimuovi stanza">
                  <Button
                    size="small"
                    shape="circle"
                    type="dashed"
                    className="flex items-center justify-center mr-2"
                    onClick={() => {
                      setRooms(rooms.filter((el) => el.id !== room.id));
                    }}
                  >
                    <CloseOutlined className="m-0 p-0" />
                  </Button>
                </Tooltip>
              )}

              <p>Camera #{index + 1}</p>
            </div>
          </Divider>
          <div className="flex flex-row items-center w-full">
            <span>Adulti</span>
            <InputNumber
              min={index > 0 && room.children > 0 ? 0 : 1}
              defaultValue={room.adults}
              onChange={(value) => {
                setRooms(
                  rooms.map((el) => {
                    if (el.id === room.id) el.adults = value;
                    return el;
                  })
                );
              }}
              style={{ marginLeft: "auto" }}
            />
          </div>
          <div className="flex flex-row items-center w-full mt-2">
            <span>Bambini</span>
            <InputNumber
              min={room.adults > 0 ? 0 : 1}
              defaultValue={room.children}
              onChange={(value) => {
                setRooms(
                  rooms.map((el) => {
                    if (el.id === room.id) el.children = value;
                    return el;
                  })
                );
              }}
              style={{ marginLeft: "auto" }}
            />
          </div>
        </div>
      ))}
    </div>
  );

  const TitlePopover = (
    <div className="flex flex-row items-center">
      <span>Stanze</span>
      <Button
        className="ml-auto"
        onClick={() =>
          setRooms([...rooms, { id: rooms.length + 1, adults: 2, children: 0 }])
        }
      >
        Aggiungi
      </Button>
    </div>
  );

  function getGuestsCount() {
    let guests = 0;
    rooms.map((el) => {
      guests += el.adults + el.children;
    });
    return guests;
  }

  return (
    <StyleProvider hashPriority="high" className="bg-transparent">
      <ConfigProvider locale={itIT}>
        <div className="flex flex-col items-center p-3 gap-2">
          <div className="flex flex-col sm:flex-row items-center gap-2 w-full">
            <RangePicker
              format={dateFormat}
              defaultValue={[dayjs(), dayjs().add(2, "day")]}
              className="font-2xl w-full sm:w-2/3"
            />
            <Popover
              content={<PopoverContent rooms={rooms} setRooms={setRooms} />}
              title={TitlePopover}
              trigger="click"
            >
              <Button className="bg-white w-full sm:w-1/3">
                {rooms.length} {rooms.length > 1 ? "stanze" : "stanza"} &#x2022;{" "}
                {getGuestsCount()} {getGuestsCount() > 1 ? "ospiti" : "ospite"}
              </Button>
            </Popover>
          </div>
          <div className="flex flex-col sm:flex-row items-center gap-2 w-full">
            <Input
              placeholder="Codice Promo"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
              className="w-full sm:w-2/3"
            />
            <Button type="primary" className="w-full sm:w-1/3">
              Verifica Disponibilità
            </Button>
          </div>
        </div>
      </ConfigProvider>
    </StyleProvider>
  );
}

export default EmbedBookingEngine;
