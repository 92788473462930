import React from "react";
import SimpleImageSlider from "react-simple-image-slider";
import Row from "./Row";
import { Badge } from "antd";
import { useTranslation } from "react-i18next";

function PreventivoGuestRowProduct({ row }) {
  const { t } = useTranslation("common");

  return (
    <div className="p-3 rounded-md w-full">
      <div className="grid grid-cols-12 gap-3">
        <div
          className="relative col-span-12 xl:col-span-4 rounded-md min-h-[200px] bg-cover bg-center"
          style={{
            backgroundImage: row.product.image
              ? "url('https://www.idaweb.eu" + row.product.image + "')"
              : "url('https://www.idaweb.eu/Archive/Media/empty.png')",
          }}
        >
          {parseInt(row.product.price) === 0 ? (
            <Badge.Ribbon text="OMAGGIO" color="green">
              <div className="block overflow-hidden w-full h-full "></div>
            </Badge.Ribbon>
          ) : (
            <div className="block overflow-hidden w-full h-full "></div>
          )}
        </div>

        <div className="flex flex-col gap-5 col-span-12 text-center xl:text-left xl:col-span-8 p-0 xl:px-5">
          <div className="grid grid-cols-12 items-center">
            <div className="flex flex-col xl:flex-row w-full items-center xl:items-baseline gap-2 col-span-12 xl:col-span-12">
              <h1 className="text-xl truncate font-medium">
                {row.product.name}
              </h1>
              <span className="text-gray-400">
                {t("products.quantity")}&nbsp;<b>x {row.product.quantity}</b>
              </span>
            </div>
          </div>
          {row.product.description && (
            <div className="text-gray-600">
              <p>{row.product.description}</p>
            </div>
          )}

          <div className="w-full mt-4 xl:mt-0">
            <div className="flex flex-col text-center xl:text-right">
              <span className="text-gray-400">{t("products.price")}</span>
              {row.product.striked_price &&
              row.product.striked_price !== row.product.price ? (
                <div className="flex flex-col mt-2 xl:mt-0 xl:flex-row justify-center items-center xl:ml-auto xl:justify-end gap-3">
                  <span className="text-red-500 line-through text-md">
                    {parseFloat(row.product.striked_price).toFixed(2)} &euro;
                  </span>
                  {parseInt(row.product.price) === 0 ? (
                    <span className="text-xl text-green-500 font-semibold">
                      {t("products.free")}
                    </span>
                  ) : (
                    <span className="text-xl text-green-500 font-semibold">
                      {parseFloat(row.product.price).toFixed(2)} &euro;
                    </span>
                  )}
                </div>
              ) : (
                <span className="text-xl text-green-500 font-semibold">
                  {parseFloat(row.product.price).toFixed(2)} &euro;
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreventivoGuestRowProduct;
