import React from "react";

function BestChoiceFreeGiveAway({ bestChoice, setBestChoice, language }) {
  return (
    <>
      <div className="w-full">
        <div className="flex flex-row items-center">
          <label
            htmlFor="title-advisor"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Vantaggio
          </label>

          {/* <span
            className="ml-auto cursor-pointer"
            onClick={() => {
              setBestChoice({
                ...bestChoice,
                free_giveaway_obj: [
                  ...bestChoice.free_giveaway_obj,
                  {
                    id_translation: null,
                    group_uid: "",
                    language: language.value,
                    text: "",
                  },
                ],
              });
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 text-gray-600"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 6v12m6-6H6"
              />
            </svg>
          </span> */}
        </div>

        <div className="mt-2 flex flex-row items-center w-full gap-3">
          <div
            className="flex flex-1 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 
                focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md"
          >
            <input
              type="text"
              className="
                    ml-2 block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-500 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="Es. Cancellazione gratuita e flessibile"
              value={bestChoice.free_giveaway_obj.text}
              onChange={(e) =>
                setBestChoice({
                  ...bestChoice,
                  free_giveaway_obj: {
                    ...bestChoice.free_giveaway_obj,
                    text: e.target.value,
                  },
                })
              }
            />
          </div>

          {/* <div
              className="cursor-pointer"
              onClick={() => {
                setBestChoice({
                  ...bestChoice,
                  free_giveaway_obj: bestChoice.free_giveaway_obj.filter(
                    (el, h) => h !== index
                  ),
                });
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5 text-red-400"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
            </div> */}
        </div>
      </div>
    </>
  );
}

export default BestChoiceFreeGiveAway;
