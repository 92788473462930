export default function Container(props) {
  return (
    <div className="main">
      <div className="container">
        <div className="signup-content">
          <div className="signup-desc">
            <div className="signup-desc-content">
              <h2 style={{ paddingBottom: "10px" }}>
                <span className="text-primary">Ida</span>Web
              </h2>
              <p className="subtitle-description">
                Crea il tuo listino prezzi in pochi click!
              </p>
              <img
                src="https://www.idaweb.eu/Images/Sys/ida2_logo.png"
                alt="logo-ida"
                className="signup-img"
              />
            </div>
          </div>
          <div className="signup-form-conent">{props.children}</div>
        </div>
      </div>
    </div>
  );
}
