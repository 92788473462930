import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { BootstrapButtonForward } from "./button";

const BootstrapButtonBack = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "10px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#FFF",
  borderColor: "#FFF",
  color: "#999999",
  width: "100px",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&.Mui-disabled": {
    color: "#c1c1c1",
    backgroundColor: "#FFF",
    border: "1px dashed #c1c1c1",
  },
  "&:hover": {
    backgroundColor: "#F2F2F2",
    borderColor: "#F2F2F2",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#FFF",
    borderColor: "#FFF",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

export default function WizardButtons(props) {
  return (
    <div className="wizard-buttons">
      {/* <h2>Step {props.currentStep}</h2>
      <p>Total Steps: {props.totalSteps}</p>
      <p>Is Active: {props.isActive}</p> */}
      {props.currentStep !== 1 && (
        <BootstrapButtonBack onClick={props.previousStep}>
          Indietro
        </BootstrapButtonBack>
      )}
      {props.currentStep < props.totalSteps && (
        <BootstrapButtonForward
          onClick={props.nextStep}
          disabled={props.cannotProceed}
        >
          Avanti
        </BootstrapButtonForward>
      )}
      {props.currentStep === props.totalSteps && (
        <BootstrapButtonForward
          onClick={props.finish}
          disabled={props.cannotProceed}
        >
          Concludi
        </BootstrapButtonForward>
      )}
    </div>
  );
}
