import { Grid } from "@mui/material";
import React from "react";
import MultipleImageUploadComponent from "../imageUpload";
import {
  Bolder,
  Container,
  Flex,
  Helper,
  SecondaryText,
  Text,
  Title,
} from "../styles";
import TextInput from "../textInput";
import WizardButtons from "../wizardButtons";
import YesNoSwitch from "../yesno";
import moment from "moment";
import "moment/locale/it";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import LoadingModal from "../loadingModal";
import pricesService from "../../../../services/prices.service";
import { useNavigate } from "react-router-dom";

export default function Step9(props) {
  const [cannotProceed, setCannotProceed] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  let navigate = useNavigate();

  const finish = () => {
    confirmAlert({
      title: "Confermare creazione prezzi?",
      message: (
        <p>
          Hai inserito tutti i campi correttamente, proseguire e concludere la
          creazione?
        </p>
      ),
      buttons: [
        {
          label: "SI",
          onClick: () => {
            setOpen(true);

            const data = new FormData();
            data.append("state", JSON.stringify(props.state));

            if (props.images) {
              const files = [...props.images];
              files.forEach((file, i) => {
                data.append(`file-${i}`, file, file.name);
              });
            }
            pricesService.savePrices(data).then(
              (result) => {
                result = result.data;

                if (!result.success) {
                  navigate("/prices/error", {
                    state: {
                      error: result.model,
                    },
                  });
                } else {
                  navigate("/prices/success");
                }
              },
              (error) => {}
            );
          },
        },
        {
          label: "NO",
          onClick: () => alert("Click No"),
        },
      ],
    });
  };
  return (
    <>
      <LoadingModal open={open} setOpen={setOpen} />
      <Container>
        <Helper>
          <Title>
            📆Vuoi estendere questi prezzi anche all'anno &nbsp;
            <font color="#2b5cdf">{moment().add(1, "years").year()}</font>
          </Title>
        </Helper>
        <Bolder>Risposta</Bolder>

        <YesNoSwitch
          checked={props.state.createNewYear}
          onChange={(checked) =>
            props.setState({ ...props.state, createNewYear: checked })
          }
        />
      </Container>

      <WizardButtons
        cannotProceed={cannotProceed}
        currentStep={props.currentStep}
        previousStep={props.previousStep}
        totalSteps={props.totalSteps}
        nextStep={props.nextStep}
        finish={finish}
      />
    </>
  );
}
