import styled from "@emotion/styled";

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  margin-right: 20px;
  gap: 5px;

  .step-title {
    text-transform: uppercase;
    font-weight: 800;
    color: #2b54c1;
    font-size: 17px;
  }
`;

export default function WizardStatusStep(props) {
  return (
    <Flex>
      <span className="step-title">STEP {props.currentStep}</span>
      <span className="step-title">/</span>
      <span className="step-title">{props.totalSteps}</span>
    </Flex>
  );
}
