import { Button, Input, Modal, Spin } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import preventiviService from "../../../services/preventivi.service";
import { List } from "antd";
import useStateCallback from "../../../components/useStateCallback";
import { toast } from "react-hot-toast";
import { StyleProvider } from "@ant-design/cssinjs";

const SaveAsTemplatePreventivo = ({ preventivo, setPreventivo }) => {
  const { token } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [templateName, setTemplateName] = useState("");

  const saveTemplate = async () => {
    setLoading(true);
    preventiviService
      .saveTemplate(preventivo.id_preventivo, token, {
        name: templateName,
      })
      .then(
        (result) => {
          if (result.status === 201)
            toast.success("La template è stata salvata");
          else toast.error("Si è verificato un errore");
          setLoading(false);
          setTemplateName("");
          setIsModalOpen(false);
        },
        (error) => {}
      );
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <StyleProvider hashPriority="high">
      <Button
        className="!flex !flex-row !items-center !ml-auto !bg-[#1677ff] hover:!bg-[#4096ff] w-full"
        type="primary"
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 text-white mr-2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z"
            />
          </svg>
        }
        onClick={showModal}
      >
        Salva come template
      </Button>
      <Modal
        title="Salva il preventivo come template"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel} disabled={loading}>
            Annulla
          </Button>,
          <Button
            key="ok"
            onClick={saveTemplate}
            loading={loading}
            disabled={!templateName}
            type="primary"
          >
            Salva
          </Button>,
        ]}
      >
        <Spin spinning={loading}>
          <p>
            Puoi salvare questo preventivo come modello, così nel prossimo che
            farai potrai partire con le stesse proprietà, messaggi e condizioni
            di questo che stai attualmente modificando.
            <br></br>
            <br></br>
            Scegli un nome risonoscibile per la template e premi su '
            <b>Salva</b>'.
          </p>
          <Input
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            placeholder="Modello 1"
            className="!mt-3"
          />
        </Spin>
      </Modal>
    </StyleProvider>
  );
};
export default SaveAsTemplatePreventivo;
