import React, { useEffect, useState } from "react";
import structureService from "../../services/structure.service";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { BsCalendar4Week } from "react-icons/bs";
import { Carousel } from "flowbite-react";
import PacketItem from "./PacketItem";

function Packets({ language, uuid }) {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPackets();
  }, []);

  const fetchPackets = () => {
    setLoading(true);
    structureService.getPackets(language + "/" + uuid).then((result) => {
      if (result.status === 200) {
        setItems(result.data);
        setLoading(false);
      }
    });
  };

  if (items?.length > 0)
    return (
      <>
        {loading ? (
          <></>
        ) : (
          <div class="relative overflow-hidden rounded-lg h-96 md:h-80">
            <Carousel slideInterval={8000} className="pb-16">
              {items?.map((item, index) => {
                return (
                  <PacketItem
                    item={item}
                    index={index}
                    language={language}
                    uuid={uuid}
                  />
                );
              })}
            </Carousel>
          </div>
        )}
      </>
    );
  else return null;
}

export default Packets;
