import React from "react";
import PreventivoLanguageSelect from "../PreventivoLanguageSelect";

function PreventivoLingua({ language, UpdatePreventivo }) {
  return (
    <div className="flex flex-row items-center gap-2 w-full">
      <PreventivoLanguageSelect
        value={language}
        onChange={(e) => {
          UpdatePreventivo("12", e);
        }}
      />
    </div>
  );
}

export default PreventivoLingua;
