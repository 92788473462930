import { Card } from "@mui/material";
import { Link } from "react-router-dom";
import DynamicHeroIcon from "../heroicons/hicon";

export default function ErrorBox(props) {
  return (
    <Card
      sx={{
        borderRadius: "14px",
        padding: "40px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <DynamicHeroIcon
        icon="ExclamationCircleIcon"
        style={{ width: "4rem", color: "red" }}
      />
      {props.title && (
        <h1 style={{ margin: "10px", fontSize: "2.5rem" }}>{props.title}</h1>
      )}
      {props.text && (
        <p style={{ fontSize: "15px", margin: "0" }}>{props.text}</p>
      )}
      {props.url && <Link to={props.url}>{props.url_text}</Link>}
    </Card>
  );
}
