import React, { useEffect, useState } from "react";
import { Alert, Collapse, theme } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;

function PreventivoCheckout({ preventivo }) {
  const { token } = theme.useToken();
  const [priceRooms, setPriceRooms] = useState(0.0);
  const [priceProducts, setPriceProduct] = useState(0.0);
  const { t } = useTranslation("common");

  useEffect(() => {
    if (preventivo && preventivo.rows && preventivo.rows.length > 0) {
      setPriceRooms(getPriceRooms());
      setPriceProduct(getPriceProducts());
    }
  }, []);

  const panelStyle = {
    borderRadius: token.borderRadiusLG,
    border: "none",
  };
  const Header = () => (
    <div className="text-lg text-green-700 font-semibold uppercase mb-3 flex flex-row items-center gap-1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6 text-green-500 hidden lg:visible"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M15.182 15.182a4.5 4.5 0 01-6.364 0M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z"
        />
      </svg>
      {t("checkout.review")}
    </div>
  );
  const CheckoutRow = ({ label, value }) => {
    return (
      <div className="flex flex-col lg:flex-row items-center py-1 text-lg w-full">
        <span className="text-gray-500">{label}</span>
        <span className="lg:ml-auto text-black">{value} &euro;</span>
      </div>
    );
  };
  const getPriceRooms = () => {
    var priceRooms = 0.0;
    preventivo.rows
      ?.filter((el) => el.id_category)
      ?.map((el) => (priceRooms += el.price));
    return parseFloat(priceRooms).toFixed(2);
  };
  const getPriceProducts = () => {
    var priceProducts = 0.0;
    preventivo.rows
      ?.filter((el) => el.id_product)
      ?.map((el) => (priceProducts += el.price));
    return parseFloat(priceProducts).toFixed(2);
  };

  return (
    <div className="flex flex-col gap-2">
      {preventivo.due_date && (
        <Alert
          message={
            t("checkout.expire_1") +
            dayjs(preventivo.due_date).format("DD/MM/YYYY HH:mm:ss")
          }
          type="warning"
          showIcon
        />
      )}
      <Collapse
        bordered={false}
        defaultActiveKey={["1"]}
        className="!bg-green-50 w-full checkout"
        expandIcon={({ isActive }) => (
          <CaretRightOutlined
            rotate={isActive ? 90 : 0}
            className="!text-green-500"
          />
        )}
      >
        <Panel header={<Header />} key="1" style={panelStyle}>
          <div className="bg-green-50 p-7 !pt-0 flex-col gap-3 rounded-sm">
            <ul role="list" className="-my-6 divide-y divide-gray-200 mt-6">
              {priceRooms > 0 && (
                <li key={"4"} className="py-1">
                  <CheckoutRow
                    label={t("checkout.total_rooms")}
                    value={priceRooms}
                  />
                </li>
              )}
              {priceProducts > 0 && (
                <li key={"6"} className="py-1">
                  <CheckoutRow
                    label={t("checkout.total_products")}
                    value={priceProducts}
                  />
                </li>
              )}

              <li key={"7"} className="py-1">
                <div className="flex flex-col lg:flex-row items-center py-3 text-lg">
                  <span className="text-black font-semibold">
                    {t("checkout.total")}
                  </span>
                  <span className="lg:ml-auto text-green-700 font-semibold text-2xl">
                    {parseFloat(
                      parseFloat(priceRooms) + parseFloat(priceProducts)
                    ).toFixed(2)}{" "}
                    &euro;
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
}

export default PreventivoCheckout;
