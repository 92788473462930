import axios from "axios";
import { BACKEND_URL } from "../config/backendConfig";

class webService {
  getConfigs(token) {
    return axios.get(BACKEND_URL + "web/admin/configs/" + token);
  }
  getConfig(token, id_config) {
    return axios.get(
      BACKEND_URL + "web/admin/config/" + token + "/" + id_config
    );
  }
  getAdvisors(token, id_config, language) {
    return axios.get(
      BACKEND_URL +
        "web/admin/advisors/" +
        token +
        "/" +
        id_config +
        "/" +
        language
    );
  }
  getStructures(token) {
    return axios.get(BACKEND_URL + "web/admin/structures/" + token);
  }
  saveConfig(token, config) {
    return axios.post(BACKEND_URL + "web/admin/configs/" + token, config);
  }
  patchConfig(token, config) {
    return axios.patch(BACKEND_URL + "web/admin/configs/" + token, config);
  }
  deleteConfig(token, id_config) {
    return axios.delete(
      BACKEND_URL + "web/admin/configs/" + token + "/" + id_config
    );
  }
  patchAdvisor(token, id_config, advisor) {
    return axios.patch(
      BACKEND_URL + "web/admin/advisor/" + token + "/" + id_config,
      advisor
    );
  }
  saveAdvisor(token, id_config, advisor) {
    return axios.post(
      BACKEND_URL + "web/admin/advisor/" + token + "/" + id_config,
      advisor
    );
  }
  deleteAdvisor(token, id_advisor) {
    return axios.delete(
      BACKEND_URL + "web/admin/advisor/" + token + "/" + id_advisor
    );
  }
  getBanners(token, id_config) {
    return axios.get(
      BACKEND_URL + "web/admin/banners/" + token + "/" + id_config
    );
  }
  getBestChoices(token, id_config, lang) {
    return axios.get(
      BACKEND_URL +
        "web/admin/best_choices/" +
        token +
        "/" +
        id_config +
        "/" +
        lang
    );
  }
  uploadBannerImage(token, id_config, formData) {
    return axios.post(
      BACKEND_URL + "web/admin/banners/" + token + "/" + id_config,
      formData
    );
  }
  deleteBannerImage(token, id_banner) {
    return axios.delete(
      BACKEND_URL + "web/admin/banners/" + token + "/" + id_banner
    );
  }
  getOta(token) {
    return axios.get(BACKEND_URL + "web/admin/best_choices/ota/list/" + token);
  }
  getBestChoice(token, id_best_choice, lang) {
    return axios.get(
      BACKEND_URL +
        "web/admin/best_choice/" +
        token +
        "/" +
        id_best_choice +
        "/" +
        lang
    );
  }
  getPriceOta(params) {
    return axios.get(BACKEND_URL + "web/admin/ota/price/" + params);
  }
  patchBestChoice(token, id_config, language, bestChoice) {
    return axios.patch(
      BACKEND_URL +
        "web/admin/best_choices/" +
        token +
        "/" +
        id_config +
        "/" +
        language,
      bestChoice
    );
  }
  saveBestChoice(token, id_config, language, bestChoice) {
    return axios.post(
      BACKEND_URL +
        "web/admin/best_choices/" +
        token +
        "/" +
        id_config +
        "/" +
        language,
      bestChoice
    );
  }
  deleteBestChoice(token, id_best_choice) {
    return axios.delete(
      BACKEND_URL + "web/admin/best_choices/" + token + "/" + id_best_choice
    );
  }
  getBookingEngine(token, id_config, language) {
    return axios.get(
      BACKEND_URL +
        "web/admin/booking_engine/" +
        token +
        "/" +
        id_config +
        "/" +
        language
    );
  }
  saveBookingEngine(token, id_config, bookingEngine) {
    return axios.post(
      BACKEND_URL + "web/admin/booking_engine/" + token + "/" + id_config,
      bookingEngine
    );
  }
  getPages(token, id_config, language) {
    return axios.get(
      BACKEND_URL +
        "web/admin/pages/" +
        token +
        "/" +
        id_config +
        "/" +
        language
    );
  }
  savePages(token, id_config, language, pages) {
    return axios.post(
      BACKEND_URL +
        "web/admin/pages/" +
        token +
        "/" +
        id_config +
        "/" +
        language,
      pages
    );
  }
  getRooms(token, id_config, language) {
    return axios.get(
      BACKEND_URL +
        "web/admin/rooms/" +
        token +
        "/" +
        id_config +
        "/" +
        language
    );
  }
  getCategories(token, language) {
    return axios.get(
      BACKEND_URL + "web/admin/categories/" + token + "/" + language
    );
  }
  getPriceCategory(token, id_category) {
    return axios.get(
      BACKEND_URL + "web/admin/categories/price/" + token + "/" + id_category
    );
  }
  patchRoom(token, id_config, language, room) {
    return axios.patch(
      BACKEND_URL +
        "web/admin/room/" +
        token +
        "/" +
        id_config +
        "/" +
        language,
      room
    );
  }
  saveRoom(token, id_config, language, room) {
    return axios.post(
      BACKEND_URL +
        "web/admin/room/" +
        token +
        "/" +
        id_config +
        "/" +
        language,
      room
    );
  }
  deleteRoom(token, id_room) {
    return axios.delete(
      BACKEND_URL + "web/admin/room/" + token + "/" + id_room
    );
  }
  getStructurePreview(token, id_config, language) {
    return axios.get(
      BACKEND_URL +
        "web/admin/structure_preview/" +
        token +
        "/" +
        id_config +
        "/" +
        language
    );
  }
  saveStructurePreview(token, id_config, language, formData) {
    return axios.post(
      BACKEND_URL +
        "web/admin/structure_preview/" +
        token +
        "/" +
        id_config +
        "/" +
        language,
      formData
    );
  }
  getServices(token, id_config, language) {
    return axios.get(
      BACKEND_URL +
        "web/admin/services/" +
        token +
        "/" +
        id_config +
        "/" +
        language
    );
  }
  saveServices(token, id_config, language, services) {
    return axios.post(
      BACKEND_URL +
        "web/admin/services/" +
        token +
        "/" +
        id_config +
        "/" +
        language,
      services
    );
  }
  getSurroundings(token, id_config, language) {
    return axios.get(
      BACKEND_URL +
        "web/admin/surroundings/" +
        token +
        "/" +
        id_config +
        "/" +
        language
    );
  }
  saveSurroundings(token, id_config, language, formData) {
    return axios.post(
      BACKEND_URL +
        "web/admin/surroundings/" +
        token +
        "/" +
        id_config +
        "/" +
        language,
      formData
    );
  }
  getGalleryImages(token, id_config) {
    return axios.get(
      BACKEND_URL + "web/admin/gallery/" + token + "/" + id_config
    );
  }
  getMedia(token) {
    return axios.get(BACKEND_URL + "web/admin/media/" + token);
  }
  saveMedia(token, id_config, media) {
    return axios.post(
      BACKEND_URL + "web/admin/media/" + token + "/" + id_config,
      media
    );
  }
  deleteGalleryImage(token, id_gallery) {
    return axios.delete(
      BACKEND_URL + "web/admin/media/" + token + "/" + id_gallery
    );
  }
  getRestaurant(token, id_config, language) {
    return axios.get(
      BACKEND_URL +
        "web/admin/restaurant/" +
        token +
        "/" +
        id_config +
        "/" +
        language
    );
  }
  saveRestaurant(token, id_config, language, formData) {
    return axios.post(
      BACKEND_URL +
        "web/admin/restaurant/" +
        token +
        "/" +
        id_config +
        "/" +
        language,
      formData
    );
  }
  getEvents(token, id_config, language) {
    return axios.get(
      BACKEND_URL +
        "web/admin/events/" +
        token +
        "/" +
        id_config +
        "/" +
        language
    );
  }
  saveEvents(token, id_config, language, data) {
    return axios.post(
      BACKEND_URL +
        "web/admin/events/" +
        token +
        "/" +
        id_config +
        "/" +
        language,
      data
    );
  }
  getServices(token, id_config, language) {
    return axios.get(
      BACKEND_URL +
        "web/admin/services/extra/" +
        token +
        "/" +
        id_config +
        "/" +
        language
    );
  }
  saveEventsExtra(token, id_config, language, data) {
    return axios.post(
      BACKEND_URL +
        "web/admin/services/extra/" +
        token +
        "/" +
        id_config +
        "/" +
        language,
      data
    );
  }
  getWhereAreWe(token, id_config, language) {
    return axios.get(
      BACKEND_URL +
        "web/admin/wherearewe/" +
        token +
        "/" +
        id_config +
        "/" +
        language
    );
  }
  saveWhereAreWe(token, id_config, language, data) {
    return axios.post(
      BACKEND_URL +
        "web/admin/wherearewe/" +
        token +
        "/" +
        id_config +
        "/" +
        language,
      data
    );
  }
  deletePage(token, id_config, id_page) {
    return axios.delete(
      BACKEND_URL +
        "web/admin/delete/pages/" +
        token +
        "/" +
        id_config +
        "/" +
        id_page
    );
  }
}
export default new webService();
