import { Card, CircularProgress, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import HorizontalBar from "../../components/layout/horizontalbar";
import * as React from "react";
import structureService from "../../services/structure.service";
import { GetLabel } from "../../languages/i18n";
import SuccessMessage from "./SuccessMessage";

export default function Success() {
  const { uuid, link } = useParams();
  const [isStructureLoading, setStructureLoading] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [structureInfo, setStructureInfo] = React.useState({
    structure_name: "",
    structure_address: "",
    structure_location: "",
    structure_logo: "",
    structure_phone: "",
    structure_email: "",
    uuid: null,
  });
  const [confirmDetails, setConfirmDetails] = React.useState({
    image1: "",
    image2: "",
    total: 0.0,
    customer_name: "",
    checkin: "",
    email: "",
    rows: [
      {
        checkin: "",
        checkout: "",
        booking: "",
        booking_ref: "",
        pin: "",
      },
    ],
    extras: [],
    packet: "",
  });

  const [packet, setPacket] = React.useState({});

  React.useEffect(() => {
    getStructureInfo(uuid);
    getConfirmedDetails(link);
    localStorage.removeItem("cart");
  }, []);

  const getStructureInfo = async (uuid_pass) => {
    setStructureLoading(true);
    structureService.getStructureInfo(`${uuid_pass}`).then(
      (result) => {
        result = result.data;
        setStructureInfo(result);
        setStructureLoading(false);
      },
      (error) => {}
    );
  };

  const getConfirmedDetails = async (link) => {
    setIsLoading(true);
    structureService.getConfirmedDetails(`${link}`).then(
      (result) => {
        result = result.data;
        if (result.success) setConfirmDetails(result.model);
        setIsLoading(false);
      },
      (error) => {}
    );
  };

  return (
    <>
      <div
        className="bg-body"
        style={{
          background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('${structureInfo.structure_background}')`,
          minHeight: "100vh",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <Grid item xs={12} sm={6}>
            <Card
              sx={{
                padding: "12px",
                paddingLeft: "30px",
                paddingRight: "30px",
              }}
            >
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <h3 className="confirmed-title">
                    {GetLabel(confirmDetails.language, "SUCCESS_THANK_YOU")}{" "}
                    {confirmDetails.customer_name},{" "}
                    {GetLabel(confirmDetails.language, "SUCCESS_THANK_YOU_1")}
                  </h3>
                  <p className="confirmed-subtitle">
                    {GetLabel(confirmDetails.language, "SUCCESS_THANK_YOU_2")}{" "}
                    <b>{confirmDetails.email}</b>{" "}
                    {GetLabel(confirmDetails.language, "SUCCESS_THANK_YOU_3")}
                  </p>
                  <SuccessMessage
                    uuid={uuid}
                    language={confirmDetails.language}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="d-row">
                      <img
                        src="https://www.idaweb.eu/Archive/Forum/emails/be/ok-mark.png"
                        className="check-mark"
                      />
                      <p>
                        <b>{structureInfo.structure_name}</b>{" "}
                        {GetLabel(
                          confirmDetails.language,
                          "SUCCESS_THANK_YOU_4"
                        )}{" "}
                        <b>{confirmDetails.checkin}</b>
                      </p>
                    </div>
                    <div className="d-row">
                      <img
                        src="https://www.idaweb.eu/Archive/Forum/emails/be/ok-mark.png"
                        className="check-mark"
                      />
                      <p>
                        <b>
                          {GetLabel(
                            confirmDetails.language,
                            "SUCCESS_THANK_YOU_5"
                          )}
                        </b>{" "}
                        {GetLabel(
                          confirmDetails.language,
                          "SUCCESS_THANK_YOU_6"
                        )}{" "}
                        {structureInfo.structure_name}
                      </p>
                    </div>
                    <div className="d-row">
                      <img
                        src="https://www.idaweb.eu/Archive/Forum/emails/be/ok-mark.png"
                        className="check-mark"
                      />
                      <p>
                        {GetLabel(
                          confirmDetails.language,
                          "SUCCESS_THANK_YOU_7"
                        )}
                      </p>
                    </div>
                    {confirmDetails.discount_code && (
                      <div className="d-row">
                        <img
                          src="https://www.idaweb.eu/Archive/Forum/emails/be/tag.png"
                          className="check-mark"
                          style={{ width: "24px", height: "24px" }}
                        />
                        <p>
                          {GetLabel(
                            confirmDetails.language,
                            "SUCCESS_THANK_YOU_18"
                          )}
                          <span className="ml-1 font-semibold text-red-500">
                            {confirmDetails.discount_code}
                          </span>
                        </p>
                      </div>
                    )}
                  </div>

                  {/* <SuccessMessage
                    uuid={uuid}
                    language={confirmDetails.language}
                  /> */}

                  <h4 className="confirmed-title fs-1  mb-3">
                    {structureInfo.structure_name},{" "}
                    {structureInfo.structure_location}
                  </h4>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <img
                        src={confirmDetails.image1}
                        className="confirmed-img"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <img
                        src={confirmDetails.image2}
                        className="confirmed-img"
                      />
                    </Grid>
                  </Grid>

                  <h4
                    className="confirmed-title fs-1 mb-3"
                    style={{ marginTop: "50px" }}
                  >
                    {GetLabel(confirmDetails.language, "SUCCESS_THANK_YOU_8")}
                  </h4>

                  {confirmDetails.rows.map((row) => {
                    return (
                      <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                        {confirmDetails.packet && (
                          <div className="d-row details-container first-container">
                            <p
                              style={{
                                fontWeight: "500",
                                color: "rgb(108 108 108)",
                              }}
                            >
                              {GetLabel(
                                confirmDetails.language,
                                "SUCCESS_THANK_YOU_17"
                              )}
                            </p>
                            <p className="ml-auto details-table-value">
                              {confirmDetails.packet}
                            </p>
                          </div>
                        )}

                        <div className="d-row details-container first-container">
                          <p
                            style={{
                              fontWeight: "500",
                              color: "rgb(108 108 108)",
                            }}
                          >
                            {GetLabel(
                              confirmDetails.language,
                              "SUCCESS_THANK_YOU_9"
                            )}
                          </p>
                          <p className="ml-auto details-table-value">
                            {row.booking}
                          </p>
                        </div>
                        <div className="d-row details-container second-container">
                          <p
                            style={{
                              fontWeight: "500",
                              color: "rgb(108 108 108)",
                            }}
                          >
                            Check-in
                          </p>
                          <p className="ml-auto details-table-value">
                            {row.checkin}
                          </p>
                        </div>
                        <div className="d-row details-container second-container">
                          <p
                            style={{
                              fontWeight: "500",
                              color: "rgb(108 108 108)",
                            }}
                          >
                            Check-out
                          </p>
                          <p className="ml-auto details-table-value">
                            {row.checkout}
                          </p>
                        </div>
                        <div className="d-row details-container second-container">
                          <p
                            style={{
                              fontWeight: "500",
                              color: "rgb(108 108 108)",
                            }}
                          >
                            {GetLabel(
                              confirmDetails.language,
                              "SUCCESS_THANK_YOU_10"
                            )}
                          </p>
                          <p className="ml-auto details-table-value">
                            {row.booking_ref}
                          </p>
                        </div>
                        <div className="d-row details-container second-container">
                          <p
                            style={{
                              fontWeight: "500",
                              color: "rgb(108 108 108)",
                            }}
                          >
                            {GetLabel(
                              confirmDetails.language,
                              "SUCCESS_THANK_YOU_11"
                            )}
                          </p>
                          <p className="ml-auto details-table-value">
                            {row.pin}
                          </p>
                        </div>
                        <div className="d-row details-container second-container">
                          <p
                            style={{
                              fontWeight: "500",
                              color: "rgb(108 108 108)",
                            }}
                          >
                            {GetLabel(
                              confirmDetails.language,
                              "SUCCESS_THANK_YOU_12"
                            )}
                          </p>
                          <p className="ml-auto details-table-value">
                            {confirmDetails.customer_name}
                          </p>
                        </div>
                        <div
                          className="d-row details-container last-container"
                          style={{ backgroundColor: "#f3f3f3" }}
                        >
                          <p
                            style={{
                              fontWeight: "500",
                              color: "rgb(108 108 108)",
                            }}
                          >
                            Prezzo Unità
                          </p>
                          <p className="ml-auto details-table-value">
                            € {row.room_price}
                          </p>
                        </div>
                      </div>
                    );
                  })}

                  {confirmDetails.extras.length > 0 && (
                    <h4
                      className="confirmed-title fs-1 mb-3"
                      style={{ marginTop: "50px" }}
                    >
                      {GetLabel(
                        confirmDetails.language,
                        "SUCCESS_THANK_YOU_13"
                      )}
                    </h4>
                  )}

                  {confirmDetails.extras.map((extra) => {
                    return (
                      <div className="d-row details-container last-container">
                        <img
                          src={extra.obj_product.image}
                          style={{
                            width: "55px",
                            height: "55px",
                            borderRadius: "999px",
                            objectFit: "cover",
                          }}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "center",
                            paddingLeft: "30px",
                            width: "70%",
                          }}
                        >
                          <h4 style={{ margin: "0" }}>
                            {extra.obj_product.description}
                          </h4>
                          <p
                            style={{
                              margin: "0",
                              marginTop: "5px",
                              fontSize: "15px",
                              color: "gray",
                            }}
                          >
                            {extra.obj_product.note}
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "20%",
                          }}
                        >
                          <b
                            style={{
                              fontSize: "20px",
                            }}
                          >
                            €{" "}
                            {parseFloat(
                              extra.obj_product.price_unit * extra.qty
                            ).toFixed(2)}
                          </b>
                          <span style={{ fontSize: "14px", marginTop: "10px" }}>
                            {GetLabel(
                              confirmDetails.language,
                              "SUCCESS_THANK_YOU_14"
                            )}{" "}
                            {extra.qty}
                          </span>
                        </div>
                      </div>
                    );
                  })}

                  <table className="booking-price">
                    <tbody>
                      <tr>
                        <td style={{ fontSize: "18px" }}>
                          {GetLabel(
                            confirmDetails.language,
                            "SUCCESS_THANK_YOU_15"
                          )}
                        </td>
                        <td>
                          {GetLabel(
                            confirmDetails.language,
                            "SUCCESS_THANK_YOU_16"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: "18px" }}>
                          {GetLabel(confirmDetails.language, "LABEL_PRICE")}
                        </td>
                        <td class="price">
                          € {parseFloat(confirmDetails.total).toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
