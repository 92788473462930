import { Button, Result } from "antd";
import React, { useEffect, useState } from "react";
import Webcam from "react-webcam";
import LoadingIndicator from "../../components/LoadingIndicator";
import ocrService from "../../services/ocr.service";
import { useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";

const WebcamComponent = () => <Webcam />;
const videoConstraints = {
  facingMode: isMobile ? "environment" : "user",
};
const Scanner = ({ info, setInfo }) => {
  const { token } = useParams();
  const [picture, setPicture] = useState("");
  const [preview, setPreview] = useState();
  const [base64img, setBase64img] = useState("");
  const [uploadFromPC, setUploadFromPC] = useState(false);
  const [send, setSend] = useState(0);

  const webcamRef = React.useRef(null);

  const inputFileRef = React.useRef(null);

  const onFilechange = (e) => {
    const objectUrl = URL.createObjectURL(e.target.files[0]);

    setPicture(e.target.files[0]);
    setPreview(objectUrl);
    setUploadFromPC(true);
    return () => URL.revokeObjectURL(objectUrl);
  };

  const capture = () => {
    const pictureSrc = webcamRef.current.getScreenshot();
    setPicture(pictureSrc);
    setPreview(pictureSrc);
    setUploadFromPC(false);
  };
  const sendIda = () => {
    if (uploadFromPC) {
      var form = new FormData();
      form.append("image", picture);

      setBase64img(picture);
      setPicture("");
      setSend(2);

      ocrService
        .extractOcr(token, form)
        .then((result) => {
          if (result.status === 200) {
            setInfo({ ...info, used_scans: info.used_scans + 1 });
            if (info.used_scans + 1 >= info.total_scans) setSend(3);
            else setSend(4);
            setPreview();
          }
        })
        .catch((error) => {
          setSend(5);
          setPreview();
        });
    } else {
      setBase64img(picture);
      setPicture("");
      setSend(2);

      ocrService
        .extractOcrB64(token, { base64: picture })
        .then((result) => {
          if (result.status === 200) {
            setInfo({ ...info, used_scans: info.used_scans + 1 });
            if (info.used_scans + 1 >= info.total_scans) setSend(3);
            else setSend(4);
            setPreview();
          }
        })
        .catch((error) => {
          setSend(5);
          setPreview();
        });
    }
  };
  return (
    <>
      {send === 0 ? (
        <>
          <span className="px-16 text-center mb-5">
            Benvenuto sul modulo <b>OCR</b>, premi "Scansiona" per fotografare
            il tuo documento e estrarre i dati o "Carica una foto" per usarne
            uno già esistente.
          </span>
          {/*
          <div className=" rounded-lg h-[200px] w-[350px] overflow-hidden relative">
            {picture === "" && !preview ? (
              <>
                 <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                  className="h-full w-full object-cover"
                />
                <div
                  className="p-5 absolute w-full h-full px-10"
                  style={{ top: 0 }}
                >
                  <div className="border-2 border-green-500 border-dashed h-full w-full rounded-lg" />
                </div>
                
              </>
            ) : (
              <img
                src={preview ? preview : picture}
                className="object-cover h-full w-full"
              />
            )}
          </div>
             */}
          {preview && (
            <img
              src={preview ? preview : picture}
              className="object-cover h-full w-[300px] rounded-md"
            />
          )}

          <div>
            {picture !== "" && preview ? (
              <div className="flex flex-col items-center">
                <span className="text-center px-16 mb-5">
                  Se hai scansionato correttamente, premi su <b>Prosegui</b>,
                  altrimenti prova ancora.
                </span>
                <div className="flex flex-row items-center gap-3">
                  <Button
                    type="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      setPicture("");
                      setPreview();
                    }}
                  >
                    Riprova
                  </Button>
                  <Button
                    type="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      setSend(1);
                    }}
                  >
                    Prosegui
                  </Button>
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-3 items-center">
                <Button
                  type="primary"
                  // onClick={(e) => {
                  //   e.preventDefault();
                  //   capture();
                  // }}
                  onClick={(e) => {
                    inputFileRef.current.click();
                  }}
                  className="!flex !flex-row !items-center w-auto gap-1 mt-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z"
                    />
                  </svg>
                  Scansiona
                </Button>
                {/* <span className="text-sm">oppure</span> */}

                <input
                  type="file"
                  ref={inputFileRef}
                  onChange={onFilechange}
                  className="hidden"
                  accept="image/jpeg"
                />
                {/* <Button
                  type="dashed"
                  onClick={(e) => {
                    inputFileRef.current.click();
                  }}
                  className="!flex !flex-row !items-center w-auto gap-1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                    />
                  </svg>
                  Carica una foto
                </Button> */}
              </div>
            )}
          </div>
        </>
      ) : send === 1 ? (
        <div className="flex flex-col gap-3 px-16 items-center">
          {/* <p className="text-center">
            Se vuoi creare una prenotazione dal tuo dispositivo corrente, premi
            su "<b>Crea prenotazione</b>", se invece vuoi inviare i dati del
            documento sul tuo computer premi "<b>Invia su IDA</b>"
          </p> */}
          <p className="text-center">
            Premi "<b>Invia su IDA</b>" per estrarre i dati e inviarli al tuo
            computer.
          </p>
          <div className="w-64 mt-6">
            <Button type="primary" className="w-full" onClick={sendIda}>
              Invia su IDA
            </Button>
          </div>
          {/* <div className="w-64">
            <Button type="dashed" className="w-full">
              Crea prenotazione
            </Button>
          </div> */}
        </div>
      ) : send === 2 ? (
        <div className="flex flex-col gap-3 items-center">
          <span>Sto inviando i dati...</span>
          <LoadingIndicator />
        </div>
      ) : send === 3 ? (
        <Result
          status="error"
          title="Scansioni terminate"
          subTitle={
            "Attenzione non hai più scansioni a disposizione. Contattare l'assistenza per averne altre."
          }
        />
      ) : send === 4 ? (
        <Result
          status="success"
          title="Il documento è stato inviato correttamente su Ida"
          extra={[
            <Button
              type="primary"
              onClick={() => {
                setPicture("");
                setPreview();
                setSend(0);
              }}
            >
              Scansiona ancora
            </Button>,
          ]}
        />
      ) : (
        send === 5 && (
          <Result
            status="error"
            title="Si è verificato un errore"
            subTitle={
              "Qualcosa non ha funzionato, assicurarsi di aver inquadrato correttamente la foto e di avere IDA aperto su un computer."
            }
            extra={[
              <Button
                type="primary"
                onClick={() => {
                  setPicture("");
                  setPreview();
                  setSend(0);
                }}
              >
                Riprova
              </Button>,
            ]}
          />
        )
      )}
    </>
  );
};
export default Scanner;
