import { CircularProgress } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { decode as base64_decode, encode as base64_encode } from "base-64";
export default function SearchBase() {
  let navigate = useNavigate();
  function query() {
    return new URLSearchParams(window.location.search);
  }
  React.useEffect(() => {
    // console.log(
    //   query().get("date_from"),
    //   query().get("date_to"),
    //   query().get("guests"),
    //   query().get("dcode"),
    //   query().get("uuid"),
    //   query().get("lang")
    // );
    const searchBase = {
      date_from: query().get("date_from"),
      date_to: query().get("date_to"),
      promo_code: "",
      rooms: [
        {
          id: "room_0",
          adults: parseInt(query().get("guests")),
          childs: [],
        },
      ],
      uuid: query().get("uuid"),
      lang: query().get("lang"),
    };
    navigate("/Home/search_base/" + base64_encode(JSON.stringify(searchBase)));
  }, []);
  return <CircularProgress />;
}
