import { ShoppingCartOutlined, TagsFilled } from "@ant-design/icons";
import { Badge } from "antd";
import React from "react";
import CartCoupon from "./CartCoupon";
import { Collapse, theme, Skeleton } from "antd";
const { Panel } = Collapse;

function Cart({ isLoading, setIsLoading, checkout }) {
  const { token } = theme.useToken();

  const [items] = React.useState([
    {
      image:
        "https://cdn.shopify.com/s/files/1/0013/2802/1548/products/Now---Tech-House-_Ableton-Template_64x64.png?v=1676086192",
      quantity: 1,
      text: "Abbonamento annuale programma",
      price: 49.99,
    },
    {
      image:
        "https://cdn.shopify.com/s/files/1/0013/2802/1548/products/Now---Tech-House-_Ableton-Template_64x64.png?v=1676086192",
      quantity: 2,
      text: "Account Governante",
      price: 23.99,
    },
  ]);
  const [discount, setDiscount] = React.useState({
    discount_value: 0.0,
    discount_code: "",
  });
  const [shipping] = React.useState({
    has_shipping: checkout.has_shipping,
    shipping_price: checkout.shipping_cost,
  });
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };

  function getTotal() {
    let total = 0.0;
    for (let i = 0; i < checkout.cart_rows.length; i++)
      total +=
        parseFloat(checkout.cart_rows[i].quantity) *
        parseFloat(checkout.cart_rows[i].price_unit);

    if (discount && discount.discount_value) {
      total -= discount.discount_value;
    }
    if (shipping.has_shipping && shipping.shipping_price) {
      total += shipping.shipping_price;
    }
    return parseFloat(total).toFixed(2);
  }

  const ReportRow = ({ text, value }) => (
    <div className="w-full flex flex-row justify-start items-end">
      <h4 className="text-md">{text}</h4>
      <div className="flex flex-row items-end ml-auto">
        <h3 className="text-md font-semibold">€ {value}</h3>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col gap-5 mb-20">
      <div className="block sm:hidden">
        <Collapse
          bordered={false}
          defaultActiveKey={["1"]}
          expandIcon={({ isActive }) => (
            <ShoppingCartOutlined className="!text-2xl !text-blue-500" />
          )}
          style={{
            background: token.colorBgContainer,
          }}
        >
          <Panel
            header={
              <div className="flex flex-row items-center w-full text-blue-500">
                Mostra sommario ordine{" "}
                <span className="ml-auto font-semibold text-lg">
                  € {getTotal()}
                </span>
              </div>
            }
            key="1"
            style={panelStyle}
          >
            {isLoading ? (
              <Skeleton.Input
                size="large"
                className="!w-full mb-1"
                active={true}
              />
            ) : (
              <>
                {checkout.cart_rows?.map((item, index) => (
                  <div
                    className="flex flex-row items-center gap-4 sm:gap-0"
                    key={"cart-item-" + index}
                  >
                    <div className="w-1/5">
                      <Badge count={item.quantity}>
                        <img
                          src={item.product_image}
                          className="rounded-md object-cover h-[70px] w-[70px]"
                          alt={"product"}
                        />
                      </Badge>
                    </div>
                    <p className="text-sm font-semibold text-gray-700 w-3/5">
                      {item.product_name}
                    </p>
                    <p className="text-md font-semibold text-gray-700 w-1/5">
                      &euro;
                      {parseFloat(item.price_unit * item.quantity).toFixed(2)}
                    </p>
                  </div>
                ))}
              </>
            )}
            <div className="border-gray-300 border-b w-full mt-3" />

            <CartCoupon discount={discount} setDiscount={setDiscount} />

            <div className="border-gray-300 border-b w-full mb-3" />
            <div className="flex flex-col">
              {discount && discount.discount_value > 0 && (
                <ReportRow
                  text={
                    <div className="flex flex-row items-center w-full gap-2 text-gray-600">
                      <span>Sconto</span>
                      <TagsFilled />
                      <span className="font-semibold">
                        {discount.discount_code}
                      </span>
                    </div>
                  }
                  value={parseFloat(-discount.discount_value).toFixed(2)}
                />
              )}

              {shipping.has_shipping && shipping.has_shipping && (
                <ReportRow
                  text={
                    <div className="flex flex-row items-center w-full gap-2 text-gray-600">
                      <span>Spedizione</span>
                      <span className="font-semibold">
                        {shipping.has_shipping}
                      </span>
                    </div>
                  }
                  value={parseFloat(shipping.shipping_price).toFixed(2)}
                />
              )}
            </div>
            <div className="w-full flex flex-row justify-start items-center">
              <h4 className="font-semibold text-lg">Totale</h4>
              <div className="flex flex-row items-center ml-auto">
                <span className="text-gray-500 text-sm mr-2">EURO</span>
                <h3 className="text-2xl font-semibold">€ {getTotal()}</h3>
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>

      <div className="hidden sm:block">
        {isLoading ? (
          <>
            <Skeleton.Input
              size="small"
              className="!w-full mb-1"
              active={true}
            />
            <Skeleton.Input
              size="small"
              className="!w-full mb-1"
              active={true}
            />
          </>
        ) : (
          <>
            {checkout.cart_rows?.map((item, index) => (
              <div
                className="flex flex-row items-center gap-2"
                key={"cart-item-" + index}
              >
                <div className="w-1/5">
                  <Badge count={item.quantity}>
                    <img
                      src={item.product_image}
                      className="rounded-md object-cover h-[70px] w-[70px]"
                      alt={"product-lg"}
                    />
                  </Badge>
                </div>
                <p className="text-sm font-semibold text-gray-700 w-3/5">
                  {item.product_name}
                </p>
                <p className="text-md font-semibold text-gray-700 w-1/5">
                  &euro;{" "}
                  {parseFloat(item.price_unit * item.quantity).toFixed(2)}
                </p>
              </div>
            ))}
          </>
        )}

        <div className="border-gray-300 border-b w-full mt-3" />

        <CartCoupon
          discount={discount}
          setDiscount={setDiscount}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />

        <div className="border-gray-300 border-b w-full mb-3" />
        <div className="flex flex-col mt-6">
          {discount && discount.discount_value > 0 && (
            <ReportRow
              text={
                <div className="flex flex-row items-center w-full gap-2 text-gray-600">
                  <span>Sconto</span>
                  <TagsFilled />
                  <span className="font-semibold">
                    {discount.discount_code}
                  </span>
                </div>
              }
              value={parseFloat(-discount.discount_value).toFixed(2)}
            />
          )}

          {shipping.has_shipping && shipping.shipping_price && (
            <ReportRow
              text={
                <div className="flex flex-row items-center w-full gap-2 text-gray-600">
                  <span>Spedizione</span>
                  <span className="font-semibold">{shipping.has_shipping}</span>
                </div>
              }
              value={parseFloat(shipping.shipping_price).toFixed(2)}
            />
          )}
        </div>
        <div className="w-full flex flex-row justify-start items-center">
          {isLoading ? (
            <Skeleton.Input
              size="small"
              className="!w-full mb-1"
              active={true}
            />
          ) : (
            <>
              <h4 className="font-semibold text-lg">Totale</h4>

              <div className="flex flex-row items-center ml-auto">
                <span className="text-gray-500 text-sm mr-2">EURO</span>
                <h3 className="text-2xl font-semibold">€ {getTotal()}</h3>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Cart;
