export default function Header(props) {
  return (
    <>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="author" content="colorlib.com" />
      <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
    </>
  );
}
