import React, { useEffect } from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import { Button, Collapse, Form, Spin, theme } from "antd";
import { Typography } from "antd";
import { Popconfirm, message } from "antd";
import "dayjs/locale/it";
import { toast } from "react-hot-toast";
import preventiviService from "../../services/preventivi.service";
import LockedAction from "./Helpers/LockedAction";

const { Title, Paragraph } = Typography;
const { Panel } = Collapse;

function PreventivoProductRow({
  row,
  preventivo,
  setPreventivo,
  index,
  token_str,
}) {
  const { token } = theme.useToken();

  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };

  const [loading, setLoading] = React.useState(false);

  const PreventivoCardRow = ({
    icon,
    label,
    text,
    value,
    editable,
    isFullWidth,
  }) => (
    <Form.Item
      label={
        <div className="flex flex-row items-center gap-2">
          {icon}
          <span className="font-semibold text-slate-600">{label}</span>
        </div>
      }
      labelAlign="left"
      className={`!m-0 !w-full ${isFullWidth && "full-content"}`}
    >
      {value !== "" || !editable ? (
        <span className="ml-3 flex items-start w-full">{text}</span>
      ) : (
        <div className="flex flex-row items-center gap-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5 text-slate-400"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
            />
          </svg>
          <span className="text-slate-400">vuoto</span>
        </div>
      )}
    </Form.Item>
  );
  const ContainerHeader = () => (
    <div className="flex flex-col md:flex-row items-center gap-3">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6 text-slate-600"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M21 7.5l-2.25-1.313M21 7.5v2.25m0-2.25l-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3l2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75l2.25-1.313M12 21.75V19.5m0 2.25l-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25"
        />
      </svg>
      <Paragraph
        className="!m-0 font-medium text-lg text-slate-600"
        ellipsis={true}
      >
        {row.name}
      </Paragraph>

      <div className="font-medium text-lg md:!ml-auto text-slate-500 flex flex-row items-center gap-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122"
          />
        </svg>
        <span>Quantità:</span>
        <span>{row.quantity}</span>
      </div>

      <Title level={4} className="md:!ml-auto !m-0 !text-slate-600">
        {parseFloat(row.price).toFixed(2)} &euro;
      </Title>
    </div>
  );
  function isNumber(str) {
    if (typeof str != "string") return false; // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  }
  const deleteRowProduct = (e) => {
    setLoading(true);
    preventiviService
      .deleteRowRoom(token_str + "/" + row.id_preventivo_row)
      .then(
        (result) => {
          result = result.data;
          if (result) {
            setPreventivo({
              ...preventivo,
              rows: preventivo.rows.filter((el, i) => i !== index),
            });
            message.success("Il prodotto è stato rimosso dal preventivo.");
          }

          setLoading(false);
        },
        (error) => {}
      );
  };
  const patchRowProduct = () => {
    preventiviService
      .patchRowProduct(token_str, {
        id_preventivo_row: row.id_preventivo_row,
        id_preventivo: row.id_preventivo,
        striked_price: row.striked_price,
        price: row.price,
        quantity: row.quantity,
        description: row.description,
      })
      .then(
        (result) => {
          result = result.data;
          if (!result)
            toast.error(
              "Si è verificato un errore durante il salvataggio del prodotto."
            );
        },
        (error) => {
          toast.error(error.response.data.ExceptionMessage);
        }
      );
  };

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={["1"]}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      className="bg-slate-50"
    >
      <Panel header={<ContainerHeader />} key="1" style={panelStyle}>
        <Spin spinning={loading}>
          <div className="mt-3">
            <Form
              labelCol={{
                span: 6,
              }}
              layout="horizontal"
            >
              <PreventivoCardRow
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 text-slate-600"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3"
                    />
                  </svg>
                }
                label={"Quantità"}
                text={
                  <>
                    {preventivo.status === 2 ||
                    preventivo.status === 3 ||
                    preventivo.status === 4 ? (
                      <>{row.quantity && <>{row.quantity}</>}</>
                    ) : (
                      <Paragraph
                        editable={{
                          tooltip: "Modifica la quantità",
                          text: row.quantity,
                          onChange: (new_value) => {
                            if (isNumber(new_value) === false) {
                              toast.error("Sono accettati solo numeri.");
                              return;
                            }

                            setPreventivo(
                              {
                                ...preventivo,
                                rows: preventivo.rows.map((el, _index) => {
                                  if (_index === index) {
                                    el.quantity = new_value;
                                    el.price = new_value * el.price;
                                  }
                                  return el;
                                }),
                              },
                              patchRowProduct
                            );
                            toast.success("La quantità è stata salvata");
                          },
                        }}
                        className="!m-0 flex flex-row items-center"
                      >
                        {row.quantity ? (
                          <>{row.quantity}</>
                        ) : (
                          <div className="flex flex-row items-center gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-5 h-5 text-slate-400"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                              />
                            </svg>
                            <span className="text-slate-400">vuoto</span>
                          </div>
                        )}
                      </Paragraph>
                    )}
                  </>
                }
                value={row.quantity}
              />

              <PreventivoCardRow
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 text-slate-600"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M14.25 7.756a4.5 4.5 0 100 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                }
                label={"Prezzo barrato"}
                text={
                  <>
                    {preventivo.status === 2 ||
                    preventivo.status === 3 ||
                    preventivo.status === 4 ? (
                      <span className="text-red-500 line-through">
                        {parseFloat(row.striked_price).toFixed(2)} &euro;
                      </span>
                    ) : (
                      <Paragraph
                        editable={{
                          tooltip: "Modifica il prezzo barrato",
                          text: row.striked_price,
                          onChange: (new_value) => {
                            if (isNumber(new_value) === false) {
                              toast.error("Sono accettati solo numeri.");
                              return;
                            }

                            setPreventivo(
                              {
                                ...preventivo,
                                rows: preventivo.rows.map((el, _index) => {
                                  if (_index === index) {
                                    el.striked_price =
                                      parseFloat(new_value).toFixed(2);
                                  }
                                  return el;
                                }),
                              },
                              patchRowProduct
                            );
                            toast.success("Il prezzo barrato è stato salvato");
                          },
                        }}
                        className="text-red-500 line-through !m-0 flex flex-row items-center"
                      >
                        {row.striked_price ? (
                          <>{parseFloat(row.striked_price).toFixed(2)} &euro;</>
                        ) : (
                          <div className="flex flex-row items-center gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-5 h-5 text-slate-400"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                              />
                            </svg>
                            <span className="text-slate-400">vuoto</span>
                          </div>
                        )}
                      </Paragraph>
                    )}
                  </>
                }
                value={row.striked_price}
              />

              <PreventivoCardRow
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 text-slate-600"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M14.25 7.756a4.5 4.5 0 100 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                }
                label={"Prezzo"}
                text={
                  <>
                    {preventivo.status === 2 ||
                    preventivo.status === 3 ||
                    preventivo.status === 4 ? (
                      <>
                        {row.price && (
                          <>{parseFloat(row.price).toFixed(2)} &euro;</>
                        )}
                      </>
                    ) : (
                      <Paragraph
                        editable={{
                          tooltip: "Modifica il prezzo",
                          text: row.price,
                          onChange: (new_value) => {
                            if (isNumber(new_value) === false) {
                              toast.error("Sono accettati solo numeri.");
                              return;
                            }

                            setPreventivo(
                              {
                                ...preventivo,
                                rows: preventivo.rows.map((el, _index) => {
                                  if (_index === index) {
                                    el.price = parseFloat(new_value).toFixed(2);
                                  }
                                  return el;
                                }),
                              },
                              patchRowProduct
                            );
                            toast.success("Il prezzo è stato salvato");
                          },
                        }}
                        className="!m-0 flex flex-row items-center"
                      >
                        {row.price ? (
                          <>{parseFloat(row.price).toFixed(2)} &euro;</>
                        ) : (
                          <div className="flex flex-row items-center gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-5 h-5 text-slate-400"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                              />
                            </svg>
                            <span className="text-slate-400">vuoto</span>
                          </div>
                        )}
                      </Paragraph>
                    )}
                  </>
                }
                value={row.price}
              />

              <PreventivoCardRow
                isFullWidth={true}
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 text-slate-600"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
                    />
                  </svg>
                }
                label={"Descrizione"}
                text={
                  <>
                    {preventivo.status === 2 ||
                    preventivo.status === 3 ||
                    preventivo.status === 4 ? (
                      <>{row.description && <>{row.description}</>}</>
                    ) : (
                      <Paragraph
                        editable={{
                          tooltip: "Modifica la descrizione",
                          text: row.description,
                          autoSize: true,
                          onChange: (new_value) => {
                            setPreventivo(
                              {
                                ...preventivo,
                                rows: preventivo.rows.map((el, _index) => {
                                  if (_index === index) {
                                    el.description = new_value;
                                  }
                                  return el;
                                }),
                              },
                              patchRowProduct
                            );
                            toast.success("La descrizione è stata salvata");
                          },
                        }}
                        className="!m-0 flex flex-row items-center"
                      >
                        {row.description ? (
                          <>{row.description}</>
                        ) : (
                          <div className="flex flex-row items-center gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-5 h-5 text-slate-400"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                              />
                            </svg>
                            <span className="text-slate-400">vuoto</span>
                          </div>
                        )}
                      </Paragraph>
                    )}
                  </>
                }
                value={row.description}
              />
            </Form>
            {preventivo.status === 2 ||
            preventivo.status === 3 ||
            preventivo.status === 4 ? (
              <div className="mt-6">
                <LockedAction />
              </div>
            ) : (
              <Popconfirm
                title="Eliminare la riga corrente?"
                description="Vuoi eliminare la riga corrente dalla proposta? L'operazione è irreversibile"
                onConfirm={deleteRowProduct}
                okText="Sì"
                cancelText="Annulla"
                className="ant-pop-confirm"
              >
                <Button
                  className="!flex !flex-row !items-center mt-5"
                  type="dashed"
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6 text-red-500 mr-2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  }
                  danger
                >
                  Elimina Riga
                </Button>
              </Popconfirm>
            )}
          </div>
        </Spin>
      </Panel>
    </Collapse>
  );
}

export default PreventivoProductRow;
