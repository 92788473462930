import axios from "axios";
import { BACKEND_URL } from "../config/backendConfig";

class ocrService {
  getOcr(uuid) {
    return axios.get(BACKEND_URL + "ocr/status/" + uuid);
  }
  sendIda(data) {
    return axios.post("http://192.168.1.229/ocr/ocr/sendImageData", data);
  }
  extractOcr(token, data) {
    return axios.post(BACKEND_URL + "ocr/extract/" + token, data);
  }
  extractOcrB64(token, data, useForm) {
    return axios.post(BACKEND_URL + "ocr/extract/b64/" + token, data);
  }
}
export default new ocrService();
