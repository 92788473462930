import { Select, Space } from "antd";
import React, { useEffect } from "react";
const { Option } = Select;

function PreventivoLanguageSelect({ value, onChange }) {
  return (
    <Select
      style={{
        width: 400,
      }}
      placeholder="Scegli una lingua"
      value={value}
      onChange={onChange}
      optionLabelProp="label"
    >
      <Option value="IT" label="Italiano">
        <Space>
          <img
            src="https://www.idaweb.eu/Images/Sys/flag/IT.png"
            className="w-[16px]"
            alt="flag-ita"
          />
          <span>Italiano</span>
        </Space>
      </Option>
      <Option value="EN" label="Inglese">
        <Space>
          <img
            src="https://www.idaweb.eu/Images/Sys/flag/UK.png"
            className="w-[16px]"
            alt="flag-eng"
          />
          <span>Inglese</span>
        </Space>
      </Option>
    </Select>
  );
}
export default PreventivoLanguageSelect;
