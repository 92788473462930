import { Button, Modal, Spin, Input } from "antd";
import { useState } from "react";
import preventiviService from "../../../services/preventivi.service";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
const { TextArea } = Input;

const PreventivoRefuseModal = ({ open, setOpen }) => {
  const { uuid } = useParams();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState("");
  const { t } = useTranslation("common");

  const handleOk = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const rifiutaPreventivo = async () => {
    setLoading(true);
    preventiviService.refusePreventivo(uuid, { feedback: feedback }).then(
      (result) => {
        if (result.status === 200) {
          navigate("/error", {
            replace: true,
            state: {
              title: t("refuse.titleRefused"),
              error: t("refuse.refused"),
            },
          });
        }
      },
      (error) => {}
    );
  };
  return (
    <Modal
      title={t("refuse.title")}
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="submit" type="link" onClick={() => setOpen(false)}>
          {t("header.chat.cancelButton")}
        </Button>,
        <Button
          type="primary"
          loading={loading}
          onClick={rifiutaPreventivo}
          danger
          disabled={feedback.length === 0}
        >
          {t("refuse.refuseButton")}
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <div>
          <p>{t("refuse.refuseText")}</p>
          <br></br>
          <TextArea
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            placeholder="Il tuo feedback"
            autoSize={{
              minRows: 3,
              maxRows: 5,
            }}
            showCount
            maxLength={250}
          />
          <br></br>
          <p>
            <b>{t("refuse.refuseText2")}</b>
            <br></br>
            <br></br>
            {t("refuse.refuseText3")}
          </p>
          <br></br>
        </div>
      </Spin>
    </Modal>
  );
};
export default PreventivoRefuseModal;
