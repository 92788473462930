import React from "react";
import PreventivoHelper from "../PreventivoHelper";
import { QuestionCircleOutlined } from "@ant-design/icons";

const content = (
  <div className="max-w-md max-h-52 overflow-y-auto">
    I metodi di garanzia indicano le modalità di conferma del preventivo per il
    cliente.
    <br />
    <br />
    Al fine di tutelare la struttura, IdaWeb offre diversi metodi di garanzia
    della prevendita:
    <br />
    <br />
    <ul>
      <li className="mb-3">
        <b>Conferma Carta</b>: Il cliente dovrà inserire i dati di una carta di
        credito / prepagata validi e attualmente in uso, sarà poi compito della
        struttura assicurarsi della sua veridicità.
      </li>
      <li>
        <b>Pagamento Online</b>: Richiedi al cliente il pagamento di una somma a
        copertura della garanzia della prevendita.
      </li>
    </ul>
  </div>
);

function GaranziaHelper() {
  return (
    <PreventivoHelper
      title={<h3 className="text-lg">I metodi di garanzia</h3>}
      content={content}
    >
      <QuestionCircleOutlined
        className="text-blue-500"
        style={{ fontSize: "18px" }}
      />
    </PreventivoHelper>
  );
}

export default GaranziaHelper;
