import {
  Breadcrumb,
  Affix,
  Layout,
  Menu,
  theme,
  Spin,
  Badge,
  Divider,
  Checkbox,
} from "antd";
import Row from "./Row";
import { Button, Skeleton, Space, Modal, Input } from "antd";
import { useEffect, useState } from "react";
import PreventivoCheckout from "./PreventivoCheckout";
import PreventivoCheckoutOta from "./PreventivoCheckoutOta";
import PreventivoGuestRowProduct from "./PreventivoGuestRowProduct";
import PreventivoChatBox from "./PreventivoChatBox";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import preventiviService from "../../../services/preventivi.service";
import PreventivoGuestRow from "./PreventivoGuestRow";
import useStateCallback from "../../../components/useStateCallback";
import dayjs from "dayjs";
import PreventivoRefuseModal from "./PreventivoRefuseModal";
import PreventivoGuestPayment from "./PreventivoGuestPayment";
import { useTranslation } from "react-i18next";

const { Header, Content, Footer } = Layout;

const info = (cancellationRules) => {
  Modal.info({
    title: "Termini e condizioni di cancellazione del preventivo",
    content: <div>{cancellationRules}</div>,
    onOk() {},
  });
};

const PreventivoGuest = () => {
  const { uuid, view_as_preview } = useParams();
  const [loading, setLoading] = useState(true);
  const [loadingInfo, setLoadingInfo] = useState(true);
  const [chatOpen, setChatOpen] = useState(false);
  const [preventivo, setPreventivo] = useStateCallback(false);
  const [structureInfo, setStructureInfo] = useState(false);
  const [openRefuse, setOpenRefuse] = useState(false);
  const [cancellationRulesChecked, setCancellationRulesChecked] =
    useState(false);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const { t, i18n } = useTranslation("common");

  let navigate = useNavigate();

  useEffect(() => {
    if (uuid) {
      setIsPreview(view_as_preview);
      fetchInfo();
    }
  }, []);

  const fetchInfo = async () => {
    setLoadingInfo(true);
    preventiviService.getStructureInfo(uuid).then(
      (result) => {
        result = result.data;
        setStructureInfo(result);
        setLoadingInfo(false);
        fetchPreventivo();
      },
      (error) => {}
    );
  };
  const fetchPreventivo = async () => {
    setLoading(true);
    var parameters = uuid;
    parameters += view_as_preview ? "/" + view_as_preview : "";
    preventiviService.getPreventivoPublic(parameters).then(
      (result) => {
        result = result.data;
        i18n.changeLanguage(result.language.toLowerCase());
        setPreventivo(result, () => {
          // Controlli
          if (result.due_date) {
            const due_date = dayjs(result.due_date);
            if (due_date.isBefore(dayjs())) {
              navigate("/error", {
                replace: true,
                state: {
                  error: t("content.errors.expired"),
                },
              });
            }
          }

          if (result.confirmed_date) {
            navigate("/error", {
              replace: true,
              state: {
                error: t("content.errors.alreadyAccepted"),
              },
            });
          }
        });
        setLoading(false);
      },
      (error) => {
        navigate("/error", {
          replace: true,
          state: {
            error: t("content.errors.unhandled"),
          },
        });
      }
    );
  };

  const Item1 = () => (
    <Row gap={2} className="!text-gray-400 !justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        class="w-4 h-4"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
        />
      </svg>

      <span className="text-gray-600">{structureInfo.structure_phone}</span>
    </Row>
  );
  const Item2 = () => (
    <Row gap={2} className="!text-gray-400 !justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        class="w-5 h-5"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
        />
      </svg>

      <span className="!text-gray-600">
        <a
          href={`mailto:${structureInfo.structure_email}`}
          className="!text-gray-600"
        >
          {structureInfo.structure_email}
        </a>
      </span>
    </Row>
  );
  const Item3 = () => (
    <Row gap={2} className="!text-gray-400 !justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6.115 5.19l.319 1.913A6 6 0 008.11 10.36L9.75 12l-.387.775c-.217.433-.132.956.21 1.298l1.348 1.348c.21.21.329.497.329.795v1.089c0 .426.24.815.622 1.006l.153.076c.433.217.956.132 1.298-.21l.723-.723a8.7 8.7 0 002.288-4.042 1.087 1.087 0 00-.358-1.099l-1.33-1.108c-.251-.21-.582-.299-.905-.245l-1.17.195a1.125 1.125 0 01-.98-.314l-.295-.295a1.125 1.125 0 010-1.591l.13-.132a1.125 1.125 0 011.3-.21l.603.302a.809.809 0 001.086-1.086L14.25 7.5l1.256-.837a4.5 4.5 0 001.528-1.732l.146-.292M6.115 5.19A9 9 0 1017.18 4.64M6.115 5.19A8.965 8.965 0 0112 3c1.929 0 3.716.607 5.18 1.64"
        />
      </svg>

      <span className="!text-gray-600">
        <a href={structureInfo.structure_website} className="!text-gray-600">
          {structureInfo.structure_website}
        </a>
      </span>
    </Row>
  );

  const Actions = ({ key }) => (
    <>
      <Checkbox
        checked={cancellationRulesChecked}
        onChange={(e) => setCancellationRulesChecked(e.target.checked)}
        className="text-[17px]"
      >
        {t("content.actions.conditions_1")}&nbsp;
        <Button
          type="link"
          className="!m-0 !p-0 text-[17px]"
          onClick={() => info(preventivo.cancellation_rules)}
        >
          {t("content.actions.conditions_2")}
        </Button>
      </Checkbox>

      <Button
        className={`bg-indigo-500 font-medium
         text-lg !p-7 rounded-md cursor-pointer text-[#c2c2c2] 
                        ${
                          cancellationRulesChecked &&
                          "!text-white hover:!text-white hover:!border-indigo-600 hover:bg-indigo-600 "
                        }
                       transition md:flex flex-row items-center justify-center gap-2 hidden`}
        disabled={!cancellationRulesChecked}
        onClick={() => setPaymentOpen(true)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          className={`w-6 h-6 text-white ${
            !cancellationRulesChecked && "!text-[#c2c2c2]"
          }`}
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>

        <span>{t("content.actions.confirmButton")}</span>
      </Button>
      <div className="w-full text-center">
        <span
          className="text-blue-500 hover:text-blue-600 underline cursor-pointer"
          onClick={() => setOpenRefuse(true)}
        >
          {t("content.actions.refuseButton")}
        </span>
      </div>

      <div className="fixed bottom-0 left-0 w-full md:hidden">
        <div
          onClick={() => setPaymentOpen(true)}
          className={`font-medium
                       text-white text-lg p-5 rounded-t-md cursor-pointer ${
                         cancellationRulesChecked
                           ? "bg-indigo-500 hover:!text-white hover:!border-indigo-600 hover:bg-indigo-600 "
                           : "!bg-gray-200 !text-[#c2c2c2] pointer-events-none"
                       }
                       transition flex flex-row items-center justify-center gap-2`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            className={`w-6 h-6 text-white ${
              !cancellationRulesChecked && "text-[#c2c2c2]"
            }`}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>

          <span>{t("content.actions.confirmButton")}</span>
        </div>
      </div>
    </>
  );
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [top] = useState(30);

  return (
    <Spin spinning={loadingInfo}>
      <Layout className="layout">
        <PreventivoChatBox open={chatOpen} setOpen={setChatOpen} />
        <PreventivoGuestPayment
          open={paymentOpen}
          setOpen={setPaymentOpen}
          structureInfo={structureInfo}
          preventivo={preventivo}
        />
        <Header className="!bg-white flex flex-col sm:flex-row items-center !h-auto py-2 gap-3 sm:gap-10">
          {structureInfo.structure_logo && (
            <img
              src={"https://www.idaweb.eu/" + structureInfo.structure_logo}
              alt="logo-struttura"
              className="w-48"
            />
          )}

          <div className="flex flex-col items-center sm:items-start">
            <h1 className="text-4xl text-center sm:text-left sm:text-4xl text-black my-4 sm:my-0 font-semibold">
              {structureInfo.structure_name}
            </h1>

            <div className="hidden sm:flex">
              <Breadcrumb separator="•" className="mt-2 brcr">
                {structureInfo.structure_phone && (
                  <Breadcrumb.Item className="!text-gray-400 !flex !flex-row !items-center">
                    <Item1 />
                  </Breadcrumb.Item>
                )}
                {structureInfo.structure_email && (
                  <Breadcrumb.Item className="!text-gray-400 !flex !flex-row !items-center">
                    <Item2 />
                  </Breadcrumb.Item>
                )}
                {structureInfo.structure_website && (
                  <Breadcrumb.Item className="!text-gray-400 !flex !flex-row !items-center">
                    <Item3 />
                  </Breadcrumb.Item>
                )}
              </Breadcrumb>
            </div>

            <div className="flex sm:hidden flex-col gap-2 leading-3 mt-3 justify-center text-center">
              {structureInfo.structure_phone && <Item1 />}
              {structureInfo.structure_email && <Item2 />}
              {structureInfo.structure_website && <Item3 />}
            </div>
          </div>

          <div className="mb-3 lg:mb-0 lg:ml-auto">
            <button
              className="font-medium bg-indigo-50 hover:bg-indigo-100
                       text-indigo-500 text-lg p-3 rounded-md cursor-pointer hover:text-indigo-600
                       transition flex flex-row items-center gap-2"
              onClick={() => setChatOpen(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                />
              </svg>

              <span>{t("header.chat.title")}</span>
            </button>
          </div>
        </Header>
        <Content className="p-4 md:p-10 max-w-8xl w-full mx-auto">
          <div
            className="site-layout-content p-4 md:p-10"
            style={{
              background: colorBgContainer,
            }}
          >
            <Space direction="vertical" style={{ width: "100%" }} size={16}>
              <Skeleton loading={loading} active={true}>
                <div className="grid grid-cols-12 gap-x-4">
                  <div className="col-span-12 md:col-span-7">
                    <div className="w-full p-3">
                      <h1 className="text-2xl font-semibold uppercase text-center md:text-left">
                        {t("content.title")}
                      </h1>
                      {preventivo.message && (
                        <div className="mt-5 text-center md:text-left">
                          {preventivo.message}
                        </div>
                      )}
                      <Divider />
                    </div>
                    <ul role="list" className="-my-6 divide-y divide-gray-200">
                      {preventivo.rows?.map((row, index) => (
                        <li key={"preventivo-row-" + index} className="py-6">
                          {row.id_category ? (
                            <PreventivoGuestRow row={row} />
                          ) : (
                            <PreventivoGuestRowProduct row={row} />
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-span-12 md:col-span-5">
                    <div className="hidden lg:flex w-full">
                      <Affix offsetTop={top} className="w-full">
                        <div className="flex flex-col gap-3 w-full">
                          <PreventivoCheckout preventivo={preventivo} />
                          <PreventivoRefuseModal
                            open={openRefuse}
                            setOpen={setOpenRefuse}
                          />

                          <Actions key={"affix-top"} />
                        </div>
                      </Affix>
                    </div>
                    <div className="flex lg:hidden w-full">
                      <div className="flex flex-col gap-3 w-full">
                        <PreventivoCheckout
                          preventivo={preventivo}
                          view_as_preview={view_as_preview}
                        />
                        <PreventivoRefuseModal
                          open={openRefuse}
                          setOpen={setOpenRefuse}
                        />
                        <Actions />
                      </div>
                    </div>
                  </div>
                </div>
              </Skeleton>
            </Space>
          </div>
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          F451 ©2023 Created for IdaWeb
        </Footer>

        {view_as_preview && (
          <div
            className="absolute bottom-0 left-0 z-[9999] rounded-tr-lg rounded-br-lg p-3
          bg-white shadow-lg border-red-400 border-dashed border-2"
          >
            <div className="flex flex-row items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                class="w-6 h-6 text-red-400"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
              <span className="text-red-500 font-semibold text-xl uppercase">
                Anteprima Preventivo
              </span>
            </div>

            <p className="text-lg">
              Il preventivo è in modalità <b>ANTEPRIMA</b>.<br></br>
              Pertanto non è possibile concludere il <br></br>
              pagamento o inviare messaggi.
            </p>
          </div>
        )}
      </Layout>
    </Spin>
  );
};
export default PreventivoGuest;
