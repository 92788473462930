import { PhotographIcon, UserCircleIcon } from "@heroicons/react/solid";
import React from "react";
import webService from "../../services/web.service";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
export default function AdvisorEdit({
  advisor,
  advisors,
  setAdvisor,
  setAdvisors,
  setOpen,
}) {
  const [isDisabled, setIsDisabled] = React.useState(false);
  const { token, id_config } = useParams();

  React.useEffect(() => {
    var isDis = false;
    if (!advisor.title_text_obj.text) isDis = true;
    if (!advisor.translation_text_obj.text) isDis = true;
    if (!advisor.action_text_obj.text) isDis = true;
    if (!advisor.action_url) isDis = true;
    setIsDisabled(isDis);
  }, [advisor]);

  const saveAdvisor = async () => {
    setIsDisabled(true);
    if (advisor.id_advisor) {
      webService.patchAdvisor(token, id_config, advisor).then(
        (result) => {
          const status = result.status;
          if (status === 200) {
            setAdvisors(
              advisors.map((item) => {
                if (item.id_advisor === advisor.id_advisor) return result.data;
                return item;
              })
            );
            toast.success("L'annuncio è stato salvato correttamente.");
          } else {
            toast.error("Si è verificato un errore");
          }
          setIsDisabled(false);
        },
        (error) => {
          toast.error("Si è verificato un errore");
          setIsDisabled(false);
        }
      );
    } else {
      webService.saveAdvisor(token, id_config, advisor).then(
        (result) => {
          const status = result.status;
          if (status === 201) {
            setAdvisors([...advisors, result.data]);
            toast.success("L'annuncio è stato salvato correttamente.");
            setOpen(false);
          } else {
            toast.error("Si è verificato un errore");
          }
          setIsDisabled(false);
        },
        (error) => {
          toast.error("Si è verificato un errore");
          setIsDisabled(false);
        }
      );
    }
  };

  const deleteAdvisor = async () => {
    setIsDisabled(true);
    webService.deleteAdvisor(token, advisor.id_advisor).then(
      (result) => {
        const status = result.status;
        if (status === 200) {
          toast.success("L'annuncio è stato eliminato.");
          setAdvisors(
            advisors.filter((el) => el.id_advisor !== advisor.id_advisor)
          );
          setOpen(false);
        } else {
          toast.error("Si è verificato un errore");
        }
        setIsDisabled(false);
      },
      (error) => {
        toast.error("Si è verificato un errore");
        setIsDisabled(false);
      }
    );
  };

  return (
    <form>
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-lg font-semibold leading-7 text-gray-900">
            {advisor.id_advisor ? "Modifica Annuncio" : "Nuovo Annuncio"}
          </h2>
          <div className="mt-10 flex flex-col gap-6">
            <div className="w-full">
              <label
                htmlFor="title-advisor"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Titolo
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="text"
                    id="title-advisor"
                    className="ml-2 block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="Es. Offerta imperdibile!"
                    value={advisor.title_text_obj.text}
                    onChange={(e) =>
                      setAdvisor({
                        ...advisor,
                        title_text_obj: {
                          ...advisor.title_text_obj,
                          text: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="w-full">
              <label
                htmlFor="body-advisor"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Corpo dell'annuncio
              </label>
              <div className="mt-2">
                <textarea
                  id="body-advisor"
                  rows={3}
                  className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
                  defaultValue={""}
                  placeholder="Es. Prenota oggi la nostra struttura e avrai inclusi..."
                  value={advisor.translation_text_obj.text}
                  onChange={(e) =>
                    setAdvisor({
                      ...advisor,
                      translation_text_obj: {
                        ...advisor.translation_text_obj,
                        text: e.target.value,
                      },
                    })
                  }
                />
              </div>
            </div>
            <div className="w-full">
              <label
                htmlFor="action-advisor"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Azione
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="text"
                    id="action-advisor"
                    className="ml-2 block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="Es. CHIAMA ORA"
                    value={advisor.action_text_obj.text}
                    onChange={(e) =>
                      setAdvisor({
                        ...advisor,
                        action_text_obj: {
                          ...advisor.action_text_obj,
                          text: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="w-full">
              <label
                htmlFor="url-action-advisor"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                URL Azione
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="text"
                    id="url-action-advisor"
                    className="ml-2 block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="Es. mailto:latuaemail@gmail.com"
                    value={advisor.action_url}
                    onChange={(e) =>
                      setAdvisor({
                        ...advisor,
                        action_url: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        {advisor.id_advisor && (
          <button
            type="button"
            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 mr-auto"
            onClick={() => {
              if (window.confirm("Eliminare l'annuncio corrente?")) {
                deleteAdvisor();
              }
            }}
          >
            Elimina
          </button>
        )}

        <button
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
          onClick={() => setOpen(false)}
        >
          Annulla
        </button>
        <button
          className={`rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
            isDisabled ? "!pointer-events-none !bg-gray-100 !text-gray-400" : ""
          }`}
          disabled={isDisabled}
          onClick={saveAdvisor}
        >
          Salva
        </button>
      </div>
    </form>
  );
}
