import React from "react";

function PacketQuote() {
  return (
    <div
      className="w-full shadow bg-[#FCFCFC] rounded-lg flex flex-row
     items-center overflow-hidden px-8 py-5"
    >
      <div className="flex flex-col gap-3">
        <h4 className="font-semibold text-2xl">
          Non trovi il pacchetto giusto per te?
        </h4>
        <p className="text-sm text-gray-500">
          Possiamo creare un'offerta su misura per le tue esigenze!
        </p>
        <div
          className="bg-blue-600 hover:bg-blue-700 cursor-pointer 
        rounded-lg shadow text-white text-md font-semibold px-3 py-2 w-fit
        transition-all"
        >
          Richiedi un preventivo!
        </div>
      </div>
      <img
        src="https://media.tenor.com/q-zZSTX6jSIAAAAC/mail-download.gif"
        className="ml-auto w-32"
      />
    </div>
  );
}

export default PacketQuote;
