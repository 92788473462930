
export default function NotFound(){
    return(
        <>
        <div style={{
            backgroundImage:`url("/assets/images/notfoundbg.jpg")`,
            backgroundSize:'cover',
            minHeight:'100vh',
            margin:'0',
            display:'flex',
            alignItems:'center',
            justifyContent:'center'
        }}>
            <div style={{
                backgroundColor:'#000',
                minHeight:'100vh',
                width:'100%',
                margin:'0',
                opacity:'.5'
            }}>
            </div>
            <div style={{
                position:'absolute',
                textAlign:'center'
            }}>
                <h1 style={{
                    color:'#FFF',
                    fontSize:'10rem',
                    margin:'0'
                }}>404</h1>
                <p
                    style={{
                        color:'#FFF',
                        fontSize:'1.5rem'
                    }}
                >La pagina che stai cercando non è stata trovata</p>
                <p
                    style={{
                        color:'#FFF',
                        fontSize:'1.0rem'
                    }}
                >IdaBooking.eu</p>
            </div>
        </div>
        </>
    )
}