import * as React from 'react';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';

export default function Ota(props) {
    return(
        <>
            {
                props.ota.map((ota, index) => {
                    return (
                        <>
                            <Tooltip title={ota.name + ": " + parseFloat(ota.price).toFixed(2) + " €"} placement="top">
                                <Chip 
                                    label={parseFloat(ota.price).toFixed(2) + " €"} 
                                    avatar={<Avatar alt={ota.name} src={ota.icon} />}
                                    color={(ota.is_be === false) ? "error" : "success"} 
                                    className={ota.className}
                                />
                            </Tooltip>
                        </>
                    )
                })
            }
        </>
    )
}