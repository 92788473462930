import * as React from "react";
import Grid from "@mui/material/Grid";
import HorizontalBar from "../../components/layout/horizontalbar";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Flickity from "react-flickity-component";
import Ota from "./ota";
import "../../flickity.css";
import DynamicHeroIcon from "../heroicons/hicon";
import Button from "@mui/material/Button";
import { useSpring, animated } from "react-spring"; // Using hooks in V9

import { library } from "@fortawesome/fontawesome-svg-core";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RoomDetails from "../room_details/room_details";
import { GetLabel } from "../../languages/i18n";
import Packets from "../packets/Packets";
import NotRefundable from "./NotRefundable";

const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Room(props) {
  const [value, setValue] = React.useState(0);
  const [showRoomDetails, setShowRoomDetails] = React.useState(false);
  const [focusedRoom, setFocusedRoom] = React.useState({});
  const [openNotRefundable, setOpenNotRefundable] = React.useState(false);
  const [priceRoom, setPriceRoom] = React.useState(0);

  const marginLeftSm = "20px !important";
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const style = useSpring({
    from: { opacity: 0, marginTop: 100 },
    to: { opacity: 1, marginTop: 0 },
  });
  const ShowRoomDetails = (e, room) => {
    setFocusedRoom(room);
    setShowRoomDetails(true);
  };

  return (
    <>
      {props.isSearching === false && (
        <>
          <animated.div style={style} className="room">
            <RoomDetails
              addCart={props.addCart}
              room={focusedRoom}
              open={showRoomDetails}
              setOpen={setShowRoomDetails}
              uuid={props.uuid}
              tax={props.tax}
              nrooms={props.nrooms}
              language={props.language}
            ></RoomDetails>
            <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                {props.rooms.map((room, index_room) => {
                  return (
                    <Tab
                      label={room.tabName}
                      {...a11yProps(room.index)}
                      className="room-variant"
                    />
                  );
                })}
              </Tabs>
            </Box>
            {props.rooms.map((room, index_room) => {
              return (
                <TabPanel
                  value={value}
                  index={room.index}
                  className={`room-panel rounded-tr-lg ${
                    !openNotRefundable && "!rounded-b-lg"
                  }`}
                >
                  <Grid container spacing={2} sx={{ height: "auto" }}>
                    <Grid item sm={9} xs={12}>
                      <Grid container>
                        <Grid
                          item
                          sm={3}
                          xs={12}
                          sx={{ marginBottom: { sm: "0", xs: "10px" } }}
                        >
                          <Flickity options={{ lazyLoad: true }}>
                            {room.images.map((image, i) => {
                              return (
                                <div
                                  style={{
                                    background: "url(" + image.image + ")",
                                  }}
                                  className="carousel-img"
                                ></div>
                              );
                            })}
                          </Flickity>
                        </Grid>
                        <Grid item sm={9} xs={12} className="room-box">
                          <Box
                            sx={{
                              marginLeft: { sm: marginLeftSm, xs: "0" },
                              marginBottom: {
                                sm: "10px !important",
                                xs: "0",
                              },
                            }}
                          >
                            <Ota ota={room.ota}></Ota>
                          </Box>

                          <Typography
                            variant="h2"
                            gutterBottom
                            component="div"
                            className="room-name"
                            sx={{
                              marginLeft: { sm: marginLeftSm, xs: "0" },
                              marginTop: {
                                sm: "0 !important",
                                xs: "20px !important",
                              },
                            }}
                          >
                            {room.roomName}
                          </Typography>

                          <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <Typography
                              variant="div"
                              gutterBottom
                              component="div"
                              className="room-details"
                              sx={{
                                display: "flex",
                                marginLeft: { sm: marginLeftSm, xs: "0" },
                                marginTop: {
                                  sm: "5px !important",
                                  xs: "20px !important",
                                },
                              }}
                            >
                              <span className="room-details-guests">
                                {GetLabel(props.language, "LABEL_ROOM_GUESTS")}:{" "}
                              </span>
                              {room.guests >= 6 ? (
                                <>
                                  {room.guests}
                                  <DynamicHeroIcon
                                    icon="UserIcon"
                                    className="room-details-icon"
                                  ></DynamicHeroIcon>
                                </>
                              ) : (
                                [...Array(room.guests)].map((e, i) => (
                                  <DynamicHeroIcon
                                    icon="UserIcon"
                                    className="room-details-icon"
                                  ></DynamicHeroIcon>
                                ))
                              )}
                              <span
                                style={{
                                  marginLeft: "15px",
                                  marginRight: "15px",
                                  color: "#adadad",
                                }}
                              >
                                •
                              </span>
                              <DynamicHeroIcon
                                icon="CubeTransparentIcon"
                                className="room-details-icon room-details-mq-icon"
                              ></DynamicHeroIcon>
                              <span>{room.mq}m²</span>
                            </Typography>
                          </Box>

                          <Typography
                            variant="p"
                            gutterBottom
                            component="div"
                            sx={{
                              color: "#4a4a4a",
                              display: "flex",
                              marginLeft: { sm: marginLeftSm, xs: "0" },
                              marginTop: {
                                sm: "5px !important",
                                xs: "20px !important",
                              },
                              marginBottom: "0",
                            }}
                          >
                            {GetLabel(props.language, "ROOM_SERVICES")}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              marginLeft: { sm: marginLeftSm, xs: "0" },
                              whiteSpace: "pre",
                              overflowX: "auto",
                              marginRight: "5px",
                            }}
                            className="ios-scroll"
                          >
                            {room.services.length > 0 ? (
                              room.services.map((service, i) => {
                                return (
                                  <div
                                    style={{
                                      marginRight: "10px",
                                      marginTop: "3px",
                                      marginBottom: "3px",
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={service.icon}
                                      className="service-icon"
                                      style={{ width: "20px" }}
                                    />
                                    <span
                                      className="service-icon-text"
                                      style={{
                                        marginTop:
                                          service.icon === null ? "25px" : "",
                                      }}
                                    >
                                      {service.name}
                                    </span>
                                  </div>
                                );
                              })
                            ) : (
                              <p
                                style={{
                                  marginRight: "10px",
                                  marginTop: "3px",
                                  marginBottom: "3px",
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={"fa-times"}
                                  className="service-icon"
                                />
                                <span className="service-icon-text">
                                  {GetLabel(props.language, "NO_SERVICES")}
                                </span>
                              </p>
                            )}
                          </Box>
                          <Typography
                            variant="p"
                            gutterBottom
                            component="div"
                            className="room-description"
                            sx={{ marginLeft: { sm: marginLeftSm, xs: "0" } }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: room.description,
                              }}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sm={3} xs={12} className="room-price">
                      <Box className="main">
                        <p>{GetLabel(props.language, "PRICE_FROM")}</p>
                        {room.striked_price !== 0 ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                position: "relative",
                              }}
                            >
                              <span
                                class="selected_room_price booking-price-discount"
                                style={{
                                  fontSize: "16px !important",
                                  color: "#aaabad",
                                  fontWeight: "bolder",
                                  textDecoration: "line-through",
                                }}
                              >
                                € {parseFloat(room.striked_price).toFixed(2)}
                              </span>

                              <div
                                className="flex flex-row items-center absolute"
                                style={{ right: "-30px", top: "15px" }}
                              >
                                <DynamicHeroIcon
                                  icon="TagIcon"
                                  style={{
                                    textShadow:
                                      "1px 0 1px #dc3522 -1px 0 1px #dc3522",
                                    color: "red",
                                    width: "24px",
                                    height: "24px",
                                    position: "absolute",
                                    right: 0,
                                  }}
                                ></DynamicHeroIcon>
                                <span class="tag-discount__label">
                                  -{room.discount_perc}%
                                </span>
                              </div>
                            </div>
                            <h1 className="discount-price">
                              {parseFloat(room.price).toFixed(2)} €
                            </h1>
                          </>
                        ) : (
                          <h1 style={{ fontSize: "25px", fontWeight: "600" }}>
                            {parseFloat(room.price).toFixed(2)} €
                          </h1>
                        )}

                        <div className="flex flex-col gap-2">
                          <Button
                            variant="contained"
                            className="booknow"
                            onClick={(e) => ShowRoomDetails(e, room)}
                            sx={{
                              borderRadius: "9999px",
                              width: { sm: "auto", xs: "100%" },
                              fontWeight: "600",
                            }}
                          >
                            {GetLabel(props.language, "BUTTON_ROOM_INFO_BOOK")}
                          </Button>
                          {room.isNotRefundable && (
                            <Button
                              variant="contained"
                              onClick={() => {
                                setOpenNotRefundable(!openNotRefundable);
                                setPriceRoom(room.price);
                              }}
                              sx={{
                                borderRadius: "9999px",
                                width: { sm: "auto", xs: "100%" },
                                fontWeight: "600",
                              }}
                              color="secondary"
                            >
                              {GetLabel(props.language, "BUTTON_NOREFUNDABLE")}
                            </Button>
                          )}
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </TabPanel>
              );
            })}

            <NotRefundable
              open={openNotRefundable}
              language={props.language}
              uuid={props.uuid}
              idCategory={props.idCategory}
              priceRoom={priceRoom}
              setPriceRoom={setPriceRoom}
              rooms={props.rooms}
              addCart={props.addCart}
            />
          </animated.div>
        </>
      )}
    </>
  );
}
