import React, { useState } from "react";
import SecondStepRoom from "./SecondStepRoom";
import { Button, Checkbox, Input, Space } from "antd";
import dayjs from "dayjs";
import RowForm from "./RowForm";
import TextArea from "antd/es/input/TextArea";
import { useTranslation } from "react-i18next";

function ThirdStep({ state, setState }) {
  const { t } = useTranslation("common");
  return (
    <div
      className="my-10 backdrop-blur-lg w-full h-full 
  rounded-lg py-10 border border-dashed border-gray-500
  px-5 md:px-20 gap-5 flex flex-col min-h-[660px]"
    >
      <div className="flex flex-col">
        <h1 className="text-xl font-bold tracking-tight text-white sm:text-2xl">
          {t("request.step3.title")}
        </h1>
        <p className="text-md leading-8 text-gray-400">
          {t("request.step3.subtitle")}
        </p>

        <div className="mt-10 flex flex-col gap-4">
          {state.services?.map((service, index) => (
            <Checkbox
              className="!m-0 !flex !flex-row !items-baseline !gap-3"
              value={service.checked}
              checked={service.checked}
              onChange={(e) =>
                setState({
                  ...state,
                  services: state.services.map((el) => {
                    if (
                      el.id_request_extra_config ===
                      service.id_request_extra_config
                    )
                      el.checked = e.target.checked;
                    return el;
                  }),
                })
              }
            >
              <div className="flex flex-col gap-1">
                <span className="text-2xl font-semibold tracking-tight text-white">
                  {service.title}
                </span>
                <span className="text-green-200">
                  {t("request.step3.supplement")} &euro; {service.price}{" "}
                  {service.is_day
                    ? t("request.step3.supplement_room")
                    : t("request.step3.supplement_quote")}
                </span>
              </div>
            </Checkbox>
          ))}

          {state.services.length === 0 && <>{t("request.no_services")}</>}
        </div>

        {/* <div
          className="mt-10 flex flex-col gap-4 border border-gray-200 
        border-dashed rounded-lg p-5"
        >
          <span className="text-sm">Hai un codice da utilizzare?</span>
          <Space.Compact
            style={{
              width: "100%",
            }}
          >
            <Input placeholder="Codice Coupon" />
            <Button type="primary">Applica</Button>
          </Space.Compact>
        </div> */}

        <div className="mt-10">
          <RowForm
            label={t("request.step3.notes")}
            input={
              <TextArea
                value={state.notes}
                onChange={(e) => setState({ ...state, notes: e.target.value })}
                placeholder={t("request.step3.requests")}
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            }
          />
        </div>
      </div>
    </div>
  );
}

export default ThirdStep;
