import React from "react";

function RowForm({ label, input }) {
  return (
    <div className="col-span-12 lg:col-span-6 flex flex-col items-start gap-2">
      <span className="text-white w-full">{label}</span>
      {input}
    </div>
  );
}

export default RowForm;
