import { Divider, Form } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import Dropify from "../../../components/payment/dropzone";

function PaymentBonifico({
  setFile,
  setIsDisabledPayment,
  bonificoCode,
  bonificoDetails,
  istructions,
}) {
  const { t } = useTranslation("common");

  return (
    <div className="flex flex-col text-white gap-5 backdrop-blur-md p-4 rounded-lg">
      <h2 className="text-center w-full text-2xl font-semibold">
        Bonifico Bancario
      </h2>
      {istructions ? (
        <>{istructions}</>
      ) : (
        <>
          <p>
            <b>Istruzioni</b>:<br></br>
            Provvedi ad effettuare il pagamento entro i prossimi 3 giorni. Non
            appena riceveremo il bonifico, ti manderemo una conferma via mail
            con i dettagli del tuo acquisto.
          </p>
          <p>
            Una volta eseguito il bonifico ricordati di salvare una copia della
            ricevuta e di caricarla qui sotto.
          </p>
          <p>
            <b>Importante</b>: Non controlliamo giornalmente gli accrediti,
            quindi non dimenticarti di caricare di seguito la ricevuta del
            bonifico, altrimenti la prenotazione non sarà valida.
          </p>
          <p>
            <b>
              * Si prega di inserire gli estremi in maniera corretta in
              particolare la causale per facilitare l'evasione del suo ordine
            </b>
          </p>
        </>
      )}
      <div className="border-b-2 border-gray-400 border-dashed" />

      <div className="grid grid-cols-12 gap-4 items-center">
        <div className="col-span-12 md:col-span-4 !text-lg">IBAN:</div>
        <div className="col-span-12 md:col-span-8 text-right leggibile !text-lg">
          {bonificoDetails.iban}
        </div>

        <div className="col-span-12 md:col-span-4 !text-lg">Causale:</div>
        <div className="col-span-12 md:col-span-8 text-right leggibile !text-lg">
          {bonificoCode}
        </div>

        <div className="col-span-12 md:col-span-4 !text-lg">Beneficiario:</div>
        <div className="col-span-12 md:col-span-8 text-right leggibile !text-lg">
          {bonificoDetails.beneficiario}
        </div>

        <div className="col-span-12 md:col-span-4 !text-lg">Importo:</div>
        <div className="col-span-12 md:col-span-8 text-right leggibile !text-lg">
          {parseFloat(bonificoDetails.total).toFixed(2)} &euro;
        </div>
      </div>

      <div className="border-1 border-dashed border-blue-300">
        <Dropify
          setFile={setFile}
          language={"IT"}
          classNameLabel="!mt-3 "
          setIsDisabled={setIsDisabledPayment}
        ></Dropify>
      </div>
    </div>
  );
}

export default PaymentBonifico;
