import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoadingIndicator from "../../components/LoadingIndicator";
import webService from "../../services/web.service";
import { toast } from "react-hot-toast";

function PagesEdit({ language }) {
  const { token, id_config } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [pages, setPages] = React.useState([]);

  React.useEffect(() => {
    if (id_config) fetchPages();
  }, [language]);

  const fetchPages = async () => {
    setLoading(true);
    webService.getPages(token, id_config, language.value).then(
      (result) => {
        setPages(result.data);
        setLoading(false);
      },
      (error) => {
        toast.error("Si è verificato un errore");
        setIsDisabled(false);
      }
    );
  };

  const savePages = async () => {
    setIsDisabled(true);
    webService.savePages(token, id_config, language.value, pages).then(
      (result) => {
        if (result.status === 200)
          toast.success("Le pagine sono state salvate");
        else toast.error("Si è verificato un errore");
        setIsDisabled(false);
      },
      (error) => {
        toast.error("Si è verificato un errore");
        setIsDisabled(false);
      }
    );
  };

  const deletePage = (page, index) => {
    setIsDisabled(true);
    webService.deletePage(token, id_config, page.id_page).then(
      (result) => {
        if (result.status === 200) {
          toast.success("La pagina è stata eliminata");
          setPages(pages.filter((el, h) => h !== index));
        } else toast.error("Si è verificato un errore");
        setIsDisabled(false);
      },
      (error) => {
        toast.error("Si è verificato un errore");
        setIsDisabled(false);
      }
    );
  };

  return (
    <>
      {loading ? (
        <div className="w-full h-full flex items-center justify-center">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <div className="bg-gray-100">
            <div className="pt-5 pr-5 w-full flex justify-end">
              <button
                className={`rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                onClick={savePages}
                type="button"
              >
                Salva le pagine
              </button>
            </div>
            <div className="flex flex-col w-full md:w-[600px] p-5">
              <div className="flex flex-row items-center w-full">
                <label
                  htmlFor="title-advisor"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Lista di pagine ({pages.length})
                </label>

                <span
                  className="ml-5 cursor-pointer"
                  onClick={() => {
                    setPages([
                      ...pages,
                      {
                        id_page: null,
                        id_config: id_config,
                        url: "",
                        name: "",
                        name_obj: {
                          id_translation: null,
                          group_uid: "",
                          language: language.value,
                          text: "",
                        },
                      },
                    ]);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 text-gray-600"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 6v12m6-6H6"
                    />
                  </svg>
                </span>
              </div>

              {pages?.map((item, index) => (
                <div
                  className="my-5 flex flex-row items-center w-full gap-3"
                  key={"page-" + index}
                >
                  <div className="w-full md:w-1/3">
                    <label>Nome pagina</label>
                    <div
                      className="flex flex-1 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 
                focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md bg-white"
                    >
                      <input
                        type="text"
                        className="
                    ml-2 block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-500 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Es. Home, Stanze, ..."
                        value={item.name_obj.text}
                        onChange={(e) =>
                          setPages(
                            pages.map((it, i) => {
                              if (i === index)
                                pages[i].name_obj.text = e.target.value;
                              return it;
                            })
                          )
                        }
                      />
                    </div>
                  </div>

                  <div className="w-full md:w-2/3 flex flex-row items-center">
                    <div className="flex flex-col flex-1 w-full">
                      <label>URL pagina</label>
                      <div
                        className="flex flex-1 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 
                focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md bg-white w-full"
                      >
                        <input
                          type="text"
                          className="w-full ml-2 block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-500 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          placeholder="Es. /home"
                          value={item.url}
                          onChange={(e) =>
                            setPages(
                              pages.map((it, i) => {
                                if (i === index) pages[i].url = e.target.value;
                                return it;
                              })
                            )
                          }
                        />
                      </div>
                    </div>
                    <div
                      className="cursor-pointer mt-5 ml-3"
                      onClick={() => {
                        deletePage(item, index);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-5 h-5 text-red-400"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default PagesEdit;
