import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import structureService from "../../services/structure.service";

function SuccessMessage({ uuid, language }) {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(() => {
    fetchSuccessMessage();
  }, []);

  const fetchSuccessMessage = () => {
    setLoading(true);
    structureService
      .getSuccessMessage(language + "/" + uuid)
      .then((result) => {
        setLoading(false);
        if (result.status === 200) {
          setMessage(result.data?.email_message);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  if (loading) return <CircularProgress />;
  else if (!loading && !message) return null;
  else
    return (
      <pre
        className="mt-1 mb-6 w-full bg-gray-100 p-4 rounded-sm"
        style={{ fontFamily: "inherit", whiteSpace: "break-spaces" }}
      >
        {message}
      </pre>
    );
}

export default SuccessMessage;
