import React, { useEffect } from "react";
import { Button, Modal } from "antd";
import { useState } from "react";

function PreviewMessage({ open, setOpen, html, setHtml }) {
  useEffect(() => {
    if (!open) setHtml("");
  }, [open]);
  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <Modal
      title="Anteprima del messaggio"
      open={open}
      onCancel={handleCancel}
      className="!w-full md:!w-[1000px]"
      footer={[
        <Button key="back" onClick={handleCancel}>
          Chiudi
        </Button>,
      ]}
    >
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </Modal>
  );
}

export default PreviewMessage;
