import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function Collapse(props) {
  return (
    <Accordion {...props} style={{ boxShadow: "none", ...props.style }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{props.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{props.children}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}
