import { Select, Space, Spin } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import preventiviService from "../../services/preventivi.service";
const { Option } = Select;

const PreventivoWarranitySelect = ({ status, initialValue, onChange }) => {
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [enabledPayments, setEnabledPayments] = useState([]);

  useEffect(() => {
    if (token && enabledPayments.length === 0) fetchPaymentMethods();
  }, []);

  const fetchPaymentMethods = async () => {
    setLoading(true);
    preventiviService.getPaymentMethods(token).then(
      (result) => {
        result = result.data;
        setEnabledPayments(result);
        setLoading(false);
      },
      (error) => {}
    );
  };

  return (
    <Spin spinning={loading}>
      <Select
        style={{
          width: "100%",
        }}
        placeholder="Seleziona un metodo di garanzia"
        onChange={(e) => onChange(e)}
        optionLabelProp="label"
        value={initialValue}
        disabled={status === 2 || status === 3 || status === 4}
      >
        <Option value="0" label="Conferma Carta">
          <Space>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 text-orange-600"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"
              />
            </svg>
            <span className="text-orange-600">Conferma Carta</span>
          </Space>
        </Option>
        <Option
          value="3"
          label="Pagamento Con Bonifico"
          disabled={!enabledPayments.filter((el) => el === 3).length}
        >
          <Space>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className={`w-5 h-5 ${
                enabledPayments.filter((el) => el === 3).length &&
                "text-orange-600"
              }`}
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
              />
            </svg>

            <span
              className={`${
                enabledPayments.filter((el) => el === 3).length &&
                "text-orange-600"
              }`}
            >
              Pagamento Con Bonifico&nbsp;
              {!enabledPayments.filter((el) => el === 3).length
                ? "( nessun iban trovato )"
                : ""}
            </span>
          </Space>
        </Option>
        <Option value="1" label="Pagamento Online" disabled={true}>
          <Space>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 "
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
              />
            </svg>
            <span className="">Pagamento Online (in arrivo)</span>
          </Space>
        </Option>
        <Option value="2" label="Pagamento In Struttura">
          <Space>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 text-green-600"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.182 15.182a4.5 4.5 0 01-6.364 0M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z"
              />
            </svg>

            <span className="text-green-600">Pagamento In Struttura</span>
          </Space>
        </Option>
      </Select>
    </Spin>
  );
};
export default PreventivoWarranitySelect;
