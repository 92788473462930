import React, { useState } from "react";
import { Card } from "antd";
import Zoom from "react-medium-image-zoom";
import { saveAs } from "file-saver";
import { Button, Divider, Form, Input } from "antd";
import { useParams } from "react-router-dom";
import preventiviService from "../../../services/preventivi.service";
import { toast } from "react-hot-toast";
import PreventivoCardRow from "./PreventivoCardRow";
import { StyleProvider } from "@ant-design/cssinjs";
import { Popconfirm } from "antd";

const { TextArea } = Input;

function PreventivoBonifico({ preventivo, setPreventivo }) {
  const { token } = useParams();
  const [invalidFeedback, setInvalidFeedback] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const setBonificoValidity = (is_invalid) => {
    setLoading(true);
    preventiviService
      .setInvalidBonifico(token, {
        ...preventivo.transfer,
        is_invalid: is_invalid,
        invalid_feedback: invalidFeedback,
      })
      .then(
        (result) => {
          if (result.status === 200) {
            toast.success("Il bonifico è stato aggiornato");
            setPreventivo(
              {
                ...preventivo,
                transfer: {
                  ...preventivo.transfer,
                  is_invalid: is_invalid,
                  invalid_feedback: invalidFeedback,
                },
              },
              () => {
                setInvalidFeedback("");
              }
            );
            setOpen(false);
            setLoading(false);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
  };

  const deleteRooms = async () => {
    setLoading(true);
    preventiviService
      .deleteRoomsPreventivo(token + "/" + preventivo.id_preventivo)
      .then(
        (result) => {
          if (result.status === 200) {
            toast.success("Le stanze sono state eliminate.");
            setPreventivo({
              ...preventivo,
              rows: preventivo.rows.filter((el) => el.id_product),
            });
            setLoading(false);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
  };

  return (
    <StyleProvider hashPriority="high">
      <Card
        title={<div className="flex flex-row items-center gap-3">Bonifico</div>}
        bordered={false}
        className={
          preventivo.transfer.is_invalid ? "bg-red-100" : "bg-green-50"
        }
      >
        <Form
          labelCol={{
            span: 4,
          }}
          layout="horizontal"
        >
          <PreventivoCardRow
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5 text-slate-600"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M8.25 9.75h4.875a2.625 2.625 0 010 5.25H12M8.25 9.75L10.5 7.5M8.25 9.75L10.5 12m9-7.243V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185z"
                />
              </svg>
            }
            label={"Causale"}
            text={
              <span className="leggibile text-black">
                {preventivo.transfer.code_transfer}
              </span>
            }
            value={preventivo.transfer.code_transfer}
          />

          <PreventivoCardRow
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5 text-slate-600"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                />
              </svg>
            }
            label={"Ricevuta"}
            text={
              <div className="flex flex-col gap-3">
                <Zoom>
                  <img
                    src={
                      "https://www.idaweb.eu/" + preventivo.transfer.filename
                    }
                    alt="preventivo-preview"
                    className="w-1/2 object-cover"
                  />
                </Zoom>
                <div className="flex flex-col xl:flex-row items-start xl:items-center gap-3">
                  <Button
                    type="dashed"
                    icon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        class="w-5 h-5 text-gray-700"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                        />
                      </svg>
                    }
                    className="!flex !flex-row !items-center bg-white gap-2"
                    onClick={() =>
                      saveAs(
                        "https://www.idaweb.eu/" + preventivo.transfer.filename,
                        preventivo.transfer.filename.replace(/^.*[\\\/]/, "")
                      )
                    }
                  >
                    Scarica
                  </Button>

                  {!preventivo.transfer.is_invalid ? (
                    <>
                      {!open && (
                        <Button
                          type="primary"
                          icon={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="2"
                              stroke="currentColor"
                              class="w-5 h-5 text-white"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                              />
                            </svg>
                          }
                          className="!flex !flex-row !items-center gap-2"
                          danger
                          onClick={() => setOpen(true)}
                        >
                          Segna bonifico non valido
                        </Button>
                      )}
                    </>
                  ) : (
                    <div className="flex flex-row items-center gap-2">
                      <div className="custom ">
                        <Button
                          type="primary"
                          icon={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-5 h-5 text-white"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M4.5 12.75l6 6 9-13.5"
                              />
                            </svg>
                          }
                          className="!flex !flex-row !items-center gap-2"
                          onClick={() => setBonificoValidity(false)}
                        >
                          Segna bonifico valido
                        </Button>
                      </div>
                      {preventivo?.rows.filter((el) => el.id_category).length >=
                        1 && (
                        <Popconfirm
                          title="Conferma eliminazione stanze"
                          description={
                            <div>
                              Il pagamento non è andato a buon fine?
                              <br></br>
                              Puoi scegliere di rimuovere le stanze che hai
                              assegnato in questo preventivo. <br></br>
                              <b>
                                Nessuna proprietà di questo preventivo sarà
                                variata, solo le camere assegnate saranno
                                rimosse.
                              </b>
                              <br></br>
                              <br></br>
                              Eliminando le stanze il <b>totale</b> si azzererà,
                              ti consigliamo di annotarti sulle '<b>Note</b>' il
                              totale del preventivo.
                            </div>
                          }
                          onConfirm={deleteRooms}
                          okText="Conferma"
                          cancelText="No, annulla"
                        >
                          <Button
                            type="primary"
                            icon={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                class="w-5 h-5 text-white"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                                />
                              </svg>
                            }
                            className="!flex !flex-row !items-center gap-2"
                            danger
                          >
                            Elimina stanze assegnate
                          </Button>
                        </Popconfirm>
                      )}
                    </div>
                  )}
                </div>

                {preventivo.transfer.is_invalid && (
                  <div className="text-red-600 font-semibold">
                    Hai segnato questo bonifico come invalido.
                  </div>
                )}
              </div>
            }
            value={preventivo.transfer.filename}
          />
        </Form>
        {open && (
          <div className="flex flex-col">
            <Divider />
            Stai segnando il bonifico come invalido.
            <br></br>
            <b>Il cliente riceverà una mail per informarlo dell'accaduto.</b>
            <p>
              Inserisci di seguito il testo che riceverà il cliente via mail.
            </p>
            <br></br>
            <TextArea
              value={invalidFeedback}
              onChange={(e) => setInvalidFeedback(e.target.value)}
              placeholder="Non ricevuto dopo 3 giorni lavorativi"
              autoSize={{
                minRows: 3,
                maxRows: 5,
              }}
            />
            <br></br>
            Procedere?
            <div className="flex flex-row items-center custom w-full justify-end gap-2">
              <Button
                onClick={() => {
                  setOpen(false);
                  setInvalidFeedback("");
                }}
                className="bg-white"
              >
                Annulla
              </Button>
              <Button
                type="primary"
                onClick={() => setBonificoValidity(true)}
                disabled={!invalidFeedback}
                loading={loading}
              >
                Ok
              </Button>
            </div>
          </div>
        )}
      </Card>
    </StyleProvider>
  );
}

export default PreventivoBonifico;
