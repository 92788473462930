import { minHeight } from "@mui/system";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function QuillEditor({
  value,
  setValue,
  fullWidth,
  height,
  minHeight,
  placeholder,
  disabled,
}) {
  return (
    <ReactQuill
      theme="snow"
      value={value}
      onChange={(content, delta, source, editor) => {
        if (source === "user") setValue(content);
      }}
      style={{
        height: height ? height : "100px",
        borderRadius: "8px !important",
        width: fullWidth ? "100%" : "",
      }}
      disabled={disabled}
    />
  );
}
