import * as React from "react";
import moment from "moment";
import "moment/locale/it";

export default function CalendarIcon({ day, language }) {
  return (
    <>
      {moment(day).isValid() ? (
        <p class="calendar">
          {moment(day).locale(language).format("D")}{" "}
          <em>{moment(day).locale(language).format("MMMM").toUpperCase()}</em>
        </p>
      ) : (
        <p class="calendar">
          - <em>-</em>
        </p>
      )}
    </>
  );
}
