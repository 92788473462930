import { Select } from "antd";
import axios from "axios";
import jsonp from "fetch-jsonp";
import qs from "qs";
import { useState } from "react";
import { BACKEND_URL_PAY } from "../config/backendConfig";

let timeout;
let currentValue;

//const API_URL = "http://localhost:3001";
const API_URL = BACKEND_URL_PAY;

const fetch = (value, callback) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;
  const fake = () => {
    axios.get(API_URL + `select/provinces/${value}`).then((d) => {
      var result = d.data;
      if (currentValue === value) {
        const data = result.map((item) => ({
          value: item.initials,
          text: item.Column1,
        }));
        callback(data);
      }
    });
  };
  timeout = setTimeout(fake, 300);
};
const SearchInput = (props) => {
  const [data, setData] = useState([]);
  const [value, setValue] = useState();
  const handleSearch = (newValue) => {
    if (newValue) {
      fetch(newValue, setData);
    } else {
      setData([]);
    }
  };
  return (
    <Select
      showSearch
      value={props.value}
      placeholder={props.placeholder}
      style={props.style}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={(newValue) => props.onChange(newValue)}
      notFoundContent={null}
      options={(data || []).map((d) => ({
        value: d.value,
        label: d.text,
      }))}
      size={props.size ? props.size : "large"}
      className={props.className}
    />
  );
};
const ProvinceSelect = (props) => (
  <SearchInput placeholder="Provincia" {...props} />
);
export default ProvinceSelect;
