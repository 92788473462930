import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, Grid } from "@mui/material";
import { Margined } from "./partials/styles";
export default function SuccessPrices(props) {
  return (
    <Margined>
      <div className="container-success">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CheckCircleIcon sx={{ color: "#3BB90D", fontSize: "6rem" }} />
          </Grid>
          <Grid item xs={12}>
            <h3>Operazione completata!</h3>
            <p>Il tuo listino prezzi è stato creato correttamente!</p>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              sx={{ borderRadius: "9999px" }}
              onClick={() => window.location.replace("https://www.idaweb.eu")}
            >
              Torna su IdaWeb
            </Button>
          </Grid>
        </Grid>
      </div>
    </Margined>
  );
}
