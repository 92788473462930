import { Button, Modal, Spin, Input, Alert } from "antd";
import { useEffect, useState, useTransition } from "react";
import ChatBox from "../Communication/ChatBox";
import { useParams } from "react-router-dom";
import preventiviService from "../../../services/preventivi.service";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const { TextArea } = Input;

const PreventivoChatBox = ({ open, setOpen }) => {
  const { uuid, view_as_preview } = useParams();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState(false);
  const [answer, setAnswer] = useState(false);
  const [text, setText] = useState("");
  const [cantSend, setCantSend] = useState(true);
  const { t } = useTranslation("common");

  const MINUTE_MS = 60000;

  useEffect(() => {
    const interval = setInterval(() => {
      fetchMessages();
    }, MINUTE_MS);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (open) fetchMessages();
    if (!open) setAnswer(false);
  }, [open]);

  useEffect(() => {
    if (text && text.length >= 25) setCantSend(false);
    else setCantSend(true);
  }, [text]);

  const fetchMessages = async () => {
    setLoading(true);
    preventiviService.getMessagesPublic(uuid).then(
      (result) => {
        result = result.data;
        setMessages(result);
        setLoading(false);
      },
      (error) => {}
    );
  };

  const sendMessage = async () => {
    setLoadingMessage(true);
    preventiviService.sendNewMessage(uuid, { body: text }).then(
      (result) => {
        if (result.status === 200) {
          result = result.data;

          toast.success(t("header.chat.successMessageSend"));

          setLoadingMessage(false);
          setText("");
          setMessages([...messages, result]);
        } else toast.error(t("header.chat.error"));
      },
      (error) => {
        toast.error(error.response.data.ExceptionMessage);
        setLoadingMessage(false);
      }
    );
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Modal
      title={t("header.chat.modalTitle")}
      open={open}
      onCancel={handleCancel}
      className="!w-full md:!w-[700px]"
      footer={
        <div className="flex flex-row items-center justify-end">
          <Button key="back" onClick={handleCancel}>
            {t("header.chat.cancelButton")}
          </Button>
          {!answer ? (
            <Button
              type="primary"
              onClick={() => setAnswer(true)}
              className="flex flex-row items-center text-white gap-2"
              disabled={view_as_preview}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 text-white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                />
              </svg>
              {t("header.chat.okButton")}
            </Button>
          ) : (
            <Button
              type="primary"
              loading={loadingMessage}
              onClick={sendMessage}
              className="flex flex-row items-center text-white gap-2"
              disabled={cantSend}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 text-white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                />
              </svg>
              {t("header.chat.sendButton")}
            </Button>
          )}
        </div>
      }
    >
      <Spin spinning={loading}>
        <div className="flex flex-col gap-2">
          <Alert
            message={t("header.chat.disclamerMessage")}
            type="info"
            showIcon
          />
          <div className="h-full xl:max-h-[600px] overflow-auto">
            <ChatBox messages={messages} isPublic={true} />
          </div>
          {answer && (
            <div className="mt-auto mb-5 flex flex-col gap-2">
              <label>{t("header.chat.newMessagePlaceholder")}</label>
              <TextArea
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder={t("header.chat.messageTextPlaceholder")}
                autoSize={{
                  minRows: 3,
                  maxRows: 6,
                }}
                showCount
                maxLength={250}
              />
            </div>
          )}
        </div>
      </Spin>
    </Modal>
  );
};
export default PreventivoChatBox;
