import { Grid, Typography } from "@mui/material";
import React from "react";
import DynamicHeroIcon from "../../../../components/heroicons/hicon";
import pricesService from "../../../../services/prices.service";
import { BootstrapButtonForward } from "../button";
import RoomsSelectButton from "../roomsSelect";
import {
  Bolder,
  Container,
  Flex,
  Helper,
  Striked,
  Text,
  Title,
} from "../styles";
import TextInput from "../textInput";
import WizardButtons from "../wizardButtons";

export default function Step2(props) {
  const [cannotProceed, setCannotProceed] = React.useState(false);

  React.useEffect(() => {
    var cannotProceedVar = false;

    if (props.state.categories) {
      if (props.state.categories.length === 0) {
        cannotProceedVar = true;
      } else {
        props.state.categories.map((category) => {
          if (!category.name) {
            cannotProceedVar = true;
          }
          if (
            category.rooms &&
            category.rooms.filter((room) => room.is_checked).length === 0
          ) {
            cannotProceedVar = true;
            return;
          }
        });
      }
    }
    setCannotProceed(cannotProceedVar);
  }, [props.state.categories]);

  const setRoomsCategory = (id_room, i) => {
    props.setState({
      ...props.state,
      categories: props.state.categories.map((cat, index) => {
        if (index === i) {
          cat.rooms = cat.rooms.map((room) => {
            if (room.id_room === id_room) room.is_checked = !room.is_checked;
            return room;
          });
        }
        return cat;
      }),
    });
  };

  return (
    <>
      <Container>
        <Helper>
          <Title>
            ✍️ Inserisci i dettagli delle tue{" "}
            <font color="#2b5cdf">categorie</font>
          </Title>

          <Text>
            Per ogni riga scrivi il nome delle tua categoria, seleziona quante
            persone possono essere ospitate al suo interno e assegnale delle
            stanze.<br></br>
            Consigliamo di scrivere i nomi seguendo questo formato:<br></br>
            <ul>
              <li>Camera doppia</li>
              <li>Camera singola</li>
              <li>Camera matrimoniale vista lago</li>
            </ul>
            <b>
              ⚠️ Non utilizzare nomi complessi ed evita ogni articolo non
              necessario: <br></br>
            </b>
            <br></br>❌ <Striked>Una</Striked> camera doppia{" "}
            <Striked>con</Striked> vista <Striked>sul</Striked> lago
            <br></br>✅ <span>camera doppia vista lago</span>
          </Text>
        </Helper>
        <Bolder>Risposta</Bolder>
        {props.state.n_categories && (
          <>
            {[...Array(parseInt(props.state.n_categories))].map((e, i) => (
              <Grid
                container
                spacing={2}
                style={{ marginBottom: "20px" }}
                key={"cat-" + i}
              >
                <Grid item lg={6} xs={6}>
                  <TextInput
                    label={"Nome categoria " + (i + 1)}
                    value={props.state.categories[i].name}
                    containerstyles={{ marginBottom: "20px" }}
                    placeholder={"categoria " + (i + 1)}
                    onChange={(e) =>
                      props.setState({
                        ...props.state,
                        categories: props.state.categories.map(
                          (item, index) => {
                            if (index === i) item.name = e.target.value;
                            return item;
                          }
                        ),
                      })
                    }
                  />
                </Grid>
                <Grid item lg={3} xs={6}>
                  <div>
                    <span className="custom-input-label">
                      Numero di persone
                    </span>
                    <select
                      value={props.state.categories[i].nguests}
                      className="custom-input"
                      onChange={(e) => {
                        props.setState({
                          ...props.state,
                          categories: props.state.categories.map(
                            (cat, index) => {
                              if (index === i) cat.nguests = e.target.value;
                              return cat;
                            }
                          ),
                        });
                      }}
                    >
                      {[...Array(parseInt(20))].map((e, i) => (
                        <option value={i + 1} key={"guests-step2-" + i + 1}>
                          {i + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                </Grid>
                <Grid item lg={3} xs={12}>
                  <RoomsSelectButton
                    rooms={props.state.categories[i].rooms}
                    setRooms={(id_room) => setRoomsCategory(id_room, i)}
                  />
                </Grid>

                <Grid item xs={12} style={{ paddingTop: "0" }}>
                  {props.state.categories[i].rooms &&
                    props.state.categories[i].rooms.filter(
                      (room) => room.is_checked
                    ).length > 0 && (
                      <Flex className="rooms-selected">
                        <h4>Stanze incluse (Categoria {i + 1}): </h4>
                        {props.state.categories[i].rooms.map((room) => {
                          return (
                            <Flex
                              style={{ gap: "10px" }}
                              key={"room-" + room.id_room}
                            >
                              {room.is_checked && (
                                <Flex>
                                  <DynamicHeroIcon
                                    icon="CheckIcon"
                                    style={{
                                      width: "20px",
                                      color: "green",
                                    }}
                                  />
                                  <span>{room.name}</span>
                                </Flex>
                              )}
                            </Flex>
                          );
                        })}
                      </Flex>
                    )}
                </Grid>
              </Grid>
            ))}
          </>
        )}
      </Container>
      <WizardButtons
        cannotProceed={cannotProceed}
        currentStep={props.currentStep}
        previousStep={props.previousStep}
        totalSteps={props.totalSteps}
        nextStep={props.nextStep}
      />
    </>
  );
}
