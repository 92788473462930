import { Button } from "antd";
import React from "react";

function PreventivoGuestGalleryRoom({ images, setGalleryImages, setOpen }) {
  return (
    <>
      <Button
        className="bg-white flex flex-row items-center absolute top-1 right-1 z-10"
        onClick={() => {
          setGalleryImages(images, () => {
            setOpen(true);
          });
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM10.5 7.5v6m3-3h-6"
          />
        </svg>
      </Button>
    </>
  );
}

export default PreventivoGuestGalleryRoom;
