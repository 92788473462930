import React, { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HorizontalBar from "../layout/horizontalbar";
import structureService from "../../services/structure.service";
import { Button, CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import {
  BsCalendar4Week,
  BsArrowLeft,
  BsCalendar2X,
  BsCalendarCheck,
} from "react-icons/bs";
import PacketRoom from "./PacketRoom";
import DatePicker from "react-datepicker";
import PacketItem from "./PacketItem";
import PacketQuote from "./PacketQuote";

function PacketsList() {
  const { id, lang, uuid } = useParams();
  const [language, setLanguage] = React.useState(lang);
  const [isStructureLoading, setStructureLoading] = React.useState(false);
  const [structureInfo, setStructureInfo] = React.useState({
    structure_background: "",
    structure_name: "",
    structure_address: "",
    structure_location: "",
    structure_logo: "",
    structure_phone: "",
    structure_email: "",
    uuid: uuid,
  });
  const [loading, setLoading] = React.useState(true);
  const [packets, setPackets] = React.useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getStructureInfo();
    getPacketsList();
  }, []);

  const getStructureInfo = async () => {
    setStructureLoading(true);
    structureService.getStructureInfo(`${uuid}`).then(
      (result) => {
        result = result.data;
        setStructureInfo(result);
        setStructureLoading(false);
      },
      (error) => {}
    );
  };

  const getPacketsList = () => {
    setLoading(true);
    structureService.getPacketsList(lang + "/" + uuid).then((result) => {
      if (result.status === 200) {
        setPackets(result.data);
        setLoading(false);
      }
    });
  };

  return (
    <div className="flex flex-col w-full gap-6">
      <HorizontalBar
        structure_name={structureInfo.structure_name}
        structure_address={structureInfo.structure_address}
        structure_location={structureInfo.structure_location}
        structure_logo={structureInfo.structure_logo}
        structure_phone={structureInfo.structure_phone}
        structure_email={structureInfo.structure_email}
        isStructureLoading={isStructureLoading}
        uuid={structureInfo.uuid}
        key={structureInfo.uuid}
        setLanguage={setLanguage}
        language={language}
      ></HorizontalBar>

      <div className="w-full mt-16 max-w-6xl mx-auto px-8 md:px-0">
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <div className="flex flex-col gap-10 justify-center">
              <div>
                <Button
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="!font-semibold !rounded-full !px-4 !flex !flex-row !items-center !gap-2"
                >
                  <BsArrowLeft className="w-5 h-5" />
                  Torna Indietro
                </Button>
              </div>

              {packets?.map((p, i) => (
                <PacketItem
                  item={p}
                  index={i}
                  language={language}
                  hideViewAll
                  uuid={uuid}
                />
              ))}

              <PacketQuote />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default PacketsList;
