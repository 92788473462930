import ReactFlagsSelect from "react-flags-select";

import React from "react";
import { useTranslation } from "react-i18next";
import { Select, Space } from "antd";
const { Option } = Select;

function RequestLanguage() {
  const { t, i18n } = useTranslation("common");
  const handleChange = (e) => {
    i18n.changeLanguage(e);
  };
  return (
    <Select defaultValue={"it"} onChange={handleChange} optionLabelProp="label">
      <Option
        value="it"
        label={
          <div className="flex flex-row items-center gap-2">
            <img
              src="https://www.idaweb.eu/Images/Sys/flag/IT.png"
              className="w-[16px]"
            />{" "}
            <span>Italiano</span>
          </div>
        }
      >
        <div className="flex flex-row items-center gap-2">
          <img
            src="https://www.idaweb.eu/Images/Sys/flag/IT.png"
            className="w-[16px]"
          />
          Italiano
        </div>
      </Option>
      <Option
        value="en"
        label={
          <div className="flex flex-row items-center gap-2">
            <img
              src="https://www.idaweb.eu/Images/Sys/flag/UK.png"
              className="w-[16px]"
            />{" "}
            <span>English</span>
          </div>
        }
      >
        <div className="flex flex-row items-center gap-2">
          <img
            src="https://www.idaweb.eu/Images/Sys/flag/UK.png"
            className="w-[16px]"
          />{" "}
          Inglese
        </div>
      </Option>
    </Select>
  );
}

export default RequestLanguage;
