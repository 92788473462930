import React, { useState, useRef } from "react";

function BannerImagesUploader({ setCannotUpload, image, setImage }) {
  const [previewUrl, setPreviewUrl] = React.useState("");
  const fileInput = useRef(null);

  React.useEffect(() => {
    if (image) {
      setCannotUpload(false);
    } else {
      setCannotUpload(true);
      setPreviewUrl("");
    }
  }, [image]);

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleOnDrop = (event) => {
    //prevent the browser from opening the image
    event.preventDefault();
    event.stopPropagation();
    //let's grab the image file
    let imageFile = event.dataTransfer.files[0];
    handleFile(imageFile);
  };

  const handleFile = (file) => {
    //you can carry out any file validations here...
    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
  };
  return (
    <div className="bannerUpload">
      <div className="wrapper">
        {!image && (
          <div
            className="drop_zone"
            onDragOver={handleDragOver}
            onDrop={handleOnDrop}
            onClick={() => fileInput.current.click()}
          >
            <p>Clicca qui o Trascina e rilascia la tua foto qui</p>
            <input
              type="file"
              accept="image/*"
              ref={fileInput}
              hidden
              onChange={(e) => handleFile(e.target.files[0])}
            />
          </div>
        )}

        {previewUrl && (
          <div className="image">
            <img src={previewUrl} alt="image" className="rounded-lg" />
            <span> {image && image.name} </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default BannerImagesUploader;
