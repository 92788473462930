import "./App.css";
import "./App.scss";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Search from "./pages/search/search";
import Payment from "./components/payment/payment";
import NotFound from "./pages/notfound/notfound";
import Success from "./pages/success/success";
import SearchBase from "./pages/search/searchBase";
import Prices from "./pages/prices/prices";
import SuccessPrices from "./pages/prices/success_prices";
import ErrorPrices from "./pages/prices/error_prices";
import ErrorPaymentBE from "./components/payment/error_payment";
import EmbedBookingEngine from "./components/embed/embed";
import PaymentCreate from "./pages/payment/PaymentCreate";
import Information from "./pages/Information";
import SuccessPage from "./pages/SuccessPage";
import ErrorPage from "./pages/ErrorPage";
import WebsitePanel from "./pages/websites/WebsitePanel";
import EditConfig from "./pages/websites/EditConfig";
import Maintenance from "./pages/websites/Maintenance";
import Preventivi from "./pages/preventivi/Preventivi";
import PreventivoEdit from "./pages/preventivi/PreventivoEdit";
import PreventivoGuest from "./pages/preventivi/Public/PreventivoGuest";
import PreventivoSuccess from "./pages/preventivi/Public/PreventivoSuccess";
import "./i18n";
import Admin from "./pages/admin/Admin";
import Unauthorized from "./pages/admin/Unauthorized";
import Websites from "./pages/admin/Websites/Websites";
import PreventivoRequest from "./pages/preventivi/Request/PreventivoRequest";
import RequestSuccess from "./pages/preventivi/Request/RequestSuccess";
import Ocr from "./pages/ocr/ocr";
import Packet from "./components/packets/Packet";
import PacketsList from "./components/packets/PacketsList";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/admin/:token" element={<Admin />} />
          <Route path="/admin/websites/:token" element={<Websites />} />
          <Route path="/unauthorized" element={<Unauthorized />} />

          <Route path="/Home/search_base/:json" element={<Search />} />
          <Route path="/Home/search1/:uuid" element={<Search />} />
          <Route path="/Home/search_base" element={<Search />} />
          <Route path="/Home/search1" element={<Search />} />
          <Route path="Home/search_base1" element={<SearchBase />} />
          <Route path="/packet/:lang/:uuid/:id" element={<Packet />} />
          <Route path="/packets-list/:lang/:uuid" element={<PacketsList />} />
          <Route path="/pay/:uuid/:link" element={<Payment />} />
          <Route path="/success/:uuid/:link" element={<Success />} />
          <Route path="/payment/error" element={<ErrorPaymentBE />} />

          <Route path="/preventivi/list/:token" element={<Preventivi />} />
          <Route
            path="/preventivo/edit/:token/:id"
            element={<PreventivoEdit />}
          />
          <Route
            path="/preventivo/edit/new/:token"
            element={<PreventivoEdit />}
          />
          <Route
            path="/public/preventivo/:uuid"
            element={<PreventivoGuest />}
          />
          <Route
            path="/public/preventivo/:uuid/:view_as_preview"
            element={<PreventivoGuest />}
          />
          <Route
            path="/public/preventivo/success/:uuid"
            element={<PreventivoSuccess />}
          />
          <Route
            path="/public/preventivo/request/:uuid"
            element={<PreventivoRequest />}
          />
          <Route
            path="/public/preventivo/request/success/:language"
            element={<RequestSuccess />}
          />

          <Route path="/website/config/:token" element={<WebsitePanel />} />
          <Route path="/website/maintenance" element={<Maintenance />} />
          <Route path="/website/config/edit/:token" element={<EditConfig />} />
          <Route
            path="/website/config/edit/:token/:id_config"
            element={<EditConfig />}
          />

          <Route path="/prices/:token" element={<Prices />} />
          <Route path="/prices/success" element={<SuccessPrices />} />
          <Route path="/prices/error" element={<ErrorPrices />} />

          <Route path="/payment/create/:token" element={<PaymentCreate />} />

          <Route path="/be/:uuid" element={<EmbedBookingEngine />} />

          <Route path="/ocr/:token" element={<Ocr />} />

          <Route path="/payment/gateway/:uuid" element={<Information />} />
          <Route path="/pay/result/success" element={<SuccessPage />} />
          <Route path="/error" element={<ErrorPage />} />

          <Route path="/404" element={<NotFound />}></Route>
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
