import * as React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import it from "date-fns/locale/it"; // the locale you want
import moment from "moment";
import "moment/locale/it";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import DynamicHeroIcon from "../heroicons/hicon";
import toast, { Toaster } from "react-hot-toast";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CalendarIcon from "../calendarIcon/calendarIcon";
import structureService from "../../services/structure.service";
import { GetLabel } from "../../languages/i18n";
import useStateCallback from "../useStateCallback";
import { Select } from "antd";

registerLocale("it", it); // register it with the name you want

export default function Calendar({
  isSearching,
  setSearching,
  isStructureLoading,
  searchBase,
  setSearchBase,
  roomsSearch,
  setRoomsSearch,
  setTax,
  tax,
  dates,
  setDates,
  language,
  lastIndex,
  setLastIndex,
  setIsLoadingMoreRooms,
}) {
  const [startDate, setStartDate] = React.useState(
    moment(searchBase.date_from, "DD/MM/YYYY").toDate()
  );
  const [endDate, setEndDate] = React.useState(
    moment(searchBase.date_to, "DD/MM/YYYY").toDate()
  );

  function getArray() {
    return [...Array(99)].map((e, i) => {
      return {
        value: i + 1,
        label: i + 1,
      };
    });
  }

  const [rooms, setRooms] = useStateCallback(searchBase.rooms);
  const [exludedDates, setExcludedDates] = React.useState([]);
  const [vexludedDates, setvExcludedDates] = React.useState([]);
  const [excludedIntervals, setExcludedIntervals] = React.useState([]);
  const [minDays, setMinDays] = React.useState([
    // {
    //     date: moment('16/08/2022', 'DD/MM/YYYY').toDate(),
    //     mindays: 4
    // }
  ]);
  const [prevVal, setPrevVal] = React.useState(
    searchBase.rooms ? searchBase.rooms.length : 1
  );
  const [promoCode, setPromoCode] = React.useState("");
  const [minDate, setMinDate] = React.useState(new Date());
  const [maxDate, setMaxDate] = React.useState(
    moment().add(2, "years").toDate()
  );
  const [isDisabled, setIsDisabled] = React.useState(false);

  React.useEffect(() => {
    if (isStructureLoading === false) {
      getRooms();
    }
  }, [isStructureLoading]);

  React.useEffect(() => {
    if (isStructureLoading === false) {
      getRooms(true);
    }
  }, [language]);

  React.useEffect(() => {
    if (lastIndex.do_new_search === true) {
      getRooms();
    }
  }, [lastIndex.do_new_search]);

  const getExcludedDates = async () => {
    if (searchBase.uuid !== null && searchBase.uuid !== "") {
      structureService.getExcludedDates(`${searchBase.uuid}`).then(
        (result) => {
          result = result.data;
          var excluded_arr = [];
          for (let i = 0; i < result.length; i++) {
            var excluded_date = result[i];
            excluded_arr.push(moment(excluded_date, "DD/MM/YYYY").toDate());
          }
          setExcludedDates(excluded_arr);

          getExcludedRanges();
        },
        (error) => {}
      );
    }
  };

  const getExcludedRanges = async () => {
    if (searchBase.uuid !== null && searchBase.uuid !== "") {
      structureService.getExcludedRanges(`${searchBase.uuid}`).then(
        (result) => {
          result = result.data;

          var excluded_arr = [];
          for (let i = 0; i < result.length; i++) {
            var excluded_date = result[i];
            excluded_arr.push({
              start: moment(excluded_date.date_from).toDate(),
              end: moment(excluded_date.date_to).toDate(),
            });
          }
          setExcludedIntervals(excluded_arr);
        },
        (error) => {}
      );
    }
  };

  const fetchTax = async () => {
    if (searchBase.date_from && searchBase.date_to) {
      var date_1 = searchBase.date_from;
      var date_2 = searchBase.date_to;

      if (date_1.includes("-")) {
        date_1 = moment(date_1, "MM-DD-YYYY").format("DD/MM/YYYY");
        date_2 = moment(date_2, "MM-DD-YYYY").format("DD/MM/YYYY");
      }

      var search_tax = {
        date_from: date_1,
        date_to: date_2,
      };
      structureService.getStructureTax(searchBase.uuid, search_tax).then(
        (result) => {
          result = result.data;
          setTax(result);
        },
        (error) => {}
      );
    }
  };

  const getRooms = async (new_search) => {
    if (searchBase.uuid !== null && searchBase.uuid !== "") {
      let newSearchBase = searchBase;
      newSearchBase = {
        ...newSearchBase,
        date_from: moment(startDate).format("DD/MM/YYYY"),
        date_to: moment(endDate).format("DD/MM/YYYY"),
        promo_code: promoCode,
        rooms: rooms.map((room) => {
          if (Array.isArray(room.childs)) room.childs = room.childs.length;
          else room.childs = parseInt(room.childs);
          return room;
        }),
        lang: language,
      };
      setSearchBase(newSearchBase);
      var lastIndexVar = !new_search ? lastIndex.lastIndex : 0;

      if (lastIndexVar === 0) setSearching(true);
      else setIsLoadingMoreRooms(true);

      structureService
        .getRooms(`${newSearchBase.uuid}/${lastIndexVar}`, newSearchBase)
        .then(
          (result) => {
            result = result.data;
            console.log(result);
            var oldRooms = new_search ? [] : roomsSearch;
            result.map((res) => {
              oldRooms.push(res);
            });
            setRoomsSearch(oldRooms);

            if (result.length > 0) {
              setLastIndex({
                lastIndex: result[0].lastIndex,
                has_more_rooms: result[0].has_more_rooms,
                do_new_search: false,
              });
            }
            setIsLoadingMoreRooms(false);
            setSearching(false);
            getExcludedDates();
            fetchTax();
          },
          (error) => {}
        );
    }
  };

  const onChange = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    setDates({ start_date: start, end_date: end });

    // disable today
    if (end !== null) setMinDate(moment().toDate());
    else setMinDate(moment(start).add(1, "days").toDate());

    if (start === null || end === null) setIsDisabled(true);
    else setIsDisabled(false);

    if (start !== null)
      setSearchBase({
        ...searchBase,
        date_from: moment(start).format("DD/MM/YYYY"),
      });
    if (end !== null)
      setSearchBase({
        ...searchBase,
        date_to: moment(end).format("DD/MM/YYYY"),
      });

    // var is_invalid = false;
    // if(end !== null){
    //     for(let i=0;i<exludedDates.length;i++){
    //         for (var m = moment(start); m.isBefore(moment(end)); m.add(1, 'days')) {
    //             if (m.format('DD/MM/YYYY') === moment(exludedDates[i]).format('DD/MM/YYYY')){
    //                 is_invalid = true;
    //                 break;
    //             }
    //         }
    //         if (is_invalid)
    //             break;
    //     }
    // }

    // if (is_invalid === false){
    //     setStartDate(start);
    //     setEndDate(end);

    //     // Giorni minimi
    //     for(let i=0;i<minDays.length;i++){
    //         const current_day = minDays[i];
    //         if(moment(current_day.date).format('DD/MM/YYYY') === moment(start).format('DD/MM/YYYY')){

    //             var a = moment(current_day.date);
    //             var b = moment(current_day.date).add(current_day.mindays, 'days');

    //             // Salvo le chiusure standard
    //             var excluded_old = exludedDates;
    //             var excluded_new = [];
    //             for (var m = moment(a); m.isBefore(b); m.add(1, 'days')) {
    //                 excluded_new.push(moment(m,'DD/MM/YYYY').toDate());
    //             }
    //             for(let j=0;j<exludedDates.length;j++){
    //                 excluded_new.push(exludedDates[j]);
    //             }
    //             setvExcludedDates(excluded_old);
    //             setExcludedDates(excluded_new);
    //             break;
    //         }
    //     }

    //     if(end !== null){
    //         if (vexludedDates.length > 0){
    //             setExcludedDates(vexludedDates);
    //             setvExcludedDates([]);
    //         }
    //     }
    // }else{
    //     setStartDate(new Date());
    //     setEndDate(moment().add(2, 'days').toDate())

    //     toast.error("Le date selezionate non sono valide in quanto alcuni giorni dell'intervallo selezionato non sono validi!", {
    //         position: "bottom-right"
    //     });
    // }
  };

  const renderDayContents = (day, date) => {
    var day_component = null;
    for (let i = 0; i < minDays.length; i++) {
      const dateMin = minDays[i];
      if (
        moment(dateMin.date).format("DD/MM/YYYY") ===
        moment(date).format("DD/MM/YYYY")
      ) {
        day_component = (
          <>
            <span className="day-date span">{day}</span>
            <span class="dp-note span">
              <Tooltip title={`Giorni minimi ` + dateMin.mindays}>
                <div class="min-stay span">{dateMin.mindays}</div>
              </Tooltip>
            </span>
          </>
        );
        break;
      }
    }
    if (day_component === null) {
      day_component = (
        <>
          <span className="day-date span">{day}</span>
        </>
      );
    }
    return day_component;
  };

  const changeRoomsNumber = (e) => {
    const currentVal = e;
    var nrooms = [];
    for (let nr = 0; nr < currentVal; nr++) {
      nrooms.push({
        id: nr,
        name: "Camera " + (rooms.length + 1),
        adults: nr > 0 ? 1 : 2,
        childs: 0,
      });
    }
    setRooms(nrooms);
  };

  const setRoomAdults = (e, id) => {
    const newRooms = rooms.map((room) => {
      if (room.id === id) {
        return { ...room, adults: e.target.value };
      }
      return room;
    });
    setRooms(newRooms);
  };

  const setRoomChildren = (e, id) => {
    const newRooms = rooms.map((room) => {
      if (room.id === id) {
        return { ...room, childs: parseInt(e.target.value) };
      }
      return room;
    });
    setRooms(newRooms);
  };

  const handlePromoCodeChange = (e) => {
    setPromoCode(e.target.value);
  };

  const Search = () => {
    getRooms(true);
  };

  // const isWeekday = (date) => {
  //   const day = date.getDay();
  //   return day !== 1 && day !== 2 && day !== 3 && day !== 4 && day !== 5;
  // };

  return (
    <>
      <Grid container sx={{ marginBottom: "10px" }} spacing={1}>
        <Grid item xs={4}>
          <Card
            sx={{
              borderRadius: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "150px",
            }}
          >
            <CardContent>
              <h4 className="calendar-label">
                {GetLabel(language, "LABEL_CALENDAR_ARRIVO")}
              </h4>
              <CalendarIcon day={startDate} language={language} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card
            sx={{
              borderRadius: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "150px",
            }}
          >
            <CardContent>
              <h4 className="calendar-label">
                {GetLabel(language, "LABEL_CALENDAR_PARTENZA")}
              </h4>
              <CalendarIcon day={endDate} language={language} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card
            sx={{
              borderRadius: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "150px",
            }}
          >
            <CardContent>
              <h4 className="calendar-label nights">
                {!isNaN(moment(endDate).diff(startDate, "days"))
                  ? moment(endDate).diff(startDate, "days")
                  : "-"}{" "}
                {GetLabel(language, "LABEL_CALENDAR_NOTTI")}
              </h4>
              <div class="weather">
                <div id="star1" class="star"></div>
                <div id="moon"></div>
                <div id="star2" class="star"></div>
                <div id="star3" class="star"></div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Card sx={{ borderRadius: "10px" }}>
        <CardContent className="searchbox-datepicker">
          <DatePicker
            minDate={minDate}
            maxDate={maxDate}
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            disabledKeyboardNavigation
            renderDayContents={renderDayContents}
            excludeDates={exludedDates}
            endDate={endDate}
            selectsRange
            locale={language.toLowerCase()}
            inline
            excludeDateIntervals={excludedIntervals}
            // filterDate={isWeekday}
          />

          <Grid container>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                margin: "0.4rem",
              }}
            >
              <label className="search-label-form">
                {GetLabel(language, "LABEL_SEARCH_CAMERE")}
              </label>
              <Select
                defaultValue="1"
                style={{
                  width: 120,
                }}
                options={getArray()}
                value={rooms.length}
                onChange={changeRoomsNumber}
                disabled={isSearching}
                className="rounded-input"
              />
              {/* <TextField
                type="number"
                InputProps={{
                  inputProps: {
                    max: 100,
                    min: 1,
                  },
                }}
                size="small"
                value={rooms.length}
                onChange={changeRoomsNumber}
                disabled={isSearching}
                className="rounded-input"
              /> */}
            </Grid>
            <Grid item xs={6}></Grid>

            <Grid item xs={12}>
              {rooms.map((room, index) => {
                return (
                  <Grid
                    container
                    spacing={0}
                    sx={{ mt: 2, marginLeft: "10px" }}
                  >
                    <Grid
                      item
                      xs={4}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      {GetLabel(language, "LABEL_SEARCH_ROOM_PLACEHOLDER")}{" "}
                      {index + 1}
                    </Grid>
                    <Grid item xs={4}>
                      <label className="search-label-form">
                        {GetLabel(language, "LABEL_SEARCH_ADULTI")}
                      </label>
                      <TextField
                        type="number"
                        InputProps={{
                          inputProps: {
                            max: 100,
                            min: rooms.length === 1 ? 1 : 0,
                          },
                        }}
                        size="small"
                        onChange={(e) => setRoomAdults(e, room.id)}
                        value={room.adults}
                        disabled={isSearching}
                        className="rounded-input"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <label className="search-label-form">
                        {GetLabel(language, "LABEL_SEARCH_BAMBINI")}
                      </label>
                      <TextField
                        type="number"
                        InputProps={{
                          inputProps: {
                            max: 100,
                            min: 0,
                          },
                        }}
                        size="small"
                        onChange={(e) => setRoomChildren(e, room.id)}
                        value={
                          Array.isArray(room.childs)
                            ? room.childs.length
                            : parseInt(room.childs)
                        }
                        disabled={isSearching}
                        className="rounded-input"
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>

            <Grid item xs={12}>
              <LoadingButton
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                className="btn-primary"
                loading={isSearching}
                onClick={Search}
                loadingPosition="start"
                disabled={
                  !isDisabled
                    ? rooms?.filter((el) => el.adults > 0).length >= 1
                      ? false
                      : true
                    : isDisabled
                }
              >
                {isSearching === false
                  ? GetLabel(language, "LABEL_SEARCH_BUTTON")
                  : GetLabel(language, "LABEL_SEARCH_BUTTON_SEARCHING")}
              </LoadingButton>
            </Grid>

            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    sx={{
                      display: "flex",
                      gap: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DynamicHeroIcon
                      icon={"TicketIcon"}
                      className="promo-icon"
                    ></DynamicHeroIcon>{" "}
                    {GetLabel(language, "LABEL_SEARCH_COUPON_CODE")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    size="small"
                    onChange={handlePromoCodeChange}
                    value={promoCode}
                    disabled={isSearching}
                    className="rounded-input"
                    placeholder={GetLabel(
                      language,
                      "LABEL_SEARCH_PLACEHOLDER_COUPON"
                    )}
                    sx={{ width: "100%" }}
                  />
                </AccordionDetails>
              </Accordion>
              <img
                src="https://d3jrq3tjjnb829.cloudfront.net/1530029297_comodo-secure-logo-new.png"
                className="sponsor"
              ></img>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Toaster
        toastOptions={{
          duration: 8000,
        }}
      />
    </>
  );
}
