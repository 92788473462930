import React from "react";
import {
  Alert,
  Button,
  Divider,
  Form,
  Tooltip,
  Typography,
  Modal,
  Input,
  Spin,
  Popconfirm,
  Popover,
} from "antd";
import { Card } from "antd";
import ConfirmHelper from "../Helpers/ConfirmHelper";
import PreventivoCardRow from "./PreventivoCardRow";
const { Paragraph } = Typography;

function PreventivoMessaggiConferma({
  preventivo,
  setPreventivo,
  UpdatePreventivo,
}) {
  return (
    <Card
      title={
        <div className="flex flex-row items-center gap-3">
          Messaggi di conferma e di comunicazione{" "}
          <span className="text-gray-500 font-normal text-sm">
            (Opzioni Avanzate)
          </span>
          <ConfirmHelper />
        </div>
      }
      bordered={false}
    >
      <Form layout="vertical">
        <PreventivoCardRow
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 text-slate-600"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
              />
            </svg>
          }
          label={"Messaggio di conferma web"}
          text={
            <Paragraph
              editable={{
                autoSize: true,
                tooltip: "Modifica il messaggio di conferma online",
                text: preventivo.confirm_message,
                onChange: (new_value) => {
                  UpdatePreventivo("10", new_value);
                },
              }}
              className="flex flex-row items-center"
            >
              {preventivo.confirm_message ? (
                <div className="text-slate-600">
                  {preventivo.confirm_message}
                </div>
              ) : (
                <div className="flex flex-row items-center gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 text-slate-400"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                    />
                  </svg>
                  <span className="text-slate-400">
                    Nessun messaggio di conferma inserito
                  </span>
                </div>
              )}
            </Paragraph>
          }
          value={preventivo.confirm_message}
          className={"!mb-5"}
        />

        <PreventivoCardRow
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 text-slate-600"
            >
              <path
                stroke-linecap="round"
                d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25"
              />
            </svg>
          }
          label={"Messaggio di conferma email"}
          text={
            <Paragraph
              editable={{
                autoSize: true,
                tooltip: "Modifica il messaggio di conferma nell'email",
                text: preventivo.confirm_message_email,
                onChange: (new_value) => {
                  UpdatePreventivo("11", new_value);
                },
              }}
              className="flex flex-row items-center"
            >
              {preventivo.confirm_message_email ? (
                <div className="text-slate-600">
                  {preventivo.confirm_message_email}
                </div>
              ) : (
                <div className="flex flex-row items-center gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 text-slate-400"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                    />
                  </svg>
                  <span className="text-slate-400">
                    Nessun messaggio di conferma email inserito
                  </span>
                </div>
              )}
            </Paragraph>
          }
          value={preventivo.confirm_message_email}
          className={"!mb-5"}
        />

        <PreventivoCardRow
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 text-slate-600"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z"
              />
            </svg>
          }
          label={"Oggetto standard dei messaggi"}
          text={
            <Paragraph
              editable={{
                autoSize: true,
                tooltip: "Modifica l'oggetto standard della mail",
                text: preventivo.subject,
                onChange: (new_value) => {
                  UpdatePreventivo("13", new_value);
                },
              }}
              className="flex flex-row items-center"
            >
              {preventivo.subject ? (
                <div className="text-slate-600">{preventivo.subject}</div>
              ) : (
                <div className="flex flex-row items-center gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 text-slate-400"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                    />
                  </svg>
                  <span className="text-slate-400">
                    Nessun oggetto standard inserito
                  </span>
                </div>
              )}
            </Paragraph>
          }
          value={preventivo.subject}
          className={"!mb-5"}
        />

        <PreventivoCardRow
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 text-slate-600"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
              />
            </svg>
          }
          label={"Corpo standard dei messaggi"}
          text={
            <Paragraph
              editable={{
                autoSize: true,
                tooltip: "Modifica il corpo standard della mail",
                text: preventivo.body,
                onChange: (new_value) => {
                  UpdatePreventivo("14", new_value);
                },
              }}
              className="flex flex-row items-center"
            >
              {preventivo.body ? (
                <div className="text-slate-600">{preventivo.body}</div>
              ) : (
                <div className="flex flex-row items-center gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 text-slate-400"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                    />
                  </svg>
                  <span className="text-slate-400">
                    Nessun corpo email standard inserito
                  </span>
                </div>
              )}
            </Paragraph>
          }
          value={preventivo.body}
        />
      </Form>
    </Card>
  );
}

export default PreventivoMessaggiConferma;
