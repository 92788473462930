import axios from "axios";
import { BACKEND_URL } from "../config/backendConfig";

class preventiviService {
  getPreventivi(params) {
    return axios.get(BACKEND_URL + "qte/preventivi/" + params);
  }
  getPreventivo(params) {
    return axios.get(BACKEND_URL + "qte/preventivo/" + params);
  }
  getCategories(token) {
    return axios.get(BACKEND_URL + "web/admin/categories/" + token + "/IT");
  }
  getPriceCategory(token, id_category) {
    return axios.get(
      BACKEND_URL + "web/admin/categories/price/" + token + "/" + id_category
    );
  }
  getDisponibilityCategory(token, id_category, disponibility) {
    return axios.post(
      BACKEND_URL + "qte/category/disponibility/" + token + "/" + id_category,
      disponibility
    );
  }
  getProductsList(token) {
    return axios.get(BACKEND_URL + "qte/products/" + token);
  }
  getMessages(token, id_preventivo) {
    return axios.get(
      BACKEND_URL + "qte/messages/" + token + "/" + id_preventivo
    );
  }
  getCustomersFilter(params) {
    return axios.get(BACKEND_URL + "qte/customers/" + params);
  }
  getFirmsFilter(params) {
    return axios.get(BACKEND_URL + "qte/firms/" + params);
  }
  saveNewCustomer(token, data) {
    return axios.post(BACKEND_URL + "qte/new/customer/" + token, data);
  }
  patchPreventivo(params, data) {
    return axios.patch(BACKEND_URL + "qte/preventivo/" + params, data);
  }
  saveNewRoomRow(token, data) {
    return axios.post(
      BACKEND_URL + "qte/preventivo/create/room/" + token,
      data
    );
  }
  deleteRowRoom(params) {
    return axios.delete(BACKEND_URL + "qte/preventivo/delete/room/" + params);
  }
  patchRowDates(token, data) {
    return axios.patch(
      BACKEND_URL + "qte/preventivo/create/room/" + token,
      data
    );
  }
  saveNewProductRow(token, data) {
    return axios.post(
      BACKEND_URL + "qte/preventivo/create/product/" + token,
      data
    );
  }
  patchRowProduct(token, data) {
    return axios.patch(
      BACKEND_URL + "qte/preventivo/create/product/" + token,
      data
    );
  }
  sendMessage(token, message) {
    return axios.post(BACKEND_URL + "qte/preventivo/message/" + token, message);
  }
  deletePreventivo(params) {
    return axios.delete(BACKEND_URL + "qte/preventivo/" + params);
  }
  saveEmptyPreventivo(token) {
    return axios.get(BACKEND_URL + "qte/preventivo/create/empty/" + token);
  }
  getTemplates(token) {
    return axios.get(BACKEND_URL + "qte/preventivo/templates/" + token);
  }
  useTemplateModel(params) {
    return axios.get(BACKEND_URL + "qte/preventivo/use/template/" + params);
  }
  duplicatePreventivo(params, data) {
    return axios.post(BACKEND_URL + "qte/preventivo/duplicate/" + params, data);
  }
  getPreventivoUrl(params) {
    return axios.get(BACKEND_URL + "qte/preventivo/url/" + params);
  }
  getPreventivoPublic(params) {
    return axios.get(BACKEND_URL + "qte/public/preventivo/" + params);
  }
  getStructureInfo(uuid) {
    return axios.get(BACKEND_URL + "qte/public/structure/info/" + uuid);
  }
  getMessagesPublic(uuid) {
    return axios.get(BACKEND_URL + "qte/public/chat/" + uuid);
  }
  sendNewMessage(uuid, data) {
    return axios.post(BACKEND_URL + "qte/message/" + uuid, data);
  }
  refusePreventivo(uuid, feedback) {
    return axios.patch(
      BACKEND_URL + "qte/public/preventivo/refuse/" + uuid,
      feedback
    );
  }
  confirmPreventivo(uuid, data) {
    return axios.post(
      BACKEND_URL + "qte/public/preventivo/confirm/" + uuid,
      data
    );
  }
  getConfirmPreventivo(uuid) {
    return axios.get(BACKEND_URL + "qte/public/preventivo/confirm/" + uuid);
  }
  getCustomerData(params) {
    return axios.get(BACKEND_URL + "qte/preventivo/customer/data/" + params);
  }
  saveTemplate(id_preventivo, token, data) {
    return axios.post(
      BACKEND_URL +
        "qte/preventivo/new/template/" +
        token +
        "/" +
        id_preventivo,
      data
    );
  }
  getMessagePreview(token, data) {
    return axios.post(
      BACKEND_URL + "qte/preventivo/message/preview/" + token,
      data
    );
  }
  getPaymentMethods(token) {
    return axios.get(BACKEND_URL + "qte/preventivo/payment/methods/" + token);
  }
  setInvalidBonifico(token, transfer) {
    return axios.post(BACKEND_URL + "qte/transfer/invalid/" + token, transfer);
  }
  setInvalidCard(token, card) {
    return axios.post(BACKEND_URL + "qte/card/invalid/" + token, card);
  }
  deleteRoomsPreventivo(params) {
    return axios.delete(BACKEND_URL + "qte/preventivo/delete/rooms/" + params);
  }
  deleteTemplateModel(params) {
    return axios.delete(
      BACKEND_URL + "qte/preventivo/delete/template/" + params
    );
  }
  getPaymentExtreme(uuid) {
    return axios.get(
      BACKEND_URL + "qte/public/preventivo/transfer/details/" + uuid
    );
  }
  confirmPreventivoRequest(uuid, data) {
    return axios.post(
      BACKEND_URL + "qte/public/preventivo/request/confirm/" + uuid,
      data
    );
  }
  checkPreventivoModule(uuid) {
    return axios.get(
      BACKEND_URL + "qte/public/preventivo/module/check/" + uuid
    );
  }
  getPreventivoRequestCategories(uuid, language) {
    return axios.get(
      BACKEND_URL + "qte/public/preventivo/categories/" + uuid + "/" + language
    );
  }
  getPreventivoRequestServices(uuid, language) {
    return axios.get(
      BACKEND_URL + "qte/public/preventivo/services/" + uuid + "/" + language
    );
  }
  getRequests(uuid) {
    return axios.get(BACKEND_URL + "qte/preventivo/requests/" + uuid);
  }
  getRequest(uuid, id_request) {
    return axios.get(
      BACKEND_URL + "qte/preventivo/request/" + uuid + "/" + id_request
    );
  }
  answerRequest(uuid, id_request) {
    return axios.post(
      BACKEND_URL + "qte/preventivo/request/answer/" + uuid + "/" + id_request
    );
  }
  deleteRequest(uuid, id_request) {
    return axios.delete(
      BACKEND_URL + "qte/preventivo/request/" + uuid + "/" + id_request
    );
  }
  getPreventivoAlerts(params) {
    return axios.get(BACKEND_URL + "qte/preventivo/request/alerts/" + params);
  }
  deletePreventivoAlert(token, id_preventivo_alert) {
    return axios.delete(
      BACKEND_URL +
        "qte/preventivo/request/alerts/" +
        token +
        "/" +
        id_preventivo_alert
    );
  }
  getStructurePublicInfo(uuid) {
    return axios.get(BACKEND_URL + "structure/" + uuid);
  }
}
export default new preventiviService();
