import { Button, Card, Empty, Tooltip } from "antd";
import React from "react";
import PreventivoRoomRow from "./PreventivoRoomRow";
import PreventivoProductRow from "./PreventivoProductRow";
import NewRowRoom from "./NewRow/NewRowRoom";
import { useParams } from "react-router-dom";
import NewRowProduct from "./NewRow/NewRowProduct";
import LockedAction from "./Helpers/LockedAction";

function PreventivoRoom({ preventivo, setPreventivo }) {
  const { token } = useParams();
  const [openNewRowRoom, setOpenNewRowRoom] = React.useState(false);
  const [openNewRowProduct, setOpenNewRowProduct] = React.useState(false);
  const [subtotal, setSubtotal] = React.useState(0);
  function getTotal() {
    var total = 0.0;
    preventivo.rows?.map((el) => {
      total += parseFloat(el.price);
    });
    setSubtotal(total);
  }
  React.useEffect(() => {
    getTotal();
  }, [preventivo.rows]);
  return (
    <Card
      title="Offerte del preventivo"
      bordered={false}
      className="max-h-[500px] overflow-y-auto"
    >
      <NewRowRoom
        open={openNewRowRoom}
        setOpen={setOpenNewRowRoom}
        preventivo={preventivo}
        setPreventivo={setPreventivo}
      />

      <NewRowProduct
        open={openNewRowProduct}
        setOpen={setOpenNewRowProduct}
        preventivo={preventivo}
        setPreventivo={setPreventivo}
      />

      <div className="w-full flex flex-col xl:flex-row items-center gap-3">
        {preventivo.status === 2 ||
        preventivo.status === 3 ||
        preventivo.status === 4 ? (
          <LockedAction />
        ) : (
          <>
            <div>
              <Button
                type="dashed"
                className="!flex !flex-row !items-center gap-2 my-3"
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 !m-0"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819"
                    />
                  </svg>
                }
                onClick={() => setOpenNewRowRoom(true)}
                disabled={!preventivo.id_customer && !preventivo.id_firm}
              >
                Aggiungi Camera
              </Button>
            </div>
            <div>
              <Button
                type="dashed"
                className="!flex !flex-row !items-center gap-2 my-3"
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 !m-0"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21 7.5l-2.25-1.313M21 7.5v2.25m0-2.25l-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3l2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75l2.25-1.313M12 21.75V19.5m0 2.25l-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25"
                    />
                  </svg>
                }
                onClick={() => setOpenNewRowProduct(true)}
                disabled={!preventivo.id_customer && !preventivo.id_firm}
              >
                Aggiungi Prodotto
              </Button>
            </div>
          </>
        )}

        <div className="xl:ml-auto text-lg flex flex-col xl:flex-row items-center gap-3">
          <div className="text-slate-400 text-sm">Totale preventivo:&nbsp;</div>
          <div className="text-orange-500 text-2xl font-semibold">
            {parseFloat(subtotal).toFixed(2)} &euro;
          </div>
        </div>
      </div>
      {preventivo.rows?.map((row, index) => (
        <div className="mt-5" key={"row-product-" + index}>
          {row.id_category ? (
            <PreventivoRoomRow
              index={index}
              row={row}
              preventivo={preventivo}
              setPreventivo={setPreventivo}
              token_str={token}
            />
          ) : (
            <PreventivoProductRow
              index={index}
              row={row}
              preventivo={preventivo}
              setPreventivo={setPreventivo}
              token_str={token}
            />
          )}
        </div>
      ))}

      {preventivo.rows?.length === 0 && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={"Non ci sono righe in questo preventivo..."}
        >
          {!preventivo.id_customer && !preventivo.id_firm ? (
            <span className="text-gray-400 flex flex-row items-center w-full justify-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5 text-yellow-500"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18"
                />
              </svg>
              Per aggiungerne devi prima collegare il preventivo a un cliente o
              azienda
            </span>
          ) : (
            <>
              {!preventivo.status === 2 ||
                !preventivo.status === 3 ||
                (!preventivo.status === 4 && (
                  <Button type="link" onClick={() => setOpenNewRowRoom(true)}>
                    Aggiungine una
                  </Button>
                ))}
            </>
          )}
        </Empty>
      )}
    </Card>
  );
}

export default PreventivoRoom;
