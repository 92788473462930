import React from "react";
import { Bolder, Container, Helper, Radio, Text, Title } from "../styles";
import TextInput from "../textInput";
import WizardButtons from "../wizardButtons";
import YesNoSwitch from "../yesno";

export default function Step5(props) {
  const [cannotProceed, setCannotProceed] = React.useState(false);

  React.useEffect(() => {}, []);

  return (
    <>
      <Container>
        <Helper>
          <Title>🛎️ Quale tipo di trattamento offri?</Title>
          <Text>
            Seleziona di seguito il tipo di trattamento che offri nella tua
            struttura
          </Text>
        </Helper>
        <Bolder>Risposta</Bolder>

        <Radio>
          <label className="form-control">
            <input
              type="radio"
              value={934}
              name="trattamento"
              checked={props.state.trattamento === 934}
              onChange={(e) =>
                props.setState({
                  ...props.state,
                  trattamento: parseInt(e.target.value),
                })
              }
            />
          </label>
          <span>Pernottamento</span>
        </Radio>
        <Radio>
          <label className="form-control">
            <input
              type="radio"
              value={935}
              name="trattamento"
              checked={props.state.trattamento === 935}
              onChange={(e) =>
                props.setState({
                  ...props.state,
                  trattamento: parseInt(e.target.value),
                })
              }
            />
          </label>
          <span>Con colazione (B&B)</span>
        </Radio>
        <Radio>
          <label className="form-control">
            <input
              type="radio"
              value={936}
              name="trattamento"
              checked={props.state.trattamento === 936}
              onChange={(e) =>
                props.setState({
                  ...props.state,
                  trattamento: parseInt(e.target.value),
                })
              }
            />
          </label>
          <span>Mezza Pensione</span>
        </Radio>
        <Radio>
          <label className="form-control">
            <input
              type="radio"
              value={937}
              name="trattamento"
              checked={props.state.trattamento === 937}
              onChange={(e) =>
                props.setState({
                  ...props.state,
                  trattamento: parseInt(e.target.value),
                })
              }
            />
          </label>
          <span>Pensione Completa</span>
        </Radio>
      </Container>
      <WizardButtons
        cannotProceed={cannotProceed}
        currentStep={props.currentStep}
        previousStep={props.previousStep}
        totalSteps={props.totalSteps}
        nextStep={props.nextStep}
      />
    </>
  );
}
