import React from "react";
import webService from "../../services/web.service";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import LoadingIndicator from "../../components/LoadingIndicator";
import BannerAddImage from "./BannerImagesAdd";
import GalleryList from "./GalleryList";

function Gallery({ language }) {
  const { token, id_config } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [images, setImages] = React.useState([]);

  React.useEffect(() => {
    if (id_config) fetchImages();
  }, []);

  const fetchImages = async () => {
    setLoading(true);
    webService.getGalleryImages(token, id_config).then(
      (result) => {
        const status = result.status;
        if (status === 200) {
          setImages(result.data);
        } else {
          toast.error("Si è verificato un errore");
        }
        setLoading(false);
      },
      (error) => {
        toast.error("Si è verificato un errore");
        setLoading(false);
      }
    );
  };

  const deleteImage = async (id_gallery) => {
    webService.deleteGalleryImage(token, id_gallery).then(
      (result) => {
        const status = result.status;
        if (status === 200) {
          setImages(images.filter((el) => el.id_gallery !== id_gallery));
          toast.success("La foto è stata rimossa.");
        } else {
          toast.error("Si è verificato un errore");
        }
      },
      (error) => {
        toast.error("Si è verificato un errore");
      }
    );
  };

  const SingleImage = ({ image }) => (
    <div className="rounded-lg bg-white shadow p-5">
      <div className="flex flex-row items-center">
        <h2 className="font-semibold text-lg">Immagine #{image.id_gallery}</h2>

        <span
          className="ml-auto cursor-pointer text-indigo-600 
                px-5 py-2 text-base font-medium 
                 hover:text-indigo-700"
          onClick={() => {
            if (
              window.confirm(
                "Eliminare la foto selezionata? La foto rimarrà comunque nella tua galleria di IdaWeb"
              )
            ) {
              deleteImage(image.id_gallery);
            }
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </span>
      </div>
      <div className="mt-5">
        <img
          src={"https://www.idaweb.eu" + image.filepath}
          alt={"gallery-" + image.id_gallery}
          className="object-cover w-92 h-72 rounded-lg mt-5"
          loading="lazy"
        />
      </div>
    </div>
  );
  return (
    <>
      <GalleryList
        open={open}
        setOpen={setOpen}
        images={images}
        setImages={setImages}
      />
      {loading ? (
        <div className="w-full h-full flex items-center justify-center">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <div className="bg-gray-100">
            <div className="pt-5 pr-5 w-full flex justify-end">
              <button
                className={`rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                onClick={() => setOpen(true)}
                type="button"
              >
                Aggiungi Immagini
              </button>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5 p-5">
              {images?.map((image, index) => (
                <SingleImage image={image} key={"image-" + index} />
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Gallery;
