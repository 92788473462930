import React from "react";
import { Button, Form, Spin, Card, Tooltip } from "antd";
import ChatBox from "./Communication/ChatBox";
import NewMessage from "./Communication/NewMessage";
import preventiviService from "../../services/preventivi.service";
import { useParams } from "react-router-dom";

function PreventivoCommunication({ preventivo, setPreventivo }) {
  const [openNewMessage, setOpenNewMessage] = React.useState(false);

  const { token } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [messages, setMessages] = React.useState([]);

  React.useEffect(() => {
    if (preventivo.id_preventivo && token) fetchMessages();
    else setLoading(false);
  }, [preventivo.id_preventivo]);

  const fetchMessages = async () => {
    setLoading(true);
    preventiviService.getMessages(token, preventivo.id_preventivo).then(
      (result) => {
        result = result.data;
        setMessages(result);
        setLoading(false);
      },
      (error) => {}
    );
  };

  return (
    <Card title="Bacheca Messaggi" bordered={false}>
      <Spin spinning={loading}>
        <NewMessage
          open={openNewMessage}
          setOpen={setOpenNewMessage}
          preventivo={preventivo}
          setPreventivo={setPreventivo}
          messages={messages}
          setMessages={setMessages}
        />
        <div class="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 h-full p-4">
          <div class="flex flex-col h-full overflow-x-auto mb-4">
            <div class="flex flex-col h-full max-h-[400px]">
              <ChatBox messages={messages} setOpen={setOpenNewMessage} />
            </div>
          </div>
          {preventivo.status === 0 ||
          preventivo.status === 1 ||
          preventivo.status === 5 ? (
            <div className="mx-auto">
              <Button
                disabled={
                  preventivo.id_preventivo && preventivo.rows?.length > 0
                    ? false
                    : true
                }
                type="primary"
                onClick={() => setOpenNewMessage(true)}
                className={`shadow-md flex items-center justify-center ${
                  preventivo.id_preventivo && preventivo.rows?.length > 0
                    ? "!bg-indigo-500 hover:!bg-indigo-600"
                    : ""
                } rounded-xl text-white px-4 py-1 flex-shrink-0`}
              >
                <span>Invia Nuovo Messaggio</span>
                <span class="ml-2">
                  <svg
                    class="w-4 h-4 transform rotate-45 -mt-px"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                    ></path>
                  </svg>
                </span>
              </Button>
            </div>
          ) : (
            <Tooltip title="Se il preventivo è stato rifiutato o accettato non è più possibile inviare messaggi al cliente">
              <div className="mx-auto">
                <div className="text-red-600 flex flex-row items-center gap-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                    />
                  </svg>
                  Le trattative per il preventivo sono chiuse
                </div>
              </div>
            </Tooltip>
          )}
        </div>
      </Spin>
    </Card>
  );
}

export default PreventivoCommunication;
