import React from "react";
import AuthAdmin from "../Components/Auth/AuthAdmin";
import Layout from "../Components/Layout";
import { DataGrid, itIT } from "@mui/x-data-grid";

import MenuWebsite from "./MenuWebsite";

const columns = [
  { field: "id", headerName: "ID", width: 200 },
  { field: "stucture", headerName: "Struttura", width: 200 },
  { field: "config_name", headerName: "Nome Configurazione", width: 200 },
  {
    field: "creation_date",
    headerName: "Data di creazione",
    width: 200,
  },
  {
    field: "is_in_maintenance",
    headerName: "Stato sito",
    width: 200,
  },
];

const rows = [
  {
    id: 1,
    structure: "Prova",
    config_name: "Prova config",
    creation_date: "21/10/2023",
    is_in_maintenance: false,
  },
];

function Websites() {
  return (
    <AuthAdmin>
      <Layout title="Siti web">
        <MenuWebsite />

        <div
          style={{ height: 400, width: "100%" }}
          className="h-[400px] bg-white rounded-md border-0 flex flex-col
          text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
          placeholder:text-gray-400 focus:ring-2 focus:ring-inset
          focus:ring-indigo-600 sm:text-sm sm:leading-6"
        >
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          />
        </div>
      </Layout>
    </AuthAdmin>
  );
}

export default Websites;
