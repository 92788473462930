import { useLocation } from "react-router-dom";
import React from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export default function ErrorPage() {
  const location = useLocation();
  React.useEffect(() => {
    document.title = "Oops.. qualcosa non va";
  }, []);
  return (
    <div
      className="w-screen h-screen flex flex-col 
    text-center items-center justify-center gap-5"
    >
      <ExclamationCircleOutlined style={{ color: "red", fontSize: "6rem" }} />
      <h3 className="text-3xl font-semibold uppercase text-red-600">
        {location.state.title ? location.state.error : "Errore!"}
      </h3>
      <p
        dangerouslySetInnerHTML={{
          __html: location.state.error,
        }}
      />
    </div>
  );
}
