import { Button, Drawer, Form, Input, InputNumber, Select, Spin } from "antd";
import React from "react";
import "dayjs/locale/it";
import { DatePicker } from "antd";
import { useParams } from "react-router-dom";
import preventiviService from "../../../services/preventivi.service";
import { StyleProvider } from "@ant-design/cssinjs";
import { toast } from "react-hot-toast";
import TextArea from "antd/es/input/TextArea";

const { RangePicker } = DatePicker;

const NewRowProduct = ({ open, setOpen, preventivo, setPreventivo }) => {
  const { token } = useParams();
  const [newProduct, setNewProduct] = React.useState({
    id_product: null,
    price: 0,
    striked_price: 0,
    quantity: 1,
    description: "",
  });
  const [loading, setLoading] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [products, setProducts] = React.useState([]);

  React.useEffect(() => {
    if (token) fetchProducts();
  }, []);

  React.useEffect(() => {
    if (!open) {
      setNewProduct({
        id_product: null,
        price: 0,
        striked_price: 0,
        quantity: 1,
        description: "",
      });
    }
  }, [open]);

  React.useEffect(() => {
    var disabled = false;
    if (!newProduct.id_product) disabled = true;
    if (!newProduct.description) disabled = true;
    if (newProduct.price === "") disabled = true;
    setIsDisabled(disabled);
  }, [newProduct]);

  const fetchProducts = async () => {
    setLoading(true);
    preventiviService.getProductsList(token).then(
      (result) => {
        result = result.data;
        var products_av = [];
        result.map((product) => {
          if (product.description) {
            products_av.push(product);
          }
        });
        setProducts(products_av);
        setLoading(false);
      },
      (error) => {}
    );
  };

  const onClose = () => {
    setOpen(false);
  };

  function isNumber(str) {
    if (typeof str != "string") return false; // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  }

  const saveNewProductRow = async () => {
    setLoading(true);
    const newProductObject = {
      ...newProduct,
      id_preventivo: preventivo.id_preventivo,
    };
    preventiviService.saveNewProductRow(token, newProductObject).then(
      (result) => {
        result = result.data;

        setPreventivo({
          ...preventivo,
          rows: [...preventivo.rows, result],
        });

        toast.success("Il prodotto è stato aggiunto al preventivo.");
        onClose();
        setLoading(false);
      },
      (error) => {
        toast.error(error.response.data.ExceptionMessage);
        setLoading(false);
      }
    );
  };

  return (
    <Drawer
      title={"Aggiungi un prodotto"}
      placement={"right"}
      width={500}
      onClose={onClose}
      open={open}
      footer={
        <div className="flex flex-row items-center w-full">
          <Button onClick={onClose}>Annulla</Button>
          <Button
            type="primary"
            onClick={saveNewProductRow}
            className="ml-auto"
            disabled={isDisabled}
          >
            Salva
          </Button>
        </div>
      }
    >
      <Spin spinning={loading}>
        <Form layout={"vertical"}>
          <Form.Item label="Prodotto">
            <Select
              value={newProduct.id_product}
              onChange={(e, opt) => {
                const product = products.filter((el) => el.id_product === e)[0];
                setNewProduct({
                  ...newProduct,
                  id_product: e,
                  name: product.description,
                  price: parseFloat(product.price_unit).toFixed(2),
                  description: product.description,
                });
              }}
              options={products?.map((product) => {
                return {
                  value: product.id_product,
                  label: (
                    <div className="flex flex-row items-center gap-3">
                      <span>{product.description}</span>
                      <span className="text-orange-500">
                        &euro; {parseFloat(product.price_unit).toFixed(2)}
                      </span>
                    </div>
                  ),
                };
              })}
            />
          </Form.Item>
          <Form.Item label="Quantità">
            <InputNumber
              min={1}
              max={100}
              defaultValue={1}
              onChange={(e) => {
                const product = products.filter(
                  (el) => el.id_product === newProduct.id_product
                )[0];
                setNewProduct({
                  ...newProduct,
                  quantity: e,
                  price: parseFloat(product.price_unit * e).toFixed(2),
                  striked_price: parseFloat(product.price_unit * e).toFixed(2),
                });
              }}
              className="w-full"
            />
          </Form.Item>
          <Form.Item label="Prezzo">
            <StyleProvider hashPriority="high">
              <Input
                placeholder={"0.00"}
                value={newProduct.price}
                onChange={(e) => {
                  if (isNumber(e.target.value) === false) {
                    toast.error("Sono accettati solo numeri.");
                    return;
                  }
                  setNewProduct({ ...newProduct, price: e.target.value });
                }}
              />
            </StyleProvider>
          </Form.Item>
          <Form.Item label="Prezzo Barrato">
            <StyleProvider hashPriority="high">
              <Input
                placeholder={"0.00"}
                className="line-through !text-red-600"
                value={newProduct.striked_price}
                onChange={(e) => {
                  if (isNumber(e.target.value) === false) {
                    toast.error("Sono accettati solo numeri.");
                    return;
                  }
                  setNewProduct({
                    ...newProduct,
                    striked_price: e.target.value,
                  });
                }}
              />
            </StyleProvider>
          </Form.Item>
          <Form.Item label="Descrizione">
            <TextArea
              value={newProduct.description}
              onChange={(e) =>
                setNewProduct({ ...newProduct, description: e.target.value })
              }
              placeholder="Inserisci una descrizione per il prodotto..."
              autoSize={{
                minRows: 3,
                maxRows: 6,
              }}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
};
export default NewRowProduct;
