import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { CircularProgress, Grid, LinearProgress } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import DynamicHeroIcon from "../heroicons/hicon";
import { useDropzone } from "react-dropzone";
import Dropify from "./dropzone";
import structureService from "../../services/structure.service";
import { useNavigate } from "react-router-dom";
import { GetLabel } from "../../languages/i18n";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "auto",
//   bgcolor: "background.paper",
//   boxShadow: 24,
//   p: 4,
//   borderRadius: "14px",
// };
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%" /* Adattamento della larghezza per i dispositivi mobili */,
  maxWidth: "600px" /* Larghezza massima */,
  height: "auto",
  maxHeight: "90vh" /* Altezza massima */,
  backgroundColor: "background.paper",
  boxShadow: "24px 24px 24px rgba(0, 0, 0, 0.2)",
  padding: "16px",
  borderRadius: "14px",
  overflow: "auto" /* Permette lo scrolling se il contenuto eccede l'altezza */,
  scrollbarWidth: "none",
  "-ms-overflow-style": "none" /* Nasconde la scrollbar in Internet Explorer */,
};

export default function Bonifico(props) {
  const handleClose = () => {
    props.setOpen(false);
  };
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [isCreatingBooking, setIsCreatingBooking] = React.useState(false);
  const [File, setFile] = React.useState(null);

  React.useEffect(() => {
    console.log(props.total);
  }, [props.total]);

  let navigate = useNavigate();

  const CreateBookingBonifico = (obj) => {
    setIsCreatingBooking(true);

    // crea il nuovo oggetto booking da spedire
    const save_booking_model = {
      token: props.token,
      responsabile: {
        name: props.info.name.value,
        surname: props.info.surname.value,
        email: props.info.email.value,
        phone: props.info.phone.value,
        country: props.info.country.value,
        city: props.info.city.value,
        zip_code: props.info.zip_code.value,
        address: props.info.address.value,
        note: props.info.note.value,
      },
      extras: props.extras,
    };

    let formData = new FormData();

    formData.append("booking", JSON.stringify(save_booking_model));
    formData.append("image", File);

    structureService.saveBookingBonifico(formData).then(
      (result) => {
        result = result.data;
        if (result.success === true)
          navigate("/success/" + props.uuid + "/" + props.token);
      },
      (error) => {}
    );
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        style={{ overflow: "auto", width: "auto", height: "auto" }}
      >
        <Box sx={style}>
          {props.loadingBonificoDetails ? (
            <CircularProgress />
          ) : (
            <>
              <div
                style={{
                  borderBottom: "2px dashed #ddd",
                  paddingBottom: "16px",
                }}
              >
                <h3 style={{ margin: "0" }}>
                  {GetLabel(props.language, "BT_TITLE")}
                </h3>
                <h5>{GetLabel(props.language, "BT_SUBTITLE")}</h5>
                <p>
                  {GetLabel(props.language, "BT_TEXT1")}
                  <b>{props.bonificoDetails.bonifico_day}</b>
                  {GetLabel(props.language, "BT_TEXT1_5")}
                  <br />
                  <br />
                  {GetLabel(props.language, "BT_TEXT2")}
                  <br />
                  <br />
                  <b>{GetLabel(props.language, "BT_TEXT3")}</b>:{" "}
                  {GetLabel(props.language, "BT_TEXT4")}
                  <br />
                  <br />
                  <b>{GetLabel(props.language, "BT_TEXT5")}</b>
                </p>
              </div>
              {/* <Grid
                container
                spacing={2}
                sx={{
                  marginTop: "16px",
                  marginBottom: "16px",
                  paddingBottom: "16px",
                }}
              >
                <Grid item xs={3}>
                  <b>{GetLabel(props.language, "BT_FORM_1")}</b>
                </Grid>
                <Grid item xs={9} className="leggibile">
                  {props.bonificoDetails.iban}
                </Grid>

                <Grid item xs={3}>
                  <b>{GetLabel(props.language, "BT_FORM_2")}</b>
                </Grid>
                <Grid item xs={9} className="leggibile">
                  {props.bonificoDetails.causale}
                </Grid>

                <Grid item xs={3}>
                  <b>{GetLabel(props.language, "BT_FORM_3")}</b>
                </Grid>
                <Grid item xs={9} className="leggibile">
                  {props.bonificoDetails.beneficiario}
                </Grid>

                <Grid item xs={3}>
                  <b>{GetLabel(props.language, "BT_FORM_4")}</b>
                </Grid>
                <Grid item xs={9} className="leggibile">
                  € {parseFloat(props.total).toFixed(2)}
                </Grid>
              </Grid> */}

              <Grid
                container
                spacing={2}
                sx={{
                  marginTop: "16px",
                  marginBottom: "16px",
                  paddingBottom: "16px",
                }}
              >
                <Grid item xs={12} sm={3}>
                  <b>{GetLabel(props.language, "BT_FORM_1")}</b>
                </Grid>
                <Grid item xs={12} sm={9} className="leggibile">
                  {props.bonificoDetails.iban}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <b>{GetLabel(props.language, "BT_FORM_2")}</b>
                </Grid>
                <Grid item xs={12} sm={9} className="leggibile">
                  {props.bonificoDetails.causale}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <b>{GetLabel(props.language, "BT_FORM_3")}</b>
                </Grid>
                <Grid item xs={12} sm={9} className="leggibile">
                  {props.bonificoDetails.beneficiario}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <b>{GetLabel(props.language, "BT_FORM_4")}</b>
                </Grid>
                <Grid item xs={12} sm={9} className="leggibile">
                  € {parseFloat(props.total).toFixed(2)}
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div
                    style={{ borderTop: "2px dashed #ddd", paddingTop: "16px" }}
                  >
                    <Dropify
                      setIsDisabled={setIsDisabled}
                      setFile={setFile}
                      language={props.language}
                    ></Dropify>
                  </div>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    sx={{
                      fontSize: "17px !important",
                      fontWeight: "500 !important",
                      marginLeft: "auto",
                      marginTop: "15px",
                    }}
                    className="btn-primary"
                    loading={isCreatingBooking}
                    disabled={isDisabled}
                    loadingPosition="start"
                    color="primary"
                    onClick={CreateBookingBonifico}
                  >
                    {GetLabel(props.language, "BT_FORM_5")}

                    <DynamicHeroIcon
                      icon={"ArrowRightIcon"}
                      className="input-icon"
                      style={{ color: "#FFF", marginLeft: "5px" }}
                    ></DynamicHeroIcon>
                  </LoadingButton>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
}
