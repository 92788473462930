import ErrorIcon from "@mui/icons-material/Error";
import { Button, Grid } from "@mui/material";
import { Margined } from "./partials/styles";
import { useLocation } from "react-router-dom";
import React from "react";

export default function ErrorPrices() {
  const location = useLocation();
  React.useEffect(() => {
    document.title = "Oops.. qualcosa non va";
  }, []);
  return (
    <Margined>
      <div className="container-success">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ErrorIcon sx={{ color: "red", fontSize: "6rem" }} />
          </Grid>
          <Grid item xs={12}>
            <h3>Errore!</h3>
            <p
              dangerouslySetInnerHTML={{
                __html: location.state.error,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              sx={{ borderRadius: "9999px" }}
              onClick={() => window.location.replace("https://www.idaweb.eu")}
            >
              Torna su IdaWeb
            </Button>
          </Grid>
        </Grid>
      </div>
    </Margined>
  );
}
