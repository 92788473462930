import { LinearProgress } from "@mui/material";
import * as React from "react";
import { useCallback } from "react";

import { useDropzone } from "react-dropzone";
import { GetLabel } from "../../languages/i18n";
import DynamicHeroIcon from "../heroicons/hicon";

function Dropify(props) {
  const onDropEvent = useCallback((acceptedFiles) => {
    props.setIsDisabled(false);
    props.setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({ multiple: false, onDrop: onDropEvent });

  const files = acceptedFiles.map((file) => (
    <div style={{ marginTop: "14px" }}>
      <DynamicHeroIcon
        icon={"DocumentTextIcon"}
        key={file.path}
        style={{
          width: "24px",
          height: "24px",
        }}
      ></DynamicHeroIcon>
      {file.path} - {file.size} bytes
    </div>
  ));

  return (
    <div className="dropzone-container">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />

        {isDragActive ? (
          <p className="dropzone-content">
            {GetLabel(props.language, "BT_PAY_1")}
          </p>
        ) : (
          <p className="dropzone-content">
            {GetLabel(props.language, "BT_PAY_2")}
          </p>
        )}

        <button type="button" className="dropzone-btn">
          {GetLabel(props.language, "BT_PAY_3")}
        </button>
      </div>
      <aside>
        {files.length === 1 && (
          <div className={`loaded-file ${props.classNameLabel}`}>
            <h3 style={{ ...props.styleText, marginBottom: "0" }}>
              {GetLabel(props.language, "BT_PAY_4")}
            </h3>
            <p style={{ ...props.styleText, marginTop: "0" }}>
              {GetLabel(props.language, "BT_PAY_5")}
            </p>
          </div>
        )}
      </aside>

      {/* <LinearProgress sx={{width:'100%', marginTop:'20px'}}/> */}
    </div>
  );
}

export default Dropify;
