import PreventivoCustomerSelect from "../PreventivoCustomerSelect";

export default function PreventivoCustomer({
  id_customer,
  id_firm,
  name_free,
  surname_free,
  preventivo,
  UpdatePreventivo,
  token,
}) {
  return (
    <PreventivoCustomerSelect
      id_customer={id_customer}
      id_firm={id_firm}
      text={name_free + " " + surname_free}
      token={token}
      onChange={async (newValue, is_customer) => {
        if (is_customer) await UpdatePreventivo("0", newValue);
        else await UpdatePreventivo("1", newValue);
      }}
      disabled={
        preventivo.rows?.filter((el) => el.id_booking_room)?.length > 0 ||
        preventivo.status === 2 ||
        preventivo.status === 3 ||
        preventivo.status === 4
      }
    />
  );
}
