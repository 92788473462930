import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PhotographIcon } from "@heroicons/react/outline";
import BannerImagesUploader from "./BannerImagesUploader";
import webService from "../../services/web.service";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import LoadingIndicator from "../../components/LoadingIndicator";

export default function GalleryList({ open, setOpen, images, setImages }) {
  const { token, id_config } = useParams();
  const cancelButtonRef = useRef(null);
  const [loading, setLoading] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [pictures, setPictures] = React.useState([]);

  React.useEffect(() => {
    if (id_config && open) fetchMedia();
  }, [open]);
  React.useEffect(() => {
    if (pictures?.filter((el) => el.is_checked).length > 0)
      setIsDisabled(false);
    else setIsDisabled(true);
  }, [pictures]);

  const fetchMedia = async () => {
    setLoading(true);
    webService.getMedia(token).then(
      (result) => {
        const status = result.status;
        if (status === 200) {
          setPictures(result.data);
        } else {
          toast.error("Si è verificato un errore");
        }
        setLoading(false);
      },
      (error) => {
        toast.error("Si è verificato un errore");
        setLoading(false);
      }
    );
  };

  const saveMedia = async () => {
    setIsDisabled(true);
    webService
      .saveMedia(
        token,
        id_config,
        pictures?.filter((el) => el.is_checked).map((el) => el.id)
      )
      .then(
        (result) => {
          const status = result.status;
          if (status === 200) {
            var new_images = [...images];
            result.data.map((img) => {
              new_images.push(img);
            });
            setImages(new_images);
            setOpen(false);
            toast.success("La galleria è stata salvata.");
          } else {
            toast.error("Si è verificato un errore");
          }
          setIsDisabled(false);
        },
        (error) => {
          toast.error("Si è verificato un errore");
          setIsDisabled(false);
        }
      );
  };

  const Picture = ({ picture }) => (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg relative">
      <img
        src={"https://www.idaweb.eu" + picture.filepath}
        className="w-32 h-24 object-cover"
        loading="lazy"
      />
      <div className="absolute top-1 right-2">
        <div className="flex h-6 items-center">
          <input
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            onChange={(e) => {
              var new_pics = pictures.map((pic) => {
                if (pic.id === picture.id) pic.is_checked = e.target.checked;
                return pic;
              });
              setPictures(new_pics);
            }}
            value={picture.is_checked}
            checked={picture.is_checked}
          />
        </div>
      </div>
    </div>
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                      <PhotographIcon
                        className="h-6 w-6 text-indigo-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Seleziona le foto
                      </Dialog.Title>
                      <small className="text-gray-500">
                        Selezionate{" "}
                        {pictures?.filter((el) => el.is_checked).length}
                      </small>
                      <div className="mt-2">
                        {loading ? (
                          <div className="w-full h-full flex items-center justify-center">
                            <LoadingIndicator />
                          </div>
                        ) : (
                          <div className="grid grid-cols-1 md:grid-cols-3 mt-6 gap-2 max-h-64 overflow-y-auto">
                            {pictures?.map((pic, index) => (
                              <Picture picture={pic} />
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    className={`inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto ${
                      isDisabled
                        ? "!pointer-events-none !bg-gray-100 !text-gray-400"
                        : ""
                    }`}
                    disabled={isDisabled}
                    onClick={saveMedia}
                  >
                    Fatto
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Annulla
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
