import { Badge, Button, Modal, Spin } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import preventiviService from "../../../services/preventivi.service";
import { List } from "antd";
import useStateCallback from "../../../components/useStateCallback";
import { toast } from "react-hot-toast";

const confirm = (id_preventivo_template, loadTemplate) => {
  Modal.confirm({
    title: "Conferma utilizzo modello",
    content: (
      <div>
        Caricando un modello di preventivo le seguenti proprietà del preventivo
        attualmente in modifica saranno sostituite:
        <br></br>
        <br></br>
        <ul className="!list-disc ml-3">
          <li>Nome del preventivo</li>
          <li>Lingua</li>
          <li>Garanzia e Condizioni</li>
          <li>Messaggio della mail</li>
          <li>Oggetto della mail</li>
          <li>Messaggi di conferma</li>
        </ul>
        <br></br>
        Proseguire con la duplicazione?
      </div>
    ),
    onOk() {
      loadTemplate(id_preventivo_template);
    },
  });
};

const warning = (id_preventivo_template, deleteTemplate) => {
  Modal.warning({
    title: "Conferma eliminazione modello",
    content: (
      <div>
        Stai per eliminare un modello
        <br></br>
        Proseguire con l'eliminazione?
      </div>
    ),
    onOk() {
      deleteTemplate(id_preventivo_template);
    },
  });
};

const PreventivoFromTemplate = ({ preventivo, setPreventivo }) => {
  const { token } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [templates, setTemplates] = useStateCallback([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (token && isModalOpen) fetchTemplates();
  }, [isModalOpen]);

  const fetchTemplates = async () => {
    setLoading(true);
    setTemplates([], () => {
      preventiviService.getTemplates(token).then(
        (result) => {
          result = result.data;
          setTemplates(result);
          setLoading(false);
        },
        (error) => {}
      );
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const deleteTemplate = async (id_preventivo_template) => {
    setLoading(true);
    preventiviService
      .deleteTemplateModel(token + "/" + id_preventivo_template)
      .then(
        (result) => {
          result = result.data;
          setTemplates(
            templates.filter(
              (el) => el.id_preventivo_template !== id_preventivo_template
            )
          );
          setLoading(false);
          toast.success("Il modello di preventivo è stato eliminato.");
        },
        (error) => {}
      );
  };
  const loadTemplate = async (id_preventivo_template) => {
    setLoading(true);
    preventiviService
      .useTemplateModel(
        token + "/" + preventivo.id_preventivo + "/" + id_preventivo_template
      )
      .then(
        (result) => {
          result = result.data;
          setPreventivo(
            {
              ...preventivo,
              name: result.name,
              language: result.language,
              cancellation_rules: result.cancellation_rules,
              message: result.message,
              subject: result.subject,
              confirm_message: result.confirm_message,
              confirm_message_email: result.confirm_message_email,
              body: result.body,
              bonifico_text: result.bonifico_text,
            },
            () => {
              setLoading(false);
              setIsModalOpen(false);
              toast.success("Il modello di preventivo è stato caricato.");
            }
          );
        },
        (error) => {}
      );
  };
  return (
    <>
      <Button
        className="!flex !flex-row !items-center !ml-auto !bg-[#1677ff] hover:!bg-[#4096ff] w-full"
        type="primary"
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 text-white mr-2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z"
            />
          </svg>
        }
        onClick={showModal}
      >
        Carica da template
      </Button>
      <Modal
        title={
          "Scegli un modello dalla lista di template (" +
          templates?.length +
          ")"
        }
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel} disabled={loading}>
            Annulla
          </Button>,
        ]}
      >
        <Spin spinning={loading}>
          <List
            itemLayout="horizontal"
            dataSource={templates}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6 text-slate-500"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                      />
                    </svg>
                  }
                  title={
                    <div className="flex flex-row items-center w-full">
                      <div className="flex flex-row items-center gap-3">
                        <span>{item.name}</span>
                        {item.id_structure && (
                          <Badge
                            status="default"
                            color="pink"
                            text={"Creata da te"}
                          />
                        )}
                      </div>
                      <img
                        src={
                          "https://www.idaweb.eu/Images/Sys/flag/" +
                          item.language +
                          ".png"
                        }
                        alt="model-language"
                        className="ml-auto w-[20px]"
                      />
                    </div>
                  }
                  description={
                    <div className="flex flex-col gap-3">
                      <span>{item.subject}</span>

                      <div className="flex flex-row items-center gap-3 w-full">
                        <Button
                          type="primary"
                          onClick={() =>
                            confirm(item.id_preventivo_template, loadTemplate)
                          }
                          size="small"
                        >
                          Usa modello
                        </Button>
                        {item.id_structure && (
                          <Button
                            danger
                            type="dashed"
                            onClick={() =>
                              warning(
                                item.id_preventivo_template,
                                deleteTemplate
                              )
                            }
                            size="small"
                            className="ml-auto"
                          >
                            Elimina
                          </Button>
                        )}
                      </div>
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        </Spin>
      </Modal>
    </>
  );
};
export default PreventivoFromTemplate;
