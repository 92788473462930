import { useEffect, useState } from "react";
import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import adminService from "../../../../services/admin.service";
import Skeleton from "@mui/material/Skeleton";
import { Chip, styled } from "@mui/material";
import useStateCallback from "../../../../components/useStateCallback";
import { FixedSizeList } from "react-window";

const HasListinoCurrentYearWidget = ({
  filter,
  search,
  setSearch,
  removeFilter,
  setRemoveFilter,
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useStateCallback([]);

  useEffect(() => {
    if (search && filter) {
      setData([], () => {
        fetchData();
      });
    }
  }, [search]);

  useEffect(() => {
    if (removeFilter) {
      setRemoveFilter(false);
      fetchData();
    }
  }, [removeFilter]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    adminService.getHasListinoStructure({ search: filter }).then(
      (result) => {
        result = result.data;
        setData(result);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const renderRow = ({ index, style }) => {
    const row = data[index];
    return (
      <div style={style}>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar alt={row.name}>{row.name[0]}</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <div className="flex flex-row items-center">
                <span className="truncate">{row.name}</span>
                <span className="ml-auto">
                  <Chip
                    color={row.has_listino ? "success" : "error"}
                    label={row.has_listino ? "Presente" : "Non presente"}
                  />
                </span>
              </div>
            }
          />
        </ListItem>
        <Divider variant="inset" component="div" />
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-3 h-full">
      {loading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <FixedSizeList
          height={400}
          itemSize={64}
          itemCount={data.length}
          overscanCount={5}
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            overflowY: "auto",
          }}
        >
          {renderRow}
        </FixedSizeList>
      )}
      <span className="text-gray-400 w-full text-center">
        Mostro {data.length}
      </span>
    </div>
  );
};
export default HasListinoCurrentYearWidget;
