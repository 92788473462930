import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

export default function SkeletonRoom() {
  return (
    <>
        <Grid container spacing={2}>
            <Grid item sm={3} xs={12}>
                <Skeleton variant="rectangular" height={180} />
            </Grid>
            <Grid item sm={6} xs={12}>
                <Skeleton width={'80%'} height={30}/>
                <Skeleton width={'40%'} height={30}/>
                <Skeleton width={'40%'} height={30}/>

                <Skeleton width={'100%'} sx={{marginTop:'20px'}}/>
                <Skeleton width={'100%'}/>
                <Skeleton width={'100%'}/>
            </Grid>
            <Grid item sm={3} xs={12} className="room-price room-price-skeleton">
                <Box className="main">
                    <Skeleton width={'70%'} className="start"/>
                    <Skeleton width={'50%'} height={30} className="price"/>
                </Box>
            </Grid>
        </Grid>
    </>
  )
}
