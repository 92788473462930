import { PhotographIcon, UserCircleIcon } from "@heroicons/react/solid";
import React from "react";
import webService from "../../services/web.service";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import OtaSelect from "./OtaSelect";
import BestChoicePros from "./BestChoicePro";
import BestChoiceFreeGiveAway from "./BestChoiceFreGiveaway";

export default function BestChoiceEditForm({
  bestChoice,
  bestChoices,
  setBestChoice,
  setBestChoices,
  setOpen,
  language,
}) {
  const [isDisabled, setIsDisabled] = React.useState(false);
  const { token, id_config } = useParams();

  React.useEffect(() => {
    var isDis = false;
    if (!bestChoice.url) isDis = true;
    setIsDisabled(isDis);
  }, [bestChoice]);

  const saveBestChoice = async () => {
    setIsDisabled(true);
    if (bestChoice.id_best_choice) {
      webService
        .patchBestChoice(token, id_config, language.value, bestChoice)
        .then(
          (result) => {
            const status = result.status;
            if (status === 200) {
              setBestChoices(
                bestChoices.map((item) => {
                  if (item.id_best_choice === bestChoice.id_best_choice)
                    return result.data;
                  return item;
                })
              );
              toast.success("La categoria è stata salvata correttamente.");
            } else {
              toast.error("Si è verificato un errore");
            }
            setIsDisabled(false);
          },
          (error) => {
            toast.error("Si è verificato un errore");
            setIsDisabled(false);
          }
        );
    } else {
      webService
        .saveBestChoice(token, id_config, language.value, bestChoice)
        .then(
          (result) => {
            const status = result.status;
            if (status === 201) {
              setBestChoices([...bestChoices, result.data]);
              toast.success("La categoria è stata salvata correttamente.");
            } else {
              toast.error("Si è verificato un errore");
            }
            setIsDisabled(false);
          },
          (error) => {
            toast.error("Si è verificato un errore");
            setIsDisabled(false);
          }
        );
    }
  };
  const getPriceOta = async (e) => {
    webService.getPriceOta(token + "/" + e.buyer).then(
      (result) => {
        result = result.data[0];
        setBestChoice({
          ...bestChoice,
          price: result.price,
          striked_price: result.striked_price,
          ota: e,
        });
      },
      (error) => {
        setBestChoice({ ...bestChoice, ota: e });
      }
    );
  };
  const deleteBestChoice = async () => {
    setIsDisabled(true);
    webService.deleteBestChoice(token, bestChoice.id_best_choice).then(
      (result) => {
        const status = result.status;
        if (status === 200) {
          toast.success("La categoria è stata eliminata.");
          setBestChoices(
            bestChoices.filter(
              (el) => el.id_best_choice !== bestChoice.id_best_choice
            )
          );
          setOpen(false);
        } else {
          toast.error("Si è verificato un errore");
        }
        setIsDisabled(false);
      },
      (error) => {
        toast.error("Si è verificato un errore");
        setIsDisabled(false);
      }
    );
  };

  return (
    <form>
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-lg font-semibold leading-7 text-gray-900">
            {bestChoice.id_best_choice ? "Modifica Prezzo" : "Nuovo Prezzo"}
          </h2>
          <div className="mt-10 flex flex-col gap-6">
            <div className="w-full">
              <div className="relative flex gap-x-3">
                <div className="flex h-6 items-center">
                  <input
                    id="candidates"
                    name="candidates"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    value={bestChoice.is_official}
                    onChange={(e) =>
                      setBestChoice({
                        ...bestChoice,
                        is_official: e.target.checked,
                      })
                    }
                    checked={bestChoice.is_official}
                  />
                </div>
                <div className="text-sm leading-6">
                  <label
                    htmlFor="candidates"
                    className="font-medium text-gray-900"
                  >
                    Questa categoria è ufficiale della struttura?
                  </label>
                  <p className="text-gray-500">
                    Spunta questa casella se il prezzo di questa categoria non
                    proviene da nessun OTA.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full">
              <OtaSelect
                value={bestChoice.ota}
                onChange={(e) => {
                  getPriceOta(e);
                }}
              />
            </div>
            <div className="w-full">
              <label
                htmlFor="title-advisor"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Prezzo{" "}
                <small>* Modificabile dal listino prezzi di IdaWeb</small>
              </label>
              <div className="mt-2">
                <div
                  className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 
                focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md bg-gray-50"
                >
                  <input
                    type="text"
                    className="pointer-events-none bg-gray-50
                    ml-2 block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-500 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    readonly
                    disabled
                    value={parseFloat(bestChoice.price).toFixed(2)}
                  />
                </div>
              </div>
            </div>
            <div className="w-full">
              <label
                htmlFor="title-advisor"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                URL
              </label>
              <div className="mt-2">
                <div
                  className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 
                focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md"
                >
                  <input
                    type="text"
                    className="
                    ml-2 block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-500 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="Ex. https://..."
                    value={bestChoice.url}
                    onChange={(e) =>
                      setBestChoice({ ...bestChoice, url: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <BestChoiceFreeGiveAway
              bestChoice={bestChoice}
              setBestChoice={setBestChoice}
              language={language}
            />
            <BestChoicePros
              bestChoice={bestChoice}
              setBestChoice={setBestChoice}
              language={language}
            />
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        {bestChoice.id_best_choice && (
          <button
            type="button"
            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 mr-auto"
            onClick={() => {
              if (window.confirm("Eliminare la categoria corrente?")) {
                deleteBestChoice();
              }
            }}
          >
            Elimina
          </button>
        )}

        <button
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
          onClick={() => setOpen(false)}
        >
          Annulla
        </button>
        <button
          className={`rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
            isDisabled ? "!pointer-events-none !bg-gray-100 !text-gray-400" : ""
          }`}
          disabled={isDisabled}
          onClick={saveBestChoice}
        >
          Salva
        </button>
      </div>
    </form>
  );
}
