import { Button, Checkbox, Divider, Input, Select, Space } from "antd";
import { useEffect, useRef, useState } from "react";
import preventiviService from "../../services/preventivi.service";
import { encode as base64_encode } from "base-64";
import {
  PlusOutlined,
  PushpinOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { StyleProvider } from "@ant-design/cssinjs";
import { toast } from "react-hot-toast";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Typography } from "antd";
import PreventivoHelper from "./PreventivoHelper";

const { Text, Link } = Typography;

const content = (
  <div>
    Le trattative del preventivo sono chiuse oppure sono state trovate delle
    righe nel preventivo collegate a delle prenotazioni sul planner.
    <br></br>
    Bisogna prima eliminare le righe con l'icona &nbsp;
    <PushpinOutlined className="text-indigo-500 text-lg" />
    &nbsp; prima di poter cambiare il cliente.
  </div>
);

const SearchInput = (props) => {
  const [data, setData] = useState([]);
  const [value, setValue] = useState();
  const [name, setName] = useState("");
  const [oldName, setOldName] = useState();
  const [searchFirm, setSearchFirm] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    handleSearch(props.text);
  }, [searchFirm]);

  useEffect(() => {
    if (value !== props.text) {
      setValue(props.text);
      setOldName(props.text);
      setSearchFirm(props.id_customer ? false : !props.id_firm ? false : true);
    }
  }, []);

  const fetch = (token, value, callback, searchFirm) => {
    if (searchFirm) {
      preventiviService
        .getFirmsFilter(token + "/" + base64_encode(value))
        .then((result) => {
          result = result.data;
          callback(result);
        });
    } else {
      preventiviService
        .getCustomersFilter(token + "/" + base64_encode(value))
        .then((result) => {
          result = result.data;
          callback(result);
        });
    }
  };
  const handleSearch = (newValue) => {
    if (newValue) {
      fetch(props.token, newValue, setData, searchFirm);
    } else {
      setData([]);
    }
  };
  const handleChange = async (newValue) => {
    setValue(newValue);
    props.onChange(newValue, !searchFirm);
  };

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const createNewCustomer = async (e) => {
    e.preventDefault();

    preventiviService
      .saveNewCustomer(props.token, {
        name: name,
        is_customer: !searchFirm,
      })
      .then((result) => {
        if (result.status === 201) {
          toast.success("La nuova anagrafica è stata creata.");
          setData([
            ...data,
            {
              text: name,
              value: searchFirm ? result.data.id_firm : result.data.id_customer,
            },
          ]);
          setValue(name);
          setOldName(name);
          setName("");
          handleChange(
            searchFirm ? result.data.id_firm : result.data.id_customer
          );
          setTimeout(() => {
            inputRef.current?.focus();
          }, 0);
        } else toast.error("Si è verificato un errore");
      });
  };
  return (
    <Select
      showSearch
      value={value}
      placeholder={props.placeholder}
      style={props.style}
      defaultActiveFirstOption={false}
      showArrow={true}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={<span>Nessun risultato</span>}
      options={(data || []).map((d) => ({
        value: d.value,
        label: d.text,
      }))}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider
            style={{
              margin: "8px 0",
            }}
          />
          <div
            style={{
              padding: "0 8px 4px",
              width: "100%",
            }}
          >
            <Checkbox
              value={searchFirm}
              onChange={(e) => {
                setSearchFirm(e.target.checked);
              }}
              checked={searchFirm}
            >
              Cerca come azienda
            </Checkbox>
          </div>
          <Divider
            style={{
              margin: "8px 0",
            }}
          />
          <label className="ml-2">Crea un nuovo cliente:</label>
          <div
            className="flex flex-row items-center gap-3"
            style={{
              padding: "0 8px 4px",
              width: "100%",
            }}
          >
            <Input
              placeholder={
                searchFirm ? "Digita il nome" : "Digita il nome e il cognome"
              }
              ref={inputRef}
              value={name}
              onChange={onNameChange}
              className="w-full"
            />
            <Button
              type="text"
              icon={<PlusOutlined />}
              onClick={createNewCustomer}
              className="flex flex-row items-center"
              disabled={!name}
            >
              {searchFirm ? "Nuova Azienda" : "Nuovo Cliente"}
            </Button>
          </div>
        </>
      )}
      disabled={props.disabled}
    />
  );
};
const PreventivoCustomerSelect = (props) => {
  return (
    <StyleProvider hashPriority="high">
      <div className="flex flex-col md:flex-row items-center gap-3">
        <SearchInput
          placeholder="Digita il nome o cognome"
          style={{
            width: "100%",
          }}
          token={props.token}
          id_customer={props.id_customer}
          id_firm={props.id_firm}
          text={props.text}
          onChange={props.onChange}
          disabled={props.disabled}
        />
        {props.disabled && (
          <PreventivoHelper
            title="Perchè non posso cambiare cliente?"
            content={content}
          >
            <Text type="danger" style={{ marginTop: "5px", width: "100%" }}>
              <WarningOutlined /> Perchè è disabilitata?
            </Text>
          </PreventivoHelper>
        )}
      </div>
    </StyleProvider>
  );
};
export default PreventivoCustomerSelect;
