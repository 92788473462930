import React from "react";
import { Dialog } from "@headlessui/react";
import {
  ArrowSmRightIcon,
  CreditCardIcon,
  XIcon,
} from "@heroicons/react/outline";
import PaymentGenerateLink from "./PaymentGenerateLink";
import { useParams } from "react-router-dom";
import paymentsService from "../../services/payments.service";

function PaymentCart({ products, focused_products, setFocusedProducts }) {
  const { token } = useParams();
  const [total, setTotal] = React.useState(0.0);
  const [open, setOpen] = React.useState(false);
  const [link, setLink] = React.useState("");
  const [shipping, setShipping] = React.useState({
    shipping: false,
    price: 0.0,
  });
  const [isCreatingCheckout, setIsCreatingCheckout] = React.useState(false);

  React.useEffect(() => {
    let sum = 0.0;
    products.map((product) => {
      sum += parseFloat(product.price) * parseFloat(product.quantity);
    });

    if (
      shipping.shipping &&
      shipping.price &&
      !isNaN(parseFloat(shipping.price))
    )
      sum += parseFloat(shipping.price);
    setTotal(sum);
  }, [products, shipping]);

  const generateCheckout = async () => {
    setIsCreatingCheckout(true);
    const checkout = {
      has_shipping: shipping.shipping,
      shipping_cost: shipping.price,
      products: focused_products,
    };
    paymentsService.saveCheckout(checkout, token).then(
      (result) => {
        result = result.data;
        if (result.success === true) {
          setLink(result.model);
          setOpen(true);
        } else {
          // TODO: GESTIRE ERRORE
        }
        setIsCreatingCheckout(false);
      },
      (error) => {}
    );
  };

  return (
    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
      <div className="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
        <div className="flex items-start justify-between">
          <div className="text-lg font-medium text-gray-900">
            Elementi nel carrello
          </div>
        </div>

        <div className="mt-8">
          <div className="flow-root">
            {products.length === 0 && (
              <p className="text-gray-400">
                Non sono stati aggiunti prodotti nel carrello
              </p>
            )}
            <ul role="list" className="-my-6 divide-y divide-gray-200">
              {products.map((product) => (
                <li key={product.id} className="flex py-6">
                  <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                    <img
                      src={"https://www.idaweb.eu/" + product.imageSrc}
                      alt={product.imageAlt}
                      className="h-full w-full object-cover object-center"
                      loading="lazy"
                    />
                  </div>

                  <div className="ml-4 flex flex-1 flex-col">
                    <div>
                      <div className="flex justify-between text-base font-medium text-gray-900">
                        <h3>
                          <a href={product.href}>{product.name}</a>
                        </h3>
                        <p className="ml-4">{product.price}</p>
                      </div>
                      <p className="mt-1 text-sm text-gray-500">
                        {product.imageAlt}
                      </p>
                    </div>
                    <div className="flex flex-1 items-end justify-between text-sm">
                      <p className="text-gray-900">
                        Quantità <strong>{product.quantity}</strong>
                      </p>

                      <div className="flex">
                        <button
                          type="button"
                          className="font-medium text-red-600 hover:text-red-500"
                          onClick={() => {
                            setFocusedProducts(
                              focused_products.filter(
                                (el) => el.id !== product.id
                              )
                            );
                          }}
                        >
                          Rimuovi
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
        <div className="flex flex-col items-start mb-4">
          <p>
            <label for="MarketingAccept" class="flex gap-4">
              <input
                type="checkbox"
                id="MarketingAccept"
                name="marketing_accept"
                class="h-5 w-5 rounded-md border-gray-200 bg-white shadow-sm"
                checked={shipping.shipping}
                onChange={(e) =>
                  setShipping({ ...shipping, shipping: e.target.checked })
                }
              />

              <span class="text-sm text-gray-700">
                Includi spese di spedizione
              </span>
            </label>
          </p>
          {shipping.shipping && (
            <div className="relative mt-2 rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-gray-500 sm:text-sm !font-bold">€</span>
              </div>
              <input
                type="text"
                name="price"
                id="price"
                className="block w-full rounded-md border-0 py-1.5 pl-7  text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Es: 9.99"
                value={shipping.price}
                onChange={(e) =>
                  setShipping({
                    ...shipping,
                    price: e.target.value,
                  })
                }
              />
            </div>
          )}
        </div>

        <div className="flex justify-between text-base font-medium text-gray-900">
          <p>Subtotale</p>
          <p className="text-xl">€ {parseFloat(total).toFixed(2)}</p>
        </div>

        <div className="mt-6">
          <a
            disabled={focused_products.length === 0 ? true : false}
            onClick={generateCheckout}
            className={`flex items-center gap-3 
            cursor-pointer
            justify-center rounded-md border 
            border-transparent bg-indigo-600 
            px-6 py-3 text-base font-medium 
            text-white shadow-sm hover:bg-indigo-700 ${
              focused_products.length === 0
                ? "!pointer-events-none !bg-gray-100 !text-gray-400"
                : ""
            }
            ${
              isCreatingCheckout
                ? "!pointer-events-none !bg-gray-100 !text-gray-400"
                : ""
            }
            `}
          >
            <CreditCardIcon className="w-[24px]" />
            Genera link per il pagamento
          </a>
        </div>

        <PaymentGenerateLink
          open={open}
          setOpen={setOpen}
          focused_products={focused_products}
          link={link}
        />
      </div>
    </div>
  );
}

export default PaymentCart;
