import React, { useState } from "react";
import { Card } from "antd";
import { Button, Divider, Form, Input } from "antd";
import { useParams } from "react-router-dom";
import preventiviService from "../../../services/preventivi.service";
import { toast } from "react-hot-toast";
import PreventivoCardRow from "./PreventivoCardRow";
import { Popconfirm } from "antd";

const { TextArea } = Input;

function PreventivoCreditCard({ preventivo, setPreventivo }) {
  const { token } = useParams();
  const [invalidFeedback, setInvalidFeedback] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hideCard, setHideCard] = useState(true);

  const setCardValidity = (is_invalid) => {
    setLoading(true);
    preventiviService
      .setInvalidCard(token, {
        ...preventivo.card,
        is_invalid: is_invalid,
        invalid_feedback: invalidFeedback,
      })
      .then(
        (result) => {
          if (result.status === 200) {
            toast.success("La carta è stata aggiornata");
            setPreventivo(
              {
                ...preventivo,
                card: {
                  ...preventivo.card,
                  is_invalid: is_invalid,
                  invalid_feedback: invalidFeedback,
                },
              },
              () => {
                setInvalidFeedback("");
              }
            );
            setOpen(false);
            setLoading(false);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
  };

  const deleteRooms = async () => {
    setLoading(true);
    preventiviService
      .deleteRoomsPreventivo(token + "/" + preventivo.id_preventivo)
      .then(
        (result) => {
          if (result.status === 200) {
            toast.success("Le stanze sono state eliminate.");
            setPreventivo({
              ...preventivo,
              rows: preventivo.rows.filter((el) => el.id_product),
            });
            setLoading(false);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
  };

  return (
    <Card
      title={
        <div className="flex flex-row items-center gap-3">Carta a garanzia</div>
      }
      bordered={false}
      className={preventivo.card.is_invalid ? "bg-red-100" : "bg-green-50"}
    >
      <Form
        labelCol={{
          span: 5,
        }}
        layout="horizontal"
      >
        <PreventivoCardRow
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 text-slate-600"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"
              />
            </svg>
          }
          label={"Numero Carta"}
          text={
            <span className="capitalize text-slate-500">
              {hideCard ? "****************" : preventivo.card.card_number}
            </span>
          }
          value={preventivo.card.card_number}
        />

        <PreventivoCardRow
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 text-slate-600"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
              />
            </svg>
          }
          label={"Titolare della carta"}
          text={
            <span className="capitalize text-slate-500">
              {hideCard ? "********** **********" : preventivo.card.card_holder}
            </span>
          }
          value={preventivo.card.card_holder}
        />

        <PreventivoCardRow
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 text-slate-600"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
              />
            </svg>
          }
          label={"Scadenza"}
          text={
            <span className="capitalize text-slate-500">
              {hideCard ? "**/**" : preventivo.card.card_expire}
            </span>
          }
          value={preventivo.card.card_expire}
        />

        <PreventivoCardRow
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 text-slate-600"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          }
          label={"CVC"}
          text={
            <span className="capitalize text-slate-500">
              {hideCard ? "***" : preventivo.card.card_cnc}
            </span>
          }
          value={preventivo.card.card_cnc}
        />
      </Form>

      <div className="flex flex-col md:flex-row items-center mt-3 gap-3">
        <Button
          type="dashed"
          onClick={() => setHideCard(!hideCard)}
          className="!bg-white"
        >
          {hideCard ? "Mostra dati carta" : "Nascondi dati carta"}
        </Button>

        {!preventivo.card.is_invalid ? (
          <>
            {!open && (
              <Button
                type="primary"
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    class="w-5 h-5 text-white"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                    />
                  </svg>
                }
                className="!flex !flex-row !items-center gap-2"
                danger
                onClick={() => setOpen(true)}
              >
                Segna carta non valida
              </Button>
            )}
          </>
        ) : (
          <div className="flex flex-row items-center gap-2">
            <div className="custom ">
              <Button
                type="primary"
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 text-white"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                }
                className="!flex !flex-row !items-center gap-2"
                onClick={() => setCardValidity(false)}
              >
                Segna carta valida
              </Button>
            </div>
            {preventivo?.rows.filter((el) => el.id_category).length >= 1 && (
              <Popconfirm
                title="Conferma eliminazione stanze"
                description={
                  <div>
                    Il pagamento non è andato a buon fine?
                    <br></br>
                    Puoi scegliere di rimuovere le stanze che hai assegnato in
                    questo preventivo. <br></br>
                    <b>
                      Nessuna proprietà di questo preventivo sarà variata, solo
                      le camere assegnate saranno rimosse.
                    </b>
                    <br></br>
                    <br></br>
                    Eliminando le stanze il <b>totale</b> si azzererà, ti
                    consigliamo di annotarti sulle '<b>Note</b>' il totale del
                    preventivo.
                  </div>
                }
                onConfirm={deleteRooms}
                okText="Conferma"
                cancelText="No, annulla"
              >
                <Button
                  type="primary"
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      class="w-5 h-5 text-white"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                      />
                    </svg>
                  }
                  className="!flex !flex-row !items-center gap-2"
                  danger
                >
                  Elimina stanze assegnate
                </Button>
              </Popconfirm>
            )}
          </div>
        )}
      </div>

      {open && (
        <div className="flex flex-col">
          <Divider />
          Stai segnando la carta come invalida.
          <br></br>
          <b>Il cliente riceverà una mail per informarlo dell'accaduto.</b>
          <p>Inserisci di seguito il testo che riceverà il cliente via mail.</p>
          <br></br>
          <TextArea
            value={invalidFeedback}
            onChange={(e) => setInvalidFeedback(e.target.value)}
            placeholder="Ammontare non sufficiente"
            autoSize={{
              minRows: 3,
              maxRows: 5,
            }}
          />
          <br></br>
          Procedere?
          <div className="flex flex-row items-center custom w-full justify-end gap-2">
            <Button
              onClick={() => {
                setOpen(false);
                setInvalidFeedback("");
              }}
              className="bg-white"
            >
              Annulla
            </Button>
            <Button
              type="primary"
              onClick={() => setCardValidity(true)}
              disabled={!invalidFeedback}
              loading={loading}
            >
              Ok
            </Button>
          </div>
        </div>
      )}
    </Card>
  );
}
export default PreventivoCreditCard;
