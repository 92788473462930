import React from "react";
import webService from "../../services/web.service";
import LoadingIndicator from "../../components/LoadingIndicator";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import dayjs from "dayjs";
import { Button, DatePicker, Divider, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import { StyleProvider } from "@ant-design/cssinjs";

function Events({ language }) {
  const { token, id_config } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [events, setEvents] = React.useState([]);

  React.useEffect(() => {
    if (id_config) fetchEvents();
  }, [language]);

  const fetchEvents = async () => {
    webService.getEvents(token, id_config, language.value).then(
      (result) => {
        setEvents(result.data);
        setLoading(false);
      },
      (error) => {
        toast.error("Si è verificato un errore");
        setIsDisabled(false);
      }
    );
  };

  const saveEvents = () => {
    webService.saveEvents(token, id_config, language.value, events).then(
      (result) => {
        if (result.status === 200)
          toast.success("Gli eventi sono stati salvati");
        setLoading(false);
      },
      (error) => {
        toast.error("Si è verificato un errore");
        setIsDisabled(false);
      }
    );
  };

  return (
    <>
      <StyleProvider hashPriority="high">
        {loading ? (
          <div className="w-full h-full flex items-center justify-center">
            <LoadingIndicator />
          </div>
        ) : (
          <>
            <div className="bg-gray-100">
              <div className="pt-5 pr-5 w-full flex justify-end gap-4">
                <button
                  className={`rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                  onClick={saveEvents}
                  type="button"
                >
                  Salva gli eventi
                </button>
                <button
                  className={`rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                  onClick={() =>
                    setEvents([
                      ...events,
                      {
                        title: "",
                        title_obj: {
                          text: "",
                        },
                        description_obj: {
                          text: "",
                        },
                        description: "",
                        location: "",
                        date: dayjs(),
                        image: "",
                      },
                    ])
                  }
                  type="button"
                >
                  Nuovo evento
                </button>
              </div>
              <div className="flex flex-col p-5">
                {events?.map((ev, index) => (
                  <div className="flex flex-col gap-2 w-full">
                    <div className="flex flex-row items-center">
                      <span className="text-xl font-semibold">
                        Evento #{index + 1}
                      </span>

                      <Button
                        type="primary"
                        danger
                        onClick={() => {
                          setEvents(
                            events.filter((eve) => eve.id_event !== ev.id_event)
                          );
                        }}
                        className="ml-auto"
                      >
                        Elimina Evento
                      </Button>
                    </div>
                    <label>Titolo:</label>
                    <Input
                      value={ev.title_obj.text}
                      onChange={(e) =>
                        setEvents(
                          events.map((eve, i) => {
                            if (eve.id_event === ev.id_event)
                              eve.title_obj.text = e.target.value;
                            return eve;
                          })
                        )
                      }
                    />

                    <label className="mt-3">Descrizione:</label>
                    <TextArea
                      value={ev.description_obj.text}
                      onChange={(e) =>
                        setEvents(
                          events.map((eve, i) => {
                            if (eve.id_event === ev.id_event)
                              eve.description_obj.text = e.target.value;
                            return eve;
                          })
                        )
                      }
                      rows={10}
                    />

                    <label className="mt-3">Location:</label>
                    <Input
                      value={ev.location}
                      onChange={(e) =>
                        setEvents(
                          events.map((eve, i) => {
                            if (eve.id_event === ev.id_event)
                              eve.location = e.target.value;
                            return eve;
                          })
                        )
                      }
                    />

                    <label className="mt-3">Immagine (URL):</label>
                    <Input
                      value={ev.image}
                      onChange={(e) =>
                        setEvents(
                          events.map((eve, i) => {
                            if (eve.id_event === ev.id_event)
                              eve.image = e.target.value;
                            return eve;
                          })
                        )
                      }
                    />

                    <label className="mt-3">Data (DD/MM/YYYY):</label>
                    <DatePicker
                      format={"DD/MM/YYYY"}
                      value={dayjs(ev.date)}
                      onChange={(e) =>
                        setEvents(
                          events.map((eve, i) => {
                            if (eve.id_event === ev.id_event) {
                              eve.date = e;
                            }
                            return eve;
                          })
                        )
                      }
                    />

                    <Divider />
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </StyleProvider>
    </>
  );
}

export default Events;
