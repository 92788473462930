import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, Grid } from "@mui/material";
import { Margined } from "../../prices/partials/styles";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
export default function RequestSuccess() {
  const { language } = useParams();
  const { t, i18n } = useTranslation("common");
  useEffect(() => {
    i18n.changeLanguage(language.toLowerCase());
    document.title = t("request.success.documentTitle");
  }, []);
  return (
    <Margined>
      <div className="container-success">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CheckCircleIcon sx={{ color: "#3BB90D", fontSize: "6rem" }} />
          </Grid>
          <Grid item xs={12}>
            <h3>{t("request.success.title")}</h3>
            <p>{t("request.success.subtitle")}</p>
            <br></br>
            <span> {t("request.success.body")}</span>
          </Grid>
        </Grid>
      </div>
    </Margined>
  );
}
