import ErrorIcon from "@mui/icons-material/Error";
import { Button, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import React from "react";
import { Margined } from "../../pages/prices/partials/styles";

export default function ErrorPaymentBE() {
  const location = useLocation();
  React.useEffect(() => {
    document.title = location.state.title;
  }, []);
  return (
    <Margined>
      <div className="container-success">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ErrorIcon sx={{ color: "red", fontSize: "6rem" }} />
          </Grid>
          <Grid item xs={12}>
            <h3>{location.state.title}</h3>
            <p
              dangerouslySetInnerHTML={{
                __html: location.state.error,
              }}
            />
          </Grid>
        </Grid>
      </div>
    </Margined>
  );
}
