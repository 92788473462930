import { useState } from "react";
import AuthAdmin from "./Components/Auth/AuthAdmin";
import Layout from "./Components/Layout";
import SearchBar from "./Components/SearchBar";
import HasListinoCurrentYearWidget from "./Components/Widgets/HasListinoCurrentYearWidget";
import LessBookingStructuresWidget from "./Components/Widgets/LessBookingStructuresWidget";
import Widget from "./Components/Widgets/Ui/Widget";

export default function Admin() {
  const [filter, setFilter] = useState("");
  const [search, setSearch] = useState(false);
  const [removeFilter, setRemoveFilter] = useState(false);

  return (
    <AuthAdmin>
      <Layout title="Dashboard">
        <div className="grid grid-cols-12 gap-3">
          <div className="col-span-12">
            <SearchBar
              filter={filter}
              setFilter={setFilter}
              search={search}
              setSearch={setSearch}
              setRemoveFilter={setRemoveFilter}
            />
          </div>
          <div className="col-span-12 md:col-span-6">
            <Widget title={"Strutture con meno prenotazioni"}>
              <LessBookingStructuresWidget
                filter={filter}
                search={search}
                setSearch={setSearch}
                removeFilter={removeFilter}
                setRemoveFilter={setRemoveFilter}
              />
            </Widget>
          </div>
          <div className="col-span-12 md:col-span-6">
            <Widget title={"Strutture che non hanno il listino base"}>
              <HasListinoCurrentYearWidget
                filter={filter}
                search={search}
                setSearch={setSearch}
                removeFilter={removeFilter}
                setRemoveFilter={setRemoveFilter}
              />
            </Widget>
          </div>
        </div>
      </Layout>
    </AuthAdmin>
  );
}
