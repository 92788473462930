import { Breadcrumb } from "antd";
import React from "react";

function Path(props) {
  return (
    <Breadcrumb>
      {props.items?.map((item) => (
        <Breadcrumb.Item>{item}</Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}

export default Path;
