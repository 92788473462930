import React from "react";
import {
  Alert,
  Button,
  Divider,
  Form,
  Tooltip,
  Typography,
  Modal,
  Input,
  Spin,
  Popconfirm,
  Popover,
} from "antd";
import { Card } from "antd";
import PreventivoWarranitySelect from "../PreventivoWarranitySelect";
import GaranziaHelper from "../Helpers/GaranziaHelper";
import CondizioniHelper from "../Helpers/CondizioniHelper";
const { Paragraph } = Typography;

function PreventivoGaranzie({ preventivo, setPreventivo, UpdatePreventivo }) {
  const PreventivoWarranity = () => {
    return (
      <>
        {preventivo.warranity_method !== "" && (
          <>
            <PreventivoWarranitySelect
              initialValue={
                preventivo.warranity_method
                  ? preventivo.warranity_method.toString()
                  : "0"
              }
              onChange={(e) => {
                UpdatePreventivo("6", e);
              }}
              status={preventivo.status}
            />
            {parseInt(preventivo.warranity_method) === 0 ? (
              <div className="text-slate-600 mt-3 w-full">
                L'utente dovrà inserire i dati di una carta di debito/prepagata
                validi al fine di garantire per l'accettazione del preventivo.
              </div>
            ) : parseInt(preventivo.warranity_method) === 1 ? (
              <div className="text-slate-600 mt-3 w-full">
                L'utente dovrà effettuare il pagamento, a seconda delle
                condizioni del preventivo, un pagamento online a garanzia
                dell'accettazione di questo preventivo.
              </div>
            ) : parseInt(preventivo.warranity_method) === 2 ? (
              <div className="text-slate-600 mt-3 w-full">
                All'utente non è richiesto nessun metodo di garanzia, pagherà
                direttamente in struttura. Questo metodo è consigliato per gli
                utenti abituali.
              </div>
            ) : (
              parseInt(preventivo.warranity_method) === 3 && (
                <div className="flex flex-col">
                  <div className="text-slate-600 my-3 w-full">
                    L'utente dovrà pagare l'importo tramite bonifico al momento
                    della conferma del preventivo, puoi specificare di seguito
                    le istruzioni per effettuare il pagamento.
                    <b>
                      Ricordati che gli estremi tra cui: causale, importo e iban
                      saranno impostati automaticamente
                    </b>
                    .
                  </div>

                  <Paragraph
                    editable={{
                      autoSize: true,
                      tooltip:
                        "Modifica le istruzioni per il pagamento del bonifico",
                      text: preventivo.bonifico_text,
                      onChange: (new_value) => {
                        UpdatePreventivo("16", new_value);
                      },
                    }}
                    className="flex flex-row items-center"
                  >
                    {preventivo.bonifico_text ? (
                      <div className="text-slate-600">
                        {preventivo.bonifico_text}
                      </div>
                    ) : (
                      <div className="flex flex-row items-center gap-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5 text-slate-400"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                          />
                        </svg>
                        <span className="text-slate-400">
                          Non sono state indicate istruzioni di pagamento
                        </span>
                      </div>
                    )}
                  </Paragraph>
                </div>
              )
            )}
          </>
        )}
      </>
    );
  };

  return (
    <Card
      title={
        <div className="flex flex-row items-center gap-3">
          Garanzia e Condizioni <GaranziaHelper />
        </div>
      }
      bordered={false}
    >
      <Form
        labelCol={{
          span: 4,
        }}
        layout="horizontal"
      >
        <PreventivoWarranity />

        <Divider plain>
          <div className="text-slate-400 flex flex-row items-center gap-3">
            Condizioni del preventivo
            <CondizioniHelper />
          </div>
        </Divider>
        <Paragraph
          editable={{
            autoSize: true,
            tooltip: "Modifica le condizioni del preventivo",
            text: preventivo.cancellation_rules,
            onChange: (new_value) => {
              UpdatePreventivo("7", new_value);
            },
          }}
          className="flex flex-row items-center"
        >
          {preventivo.cancellation_rules ? (
            <div className="text-slate-600">
              {preventivo.cancellation_rules}
            </div>
          ) : (
            <div className="flex flex-row items-center gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5 text-slate-400"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                />
              </svg>
              <span className="text-slate-400">
                Nessuna condizione di cancellazione inserita
              </span>
            </div>
          )}
        </Paragraph>
      </Form>
    </Card>
  );
}

export default PreventivoGaranzie;
