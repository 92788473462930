import { Button, Modal, Form, Input, Alert, TimePicker, Spin } from "antd";
import { useEffect, useState } from "react";
import { StyleProvider } from "@ant-design/cssinjs";
import PaymentForm from "./PaymentCard";
import "react-credit-cards/es/styles-compiled.css";
import preventiviService from "../../../services/preventivi.service";
import { useNavigate, useParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import PaymentBonifico from "./PaymentBonifico";

const PreventivoGuestPayment = ({
  structureInfo,
  preventivo,
  open,
  setOpen,
}) => {
  const format = "HH:mm";

  const { uuid, view_as_preview } = useParams();
  const [isPaying, setIsPaying] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDisabledPayment, setIsDisabledPayment] = useState(true);
  const [state, setState] = useState({
    name: "",
    surname: "",
    email: "",
    phone: "",
    confirm_email: "",
    checkin_time: "11:00",
  });
  const [card, setCard] = useState({});
  const [file, setFile] = useState();
  const [bonificoCode, setBonificoCode] = useState("Prenotazione ABCDE");
  const [loading, setLoading] = useState(false);
  const [bonificoDetails, setBonificoDetails] = useState({
    iban: "",
    beneficiario: "",
    total: 0.0,
  });

  const navigate = useNavigate();
  const { t } = useTranslation("common");

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  function makeid(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  useEffect(() => {
    var isDis = false;
    if (!state.name) isDis = true;
    if (!state.surname) isDis = true;
    if (!state.email) isDis = true;
    if (!state.phone) isDis = true;
    if (!state.confirm_email) isDis = true;
    if (!state.checkin_time) isDis = true;

    if (state.confirm_email !== state.email) isDis = true;

    if (state.email) if (!isValidEmail(state.email)) isDis = true;

    if (preventivo.warranity_method !== 2) if (isDisabledPayment) isDis = true;

    // if in preview
    if (view_as_preview) isDis = true;
    setIsDisabled(isDis);
  }, [state, isDisabledPayment]);

  useEffect(() => {
    setBonificoCode("Pagamento pren. " + makeid(5));
  }, []);

  useEffect(() => {
    if (uuid && preventivo.warranity_method === 3) fetchPaymentExtreme();
  }, [open]);

  const handleOk = () => {};
  const handleCancel = () => {
    if (!isPaying) setOpen(false);
  };
  const NoWarrannityMethod = () => (
    <div className="flex flex-col items-center text-green-400 text-center gap-4">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-20 h-20"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M15.182 15.182a4.5 4.5 0 01-6.364 0M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z"
        />
      </svg>

      <h3 className="font-semibold text-2xl">
        {t("payment.no_warranity_title")}
      </h3>
      <p>{t("payment.no_warranity_description")}</p>
    </div>
  );
  const WarranityAlert = () => (
    <>
      {preventivo.warranity_method === 0 ? (
        <Alert
          message={t("payment.card_warranity_title")}
          type="info"
          showIcon
        />
      ) : preventivo.warranity_method === 1 ? (
        <Alert
          message={t("payment.online_warranity_title")}
          type="info"
          showIcon
        />
      ) : (
        preventivo.warranity_method === 2 && (
          <Alert
            message={t("payment.no_warranity_subtitle")}
            type="success"
            showIcon
          />
        )
      )}
    </>
  );
  const doPayment = async () => {
    setIsPaying(true);

    let formData = new FormData();
    if (preventivo.warranity_method === 3) {
      formData.append("image", file);
    }
    formData.append(
      "accept_model",
      JSON.stringify({
        name: state.name,
        surname: state.surname,
        email: state.email,
        phone: state.phone,
        card: card,
        checkin_time:
          typeof state.checkin_time === "string"
            ? state.checkin_time
            : dayjs(state.checkin_time).format("HH:mm"),
        bonifico_code: preventivo.warranity_method === 3 ? bonificoCode : null,
      })
    );

    preventiviService.confirmPreventivo(uuid, formData).then(
      (result) => {
        if (result.status === 200) {
          navigate("/public/preventivo/success/" + uuid);
        } else {
          navigate("/error", {
            replace: true,
            state: {
              error: result.data.model,
            },
          });
        }
      },
      (error) => {
        setIsPaying(false);
        navigate("/error", {
          replace: true,
          state: {
            error: t("content.errors.unhandled"),
          },
        });
      }
    );
  };
  const fetchPaymentExtreme = async () => {
    setLoading(true);
    preventiviService.getPaymentExtreme(uuid).then(
      (result) => {
        setLoading(false);
        if (result.status === 200) {
          setBonificoDetails(result.data);
        } else {
          navigate("/error", {
            replace: true,
            state: {
              error: result.data.model,
            },
          });
        }
      },
      (error) => {
        setLoading(false);
        navigate("/error", {
          replace: true,
          state: {
            error: t("content.errors.unhandled"),
          },
        });
      }
    );
  };
  return (
    <Modal
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      wrapClassName={{ padding: 0 }}
      className="!w-full md:!w-[1200px] !p-0 payment"
      footer={[]}
      style={{ top: 20 }}
    >
      <Spin spinning={loading}>
        {isPaying ? (
          <div className="flex flex-col gap-4 items-center justify-center min-h-[500px]">
            <img
              src="/assets/images/payment-process.gif"
              alt="process-pay-gif"
              className="w-[150px]"
            />
            <div className="max-w-[300px] text-center">
              <p className="text-blue-600 font-medium text-lg">
                {t("payment.status.processing")}
              </p>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-12">
            <div className="p-10 flex col-span-12 md:col-span-5">
              <StyleProvider hashPriority="high">
                <div className="flex flex-col gap-5 w-full">
                  {structureInfo.structure_logo && (
                    <div className="w-full flex items-center justify-center">
                      <img
                        src={
                          "https://www.idaweb.eu/" +
                          structureInfo.structure_logo
                        }
                        alt="logo-struttura"
                        className="w-[150px]"
                      />
                    </div>
                  )}
                  <div className="w-full text-center">
                    {t("payment.fillForm")}
                  </div>

                  <WarranityAlert />

                  <div className="flex flex-col items-start w-full">
                    <label>{t("payment.form.name")}</label>
                    <Input
                      className="w-full"
                      value={state.name}
                      onChange={(e) =>
                        setState({ ...state, name: e.target.value })
                      }
                    />
                  </div>
                  <div className="flex flex-col items-start w-full">
                    <label>{t("payment.form.surname")}</label>
                    <Input
                      className="w-full"
                      value={state.surname}
                      onChange={(e) =>
                        setState({ ...state, surname: e.target.value })
                      }
                    />
                  </div>
                  <div className="flex flex-col items-start w-full">
                    <label>{t("payment.form.email")}</label>
                    <Input
                      className="w-full"
                      value={state.email}
                      onChange={(e) =>
                        setState({ ...state, email: e.target.value })
                      }
                    />
                  </div>
                  <div className="flex flex-col items-start w-full">
                    <label>{t("payment.form.confirmEmail")}</label>
                    <Input
                      className="w-full"
                      value={state.confirm_email}
                      onChange={(e) =>
                        setState({ ...state, confirm_email: e.target.value })
                      }
                    />
                  </div>
                  <div className="flex flex-col items-start w-full">
                    <label>{t("payment.form.phone")}</label>
                    <PhoneInput
                      className="phone-input"
                      country={"it"}
                      value={state.phone}
                      onChange={(phone) => setState({ ...state, phone: phone })}
                    />
                  </div>
                  {preventivo.rows?.filter((el) => el.id_category).length >=
                    1 && (
                    <div className="flex flex-col items-start w-full">
                      <label>{t("payment.form.checkin_time")}</label>
                      <TimePicker
                        value={dayjs(state.checkin_time, format)}
                        onChange={(e) =>
                          setState({ ...state, checkin_time: e })
                        }
                        format={format}
                        showNow={false}
                        className="w-full"
                        allowClear={false}
                      />
                    </div>
                  )}
                </div>
              </StyleProvider>
            </div>
            <div
              className="p-10 justify-center flex bg-indigo-50 col-span-12 
          md:col-span-7 rounded-r-md bg-cover flex-col gap-6"
              style={{
                backgroundImage: 'url("/assets/images/bg-hotel.jpg")',
              }}
            >
              {preventivo.warranity_method === 0 ? (
                <PaymentForm
                  setIsDisabledPayment={setIsDisabledPayment}
                  card={card}
                  setCard={setCard}
                  t={t}
                />
              ) : preventivo.warranity_method === 2 ? (
                <NoWarrannityMethod />
              ) : (
                preventivo.warranity_method === 3 && (
                  <PaymentBonifico
                    setFile={setFile}
                    setIsDisabledPayment={setIsDisabledPayment}
                    bonificoCode={bonificoCode}
                    bonificoDetails={bonificoDetails}
                    istructions={preventivo.bonifico_text}
                  />
                )
              )}

              <div
                className={`font-semibold text-lg h-[50px] leading-[30px] 
             rounded-md flex items-center justify-center cursor-pointer hover:bg-blue-700 transition 
             ${
               isDisabled
                 ? "bg-gray-100 text-gray-400 pointer-events-none"
                 : "bg-blue-600 text-white"
             }`}
                disabled={isDisabled}
                onClick={doPayment}
              >
                {t("content.actions.confirmButton")}
              </div>
            </div>
          </div>
        )}
      </Spin>
    </Modal>
  );
};
export default PreventivoGuestPayment;
