import axios from "axios";
import { BACKEND_URL } from "../config/backendConfig";

class StructureService {
  getStructureInfo(params) {
    return axios.get(BACKEND_URL + "structure/" + params);
  }
  getRooms(params, search_base) {
    return axios.post(BACKEND_URL + "rooms/" + params, search_base);
  }
  getExcludedRanges(params) {
    return axios.get(BACKEND_URL + "structure/excluded/" + params);
  }
  getExcludedDates(params) {
    return axios.get(BACKEND_URL + "structure/dates/" + params);
  }
  getCancellationRules(params) {
    return axios.get(BACKEND_URL + "structure/policy/" + params);
  }
  getStructureTax(params, search_tax) {
    return axios.post(BACKEND_URL + "structure/tax/" + params, search_tax);
  }
  createTempBooking(params, temp_booking) {
    return axios.post(BACKEND_URL + "booking/" + params, temp_booking);
  }
  getBookingInfo(params) {
    return axios.get(BACKEND_URL + "booking/" + params);
  }
  getStructureExtras(params) {
    return axios.get(BACKEND_URL + "extra/" + params);
  }
  saveBooking(obj_booking) {
    return axios.post(BACKEND_URL + "booking/confirm", obj_booking);
  }
  saveBookingHobex(obj_booking) {
    return axios.post(BACKEND_URL + "booking/confirm/card", obj_booking);
  }
  getConfirmedDetails(params) {
    return axios.get(BACKEND_URL + "booking/confirm/" + params);
  }
  checkAvailability(params, obj_category) {
    return axios.post(BACKEND_URL + "room/free/" + params, obj_category);
  }
  saveBookingBonifico(formData) {
    return axios.post(BACKEND_URL + "booking/confirm/transfer", formData);
  }
  getStructureBonificoDetails(params) {
    return axios.get(BACKEND_URL + "structure/transfer/" + params);
  }
  checkRoomAvailability(params, cart) {
    return axios.post(BACKEND_URL + "booking/cart/add/check/" + params, cart);
  }
  getPrivacy(params) {
    return axios.get(BACKEND_URL + "structure/privacy/" + params);
  }
  getPackets(params) {
    return axios.get(BACKEND_URL + "packets/" + params);
  }
  getPacketInfo(params) {
    return axios.get(BACKEND_URL + "packet/" + params);
  }
  getPacketRooms(params) {
    return axios.get(BACKEND_URL + "packet/rooms/" + params);
  }
  createTempBookingPacket(params) {
    return axios.post(BACKEND_URL + "booking/temp/promo/" + params);
  }
  getPacketDates(params) {
    return axios.get(BACKEND_URL + "packet/dates/select/" + params);
  }
  getPaymentPacket(params) {
    return axios.get(BACKEND_URL + "packet/payment/" + params);
  }
  getPacketsList(params) {
    return axios.get(BACKEND_URL + "packets/list/" + params);
  }
  getNotRefundableDetails(params) {
    return axios.get(BACKEND_URL + "room/notrefundable/" + params);
  }
  getSuccessMessage(params) {
    return axios.get(BACKEND_URL + "confirm/message/" + params);
  }
}
export default new StructureService();
