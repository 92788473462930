import React from "react";
import { Form, Tooltip } from "antd";
function PreventivoCardRow({
  icon,
  label,
  text,
  value,
  editable,
  className,
  required,
}) {
  const RequiredElement = () => (
    <Tooltip title="Questo campo è obbligatorio">
      <span className="text-red-600">*&nbsp;</span>
    </Tooltip>
  );
  return (
    <Form.Item
      label={
        <div className="flex flex-row items-center gap-2 w-full">
          {icon}
          <span className="font-semibold text-slate-600">
            {required && <RequiredElement />}
            {label}
          </span>
        </div>
      }
      labelAlign="left"
      className={`!m-0 !my-2 ${className}`}
    >
      {value !== "" || !editable ? (
        <span className="ml-3 flex items-start w-full form-w-full">{text}</span>
      ) : (
        <div className="flex flex-row items-center gap-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5 text-slate-400"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
            />
          </svg>
          <span className="text-slate-400">vuoto</span>
        </div>
      )}
    </Form.Item>
  );
}

export default PreventivoCardRow;
