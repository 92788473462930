import React from "react";
import Path from "../components/Path";
import Cart from "./Cart";
import InformationFooter from "./InformationFooter";
import InformationForm from "./InformationForm";
import { Skeleton } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import paymentService from "../services/payment.service";

function Information() {
  const { uuid } = useParams();
  const [logo] = React.useState(
    "https://www.idaweb.eu/Images/Sys/ida2_logo.png"
  );
  const [checkout, setCheckout] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (uuid) fetchCheckout();
  }, []);

  const fetchCheckout = async () => {
    paymentService.getCheckout(uuid).then(
      (result) => {
        result = result.data;
        if (result.uuid) {
          setCheckout(result);
        } else {
          navigate("/error", {
            replace: true,
            state: {
              error: "L'ordine in questione non è stato trovato",
            },
          });
        }
        setIsLoading(false);
      },
      (error) => {
        if (error.response.status === 404) {
          navigate("/error", {
            replace: true,
            state: {
              error: "L'ordine in questione non è stato trovato",
            },
          });
        } else if (error.response.status === 500) {
          navigate("/error", {
            replace: true,
            state: {
              error: "Errore critico: " + error.response.data.ExceptionMessage,
            },
          });
        }
      }
    );
  };

  return (
    <div className="min-h-screen flex flex-col md:flex-row">
      <div className="w-full md:w-3/5 flex justify-end px-6 lg:px-24 pt-16 bg-white">
        <div className="max-w-2xl flex flex-col w-full gap-4 h-full">
          <div className="flex flex-row items-center">
            {logo && !isLoading && (
              <img src={logo} alt="logo" className="object-cover w-20" />
            )}
            <div
              className={`flex flex-col items-start gap-2 ${
                logo && !isLoading && "ml-5"
              }`}
            >
              <h1 className={`text-3xl`}>Pagina di pagamento</h1>
              {!isLoading && (
                <span className="text-xs text-gray-400">
                  ID: {checkout.hobex_id}
                </span>
              )}
            </div>
          </div>
          <InformationForm
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            checkout={checkout}
            setCheckout={setCheckout}
          />
          <InformationFooter />
        </div>
      </div>
      <div
        className="w-full md:w-2/5 bg-gray-100 border-l border-gray-200 
      flex justify-start px-0 sm:px-6 xl:px-24 lg:px-18 pt-16"
      >
        <div className="md:max-w-md flex flex-col w-full gap-4 h-full">
          {!isLoading && (
            <Cart
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              checkout={checkout}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Information;
