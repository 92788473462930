import axios from "axios";
import { BACKEND_URL } from "../config/backendConfig";

class PaymentsService {
  saveCheckout(products, token) {
    return axios.post(
      BACKEND_URL + "payment/checkout/create/" + token,
      products
    );
  }
  getProducts(token) {
    return axios.get(BACKEND_URL + "payment/products/" + token);
  }
}
export default new PaymentsService();
