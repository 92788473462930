export const placeholders = [
  {
    value: "cliente.nome",
    label: "Nome del cliente / azienda",
  },
  {
    value: "cliente.cognome",
    label: "Cognome del cliente (se presente)",
  },
  {
    value: "struttura.nome",
    label: "Il nome della struttura",
  },
  {
    value: "struttura.email",
    label: "Indirizzo Email della struttura",
  },
  {
    value: "struttura.city",
    label: "La città della struttura",
  },
  {
    value: "struttura.provincia",
    label: "La provincia della struttura",
  },
  {
    value: "struttura.indirizzo",
    label: "L'indirizzo della struttura",
  },
  {
    value: "struttura.telefono",
    label: "Il telefono della struttura",
  },
  {
    value: "struttura.iva",
    label: "La partita iva della struttura",
  },
  {
    value: "struttura.iban",
    label: "L'iban della struttura",
  },
  {
    value: "struttura.cf",
    label: "Il codice fiscale della struttura",
  },
  {
    value: "preventivo.scadenza",
    label: "La data e ora di scadenza di questo preventivo",
  },
  {
    value: "preventivo.warranity",
    label: "Il metodo di garanzia di questo preventivo",
  },
  {
    value: "preventivo.condizioni",
    label: "Le condizioni di questo preventivo",
  },
];
