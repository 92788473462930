import { Spin } from "antd";
import React, { useEffect } from "react";

function SearchBar({ filter, setFilter, search, setSearch, setRemoveFilter }) {
  return (
    <div className="flex">
      <Spin spinning={search}>
        <div className="flex flex-row items-center gap-3">
          <div className="relative text-gray-600">
            <input
              className="border-1 border-gray-300 bg-white h-10 
        px-5 pr-8 rounded-lg text-sm focus:outline-none"
              type="text"
              name="search"
              placeholder="Cerca struttura"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
            <button
              type="submit"
              onClick={() => setSearch(true)}
              className="absolute right-0 top-0 mt-3 mr-3"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="text-gray-600 h-4 w-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
            </button>
          </div>
          <button
            onClick={() => {
              setRemoveFilter(true);
              setFilter("");
            }}
            type="button"
            className={`inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 
            shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50             ${
              !filter &&
              "!pointer-events-none !bg-gray-50 !text-gray-300 hover:!bg-gray-100"
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className={`-ml-0.5 mr-1.5 h-5 w-5 text-gray-400 ${
                !filter && "!text-gray-300 "
              }`}
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
            Rimuovi Filtro
          </button>
        </div>
      </Spin>
    </div>
  );
}

export default SearchBar;
