import { Button, Modal, Space } from "antd";
import preventiviService from "../../services/preventivi.service";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";

const PreventivoDeleteConfirm = ({ open, setOpen, preventivo, setLoading }) => {
  const { token } = useParams();
  const navigate = useNavigate();

  const deletePreventivo = () => {
    setLoading(true);
    setOpen(false);
    preventiviService
      .deletePreventivo(`${token}/${preventivo.id_preventivo}`)
      .then(
        (result) => {
          result = result.data;
          if (result.success) {
            toast.success("Il preventivo è stato eliminato.");
            window.location.replace("/preventivi/list/" + token);
          } else {
            toast.error(result.model);
          }
        },
        (error) => {
          toast.error(error.response.data.ExceptionMessage);
          setLoading(false);
        }
      );
  };
  return (
    <Modal
      title="Conferma eliminazione"
      footer={[
        <Button key="back" onClick={() => setOpen(false)}>
          Annulla
        </Button>,
        <Button key="submit" type="primary" onClick={deletePreventivo} danger>
          Sì, Elimina
        </Button>,
      ]}
      open={open}
      onCancel={() => setOpen(false)}
      type="warning"
    >
      <p>Si sta per eliminare il preventivo corrente.</p>
      <p>L'operazione è irreversibile.</p>
      <br></br>
      <p>Proseguire?</p>
    </Modal>
  );
};
export default PreventivoDeleteConfirm;
