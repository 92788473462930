import { Button, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import structureService from "../../services/structure.service";
import { GetLabel } from "../../languages/i18n";
import cloneDeep from "lodash/cloneDeep";

function NotRefundable({
  open,
  uuid,
  language,
  idCategory,
  priceRoom,
  setPriceRoom,
  rooms,
  addCart,
}) {
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({});
  const [price, setPrice] = useState(0);

  function calcPrice(discount_perc, discount_value) {
    let total = 0.0;
    if (discount_perc) {
      total =
        parseFloat(priceRoom) -
        (parseFloat(priceRoom) / 100) * parseFloat(discount_perc);
    } else {
      total = parseFloat(priceRoom) - parseFloat(discount_value);
    }
    return parseFloat(total).toFixed(2);
  }

  useEffect(() => {
    if (open) {
      fetchRefundable();
    }
  }, [open]);

  const fetchRefundable = () => {
    setLoading(true);
    structureService
      .getNotRefundableDetails(language + "/" + uuid + "/" + idCategory)
      .then((result) => {
        setLoading(false);
        if (result.status === 200) {
          setState(result.data);
          setPrice(
            calcPrice(result.data.discount_perc, result.data.discount_value)
          );
        }
      });
  };

  return (
    <div
      className={`bg-white rounded-b-lg p-6 mt-1 flex items-center justify-center ${
        open ? "animate__animated animate__fadeInDown" : "hidden"
      }`}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <div className="grid grid-cols-12 w-full">
          <div className="flex flex-col gap-2 col-span-12 md:col-span-9">
            <h3 className="font-semibold text-2xl">{state.title}</h3>
            <p className="text-sm text-gray-600">{state.description}</p>
          </div>

          <div className="col-span-12 md:col-span-3 items-center justify-center pl-[16px] flex flex-col gap-2">
            <h2 className="text-2xl font-bold text-green-500">
              {price} &euro;
            </h2>
            <Button
              variant="contained"
              onClick={() => {
                let roomToAdd = cloneDeep(rooms[0]);
                roomToAdd.price = price;
                addCart(roomToAdd, true, true);
              }}
              sx={{
                borderRadius: "9999px",
                width: { sm: "auto", xs: "100%" },
                fontWeight: "600",
              }}
              color="secondary"
              className="mr-2"
            >
              {GetLabel(language, "LABEL_BOOK_NOW")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default NotRefundable;
