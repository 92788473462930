import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { GetLabel } from "../../languages/i18n";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  maxHeight: 800,
  overflowY: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "15px",
  p: 4,
};

export default function NewsletterPolicy(props) {
  const handleClose = () => props.setOpen(false);
  return (
    <div>
      <Modal open={props.open} onClose={handleClose}>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {GetLabel(props.language, "LABEL_NEWSLETTER_TERMS_TITLE")}
          </Typography>
          <Typography className="d-lb" sx={{ mt: 2 }}>
            {GetLabel(props.language, "LABEL_NEWSLETTER_TERMS_PART1")}
            <br></br>
            <br></br>
            {GetLabel(props.language, "LABEL_NEWSLETTER_TERMS_PART2")}
            <br></br>
            <br></br>
            {GetLabel(props.language, "LABEL_NEWSLETTER_TERMS_PART3")}
            <br></br>
            <br></br>
            {GetLabel(props.language, "LABEL_NEWSLETTER_TERMS_PART4")}
            <br></br>
            <br></br>
            {GetLabel(props.language, "LABEL_NEWSLETTER_TERMS_PART5")}
            <br></br>
            <br></br>
            {GetLabel(props.language, "LABEL_NEWSLETTER_TERMS_PART6")}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
