import React from "react";
import PreventivoHelper from "../PreventivoHelper";
import { QuestionCircleOutlined } from "@ant-design/icons";

const content = (
  <div className="max-w-md max-h-52 overflow-y-auto">
    In questa sezione viene riportato il prezzo proposto per questo preventivo
    per la categoria in esame.
    <br></br>
    <br></br>
    Il <b>Prezzo barrato</b> è il costo SOVRAPREZZATO TOTALE della stanza per il
    periodo di soggiorno inserito.
    <br></br>
    <br></br>
    Questo valore viene compilato automaticamente in fase di selezione della
    categoria e mostra il costo della categoria del primo OTA di IdaWeb (in
    genere Booking) della vostra struttura.
    <br></br>
    <br></br>
    Il prezzo barrato rimane comunque modificabile a piacimento,{" "}
    <b>
      questo valore deve <u>sempre</u> essere superiore al prezzo normale della
      categoria
    </b>{" "}
    in quanto evidenzia il guadagno dell'ospite prenotando direttamente in
    struttura.
    <br></br>
    <br></br>
    Il <b>Prezzo</b> indica il costo EFFETTIVO TOTALE della stanza per il
    periodo di soggiorno inserito. Questo valore è l'ammontare che il cliente
    dovrà pagare per il suo soggiorno nonchè prezzo della camera proposto nel
    preventivo.
    <br></br>
    <br></br>
    Questo valore viene compilato automaticamente in fase di selezione della
    categoria e mostra il costo della categoria corrente del listino base
    DIRETTO di IdaWeb, per cui il prezzo base senza provvigioni OTA.
    <br></br>
    <br></br>
    Il prezzo rimane modificabile a piacimento e{" "}
    <b>
      questo valore deve <u>sempre</u> essere inferiore al prezzo barrati della
      categoria
    </b>{" "}
  </div>
);

function PrezzoHelper() {
  return (
    <PreventivoHelper
      title={<h3 className="text-lg">Il prezzo della categoria</h3>}
      content={content}
    >
      <QuestionCircleOutlined
        style={{ fontSize: "18px" }}
        className="text-blue-500"
      />
    </PreventivoHelper>
  );
}

export default PrezzoHelper;
