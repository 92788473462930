import React from "react";
import PreventivoHelper from "../PreventivoHelper";
import { QuestionCircleOutlined } from "@ant-design/icons";

const content = (
  <div className="max-w-md max-h-52 overflow-y-auto">
    Le tempistiche di un preventivo indicano tutti gli intervalli temporali di
    riferimento per assicurare una gestione perfetta della prevendita.
    <br />
    <br />
    La <span className="text-blue-500 font-medium">Scadenza</span> è
    modificabile e consente di scegliere fino a quando il preventivo sarà
    visualizzabile e confermabile dal cliente.
    <br />
    <br />
    La <span className="text-blue-500 font-medium">Data di creazione</span>{" "}
    indica quando è stato creato il preventivo.
    <br />
    <br />
    La{" "}
    <span className="text-blue-500 font-medium">
      Data di visualizzazione
    </span>{" "}
    indica quando il preventivo è stato visualizzato <u>l'ultima volta</u> dal
    cliente.
    <br />
    <br />
    La <span className="text-green-500 font-medium">Data di conferma</span>{" "}
    indica quando il preventivo è stato accettato dall'utente
    <br />
    <br />
    La <span className="text-red-500 font-medium">Data di rifiuto</span> indica
    quando il preventivo è stato rifiutato dall'utente
  </div>
);

function TempisticheHelper() {
  return (
    <PreventivoHelper
      title={<h3 className="text-lg">Le tempistiche del preventivo</h3>}
      content={content}
    >
      <QuestionCircleOutlined
        className="text-blue-500"
        style={{ fontSize: "18px" }}
      />
    </PreventivoHelper>
  );
}

export default TempisticheHelper;
