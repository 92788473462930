import { Card } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Box } from "@mui/system";
import * as React from "react";
import { GetLabel } from "../../languages/i18n";

export default function ExtraPayment(props) {
  const [mytotal, setMyTotal] = React.useState(0.0);
  const [extraTotal, setExtraTotal] = React.useState([]);

  React.useEffect(() => {
    // clone deep
    setMyTotal(
      props.temp_packet && props.temp_packet.final_price
        ? JSON.parse(JSON.stringify(props.temp_packet.final_price))
        : JSON.parse(JSON.stringify(props.total))
    );
  }, [props.temp_packet]);

  React.useEffect(() => {
    checkTotalChange();
  }, [extraTotal]);

  const handleChange = (event, id_extra) => {
    props.setExtras(
      props.extras.map((item) => {
        if (item.id_extra === id_extra) {
          item.is_checked = event.target.checked;
        }

        if (event.target.checked) {
          setExtraTotal([
            ...extraTotal,
            {
              id_extra: id_extra,
              qty: 1,
              total: props.extras.filter((ex) => ex.id_extra === id_extra)[0]
                .obj_product.price_unit,
            },
          ]);
        } else {
          setExtraTotal(extraTotal.filter((ex) => ex.id_extra !== id_extra));
          props.setExtras(
            props.extras.map((ex) => {
              if (ex.id_extra === id_extra) ex.qty = 1;
              return ex;
            })
          );
        }
        return item;
      })
    );
  };
  const checkTotalChange = () => {
    let new_total =
      props.temp_packet && props.temp_packet.final_price
        ? JSON.parse(JSON.stringify(props.temp_packet.final_price))
        : JSON.parse(JSON.stringify(props.total));
    extraTotal.map((ex) => {
      new_total += ex.qty * ex.total;
    });
    setMyTotal(new_total);
  };
  const setQuantityExtra = (e, id_extra) => {
    setExtraTotal(
      extraTotal.map((item) => {
        if (item.id_extra === id_extra) item.qty = e.target.value;
        return item;
      })
    );
  };

  return (
    <Card sx={{ borderRadius: "10px", marginTop: "20px" }}>
      <h4
        style={{
          padding: "20px",
          fontSize: "20px",
          fontWeight: "400",
          margin: "0",
          marginTop: "10px",
        }}
      >
        {GetLabel(props.language, "SUBTITLE_EXTRAS")}
      </h4>

      <div
        className="extra-scroll"
        style={{ padding: "20px", maxHeight: "400px", overflowY: "auto" }}
      >
        {props.extras.map((item, index) => {
          return (
            <div className="extra" key={index}>
              <p className="extra-title">{item.obj_product.description}</p>
              <div className="extra-container">
                <Box
                  className="extra-col"
                  sx={{ width: { sm: "60%", xs: "80%" } }}
                >
                  <div className="extra-check">
                    <Checkbox
                      checked={item.is_checked}
                      onChange={(e) => handleChange(e, item.id_extra)}
                    />
                  </div>
                  <img
                    src={"https://www.idaweb.eu/" + item.obj_product.image}
                    className="extra-img"
                    loading="lazy"
                  ></img>
                  <p className="extra-description">{item.obj_product.note}</p>
                </Box>
                <Box className="extra-col" style={{ marginLeft: "auto" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p className="extra-price">
                      € {parseFloat(item.obj_product.price_unit).toFixed(2)}
                    </p>
                    {item.is_checked && (
                      <select
                        className="extra-qty-select"
                        onChange={(e) => setQuantityExtra(e, item.id_extra)}
                        value={
                          extraTotal.filter(
                            (el) => el.id_extra === item.id_extra
                          )[0].qty
                        }
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                    )}
                  </div>
                </Box>
              </div>
            </div>
          );
        })}
      </div>
      <div className="extra-footer">
        <span className="extra-footer-label">
          {GetLabel(props.language, "LABEL_EXTRA_TOTAL")}
        </span>
        <h4 className="extra-footer-total">
          € {parseFloat(mytotal).toFixed(2)}
        </h4>
      </div>
    </Card>
  );
}
