import styled from "@emotion/styled";

export const Title = styled.h1`
  color: var(--text-primary);
`;
export const Text = styled.p`
  color: var(--text-primary);
  font-size: 15px;
`;
export const SecondaryText = styled.p`
  color: var(--text-secondary) !important;
  font-size: 22px;
  font-weight: 600;
  margin-top: 2rem;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 20px;
`;
export const Bolder = styled.h1`
  font-weight: 800;
  color: var(--text-primary);
  text-transform: uppercase;
  font-size: 2rem;
`;
export const Helper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;
export const HelperText = styled.span`
  margin-top: 20px;
  color: #2b5cdf;
`;
export const Flex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const Striked = styled.span`
  color: red;
  text-decoration: line-through;
`;
export const Radio = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;

  & input {
    width: auto;
  }
  & span {
    color: var(--text-primary);
    font-size: 20px;
    font-weight: 500;
    margin-left: 20px;
  }
`;
export const Margined = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  color: var(--text-primary);
  text-align: center;

  & h3 {
    font-size: 20px;
  }

  &.container-success {
    width: 600px;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .MuiButton-root {
    text-transform: none;
    font-weight: 600 !important;
    padding: 10px;
  }
`;
export const SimpleSeparator = () => {
  return (
    <div
      style={{
        borderBottom: "1px solid #ddd",
        height: "5px",
        width: "100%",
        marginTop: "30px",
        marginBottom: "30px",
      }}
    ></div>
  );
};
