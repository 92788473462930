import { Card } from "@mui/material";

export default function PaymentRowDays(props) {
  return (
    <div className="payment-day-row">
      {props.days && (
        <div className="payment-day-row-container">
          {props.days.map((day) => {
            return (
              <div className="payment-day">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <span className="payment-day-text">
                    {day.date_ref_day}&nbsp;
                  </span>
                  <span className="payment-day-box-day-month">
                    {day.date_ref_day_month}
                  </span>
                </div>
                {day.striked_price && (
                  <span className="text-red-600 line-through">
                    {parseFloat(day.striked_price).toFixed(2)}
                  </span>
                )}
                <span className="payment-day-box-price">
                  €{" "}
                  {props.packet && props.packet.id_promo
                    ? parseFloat(
                        props.temp_packet.final_price / props.days.length
                      ).toFixed(2)
                    : parseFloat(day.price).toFixed(2)}
                </span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
