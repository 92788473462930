import React from "react";
import { Alert } from "antd";
import PreventivoRoom from "./PreventivoRoom";
import PreventivoCommunication from "./PreventivoCommunication";
import "react-medium-image-zoom/dist/styles.css";
import PreventivoBonifico from "./Components/PreventivoBonifico";
import PreventivoCreditCard from "./Components/PreventivoCreditCard";
import PreventivoDettagli from "./Components/PreventivoDettagli";
import PreventivoTempistiche from "./Components/PreventivoTempistiche";
import PreventivoGaranzie from "./Components/PreventivoGaranzie";
import PreventivoMessaggiConferma from "./Components/PreventivoMessaggiConferma";
import PreventivoMessage from "./Components/PreventivoMessage";

function PreventivoForm({ preventivo, setPreventivo, UpdatePreventivo }) {
  return (
    <div className="p-10 pt-0">
      <div className="grid grid-cols-12 gap-3">
        {preventivo.status === 2 && (
          <div className="col-span-12">
            <Alert
              message={
                <div className="flex flex-row items-center">
                  <b className="text-green-700 text-lg">Buone Notizie!&nbsp;</b>
                  <span>Questo preventivo è stato accettato dal cliente</span>
                </div>
              }
              type="success"
              showIcon
            />
          </div>
        )}

        {preventivo.status === 3 && (
          <div className="col-span-12">
            <Alert
              message={
                <div className="flex flex-row items-center">
                  <b className="text-red-700 text-lg">Uh oh!&nbsp;</b>
                  <span>Il cliente non ha accettato la tua proposta</span>
                </div>
              }
              type="error"
              showIcon
            />
          </div>
        )}

        <div className="flex flex-col gap-3 col-span-12 lg:col-span-6">
          {preventivo.card &&
            preventivo.warranity_method === 0 &&
            preventivo.card.id_credit_card && (
              <PreventivoCreditCard
                preventivo={preventivo}
                setPreventivo={setPreventivo}
              />
            )}
          {preventivo.transfer &&
            preventivo.warranity_method === 3 &&
            preventivo.transfer.id_transfer && (
              <PreventivoBonifico
                preventivo={preventivo}
                setPreventivo={setPreventivo}
              />
            )}

          <PreventivoDettagli
            preventivo={preventivo}
            UpdatePreventivo={UpdatePreventivo}
          />
          <PreventivoMessage
            preventivo={preventivo}
            UpdatePreventivo={UpdatePreventivo}
          />
          <PreventivoTempistiche
            preventivo={preventivo}
            UpdatePreventivo={UpdatePreventivo}
            setPreventivo={setPreventivo}
          />
          <PreventivoGaranzie
            preventivo={preventivo}
            UpdatePreventivo={UpdatePreventivo}
            setPreventivo={setPreventivo}
          />
          <PreventivoMessaggiConferma
            preventivo={preventivo}
            UpdatePreventivo={UpdatePreventivo}
            setPreventivo={setPreventivo}
          />
        </div>
        <div className="flex flex-col gap-3 col-span-12 lg:col-span-6">
          <PreventivoRoom
            preventivo={preventivo}
            setPreventivo={setPreventivo}
          />
          <PreventivoCommunication
            preventivo={preventivo}
            setPreventivo={setPreventivo}
          />
        </div>
      </div>
    </div>
  );
}

export default PreventivoForm;
