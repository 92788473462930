import React, { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingIndicator from "../../components/LoadingIndicator";
import webService from "../../services/web.service";
import { toast } from "react-hot-toast";
import SlideOver from "./SlideOver";
import TextArea from "antd/es/input/TextArea";
import { Input } from "antd";
import { StyleProvider } from "@ant-design/cssinjs";

function Restaurants({ language }) {
  const { token, id_config } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [restaurant, setRestaurant] = React.useState({
    title_1: "",
    title_2: "",
    description_1: "",
    description_2: "",
    image_1: "",
    image_2: "",
    title_1_obj: {
      text: "",
    },
    title_2_obj: {
      text: "",
    },
    description_1_obj: {
      text: "",
    },
    description_2_obj: {
      text: "",
    },
  });
  const [open, setOpen] = React.useState(false);
  const inputFileRef1 = useRef();
  const inputFileRef2 = useRef();

  const onBtnClick1 = () => {
    inputFileRef1.current.click();
  };
  const onBtnClick2 = () => {
    inputFileRef2.current.click();
  };
  function handleImageChange1(e) {
    setRestaurant({
      ...restaurant,
      image_1: URL.createObjectURL(e.target.files[0]),
      image_1_obj: e.target.files[0],
    });
  }
  function handleImageChange2(e) {
    setRestaurant({
      ...restaurant,
      image_2: URL.createObjectURL(e.target.files[0]),
      image_2_obj: e.target.files[0],
    });
  }

  React.useEffect(() => {
    if (id_config) fetchRestaurants();
  }, [language]);

  const fetchRestaurants = async () => {
    setLoading(true);
    webService.getRestaurant(token, id_config, language.value).then(
      (result) => {
        const status = result.status;
        if (status === 200) {
          setRestaurant(result.data);
        } else {
          toast.error("Si è verificato un errore");
        }
        setLoading(false);
      },
      (error) => {
        toast.error("Si è verificato un errore");
        setLoading(false);
      }
    );
  };

  const saveRestaurant = async () => {
    const formData = new FormData();
    formData.append("image1", restaurant.image_1_obj);
    formData.append("image2", restaurant.image_2_obj);
    formData.append(
      "restaurant",
      JSON.stringify({
        ...restaurant,
        image_1_obj: undefined,
        image_2_obj: undefined,
      })
    );

    webService.saveRestaurant(token, id_config, language.value, formData).then(
      (result) => {
        if (result.status === 200) {
          setRestaurant(result.data);
          toast.success("Il ristorante è stato salvato");
        } else toast.error("Si è verificato un errore");
      },
      (error) => {
        toast.error("Si è verificato un errore");
      }
    );
  };

  const Image1 = ({ url }) => (
    <div className="rounded-lg bg-white shadow p-5 w-64">
      <div className="flex flex-row items-center">
        <div className="flex flex-col">
          <h2 className="font-semibold text-lg">Foto #1</h2>
        </div>
        <input
          type="file"
          onChange={handleImageChange1}
          ref={inputFileRef1}
          className="hidden"
        />
        <span
          className="ml-auto cursor-pointer text-indigo-600 
                py-2 text-base font-medium 
                 hover:text-indigo-700"
          onClick={onBtnClick1}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
            />
          </svg>
        </span>
        {url && (
          <span
            className="cursor-pointer text-indigo-600 
                px-5 py-2 text-base font-medium 
                 hover:text-indigo-700"
            onClick={() => {
              if (window.confirm("Eliminare la foto selezionata?")) {
                setRestaurant({ ...restaurant, image_1: null });
              }
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
        )}
      </div>
      <div className="mt-5 h-full">
        {url ? (
          <img
            src={url}
            alt={"surroundings-preview-2"}
            className="object-cover w-64 max-w-64 rounded-lg mt-5"
            loading="lazy"
          />
        ) : (
          <div className="flex flex-col items-center h-full w-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-12 h-12 text-gray-300"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H6.911a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661z"
              />
            </svg>
            <p className="text-gray-300">Nessuna foto.</p>
          </div>
        )}
      </div>
    </div>
  );

  const Image2 = ({ url }) => (
    <div className="rounded-lg bg-white shadow p-5 w-64">
      <div className="flex flex-row items-center">
        <div className="flex flex-col">
          <h2 className="font-semibold text-lg">Foto #2</h2>
        </div>
        <input
          type="file"
          onChange={handleImageChange2}
          ref={inputFileRef2}
          className="hidden"
        />
        <span
          className="ml-auto cursor-pointer text-indigo-600 
                py-2 text-base font-medium 
                 hover:text-indigo-700"
          onClick={onBtnClick2}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
            />
          </svg>
        </span>
        {url && (
          <span
            className="cursor-pointer text-indigo-600 
                px-5 py-2 text-base font-medium 
                 hover:text-indigo-700"
            onClick={() => {
              if (window.confirm("Eliminare la foto selezionata?")) {
                setRestaurant({ ...restaurant, image_2: null });
              }
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
        )}
      </div>
      <div className="mt-5 h-full">
        {url ? (
          <img
            src={url}
            alt={"surroundings-preview-2"}
            className="object-cover w-64 max-w-64 rounded-lg mt-5"
            loading="lazy"
          />
        ) : (
          <div className="flex flex-col items-center h-full w-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-12 h-12 text-gray-300"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H6.911a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661z"
              />
            </svg>
            <p className="text-gray-300">Nessuna foto.</p>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <>
      <StyleProvider hashPriority="high">
        <SlideOver open={open} setOpen={setOpen}></SlideOver>

        {loading ? (
          <div className="w-full h-full flex items-center justify-center">
            <LoadingIndicator />
          </div>
        ) : (
          <div className="bg-gray-100 p-5">
            <div className="pt-5 pr-5 w-full flex justify-end">
              <button
                className={`rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                onClick={saveRestaurant}
                type="button"
              >
                Salva ristorante
              </button>
            </div>

            <div className="flex flex-row items-center gap-5 w-full mb-10">
              <Image1 url={restaurant.image_1} />
              <div className="flex flex-col gap-2 w-full">
                <label>Titolo 1:</label>
                <Input
                  value={restaurant.title_1_obj.text}
                  onChange={(e) =>
                    setRestaurant({
                      ...restaurant,
                      title_1_obj: {
                        ...restaurant.title_1_obj,
                        text: e.target.value,
                      },
                    })
                  }
                />

                <label className="mt-3">Descrizione 1:</label>
                <TextArea
                  value={restaurant.description_1_obj.text}
                  onChange={(e) =>
                    setRestaurant({
                      ...restaurant,
                      description_1_obj: {
                        ...restaurant.description_1_obj,
                        text: e.target.value,
                      },
                    })
                  }
                  rows={4}
                />
              </div>
            </div>

            <div className="flex flex-row items-center gap-5 w-full">
              <Image2 url={restaurant.image_2} />
              <div className="flex flex-col gap-2 w-full">
                <label>Titolo 2:</label>
                <Input
                  value={restaurant.title_2_obj.text}
                  onChange={(e) =>
                    setRestaurant({
                      ...restaurant,
                      title_2_obj: {
                        ...restaurant.title_2_obj,
                        text: e.target.value,
                      },
                    })
                  }
                />

                <label className="mt-3">Descrizione 2:</label>
                <TextArea
                  value={restaurant.description_2_obj.text}
                  onChange={(e) =>
                    setRestaurant({
                      ...restaurant,
                      description_2_obj: {
                        ...restaurant.description_2_obj,
                        text: e.target.value,
                      },
                    })
                  }
                  rows={4}
                />
              </div>
            </div>
          </div>
        )}
      </StyleProvider>
    </>
  );
}

export default Restaurants;
