import "./style.scss";
import StepWizard from "react-step-wizard";
import Header from "./partials/header";
import Container from "./partials/container";
import Step1 from "./partials/steps/step1";
import Step2 from "./partials/steps/step2";
import "animate.css";
import WizardStepContainer from "./partials/wizardStepContainer";
import React from "react";
import WizardButtons from "./partials/wizardButtons";
import Step3 from "./partials/steps/step3";
import Step4 from "./partials/steps/step4";
import Step5 from "./partials/steps/step5";
import Step6 from "./partials/steps/step6";
import Step7 from "./partials/steps/step7";
import Step8 from "./partials/steps/step8";
import Step9 from "./partials/steps/step9";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import pricesService from "../../services/prices.service";
import { useStateWithCallbackLazy } from "use-state-with-callback";

export default function Prices(props) {
  const { token } = useParams();
  var cloneDeep = require("lodash.clonedeep");

  const [isLoading, setIsLoading] = React.useState(true);
  const [rooms, setRooms] = React.useState([]);
  const [images, setImages] = React.useState();

  let custom = {
    enterRight: "animate__animated animate__backInRight",
    enterLeft: "animate__animated animate__backInLeft",
    exitRight: "animate__animated animate__backOutRight",
    exitLeft: "animate__animated animate__backOutLeft",
  };
  const [state, setState] = useStateWithCallbackLazy({
    token: "",
    n_categories: 1,
    base_price: 0.0,
    last_minute: true,
    cutoff: 7,
    trattamento: 934,
    createNewYear: false,
    categories: [
      {
        name: "",
      },
    ],
    channels: [
      {
        id: 1,
        name: "Booking.com",
        code: "",
        image:
          "https://cf.bstatic.com/static/img/booking_logo_knowledge_graph/247454a990efac1952e44dddbf30c58677aa0fd8.png",
        value: 0.0,
        type: "%",
      },
      {
        id: 2,
        name: "AirBnB",
        code: "",
        image:
          "https://www.theriver.asia/wp-content/uploads/2020/01/kisspng-airbnb-logo-coupon-privately-held-company-airbnb-logo-5b167f0c91db57.0436925715282009725974.png",
        value: 0.0,
        type: "%",
      },
      {
        id: 3,
        name: "Expedia",
        code: "",
        image:
          "https://www.mrdeals.it/wp-content/uploads/2019/02/expedia-logo-300x300.jpg",
        value: 0.0,
        type: "%",
      },
    ],
  });

  let navigate = useNavigate();

  React.useEffect(() => {
    document.title = "Crea il tuo listino prezzi";
    if (token) {
      fetchConfig();
    }
  }, []);

  const fetchConfig = async () => {
    setIsLoading(true);
    pricesService.getConfig({ token: token }).then(
      (result) => {
        result = result.data;
        if (!result.success) {
          navigate("/prices/error", {
            state: {
              error: result.model,
            },
          });
        } else {
          setState(
            {
              ...state,
              channels: JSON.parse(result.model.ota),
              token: token,
            },
            (newState) => {
              fetchRooms(newState);
            }
          );
        }
        setIsLoading(false);
      },
      (error) => {}
    );
  };

  const fetchRooms = async (newState) => {
    pricesService.getRooms({ token: newState.token }).then(
      (result) => {
        result = result.data;
        setRooms(result);

        setState({
          ...newState,
          categories: newState.categories.map((cat) => {
            cat.rooms = cloneDeep(result);
            return cat;
          }),
        });
      },
      (error) => {}
    );
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div className="prices">
          <Header />
          <Container>
            <StepWizard transitions={custom} className="step-wiz">
              <WizardStepContainer>
                <Step1 state={state} setState={setState} rooms={rooms} />
              </WizardStepContainer>
              <WizardStepContainer>
                <Step2
                  state={state}
                  setState={setState}
                  rooms={rooms}
                  setRooms={setRooms}
                />
              </WizardStepContainer>
              <WizardStepContainer>
                <Step3 state={state} setState={setState} />
              </WizardStepContainer>
              <WizardStepContainer>
                <Step4 state={state} setState={setState} />
              </WizardStepContainer>
              <WizardStepContainer>
                <Step5 state={state} setState={setState} />
              </WizardStepContainer>
              <WizardStepContainer>
                <Step6 state={state} setState={setState} />
              </WizardStepContainer>
              <WizardStepContainer>
                <Step7
                  state={state}
                  setState={setState}
                  images={images}
                  setImages={setImages}
                />
              </WizardStepContainer>
              <WizardStepContainer>
                <Step8 state={state} setState={setState} />
              </WizardStepContainer>
              <WizardStepContainer>
                <Step9 state={state} setState={setState} images={images} />
              </WizardStepContainer>
            </StepWizard>
          </Container>
        </div>
      )}
    </>
  );
}
