import { TagsFilled } from "@ant-design/icons";
import { Alert, Button, Input, Tag } from "antd";
import React from "react";

function CartCoupon(props) {
  const [appliedDiscount, setAppliedDiscount] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");

  const checkDiscountCode = () => {
    setAlertMessage("");
    if (props.discount.discount_code === "ABCDE") {
      setAppliedDiscount(props.discount.discount_code);
      props.setDiscount({
        discount_value: 10.0,
        discount_code: "ABCDE",
      });
      setSuccessMessage("Il coupon è stato accettato.");
    } else {
      setAlertMessage("Il coupon inserito non è valido");
    }
  };
  const removeDiscount = () => {
    props.setDiscount({
      discount_value: 0.0,
      discount_code: "",
    });
    setAppliedDiscount("");
    setAlertMessage("");
    setSuccessMessage("");
  };

  return (
    <div className="flex flex-col gap-3 py-4">
      {alertMessage && (
        <Alert message={alertMessage} type="warning" showIcon closable />
      )}
      {successMessage && (
        <Alert message={successMessage} type="success" showIcon closable />
      )}
      <div className="flex flex-row items-center gap-3">
        <Input
          size="large"
          value={props.discount.discount_code}
          onChange={(e) =>
            props.setDiscount({
              ...props.discount,
              discount_code: e.target.value,
            })
          }
          placeholder="Codice sconto"
          disabled={appliedDiscount ? true : false}
          allowClear={true}
        />
        <Button
          type="dashed"
          size="large"
          disabled={
            appliedDiscount ? true : props.discount.discount_code ? false : true
          }
          onClick={checkDiscountCode}
        >
          Applica
        </Button>
      </div>
      {appliedDiscount && (
        <div className="flex flex-row items-center justify-start">
          <Tag
            closable
            onClose={removeDiscount}
            className="flex flex-row items-center p-2 px-4 !text-lg
           font-semibold !border-none bg-gray-200 cart-tag text-gray-500 gap-0"
          >
            <TagsFilled />
            <span className="!text-gray-600">{appliedDiscount}</span>
          </Tag>
        </div>
      )}
    </div>
  );
}

export default CartCoupon;
