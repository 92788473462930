import { Button, message, Steps, theme } from "antd";
import { useEffect, useState } from "react";
import { StyleProvider } from "@ant-design/cssinjs";
import FirstStep from "./Steps/FirstStep";
import SecondStep from "./Steps/SecondStep";
import dayjs from "dayjs";
import ThirdStep from "./Steps/ThirdStep";
import preventiviService from "../../../services/preventivi.service";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PreventivoRequestSteps = ({ onConfirm }) => {
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const { uuid } = useParams();
  const [current, setCurrent] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const { t, i18n } = useTranslation("common");

  useEffect(() => {
    fetchServices();
  }, [i18n.language]);

  const fetchServices = async () => {
    preventiviService.getPreventivoRequestServices(uuid, i18n.language).then(
      (result) => {
        if (result.status === 200)
          setStateThirdStep({ ...stateThirdStep, services: result.data });
      },
      (error) => {}
    );
  };

  const [stateFirstStep, setStateFirstStep] = useState({
    name: "",
    surname: "",
    email: "",
    phone: "",
    country: "Italia",
    province: "Roma",
    is_italian: true,
    contact_type: 2,
    privacy: false,
    newsletter: false,
  });
  const [stateSecondStep, setStateSecondStep] = useState({
    rooms: [
      {
        id: 1,
        dates: [dayjs(), dayjs().add(2, "day")],
        treatment: "ALLIN",
        adults: 2,
        children: 0,
      },
    ],
  });
  const [stateThirdStep, setStateThirdStep] = useState({
    notes: "",
    services: [
      // {
      //   id: 1,
      //   checked: false,
      //   price: 5,
      //   title: "Cuccia per cane",
      //   is_day: false,
      // },
      // {
      //   id: 2,
      //   checked: false,
      //   price: 10,
      //   title: "Posto auto sotto al B&B",
      //   is_day: true,
      // },
      // {
      //   id: 3,
      //   checked: false,
      //   price: 7,
      //   title: "Garage per moto",
      //   is_day: true,
      // },
      // {
      //   id: 4,
      //   checked: false,
      //   price: 20,
      //   title: "Pulizia finale per cane",
      //   is_day: false,
      // },
    ],
  });

  const steps = [
    {
      title: t("request.steps.step1"),
      content: (
        <FirstStep state={stateFirstStep} setState={setStateFirstStep} />
      ),
    },
    {
      title: t("request.steps.step2"),
      content: (
        <SecondStep state={stateSecondStep} setState={setStateSecondStep} />
      ),
    },
    {
      title: t("request.steps.step3"),
      content: (
        <ThirdStep state={stateThirdStep} setState={setStateThirdStep} />
      ),
    },
  ];

  useEffect(() => {
    var isDis = false;
    if (current === 0) {
      if (!stateFirstStep.name) isDis = true;
      if (!stateFirstStep.surname) isDis = true;
      if (!stateFirstStep.email) isDis = true;
      if (!stateFirstStep.phone) isDis = true;
      if (!stateFirstStep.country && !stateFirstStep.province) isDis = true;
      if (!stateFirstStep.privacy) isDis = true;
      if (!isValidEmail(stateFirstStep.email)) isDis = true;
    }
    setIsDisabled(isDis);
  }, [stateFirstStep]);

  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <div className="!text-white">
      <Steps current={current} items={items} />
      <div>{steps[current].content}</div>
      <div
        className="w-full flex flex-row items-center"
        style={{
          marginTop: 24,
        }}
      >
        <StyleProvider hashPriority="high">
          {current > 0 && (
            <Button
              style={{
                margin: "0 8px",
              }}
              onClick={() => prev()}
            >
              {t("request.buttons.back")}
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button
              type="primary"
              onClick={() => next()}
              disabled={isDisabled}
              className="!ml-auto"
            >
              {t("request.buttons.forward")}
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button
              className="!ml-auto"
              type="primary"
              onClick={() =>
                onConfirm({
                  ...stateFirstStep,
                  ...stateSecondStep,
                  ...stateThirdStep,
                  language: i18n.language.toUpperCase(),
                })
              }
            >
              {t("request.buttons.confirm")}
            </Button>
          )}
        </StyleProvider>
      </div>
    </div>
  );
};
export default PreventivoRequestSteps;
