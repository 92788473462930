import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { CircularProgress } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 10,
  borderRadius: "10px",
  textAlign: "center",
};

export default function LoadingModal(props) {
  return (
    <Modal open={props.open}>
      <Box sx={style}>
        <CircularProgress />
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ paddingBottom: "0" }}
        >
          Stiamo creando i prezzi, non chiudere la finestra
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 2, color: "#8a8a8a" }}
        >
          Ci vorrà solo un minuto ...
        </Typography>
      </Box>
    </Modal>
  );
}
