import { useLocation } from "react-router-dom";
import React from "react";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

export default function SuccessPage() {
  const location = useLocation();
  React.useEffect(() => {
    document.title = "Tutto Ok!";
  }, []);
  return (
    <div
      className="w-screen h-screen flex flex-col 
    text-center items-center justify-center gap-5"
    >
      <CheckCircleOutlined
        className="text-green-600"
        style={{ fontSize: "6rem" }}
      />
      <h3 className="text-3xl font-semibold text-green-600 uppercase">
        grazie
      </h3>
      <p>
        Ti ringraziamo per aver acquistato con noi!<br></br>
        Abbiamo inviato una mail all'indirizzo di fatturazione contenente tutti
        i dettagli della tua transazione.
      </p>
    </div>
  );
}
