import React from "react";
import MessageHelper from "../Helpers/MessageHelper";
import {
  Alert,
  Button,
  Divider,
  Form,
  Tooltip,
  Typography,
  Modal,
  Input,
  Spin,
  Popconfirm,
  Popover,
} from "antd";
import { Card } from "antd";
const { Paragraph } = Typography;

function PreventivoMessage({ preventivo, UpdatePreventivo }) {
  return (
    <Card
      title={
        <div className="flex flex-row items-center gap-3">
          Il tuo messaggio al cliente
          <MessageHelper />
        </div>
      }
      bordered={false}
    >
      <Form
        labelCol={{
          span: 4,
        }}
        layout="horizontal"
      >
        <Paragraph
          editable={{
            autoSize: true,
            tooltip:
              "Modifica il messaggio che il cliente riceverà sul preventivo",
            text: preventivo.message,
            onChange: (new_value) => {
              UpdatePreventivo("15", new_value);
            },
          }}
          className="flex flex-row items-center"
        >
          {preventivo.message ? (
            <div className="text-slate-600">{preventivo.message}</div>
          ) : (
            <div className="flex flex-row items-center gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5 text-slate-400"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                />
              </svg>
              <span className="text-slate-400">Nessun testo inserito</span>
            </div>
          )}
        </Paragraph>
      </Form>
    </Card>
  );
}

export default PreventivoMessage;
