import * as React from "react";
import { Navigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import HorizontalBar from "../../components/layout/horizontalbar";
import Room from "../../components/room/room";
import Calendar from "../../components/calendar/calendar";
import structureService from "../../services/structure.service";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import SkeletonRoom from "../../components/skeleton_room/skeleton-room";
import CircularProgress from "@mui/material/CircularProgress";
import Fab from "@mui/material/Fab";
import DynamicHeroIcon from "../../components/heroicons/hicon";
import Badge from "@mui/material/Badge";
import Drawer from "@mui/material/Drawer";
import Swal from "sweetalert2";
import Flickity from "react-flickity-component";
import { IconButton, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/it";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import { GetLabel } from "../../languages/i18n";
import useExternalScripts from "../../components/useExternalScripts";
import Packets from "../../components/packets/Packets";
import "animate.css";

const urlParams = new URLSearchParams(window.location.search);
function makeid(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
export default function Search() {
  const { json, uuid } = useParams();
  const Swal = require("sweetalert2");

  const [rooms, setRooms] = React.useState([]);
  const [isSearching, setSearching] = React.useState(true);
  const [isStructureLoading, setStructureLoading] = React.useState(false);
  const [isOpenCart, setIsOpenCart] = React.useState(false);
  const [structureInfo, setStructureInfo] = React.useState({
    structure_background: "",
    structure_name: "",
    structure_address: "",
    structure_location: "",
    structure_logo: "",
    structure_phone: "",
    structure_email: "",
    uuid: null,
  });
  const [isLoading, setIsLoading] = React.useState(true);
  const [searchBase, setSearchBase] = React.useState({
    date_from: "",
    date_to: "",
    promo_code: "",
    rooms: [],
    uuid: "",
    lang: "",
  });
  const [cart, setCart] = React.useState([]);
  const [tax, setTax] = React.useState([]);
  const [dates, setDates] = React.useState({
    start_date: null,
    end_date: null,
  });
  const [isLoadingCart, setIsLoadingCart] = React.useState(false);
  const [removedItems, setRemovedItems] = React.useState([]);

  const [total, setTotal] = React.useState(0);
  const [language, setLanguage] = React.useState("IT");
  const [lastIndex, setLastIndex] = React.useState({
    lastIndex: 0,
    has_more_rooms: false,
    do_new_search: false,
  });
  const [isLoadingMoreRooms, setIsLoadingMoreRooms] = React.useState(false);

  let navigate = useNavigate();
  useExternalScripts(
    "https://cdn-cookieyes.com/client_data/a93ae9b855bbb163b3634c57/script.js"
  );
  React.useEffect(() => {
    setIsLoading(true);

    if (json) {
      var jj = base64_decode(json);
      var new_json = JSON.parse(jj);
      setSearchBase(new_json);
      getStructureInfo(new_json.uuid);
      if (new_json.lang) setLanguage(new_json.lang.toUpperCase());
    } else {
      if (!uuid)
        window.location.replace("/Home/search1/" + urlParams.get("uuid"));
      setSearchBase({
        date_from: moment().add(1, "days").format("DD/MM/YYYY"),
        date_to: moment().add(3, "days").format("DD/MM/YYYY"),
        promo_code: "",
        rooms: [
          {
            id: "room_0",
            adults: 2,
            childs: [],
          },
        ],
        uuid: uuid,
        lang: "IT",
      });
      getStructureInfo(uuid);
    }

    const old_cart = JSON.parse(localStorage.getItem("cart"));
    if (old_cart) {
      setCart(old_cart);
      setTotal(0);
      var cart_total = 0;
      old_cart.map((item, index) => {
        if (item.price)
          cart_total = parseFloat(cart_total) + parseFloat(item.price);
      });
      setTotal(cart_total);
    }
  }, []);

  React.useEffect(() => {
    setIsLoading(false);
  }, [searchBase]);

  const getStructureInfo = async (uuid_pass) => {
    setStructureLoading(true);
    structureService.getStructureInfo(`${uuid_pass}`).then(
      (result) => {
        result = result.data;
        setStructureInfo(result);
        setStructureLoading(false);
      },
      (error) => {}
    );
  };

  function addCart(room, goto_payment, isRefundable) {
    // controllo se la stessa configurazione non è già selezionata per le stesse date
    var rooms_to_check = [];
    const current_cart_0 = JSON.parse(localStorage.getItem("cart"));
    if (current_cart_0 && current_cart_0.length > 0) {
      current_cart_0.map((item, index) => {
        rooms_to_check.push({
          date_from: item.start_date,
          date_to: item.end_date,
          id_category: item.id_category,
        });
      });
    }
    rooms_to_check.push({
      date_from: searchBase.date_from,
      date_to: searchBase.date_to,
      id_category: room.id_category,
    });
    structureService
      .checkRoomAvailability(`${searchBase.uuid}`, rooms_to_check)
      .then(
        (result) => {
          result = result.data;
          if (result.success) {
            if (result.model) {
              if (goto_payment === false) {
                Swal.fire({
                  title: GetLabel(language, "LABEL_TITLE_ROOM_ADDED_CART"),
                  imageUrl:
                    "https://cdn.dribbble.com/users/1465772/screenshots/5726748/____dri.gif",
                  imageWidth: 290,
                  imageHeight: 200,
                  text: GetLabel(language, "LABEL_ROOM_ADDED_CART"),
                  confirmButtonText: "Ok",
                });
              }
              room.id = makeid(8);
              room.start_date = searchBase.date_from;
              room.end_date = searchBase.date_to;

              // if (isRefundable) {
              //   room.isNotRefundable = true;
              // } else room.isNotRefundable = false;

              if (goto_payment && searchBase.rooms.length === 1) {
                setCart([room]);
                localStorage.removeItem("cart");
              } else setCart([...cart, room]);

              var new_cart = JSON.parse(localStorage.getItem("cart"));
              if (new_cart) new_cart.push(room);
              else {
                new_cart = [];
                new_cart.push(room);
              }
              localStorage.setItem("cart", JSON.stringify(new_cart));

              setTotal(parseFloat(total) + parseFloat(room.price));

              if (goto_payment) CreateTempBooking(isRefundable);
            } else {
              Swal.fire({
                title: GetLabel(language, "LABEL_ERROR_ALERT_1"),
                icon: "error",
                imageWidth: 290,
                imageHeight: 200,
                text: GetLabel(language, "LABEL_BODY_ERROR_ALERT_1"),
                confirmButtonText: "Ok",
              });
            }
          }
        },
        (error) => {}
      );
  }

  const removeItemCart = (item) => {
    var new_cart = JSON.parse(localStorage.getItem("cart"));
    new_cart = new_cart.filter((el) => el.id !== item.id);
    setCart(cart.filter((el) => el.id !== item.id));
    localStorage.setItem("cart", JSON.stringify(new_cart));
    setTotal(parseFloat(total) - parseFloat(item.price));
  };

  const CreateTempBooking = (isRefundable) => {
    setIsLoading(true);

    var save_cart = JSON.parse(localStorage.getItem("cart"));
    if (save_cart) {
      save_cart = save_cart.map((item, index) => {
        item.date_from = item.start_date;
        item.date_to = item.end_date;
        item.discount_code = searchBase.promo_code;
        return item;
      });

      let canRefund = isRefundable ? "/true" : "";

      structureService
        .createTempBooking(
          searchBase.uuid + "/" + language + canRefund,
          save_cart
        )
        .then(
          (result) => {
            result = result.data;
            if (result.success === true)
              navigate(
                "/pay/" + searchBase.uuid + "/" + result.model.booking.link
              );
            else {
              setIsLoading(false);
            }
          },
          (error) => {}
        );
    } else {
    }
  };

  const openCart = () => {
    setIsOpenCart(true);
    setIsLoadingCart(true);
    var current_cart = JSON.parse(localStorage.getItem("cart"));
    if (current_cart.length > 0) {
      current_cart.map((item, index) => {
        CheckAvailability(
          item,
          item.start_date,
          item.end_date,
          item.id_category,
          index,
          current_cart.length
        );
      });
    } else setIsLoadingCart(false);
  };

  const CheckAvailability = (
    item,
    start_date,
    end_date,
    id_category,
    index,
    max
  ) => {
    var obj_category = {
      id_category: id_category,
      date_from: start_date,
      date_to: end_date,
    };
    structureService.checkAvailability(searchBase.uuid, obj_category).then(
      (result) => {
        result = result.data;
        if (result.success === true) {
          var is_available = result.model;
          var current_cart = JSON.parse(localStorage.getItem("cart"));
          if (!is_available) {
            removeItemCart(item);
            setRemovedItems([...removedItems, item]);
          }
          if (index === max - 1) setIsLoadingCart(false);
        } else {
          setIsLoading(false);
        }
      },
      (error) => {}
    );
  };

  const handleDrawerClose = () => {
    //setRemovedItems([])
    setIsOpenCart(false);
  };

  return (
    <div
      className="bg-body"
      style={{
        background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('${structureInfo.structure_background}')`,
        minHeight: "100vh",
      }}
    >
      <Drawer
        anchor={"right"}
        open={isOpenCart}
        onClose={handleDrawerClose}
        sx={{
          "& .MuiDrawer-paper": {
            xs: {
              width: "100%",
            },
            sm: {
              width: "500px",
            },
          },
        }}
      >
        {isLoadingCart ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "10px",
                paddingBottom: "10px",
                borderBottom: "1px solid #E2E8F0",
              }}
            >
              <h3 style={{ margin: 0, marginLeft: "20px" }}>
                {GetLabel(language, "LABEL_YOUR_CART")}
              </h3>
              <IconButton
                sx={{
                  marginLeft: "auto",
                  height: "40px",
                  marginRight: "20px",
                }}
                onClick={handleDrawerClose}
              >
                <DynamicHeroIcon
                  icon={"XIcon"}
                  className="input-icon"
                ></DynamicHeroIcon>
              </IconButton>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100vh",
              }}
            >
              <div
                style={{
                  padding: "15px",
                  paddingBottom: "0px !important",
                  paddingRight: "15px !important",
                }}
              >
                {removedItems.length > 0 && (
                  <>
                    {removedItems.map((item) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: "20px",
                            marginTop: "0",
                            border: "1px solid red",
                            borderRadius: "10px",
                            backgroundColor: "rgb(255 239 239)",
                            padding: "10px",
                          }}
                        >
                          <DynamicHeroIcon
                            icon="ExclamationIcon"
                            style={{
                              width: "48px",
                              height: "48px",
                              color: "red",
                            }}
                          ></DynamicHeroIcon>
                          <p
                            style={{
                              marginTop: "0",
                              marginBottom: "0",
                              color: "#c92c2c",
                              marginLeft: "10px",
                            }}
                          >
                            {GetLabel(language, "LABEL_ROOM_REMOVED_APARTMENT")}
                            <b>{item.roomName}</b>{" "}
                            {GetLabel(language, "LABEL_FROM_DAY")}{" "}
                            <b>{item.start_date}</b>{" "}
                            {GetLabel(language, "LABEL_TO_DAY")}{" "}
                            <b>{item.end_date + " "}</b>
                            {GetLabel(language, "LABEL_ROOM_REMOVED")}
                          </p>
                        </div>
                      );
                    })}
                  </>
                )}
                {JSON.parse(localStorage.getItem("cart")) &&
                JSON.parse(localStorage.getItem("cart")).length > 0 ? (
                  JSON.parse(localStorage.getItem("cart")).map(
                    (item, index) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: "#f2f2f2",
                          borderRadius: "8px",
                          padding: "15px",
                          marginBottom: "20px",
                          minHeight: "100px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div style={{ width: "150px" }}>
                            {item.images && item.images.length > 0 && (
                              <Flickity options={{ lazyLoad: true }}>
                                {item.images.map((image, i) => {
                                  return (
                                    <div
                                      style={{
                                        background: "url(" + image.image + ")",
                                      }}
                                      className="carousel-img-cart"
                                    ></div>
                                  );
                                })}
                              </Flickity>
                            )}
                          </div>
                          <div style={{ width: "auto", marginLeft: "20px" }}>
                            <h3 style={{ margin: 0 }}>{item.roomName}</h3>
                            <p style={{ margin: 0, color: "rgb(169 169 169)" }}>
                              {item.tabName}
                            </p>
                            <p style={{ margin: 0, marginTop: "10px" }}>
                              {GetLabel(language, "LABEL_FROM_DAY_SHORT")}{" "}
                              {item.start_date}{" "}
                              {GetLabel(language, "LABEL_TO_DAY")}{" "}
                              {item.end_date}
                            </p>
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <Tooltip
                                title={GetLabel(
                                  language,
                                  "LABEL_SEARCH_ADULTI"
                                )}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginRight: "20px",
                                    color: "rgb(102 102 102)",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={"male"}
                                    className="recap-icon"
                                  />
                                  <p
                                    className="adults"
                                    style={{ marginLeft: "5px" }}
                                  >
                                    {" "}
                                    x {item.guests}
                                  </p>
                                </div>
                              </Tooltip>
                              <Tooltip
                                title={GetLabel(
                                  language,
                                  "LABEL_SEARCH_BAMBINI"
                                )}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginRight: "20px",
                                    color: "rgb(102 102 102)",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={"child"}
                                    className="recap-icon-small"
                                  />
                                  <p
                                    className="adults"
                                    style={{ marginLeft: "5px" }}
                                  >
                                    {" "}
                                    x {item.childs}
                                  </p>
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "30px",
                              marginLeft: "auto",
                              marginTop: "-10px",
                            }}
                          >
                            <Tooltip
                              title={GetLabel(
                                language,
                                "LABEL_REMOVE_FROM_CART"
                              )}
                            >
                              <IconButton
                                sx={{ marginLeft: "auto" }}
                                onClick={() => removeItemCart(item)}
                              >
                                <DynamicHeroIcon
                                  icon={"TrashIcon"}
                                  className="input-icon"
                                ></DynamicHeroIcon>
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                          }}
                        >
                          <h2 style={{ margin: 0, marginTop: "-20px" }}>
                            € {parseFloat(item.price).toFixed(2)}
                          </h2>
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <DynamicHeroIcon
                      icon={"SparklesIcon"}
                      className="input-icon"
                      style={{ width: "40px", height: "40px" }}
                    ></DynamicHeroIcon>
                    <p
                      style={{
                        margin: 0,
                        marginTop: "10px",
                        fontSize: "20px",
                        width: "100%",
                        textAlign: "center",
                      }}
                      className="input-icon"
                    >
                      {GetLabel(language, "LABEL_OPS_EMPTY")}
                    </p>
                    <p
                      style={{
                        margin: 0,
                        marginTop: "10px",
                        fontSize: "14px",
                        width: "100%",
                        textAlign: "center",
                      }}
                      className="input-icon"
                    >
                      {GetLabel(language, "LABEL_ADD_ROOM_PLACEHOLDER")}
                    </p>
                  </div>
                )}
              </div>
              <div
                style={{
                  marginTop: "auto",
                  backgroundColor: "#F1F5F9",
                  padding: "16px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h4 style={{ margin: 0, fontSize: "25px", textAlign: "right" }}>
                  <span
                    style={{
                      fontWeight: "normal",
                      marginRight: "20px",
                      fontSize: "22px",
                    }}
                  >
                    {GetLabel(language, "LABEL_TOTAL")}
                  </span>{" "}
                  € {parseFloat(total).toFixed(2)}
                </h4>

                {total > 0 && (
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    sx={{
                      width: "250px",
                      fontSize: "14px !important",
                      fontWeight: "500 !important",
                      marginLeft: "auto",
                      marginTop: "15px",
                    }}
                    className="btn-primary"
                    loading={isLoading}
                    onClick={CreateTempBooking}
                    loadingPosition="start"
                    color="primary"
                  >
                    {GetLabel(language, "LABEL_GOTO_PAYMENT")}
                    <DynamicHeroIcon
                      icon={"ArrowRightIcon"}
                      className="input-icon"
                      style={{ color: "#FFF", marginLeft: "5px" }}
                    ></DynamicHeroIcon>
                  </LoadingButton>
                )}
              </div>
            </div>
          </>
        )}
      </Drawer>
      <HorizontalBar
        structure_name={structureInfo.structure_name}
        structure_address={structureInfo.structure_address}
        structure_location={structureInfo.structure_location}
        structure_logo={structureInfo.structure_logo}
        structure_phone={structureInfo.structure_phone}
        structure_email={structureInfo.structure_email}
        isStructureLoading={isStructureLoading}
        uuid={structureInfo.uuid}
        key={structureInfo.uuid}
        setLanguage={setLanguage}
        language={language}
      ></HorizontalBar>

      <Grid container spacing={2} sx={{ padding: "10px" }}>
        <Grid item xl={3} md={5} xs={12}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Calendar
              isSearching={isSearching}
              setSearching={setSearching}
              isStructureLoading={isStructureLoading}
              searchBase={searchBase}
              setSearchBase={setSearchBase}
              roomsSearch={rooms}
              setRoomsSearch={setRooms}
              tax={tax}
              setTax={setTax}
              setDates={setDates}
              dates={dates}
              language={language}
              lastIndex={lastIndex}
              setLastIndex={setLastIndex}
              setIsLoadingMoreRooms={setIsLoadingMoreRooms}
            ></Calendar>
          )}
        </Grid>
        <Grid item xl={7} md={7} xs={12} className="room-container">
          {isSearching === false ? (
            <div className="flex flex-col gap-2">
              <Packets language={language} uuid={structureInfo.uuid} />
              {rooms.length === 1 && rooms[0].gg_min !== null ? (
                <Card>
                  <CardContent>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={2}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <img src="/assets/images/no-calendar.png" />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={10}
                        sx={{
                          // display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Informiamo la gentile clientela che per le date indicate
                        la struttura richiede un soggiorno minimo di{" "}
                        <b>{rooms[0].gg_min}</b> notti. Vi preghiamo di
                        rieffettuare la ricerca o di contattarci ai seguenti
                        recapiti:
                        <br />
                        <b>Tel</b>:{" "}
                        <a
                          style={{
                            color: "#007bff",
                            textDecoration: "none",
                          }}
                          onMouseOver={(e) =>
                            (e.target.style.textDecoration = "underline")
                          }
                          onMouseOut={(e) =>
                            (e.target.style.textDecoration = "none")
                          }
                          href={`tel:${structureInfo.structure_phone}`}
                        >
                          {" "}
                          {structureInfo.structure_phone}
                        </a>{" "}
                        <br />
                        <b>Email</b>:{" "}
                        <a
                          style={{
                            color: "#007bff",
                            textDecoration: "none",
                          }}
                          onMouseOver={(e) =>
                            (e.target.style.textDecoration = "underline")
                          }
                          onMouseOut={(e) =>
                            (e.target.style.textDecoration = "none")
                          }
                          href={`mailto:${structureInfo.structure_email}`}
                        >
                          {structureInfo.structure_email}
                        </a>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ) : rooms.length > 0 ? (
                rooms.map((room_group, index_room) => {
                  return (
                    <Room
                      crooms={rooms}
                      rooms={room_group.rooms}
                      setRooms={setRooms}
                      isSearching={isSearching}
                      setSearching={setSearching}
                      uuid={structureInfo.uuid}
                      addCart={addCart}
                      tax={tax}
                      language={language}
                      nrooms={searchBase.rooms.length}
                      idCategory={room_group.id_category}
                      index_room={index_room}
                    ></Room>
                  );
                })
              ) : (
                <Card>
                  <CardContent>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={2}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <img src="/assets/images/no-calendar.png" />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={10}
                        sx={{
                          // display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {GetLabel(language, "LABEL_NO_ROOMS_FOUND")}
                        <br />
                        <b>Tel</b>: {structureInfo.structure_phone} <br />
                        <b>Email</b>: {structureInfo.structure_email}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              )}
            </div>
          ) : (
            <>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "20px",
                  }}
                >
                  <CircularProgress sx={{ color: "#FFF" }} />
                </Grid>
              </Grid>
              <Card sx={{ borderRadius: "10px", marginBottom: "20px" }}>
                <CardContent>
                  <SkeletonRoom></SkeletonRoom>
                </CardContent>
              </Card>
              <Card sx={{ borderRadius: "10px", marginBottom: "20px" }}>
                <CardContent>
                  <SkeletonRoom></SkeletonRoom>
                </CardContent>
              </Card>
              <Card sx={{ borderRadius: "10px", marginBottom: "20px" }}>
                <CardContent>
                  <SkeletonRoom></SkeletonRoom>
                </CardContent>
              </Card>
              <Card sx={{ borderRadius: "10px", marginBottom: "20px" }}>
                <CardContent>
                  <SkeletonRoom></SkeletonRoom>
                </CardContent>
              </Card>
            </>
          )}

          {isLoadingMoreRooms ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <CircularProgress sx={{ color: "#FFF" }} />
            </div>
          ) : (
            <>
              {lastIndex.lastIndex % 5 === 0 &&
                lastIndex.has_more_rooms &&
                !isSearching && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      setLastIndex({ ...lastIndex, do_new_search: true });
                    }}
                  >
                    <div
                      style={{
                        padding: "15px",
                        backgroundColor: "white",
                        color: "#000",
                        width: "auto",
                        borderRadius: "10px",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        class="w-5 h-5"
                        style={{ color: "#444444" }}
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                      Mostra di più
                    </div>
                  </div>
                )}
            </>
          )}
        </Grid>
        <Grid item xl={2} md={0} s={12}></Grid>
      </Grid>
      {(searchBase.rooms.length > 1 ||
        (JSON.parse(localStorage.getItem("cart")) &&
          JSON.parse(localStorage.getItem("cart")).length > 0)) && (
        <Fab
          color="primary"
          aria-label="add"
          className="cart-fav"
          onClick={openCart}
        >
          <Badge
            badgeContent={
              JSON.parse(localStorage.getItem("cart"))
                ? JSON.parse(localStorage.getItem("cart")).length
                : 0
            }
            color="error"
          >
            <DynamicHeroIcon
              icon={"ShoppingCartIcon"}
              className="cart-icon"
            ></DynamicHeroIcon>
          </Badge>
        </Fab>
      )}
    </div>
  );
}
