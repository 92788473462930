import React from "react";
import TextField from "@mui/material/TextField";

export default function TextInput(props) {
  return (
    <div style={props.containerstyles}>
      {props.label && <span className="custom-input-label">{props.label}</span>}
      <input
        type={props.type ? props.type : "text"}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        className="custom-input"
        placeholder={props.placeholder}
        {...props}
      />
    </div>
  );
}
