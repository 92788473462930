import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function InputText(props){
    return(
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '100%'},
            }}
            noValidate
            autoComplete="off"
        >
            <TextField
                error={props.is_invalid}
                label={props.label}
                defaultValue={props.value}
                helperText={props.error}
                onChange={props.onChange}
            />
        </Box>
    )
}