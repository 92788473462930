import React from "react";
import webService from "../../services/web.service";
import LoadingIndicator from "../../components/LoadingIndicator";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import dayjs from "dayjs";
import { Button, DatePicker, Divider, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import { StyleProvider } from "@ant-design/cssinjs";

function Services({ language }) {
  const { token, id_config } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [services, setServices] = React.useState([]);

  React.useEffect(() => {
    if (id_config) fetchService();
  }, [language]);

  const fetchService = async () => {
    webService.getServices(token, id_config, language.value).then(
      (result) => {
        setServices(result.data);
        setLoading(false);
      },
      (error) => {
        toast.error("Si è verificato un errore");
        setIsDisabled(false);
      }
    );
  };

  const saveServices = () => {
    webService.saveEventsExtra(token, id_config, language.value, services).then(
      (result) => {
        if (result.status === 200)
          toast.success("I servizi sono stati salvati");
        setLoading(false);
      },
      (error) => {
        toast.error("Si è verificato un errore");
        setIsDisabled(false);
      }
    );
  };

  return (
    <>
      <StyleProvider hashPriority="high">
        {loading ? (
          <div className="w-full h-full flex items-center justify-center">
            <LoadingIndicator />
          </div>
        ) : (
          <>
            <div className="bg-gray-100">
              <div className="pt-5 pr-5 w-full flex justify-end gap-4">
                <button
                  className={`rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                  onClick={saveServices}
                  type="button"
                >
                  Salva i servizi
                </button>
                <button
                  className={`rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                  onClick={() => {
                    setServices([
                      ...services,
                      {
                        title_obj: {
                          text: "",
                        },
                        description_obj: {
                          text: "",
                        },
                        image: "",
                      },
                    ]);
                  }}
                  type="button"
                >
                  Nuovo servizio
                </button>
              </div>
              <div className="flex flex-col p-5">
                {services?.map((service, index) => (
                  <>
                    <div className="flex flex-row items-center">
                      <span className="text-xl font-semibold">
                        Servizio #{index + 1}
                      </span>

                      <Button
                        type="primary"
                        danger
                        onClick={() => {
                          setServices(
                            services.filter(
                              (eve) => eve.id_service !== service.id_service
                            )
                          );
                        }}
                        className="ml-auto"
                      >
                        Elimina Servizio
                      </Button>
                    </div>
                    <label>Servizio:</label>
                    <Input
                      value={service.title_obj.text}
                      onChange={(e) =>
                        setServices(
                          services.map((eve, i) => {
                            if (eve.id_service === service.id_service)
                              eve.title_obj.text = e.target.value;
                            return eve;
                          })
                        )
                      }
                    />

                    <label className="mt-6">Descrizione:</label>
                    <Input
                      value={service.description_obj.text}
                      onChange={(e) =>
                        setServices(
                          services.map((eve, i) => {
                            if (eve.id_service === service.id_service)
                              eve.description_obj.text = e.target.value;
                            return eve;
                          })
                        )
                      }
                    />

                    <label className="mt-6">Immagine (URL):</label>
                    <Input
                      value={service.image}
                      onChange={(e) =>
                        setServices(
                          services.map((eve, i) => {
                            if (eve.id_service === service.id_service)
                              eve.image = e.target.value;
                            return eve;
                          })
                        )
                      }
                    />

                    <Divider />
                  </>
                ))}
              </div>
            </div>
          </>
        )}
      </StyleProvider>
    </>
  );
}

export default Services;
