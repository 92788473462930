import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingIndicator from "../../components/LoadingIndicator";
import webService from "../../services/web.service";
import { toast } from "react-hot-toast";
import SlideOver from "./SlideOver";
import AdvisorEdit from "./AdvisorEdit";
import RoomsFormEdit from "./RoomsFormEdit";

function Rooms({ language }) {
  const { token, id_config } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [rooms, setRooms] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [roomEdit, setRoomEdit] = React.useState();

  React.useEffect(() => {
    if (id_config) fetchRooms();
  }, [language]);

  const fetchRooms = async () => {
    setLoading(true);
    webService.getRooms(token, id_config, language.value).then(
      (result) => {
        const status = result.status;
        if (status === 200) {
          setRooms(result.data);
        } else {
          toast.error("Si è verificato un errore");
        }
        setLoading(false);
      },
      (error) => {
        toast.error("Si è verificato un errore");
        setLoading(false);
      }
    );
  };
  const editRoom = async (room) => {
    setOpen(true);
    setRoomEdit(room);
  };
  const createRoom = async () => {
    setRoomEdit({
      id_room: null,
      id_config: id_config,
      id_category: null,
      is_most_requested: false,
      tip: "",
      name: "",
      checkin: "",
      checkout: "",
      price: 0.0,
      tip_obj: {
        id_translation: null,
        group_uid: "",
        language: language.value,
        text: "",
      },
      category: {
        id_category: null,
        name: "",
      },
    });
    setOpen(true);
  };

  const SingleRoom = ({ room }) => (
    <div className="rounded-lg bg-white shadow p-5">
      <div className="flex flex-col">
        <div className="flex flex-row items-center">
          <div className="flex flex-col">
            <h2 className="font-semibold text-lg">{room.name}</h2>
            <div className="flex flex-row items-center">
              <small className="text-gray-400">stanza #{room.id_room}</small>
            </div>
          </div>
          <span
            className="ml-auto cursor-pointer text-indigo-600 
                px-5 py-2 text-base font-medium 
                 hover:text-indigo-700"
            onClick={() => editRoom(room)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
              />
            </svg>
          </span>
        </div>
        <div className="flex flex-row items-center mt-6">
          <div className="font-semibold">Checkin</div>
          <div className="text-gray-500 ml-6">{room.checkin}</div>
        </div>
        <div className="flex flex-row items-center">
          <div className="font-semibold">Checkout</div>
          <div className="text-gray-500 ml-6">{room.checkout}</div>
        </div>
        <div className="flex flex-row items-center">
          <div className="font-semibold">Prezzo</div>
          <div className="text-green-600 text-lg ml-6 font-semibold">
            {parseFloat(room.price).toFixed(2)} &euro;
          </div>
        </div>
        <div className="flex flex-row items-center mt-4">
          {room.is_most_requested && (
            <div className="text-sm px-4 rounded-full bg-yellow-50 text-yellow-600 font-normal">
              La più richiesta
            </div>
          )}
          {room.tip_obj && room.tip_obj.text && (
            <div className="text-sm px-4 rounded-full bg-green-50 text-green-600 ml-1 font-normal">
              {room.tip_obj.text}
            </div>
          )}
        </div>
      </div>
    </div>
  );
  return (
    <>
      <SlideOver open={open} setOpen={setOpen}>
        <RoomsFormEdit
          room={roomEdit}
          setRoom={setRoomEdit}
          rooms={rooms}
          setRooms={setRooms}
          setOpen={setOpen}
          language={language}
        />
      </SlideOver>

      {loading ? (
        <div className="w-full h-full flex items-center justify-center">
          <LoadingIndicator />
        </div>
      ) : (
        <div className="bg-gray-100">
          <div className="pt-5 pr-5 w-full flex justify-end">
            <button
              className={`rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
              onClick={createRoom}
              type="button"
            >
              Nuova Stanza
            </button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 p-5">
            {rooms?.map((room, index) => (
              <SingleRoom room={room} key={"room-" + index} />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default Rooms;
