import { PhotographIcon, UserCircleIcon } from "@heroicons/react/solid";
import React from "react";
import webService from "../../services/web.service";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import CategorySelect from "./CategoriesSelect";

export default function RoomsFormEdit({
  room,
  rooms,
  setRoom,
  setRooms,
  setOpen,
  language,
}) {
  const [isDisabled, setIsDisabled] = React.useState(false);
  const { token, id_config } = useParams();

  React.useEffect(() => {
    var isDis = false;
    // if (!advisor.title_text_obj.text) isDis = true;
    // if (!advisor.translation_text_obj.text) isDis = true;
    // if (!advisor.action_text_obj.text) isDis = true;
    // if (!advisor.action_url) isDis = true;
    setIsDisabled(isDis);
  }, [room]);

  const saveRoom = async () => {
    setIsDisabled(true);
    if (room.id_room) {
      webService.patchRoom(token, id_config, language.value, room).then(
        (result) => {
          const status = result.status;
          if (status === 200) {
            setRooms(
              rooms.map((item) => {
                if (item.id_room === room.id_room) return result.data;
                return item;
              })
            );
            toast.success("La stanza è stata salvata correttamente.");
          } else {
            toast.error("Si è verificato un errore");
          }
          setIsDisabled(false);
        },
        (error) => {
          toast.error("Si è verificato un errore");
          setIsDisabled(false);
        }
      );
    } else {
      webService.saveRoom(token, id_config, language.value, room).then(
        (result) => {
          const status = result.status;
          if (status === 201) {
            setRooms([...rooms, result.data]);
            toast.success("La stanza è stata salvata correttamente.");
          } else {
            toast.error("Si è verificato un errore");
          }
          setIsDisabled(false);
          setOpen(false);
        },
        (error) => {
          toast.error("Si è verificato un errore");
          setIsDisabled(false);
        }
      );
    }
  };

  const deleteRoom = async () => {
    setIsDisabled(true);
    webService.deleteRoom(token, room.id_room).then(
      (result) => {
        const status = result.status;
        if (status === 200) {
          toast.success("La stanza è stata eliminata.");
          setRooms(rooms.filter((el) => el.id_room !== room.id_room));
          setOpen(false);
        } else {
          toast.error("Si è verificato un errore");
        }
        setIsDisabled(false);
      },
      (error) => {
        toast.error("Si è verificato un errore");
        setIsDisabled(false);
      }
    );
  };

  const getPriceCategory = async (e) => {
    if (e.id_category) {
      webService.getPriceCategory(token, e.id_category).then(
        (result) => {
          result = result.data[0];
          setRoom({
            ...room,
            id_category: e.id_category,
            category: { name: e.name, id_category: e.id_category },
            price: result.price,
          });
        },
        (error) => {
          toast.error("Si è verificato un errore");
        }
      );
    } else {
      setRoom({
        ...room,
        id_category: e.id_category,
        category: { name: e.name, id_category: e.id_category },
      });
    }
  };

  return (
    <form>
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-lg font-semibold leading-7 text-gray-900">
            {room.id_room ? "Modifica Stanza" : "Nuova Stanza"}
          </h2>
          <div className="mt-10 flex flex-col gap-6">
            <div className="w-full">
              <div className="relative flex gap-x-3">
                <div className="flex h-6 items-center">
                  <input
                    id="candidates"
                    name="candidates"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    value={room.is_most_requested}
                    onChange={(e) =>
                      setRoom({
                        ...room,
                        is_most_requested: e.target.checked,
                      })
                    }
                    checked={room.is_most_requested}
                  />
                </div>
                <div className="text-sm leading-6">
                  <label
                    htmlFor="candidates"
                    className="font-medium text-gray-900"
                  >
                    Questa stanza è una "più richiesta"?
                  </label>
                  <p className="text-gray-500">
                    Spunta questa casella se questa stanza risulta essere tra le
                    più richieste e ambite dai tuoi clienti.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-6 flex flex-col gap-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div className="w-full">
                  <label
                    htmlFor="title-advisor"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Orario di Checkin
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        type="text"
                        id="title-advisor"
                        className="ml-2 block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Es. 10:00"
                        value={room.checkin}
                        onChange={(e) =>
                          setRoom({
                            ...room,
                            checkin: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <label
                    htmlFor="title-advisor"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Orario di Checkout
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        type="text"
                        id="title-advisor"
                        className="ml-2 block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Es. 15:00"
                        value={room.checkout}
                        onChange={(e) =>
                          setRoom({
                            ...room,
                            checkout: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <CategorySelect
                  value={room.category}
                  onChange={(e) => {
                    getPriceCategory(e);
                  }}
                  language={language}
                />
              </div>
            </div>
            <div className="w-full">
              <label
                htmlFor="title-advisor"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Prezzo{" "}
                <small>* Modificabile dal listino prezzi di IdaWeb</small>
              </label>
              <div className="mt-2">
                <div
                  className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 
                focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md bg-gray-50"
                >
                  <input
                    type="text"
                    className="pointer-events-none bg-gray-50
                    ml-2 block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-500 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    readonly
                    disabled
                    value={parseFloat(room.price).toFixed(2)}
                  />
                </div>
              </div>
            </div>
            <div className="w-full">
              <label
                htmlFor="title-advisor"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Annuncio per la stanza
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="text"
                    id="title-advisor"
                    className="ml-2 block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="Es. Questo prezzo è solo su nostro sito!"
                    value={room.tip_obj.text}
                    onChange={(e) =>
                      setRoom({
                        ...room,
                        tip_obj: {
                          ...room.tip_obj,
                          text: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        {room.id_room && (
          <button
            type="button"
            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 mr-auto"
            onClick={() => {
              if (window.confirm("Eliminare la stanza corrente?")) {
                deleteRoom();
              }
            }}
          >
            Elimina
          </button>
        )}

        <button
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
          onClick={() => setOpen(false)}
        >
          Annulla
        </button>
        <button
          className={`rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
            isDisabled ? "!pointer-events-none !bg-gray-100 !text-gray-400" : ""
          }`}
          disabled={isDisabled}
          onClick={saveRoom}
        >
          Salva
        </button>
      </div>
    </form>
  );
}
